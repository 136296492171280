export const PERSONAL_FORM = [
  {
    name: 'invoiceEmail',
    label: '收票邮箱',
    placeholder: '请输入收票邮箱',
    required: true,
    rules: [
      {
        required: true,
        message: '请输入收票邮箱',
      },
    ],
  },
  {
    name: 'phone',
    label: '联系电话',
    placeholder: '请输入联系电话',
    required: true,
    rules: [
      {
        required: true,
        message: '请输入联系电话',
      },
    ],
  },
  {
    name: 'invoiceHeaderName',
    label: '抬头名称',
    placeholder: '请输入抬头名称',
    required: true,
    rules: [
      {
        required: true,
        message: '请输入抬头名称',
      },
    ],
  },
  {
    name: 'invoiceHeaderType',
    label: '发票类型',
    hidden: true,
  },
];

export const COMPANY_FORM = [
  {
    name: 'companyTaxNumber',
    label: '单位税号',
    placeholder: '请输入单位税号',
    required: true,
    rules: [
      {
        required: true,
        message: '请输入单位税号',
      },
    ],
  },
  {
    name: 'companyRegisteredAddress',
    label: '注册地址',
    placeholder: '请输入注册地址',
  },
  {
    name: 'companyRegisteredPhone',
    label: '注册电话',
    placeholder: '请输入注册电话',
  },
  {
    name: 'openBank',
    label: '开户银行',
    placeholder: '请输入开户银行',
  },
  {
    name: 'bankAccount',
    label: '银行账户',
    placeholder: '请输入银行账户',
  },
];

export const TICKET_BUTTONS = [
  {
    num: 1,
    name: '暂不开具发票',
    key: 1,
  },
  {
    num: 2,
    name: '电子普通发票',
    key: 2,
  },
];

export const HEADER_BUTTONS = [
  {
    num: 1,
    name: '个人',
    key: 1,
  },
  {
    num: 2,
    name: '企业',
    key: 2,
  },
];

export enum UNIT_TYPE {
  DAY = 1,
  MONTH = 2,
  SEASON = 3,
  YEAR = 4,
  TIME = 5,
}

export const UNIT_TYPE_MAP = {
  [UNIT_TYPE.DAY]: '天',
  [UNIT_TYPE.MONTH]: '月',
  [UNIT_TYPE.SEASON]: '季',
  [UNIT_TYPE.YEAR]: '年',
  [UNIT_TYPE.TIME]: '次',
};
