import Konva from 'konva';
import { useEffect, useState } from 'react';

export default function useBgLayer(bgUrl: string, stage: Konva.Stage) {
  const [layer, setLayer] = useState<any>();

  useEffect(() => {
    if (!stage) return;
    const bgLayer = new Konva.Layer({
      name: 'background',
    });
    setLayer(bgLayer);
    stage.add(bgLayer);
  }, [stage]);

  useEffect(() => {
    if (!bgUrl || !layer) return;
    Konva.Image.fromURL(bgUrl, (img) => {
      const width = img.width();
      const height = img.height();
      const scale = width > height ? 512 / width : 512 / height;
      const finalWidth = width * scale;
      const finalHeight = height * scale;

      img.setAttrs({
        name: 'backgroundImage',
        width: finalWidth,
        height: finalHeight,
      });
      img.cornerRadius(12);
      layer?.destroyChildren();
      layer.add(img);
      layer.batchDraw();
      stage.setAttrs({
        width: finalWidth,
        height: finalHeight,
      });
    });
  }, [bgUrl, layer]);

  return {
    layer,
    toUrl: () =>
      layer.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: 2,
      }),
  };
}
