import { message } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

import { filterNilValue } from './index';
import reLogin from './relogin';

// const DefaultDomain = 'https://www.dejaai.com';
const DefaultDomain = (() => {
  if (['localhost', '192.168.18.122'].includes(window.location.hostname)) {
    return '';
  }
  if (window.location.host.startsWith('dejaai.shentuzn.tech')) {
    return 'http://api.shentuzn.tech';
  }
  return 'https://api.shentuzn.com';
})();
// const DefaultDomain = 'http://192.168.0.63:8888';
// const DefaultDomain = 'https://test.dejaai.com';
// const DefaultDomain = '';

export enum ErrType {
  ServiceErr = 'ServiceErr',
  HttpErr = 'HttpErr',
}

const formatUrl = (url: string = ''): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  if (!url.startsWith('/')) {
    url = '/' + url;
  }

  return DefaultDomain + url;
};

const requestStream = (url: string, data: any) => {
  return axios({
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${
        window.localStorage.getItem('access_token') || 'xxx'
      }`,
    },
    responseType: 'arraybuffer',
    timeout: 60000,
    url: formatUrl(url),
    data,
  });
};

const request = (config: AxiosRequestConfig & { ignore?: boolean }) => {
  let { data } = config;
  if (
    data &&
    (!config.headers?.['Content-Type'] ||
      config.headers?.['Content-Type'] === 'application/json')
  ) {
    data = filterNilValue(data);
  }

  return axios({
    method: 'GET',
    timeout: 60000,
    ...config,
    url: formatUrl(config.url),
    headers: {
      ...config.headers,
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${
        window.localStorage.getItem('access_token') || 'xxx'
      }`,
    },
    ...(config.params
      ? {
          params: filterNilValue(config.params),
        }
      : {}),
    ...(config.data
      ? {
          data,
        }
      : {}),
    validateStatus: (status) => {
      if (status === 401) {
        reLogin();
      }
      return status >= 200 && status < 300;
    },
  })
    .then((res) => {
      const {
        data: { code, data, msg },
        status,
        statusText,
      } = res;
      if (status === 200) {
        if (code === -6) {
          reLogin();
          return;
        }
        if (config?.ignore) {
          return res.data;
        }
        if (code === 200 || code === 0 || code === 10000) {
          return data;
        }
        message.error(msg);
        return Promise.reject({
          errType: ErrType.ServiceErr,
          errCode: code,
          errMsg: msg,
        });
      }
      return Promise.reject({
        errType: ErrType.HttpErr,
        errCode: status,
        errMsg: statusText,
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('catch', err);
      return Promise.reject({
        errType: ErrType.HttpErr,
        ...err,
      });
    });
};

const requestMethodFun =
  (method: string) =>
  (
    url: string,
    data?: any,
    config: Omit<AxiosRequestConfig & { ignore?: boolean }, 'url' | 'data'> = {}
  ) =>
    request({
      ...config,
      url,
      ...(method === 'GET'
        ? {
            params: data,
          }
        : {}),
      ...(method !== 'GET'
        ? {
            data,
          }
        : {}),
      method,
    });

request.get = requestMethodFun('GET');
request.post = requestMethodFun('POST');
request.put = requestMethodFun('PUT');
request.delete = requestMethodFun('DELETE');
request.stream = requestStream;

export default request;
