import { message, Popover, Space, Tooltip } from 'antd';
import React, { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useOrderInfo } from '../../contexts/order-info';
import reLogin from '../../utils/relogin';
import { logout } from './api';
import NavList from './components/nav-list';

import s from './style.module.scss';

type Props = {
  userInfo?: any;
};

const Logout: FC<Props> = ({ userInfo }) => {
  const navigate = useNavigate();
  const { setOrderInfo } = useOrderInfo();

  useEffect(() => {
    if (!window.localStorage.getItem('access_token')) {
      reLogin();
    }
    if (userInfo?.identity === 0) {
      message.error('请先升级到正式版账号，才能登录');
      window.location.href = `/login`;
    }
  }, [userInfo]);
  const handleLogout = () => {
    logout().then(() => {
      window.location.href = `/login?redirect=${window.location.href}`;
      window.localStorage.clear();
    });
  };

  return (
    <div className={s.flex}>
      <div
        className={s['buy-entry']}
        onClick={() => navigate('/serviceShop')}
      ></div>
      <div
        className={s['ai-ban']}
        onClick={() => {
          setOrderInfo({ url: '' });
          navigate('/bantu?serviceType=1');
        }}
      ></div>
      <div className={s.link} onClick={() => navigate('/service')}>
        <span className="font_family icon-a-bangzhuzhongxinzaixiankefu"></span>
        智能客服
      </div>
      <Link to="/userinfo?type=function" className={s.link}>
        <span className="font_family icon-gongnengjieshao"></span>
        功能介绍
      </Link>
      <Popover
        placement="bottomLeft"
        overlayInnerStyle={{
          border: '0.5px inset rgba(255, 255, 255, 0.1)',
          background: '#18181c',
        }}
        content={
          <Space direction="vertical" style={{ width: '305px' }}>
            <div className={s['logo-container']}>
              <div className={s.logo}>{userInfo?.nickName?.slice(0, 1)}</div>
              <div className={s['text-container']}>
                <div className={s['nick-name']}>{userInfo?.nickName}</div>
                {userInfo?.lifeStatus === 10 ? null : (
                  <div key="expire" className={s['small-text']}>
                    {userInfo?.lifeStatus === 0
                      ? '您的账号已过期,'
                      : '您的账号将于'}
                    {userInfo?.lifeStatus === 0 ? (
                      <>
                        请
                        <Tooltip
                          trigger="click"
                          title="客服电话：0571-88761133"
                          placement="bottom"
                        >
                          <Link className={s.link} to="/buy">
                            点击此处
                          </Link>
                        </Tooltip>
                        开通
                      </>
                    ) : (
                      <>{userInfo?.expireTime}到期</>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={s.line}></div>
            <NavList />
            <div className={s.line}></div>
            <div className={s.logout}>
              <div className={s.wrapper} onClick={handleLogout}>
                <div>退出登录</div>
              </div>
            </div>
          </Space>
        }
        trigger="hover"
      >
        <div className={s['user-name']}>{userInfo?.nickName?.slice(0, 1)}</div>
      </Popover>
    </div>
  );
};

export default Logout;
