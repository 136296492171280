import { CaretRightOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { Collapse, Modal } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import Btn from '../../../../components/btn';
import Textarea from '../../../../components/textarea';
import { getFixedPrompt } from './api';

import s from './style.module.scss';

type Props = {
  originPrompt: string;
  onChangePrompt?: (text: string) => void;
};

type Label = {
  id: number;
  children?: Label[];
  word: string;
  [key: string]: any;
};

const FixPrompt: FC<Props> = ({ originPrompt, onChangePrompt }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>(originPrompt);
  const [activeTabId, setActiveTabId] = useState<string | number>('');
  const [allLabels, setAllLabels] = useState<Label[]>([]);
  const [levelLabels, setLevelLabels] = useState<Label[]>([]);
  const [expandKeys, setExpandKeys] = useState<string | string[]>([]);

  useEffect(() => {
    setPrompt(originPrompt);
  }, [originPrompt, open]);

  useEffect(() => {
    getFixedPrompt()
      .then((res: any) => {
        setAllLabels(res);
        setActiveTabId(res?.[0]?.id);
      })
      .catch();
  }, []);

  useEffect(() => {
    const item =
      allLabels.find((item) => item.id === activeTabId)?.children || [];
    setLevelLabels(item);
    setExpandKeys(item?.[0]?.id ? [String(item?.[0]?.id)] : []);
  }, [activeTabId, allLabels]);

  const items: CollapseProps['items'] = [
    ...levelLabels.map((item) => ({
      key: item.id,
      label: <div className={s['collapse-title']}>{item.word}</div>,
      children: (
        <div className={s.collapse}>
          {item.children?.map((tab) => (
            <div
              key={tab.id}
              className={s.tab}
              onClick={() =>
                setPrompt((prompt) =>
                  (prompt + (prompt ? '，' : '') + tab.word).slice(0, 500)
                )
              }
            >
              {tab.word}
            </div>
          ))}
        </div>
      ),
    })),
  ];

  return (
    <div className={s.container}>
      <div className={s.entry} onClick={() => setOpen(true)}>
        <img
          src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/prompt-icon.png"
          className={s.icon}
        />
        灵感词汇
      </div>
      <Modal
        maskClosable={false}
        destroyOnClose
        centered
        width={800}
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <div className={s.footer}>
            <Btn
              buttonType="goast"
              className={s.cancel}
              onClick={() => setOpen(false)}
            >
              取消
            </Btn>
            <Btn
              className={s.ensure}
              onClick={() => {
                onChangePrompt?.(prompt);
                setOpen(false);
              }}
            >
              确认
            </Btn>
          </div>
        }
      >
        <div className={s['modal-title']}>灵感词汇</div>
        <Textarea
          placeholder="请选择标签"
          rows={6}
          maxLength={500}
          value={prompt}
          onChange={setPrompt}
        />

        <div className={s['tabs-container']}>
          <div className={s.tabs}>
            {allLabels.map((tab) => (
              <div
                key={tab.id}
                className={cs(s.tab, { [s.active]: activeTabId === tab.id })}
                onClick={() => setActiveTabId(tab.id)}
              >
                {tab.word}
              </div>
            ))}
          </div>
          <div className={s.clip}>
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              ghost
              items={items}
              activeKey={expandKeys}
              onChange={(keys: string | string[]) => {
                setExpandKeys(keys);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FixPrompt;
