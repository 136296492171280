import Konva from 'konva';
import { useEffect, useState } from 'react';

import { ModeType } from '../types';

export interface IProps {
  stage?: Konva.Stage;
  layer?: Konva.Layer;
  eventRecord: any;
  stroke: number;
  color?: string;
  mode?: string;
}

export default function useUtilLayer({
  layer,
  color,
  stroke,
  mode,
  eventRecord,
}: IProps) {
  const [circle, setCircle] = useState<Konva.Circle>();
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    const circle = new Konva.Circle({
      x: 0,
      y: 0,
      radius: 0,
      fill: 'rgba(100, 81, 231, 0.1)',
      stroke: '#6451e7',
      strokeWidth: 2,
    });
    layer?.add(circle);
    setCircle(circle);
  }, [layer]);

  useEffect(() => {
    if (!eventRecord) return;
    const { eventName, pos } = eventRecord;
    if (eventName === 'mousemove' || eventName === 'touchmove') {
      circle?.setAttrs({
        x: pos?.x,
        y: pos?.y,
        radius: show ? stroke / 2 : 0,
      });
    } else if (eventName === 'mouseout' || eventName === 'touchend') {
      circle?.setAttrs({
        x: 0,
        y: 0,
        radius: 0,
      });
    }
  }, [stroke, eventRecord, show]);

  useEffect(() => {
    if (!circle) return;
    circle.setAttrs({
      fill: mode === ModeType.ERASER ? 'rgba(100, 81, 231, 0.1)' : color,
      stroke: mode === ModeType.ERASER ? '#6451e7' : color,
    });
  }, [color, mode, circle]);

  useEffect(() => {
    if (
      ([ModeType.BRUSH, ModeType.ERASER] as string[]).includes(
        mode || ModeType.SELECT
      )
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [mode]);
}
