import { message, Modal, Pagination, Typography } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Btn from '../../../../components/btn';
import DejaCollectRemark from '../../../../components/deja-collect-remark';
import Download from '../../../../components/download';
import ServiceEntrance from '../../../../components/service-entrance';
import ShareDownloadImage from '../../../../components/share-download-image';
import { UserLevel } from '../../../../constants';
import { useOperateInteraction } from '../../../../contexts/operate-interaction';
import { useUserInfo } from '../../../../contexts/user-info';
import { transImage } from '../../../../utils';
import { listHistory } from '../../../../utils/api';
import { bundleDelete } from './api';

import s from './style.module.scss';

const { Paragraph } = Typography;

const tabs = [
  {
    key: 1,
    name: '全部',
    value: null,
  },
  // {
  //   key: 2,
  //   name: '我的收藏',
  // },
  {
    key: 3,
    name: '设计图',
    value: 3,
  },
  {
    key: 4,
    name: '线稿图',
    value: 2,
  },
  {
    key: 5,
    name: 'AI试衣',
    value: 1,
  },
];

interface IProps {
  isCollect?: boolean;
}

const HistoryList: FC<IProps> = ({ isCollect }) => {
  const divRef = useRef<any>();
  const { userInfo } = useUserInfo();
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [detail, setDetail] = useState<any>(null);
  const [requestPage, setRequestPage] = useState(1);
  const [type, setType] = useState<null | number>(null);
  const [mode, setMode] = useState<'show' | 'edit'>('show');
  const [ids, setIds] = useState<number[]>([]);

  const navigate = useNavigate();
  const { setEditItem } = useOperateInteraction();

  const pageSize = 44;

  const getHistory = () => {
    if (loading) return;
    if (requestPage > total && total !== 0) {
      return;
    }
    setLoading(true);
    listHistory({
      page: requestPage,
      size: pageSize,
      isEdit: 1,
      collectStatus: isCollect ? 1 : 0,
      imageSourceType: type,
    })
      .then((res) => {
        setRequestPage(res.page);
        setTotal(res.records);
        setList(res.rows);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log('err', err);
      });
  };

  const deleteIds = () => {
    if (!ids.length) {
      return message.error('请先选择要删除的作品');
    }
    bundleDelete({ ids })
      .then(() => {
        setList([]);
        setIds([]);
        setRequestPage(1);
        getHistory();
        message.success('删除成功');
      })
      .catch((err: any) => {
        console.log('获取结果错误', err);
      });
  };

  const handleClickListItem = (item: any) => {
    if (mode === 'show') {
      setDetail(item);
      return;
    }
    const idx = ids.findIndex((i) => i === item.id);
    if (idx > -1) {
      setIds(ids.filter((i) => i !== item.id));
    } else {
      setIds([...ids, item.id]);
    }
  };

  useEffect(() => {
    getHistory();
  }, [requestPage, type]);

  const changeDetailStatus = (id: number) => {
    const index = list.findIndex((item) => item.id === id);
    const item = list[index];
    const tempItem = JSON.parse(JSON.stringify(item));
    tempItem.collectStatus = Math.abs(tempItem.collectStatus - 1);
    list.splice(index, 1, tempItem);
    setList(JSON.parse(JSON.stringify(list)));
    if (detail) {
      setDetail(tempItem);
    }
  };

  const icons = [
    {
      key: 'copy',
      icon: 'icon-a-lishizuopinyulanfuzhiguanjianci',
    },
    {
      key: 'download',
      icon: 'icon-a-shengtuyouceanniuchaoqingxiazai',
      render: (item: any) => {
        return (
          <Download
            result={{
              ...item,
              imageUrl: item?.url,
            }}
            btnLoading={false}
          >
            <div className={s['download-wrap']}>
              <div
                className={cs(s.icon, {
                  [s.disabled]: loading,
                })}
              >
                <span className="font_family icon-a-shengtuyouceanniuchaoqingxiazai" />
              </div>
            </div>
          </Download>
        );
      },
    },
    {
      key: 'collect',
      icon: 'icon-a-lishizuopinyulanshoucang',
      render: (item: any) => {
        return (
          <DejaCollectRemark
            collectId={item?.id}
            collectStatus={item?.collectStatus}
            onSuccess={(isSuccess) => {
              if (isSuccess) {
                changeDetailStatus(item.id);
              }
            }}
          >
            <div
              className={cs(s['download-wrap'], {
                [s.active]: !!item?.collectStatus,
              })}
            >
              <div className={s.icon}>
                <span className="font_family icon-a-lishizuopinyulanshoucang" />
              </div>
            </div>
          </DejaCollectRemark>
        );
      },
    },
    {
      key: 'share',
      icon: 'icon-a-fenxiangtupianfenxiang',
      render: (item: any) => {
        return (
          <ShareDownloadImage url={item?.url} btnLoading={false}>
            <div className={s['download-wrap']}>
              <div
                className={cs(s.icon, {
                  [s.disabled]: loading,
                })}
              >
                <span className="font_family icon-a-fenxiangtupianfenxiang" />
              </div>
            </div>
          </ShareDownloadImage>
        );
      },
    },
  ];

  return (
    <div className={s.container}>
      <div
        className={cs(s.wapper, {
          [s.collect]: isCollect,
        })}
      >
        <div className={s.tabWapper}>
          {isCollect ? (
            <div style={{ height: '16px' }}></div>
          ) : (
            <div className={s.tabs}>
              {tabs.map((tab) => (
                <div
                  key={tab.key}
                  className={cs(s.tab, { [s.active]: type === tab.value })}
                  onClick={() => {
                    setType(tab.value);
                    setRequestPage(1);
                    setList([]);
                  }}
                >
                  {tab.name}
                </div>
              ))}
            </div>
          )}
          {!isCollect ? (
            <div className={s.operateWapper}>
              {mode === 'edit' ? (
                <div className={s.operates}>
                  <Btn
                    className={s.btn}
                    onClick={() => {
                      deleteIds();
                    }}
                  >
                    确认删除
                  </Btn>
                  <Btn
                    className={s.btn}
                    buttonType="goast"
                    onClick={() => {
                      setMode('show');
                      setIds([]);
                    }}
                  >
                    取消
                  </Btn>
                </div>
              ) : (
                <Btn
                  icon={
                    <span className="font_family icon-huihuachuangkuan-qingkonghuabu"></span>
                  }
                  className={s.delete}
                  onClick={() => setMode('edit')}
                >
                  删除
                </Btn>
              )}
            </div>
          ) : null}
        </div>
        <div className={s.list}>
          {total === 0 ? (
            <div className={s.empty}>
              <img
                className={s['empty-bg']}
                src={transImage(
                  isCollect
                    ? 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/collect-empty.png'
                    : 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/history/history-empty.png',
                  'webp'
                )}
                alt="empty"
              />
              <div className={s.text}>
                暂无{isCollect ? '收藏数据' : '历史数据'}
              </div>
            </div>
          ) : (
            <div className={s['loop-wrap']}>
              <div
                className={cs(s.loop, {
                  [s.loading]: loading,
                })}
                id="history-list"
                style={{ height: 'calc(100vh - 160px)' }}
                ref={divRef}
              >
                <div className={s['item-list']}>
                  {list.map((item: any) => (
                    <div
                      key={item.id}
                      className={cs(s.item, {
                        [s.chosed]: ids.includes(item.id),
                      })}
                      onClick={() => handleClickListItem(item)}
                    >
                      <div className={s['img-content']}>
                        <img
                          className={s.bg}
                          src={transImage(item?.url, 'webp')}
                          alt="item"
                        />
                        <div>
                          {mode === 'edit' ? (
                            <img
                              src={
                                ids.includes(item.id)
                                  ? 'https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230726/818963fc8f184905975c459874e93f24.png'
                                  : 'https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230726/854880ddb3ef48ac953543715886e6b6.png'
                              }
                              className={s.checkIcon}
                            />
                          ) : null}
                        </div>
                        <div className={s.icons}>
                          {mode !== 'edit'
                            ? icons.map((icon) => (
                                <div
                                  key={icon.key}
                                  className={cs(s['icon-content'], {
                                    [s.active]:
                                      icon.key === icons[2].key &&
                                      item.collectStatus,
                                    [s.none]:
                                      icon.key === icons[0].key &&
                                      !item.originPromptId,
                                  })}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const { key } = icon;
                                    if (key === icons[0].key) {
                                      try {
                                        navigator.clipboard.writeText(
                                          item.originPromptId
                                        );
                                        message.success('复制成功');
                                      } catch (err) {
                                        console.log('复制出错了', err);
                                        message.error('复制出错了');
                                      }
                                    }
                                  }}
                                >
                                  {icon?.render?.(item) || (
                                    <span
                                      className={`font_family ${icon.icon}`}
                                    ></span>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <Paragraph className={s.text} ellipsis={{ rows: 2 }}>
                        {item?.originPromptId}
                      </Paragraph>
                    </div>
                  ))}
                </div>
                <div className={s.pagination}>
                  <Pagination
                    total={total}
                    current={requestPage}
                    pageSize={pageSize}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    showTotal={(total) => {
                      return <div className={s.total}>{`共 ${total} 条`}</div>;
                    }}
                    onChange={(page) => {
                      setRequestPage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        open={!!detail}
        width={820}
        footer={null}
        maskStyle={{
          background: '#111213',
        }}
        modalRender={() => {
          return (
            <div className={s.detail}>
              <div
                className={s.close}
                onClick={() => {
                  setDetail(null);
                }}
              >
                <span className="font_family icon-a-lishijiluerjiyemianchahao" />
              </div>
              <div className={s.wrapper}>
                <div className={s.img}>
                  <img
                    src={detail?.url}
                    alt="detail"
                    className={s['model-image']}
                  />
                  <div
                    className={s.edit}
                    onClick={() => {
                      setEditItem(detail);
                      navigate('/generate/areaModify?tab=1');
                    }}
                  >
                    <span className="font_family icon-a-lishizuopinxiangqingbianjitupian"></span>
                    编辑图片
                  </div>
                </div>
              </div>
              <div className={s.content}>
                <div className={s.title}>
                  设计描述
                  {detail?.originPromptId && (
                    <div
                      className={s.copy}
                      onClick={() => {
                        try {
                          navigator.clipboard.writeText(detail.originPromptId);
                          message.success('复制成功');
                        } catch (err) {
                          console.log('复制出错了', err);
                          message.error('复制出错了');
                        }
                      }}
                    >
                      <span
                        className="font_family icon-a-lishizuopinxiangqingdanchuangfuzhimiaoshu"
                        style={{ marginRight: '4px' }}
                      ></span>
                      复制描述
                    </div>
                  )}
                </div>
                <div className={s.prompt}>{detail?.originPromptId || '无'}</div>
                <div className={s.title}>图案描述</div>
                {detail?.preUrl ? (
                  <div className={s['img-contents']}>
                    <div className={s['img-content']}>
                      <div className={s['img-con']}>
                        <img src={detail?.preUrl} className={s.img} />
                      </div>
                      <div className={s.text}>设计底图</div>
                    </div>
                    {detail?.extraInfo?.blendImgUrl && (
                      <div className={s['img-content']}>
                        <div className={s['img-con']}>
                          <img
                            src={detail?.extraInfo?.blendImgUrl}
                            className={s.img}
                          />
                        </div>
                        <div className={s.text}>设计元素</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={cs(s.desc, s.none)}>无</div>
                )}
                <div className={s.title}>更多信息</div>
                <div className={s.desc}>
                  <span className={s.label}>参考颜色</span>
                  {detail?.colorHex ? (
                    <span
                      className={s.color}
                      style={{ background: `#${detail?.colorHex}` }}
                    ></span>
                  ) : (
                    <span className={s.value}>无</span>
                  )}
                </div>
                <div className={s.desc}>
                  <span className={s.label}>创作时间</span>
                  <span className={s.value}>{detail?.createTime}</span>
                </div>
              </div>
              {userInfo.level === UserLevel.Ultimate && (
                <div className={s.services1}>
                  <ShareDownloadImage url={detail?.url} btnLoading={false}>
                    <div className={s['download-wrap']}>
                      <div
                        className={cs(s.icon, {
                          [s.disabled]: loading,
                        })}
                      >
                        <span className="font_family icon-a-fenxiangtupianfenxiang" />
                      </div>
                      <div>分享</div>
                    </div>
                  </ShareDownloadImage>
                  <Download
                    result={{ ...detail, imageUrl: detail?.url }}
                    btnLoading={false}
                  >
                    <div className={s['download-wrap']}>
                      <div
                        className={cs(s.icon, {
                          [s.disabled]: loading,
                        })}
                      >
                        <span className="font_family icon-a-shengtuyouceanniuchaoqingxiazai" />
                      </div>
                      <div>超清下载</div>
                    </div>
                  </Download>
                  <DejaCollectRemark
                    collectId={detail?.id}
                    collectStatus={detail?.collectStatus}
                    onSuccess={(isSuccess) => {
                      if (isSuccess) {
                        changeDetailStatus(detail?.id);
                      }
                    }}
                  >
                    <div
                      className={cs(s['download-wrap'], {
                        [s.active]: !!detail?.collectStatus,
                      })}
                    >
                      <div className={s.icon}>
                        <span className="font_family icon-a-lishizuopinyulanshoucang" />
                      </div>
                      <div>{detail?.collectStatus ? '取消收藏' : '收藏'}</div>
                    </div>
                  </DejaCollectRemark>
                </div>
              )}
              <ServiceEntrance top={234} left={830} url={detail?.url} />
            </div>
          );
        }}
        onCancel={() => setDetail(null)}
      ></Modal>
    </div>
  );
};

export default HistoryList;
