import Konva from 'konva';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import { ModeType } from '../../components/image-editor';
import { IImageEditorProps } from './type';

const Context = createContext<IImageEditorProps>({} as IImageEditorProps);

export const ImageEditorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stage, setStage] = useState<Konva.Stage>();
  const [bgLayer, setBgLayer] = useState<Konva.Layer>();
  const [shapHideLayer, setShapHideLayer] = useState<Konva.Layer>();
  const [shapLayer, setShapLayer] = useState<Konva.Layer>();
  const [utilLayer, setUtilLayer] = useState<Konva.Layer>();

  const [mode, setMode] = useState<ModeType>(ModeType.BRUSH);

  const [eventRecord, setEventRecord] = useState<any>({});
  const [bgColor, setBgColor] = useState('transparent');
  const [strokeColor, setStrokeColor] = useState('#333');
  const [strokeWidth, setStrokeWidth] = useState(10);
  const [freshTime, setFreshTime] = useState(Date.now());
  // const [layerBgImg, setLayerBgImg] = useState(bgUrl);
  const [layerBgImg, setLayerBgImg] = useState('');

  // 保存所有的节点和状态
  const [history, setHistory] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(history.length ?? 0);
  const [operate, setOperate] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  return (
    <Context.Provider
      value={{
        stage,
        setStage,
        bgLayer,
        setBgLayer,
        shapHideLayer,
        setShapHideLayer,
        shapLayer,
        setShapLayer,
        utilLayer,
        setUtilLayer,
        mode,
        setMode,
        eventRecord,
        setEventRecord,
        bgColor,
        setBgColor,
        strokeColor,
        setStrokeColor,
        strokeWidth,
        setStrokeWidth,
        freshTime,
        setFreshTime,
        layerBgImg,
        setLayerBgImg,
        history,
        setHistory,
        currentIndex,
        setCurrentIndex,
        operate,
        setOperate,
        showRemove,
        setShowRemove,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useImageEditor = (): IImageEditorProps => useContext(Context);
