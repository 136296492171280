import { message } from 'antd';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useRef } from 'react';

import Btn from '../../components/btn';
import DejaGenerateResult from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import { Tabs } from '../../components/modal-image';
import OptUpload from '../../components/opt-upload';
import TextArea from '../../components/textarea';
import {
  draftImageItemAtom,
  draftParamsAtom,
  draftResultAtom,
  draftStatusAtom,
  draftUpperImageItemAtom,
} from '../../store/generation';
import { blendGenerate, blendResult } from '../../utils/api';
import ColorSelect from '../generate/components/colorSelect';
import FixPrompt from '../generate/components/fix-prompt';
import { LabelType } from '../generate/constants';

import s from './style.module.scss';

const DraftGenerate: FC = () => {
  const timer = useRef<NodeJS.Timeout>();

  const [draftStatus, setDraftStatus] = useAtom(draftStatusAtom);
  const [draftParams, setDraftParams] = useAtom(draftParamsAtom);
  const [draftResult, setDraftResult] = useAtom(draftResultAtom);
  const [draftImageItem, setDraftImageItem] = useAtom(draftImageItemAtom);
  const [draftUpperImageItem, setDraftUpperImageItem] = useAtom(
    draftUpperImageItemAtom
  );

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const changePrompt = (value: string) => {
    setDraftParams((prev) => ({ ...prev, prompt: value }));
  };
  const changeColorCode = (value: string) => {
    setDraftParams((prev) => ({ ...prev, colorCode: value }));
  };
  const changeImgUrl = (url: string) => {
    setDraftParams((prev) => ({ ...prev, imgUrl: url }));
  };
  const changeCode = (
    blendImgUrl: string,
    fabricCode: number | string,
    fabricSourceType: number
  ) => {
    setDraftParams((prev) => ({
      ...prev,
      blendImgUrl,
      fabricCode,
      fabricSourceType,
    }));
  };
  const changePromptIdentity = (promptIdentity: string) => {
    setDraftParams((prev) => ({ ...prev, promptIdentity }));
  };

  const changeLoading = (loading: boolean) => {
    setDraftStatus((prev) => ({ ...prev, loading }));
  };

  const generateApi = () => {
    if (!draftParams.imgUrl) {
      message.error('图片不能为空');
      return Promise.reject('图片不能为空');
    }
    changeLoading(true);
    const params: any = {
      imgUrl: draftParams.imgUrl,
      labelType: LabelType.PicDraft,
      prompt: draftParams.prompt,
      colorCode: draftParams.colorCode,
    };
    if (draftParams?.blendImgUrl) {
      params.blendImgUrl = draftParams.blendImgUrl;
      params.fabricSourceType = draftParams.fabricSourceType;
      params.fabricCode = draftParams.fabricCode;
    }
    blendGenerate(params)
      .then(changePromptIdentity)
      .catch(() => {
        changeLoading(false);
      });
  };

  const pollingResultApi = () => {
    if (!draftParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), draftStatus.timing);
      return;
    }
    blendResult({
      labelType: LabelType.PicDraft,
      promptIdentity: draftParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setDraftResult(res);
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            draftStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setDraftResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), draftStatus.timing);
  };

  useEffect(() => {
    draftStatus.loading && !draftResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [draftStatus.loading, draftParams.promptIdentity, draftResult]);

  return (
    <>
      <PageLayout
        left={
          <DejaGenerateResult
            key={draftParams.promptIdentity}
            loading={draftStatus.loading as boolean}
            item={draftResult}
            tipText="选择线稿图进行创意延伸吧!"
          />
        }
      >
        <OperateLayout
          title="线稿创款"
          footer={
            <Btn
              isGenerate
              className={s.button}
              disabled={!draftParams.prompt || !draftParams.imgUrl}
              loading={draftStatus.loading}
              onClick={() => {
                generateApi();
                setDraftResult(null);
              }}
            >
              生成设计图
            </Btn>
          }
        >
          <div className={s.content}>
            <div className={s.box}>
              <div className={s.title}>线稿图</div>
              <OptUpload
                tabs={[Tabs.Drafts, Tabs.Location]}
                imageItem={draftImageItem}
                onChange={(imageItem) => {
                  setDraftImageItem(imageItem);
                  changeImgUrl(imageItem?.cosUrl);
                }}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>面料图</div>
              <OptUpload
                fabricable
                hideOptButton
                imageItem={draftUpperImageItem}
                onChange={(imageItem) => {
                  setDraftUpperImageItem(imageItem);
                  changeCode(
                    imageItem?.imageUrl,
                    imageItem?.code,
                    imageItem?.sourceType
                  );
                }}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>设计描述</div>
              <div className={s['fix-prompt-entry']}>
                <ColorSelect
                  code={draftParams.colorCode}
                  onChange={changeColorCode}
                />
                <FixPrompt
                  originPrompt={draftParams.prompt}
                  onChangePrompt={changePrompt}
                />
              </div>
              <div className={s['area-container']}>
                <TextArea
                  rows={4}
                  value={draftParams.prompt}
                  onChange={changePrompt}
                  changeColor={changeColorCode}
                  maxLength={500}
                  // userInfo={userInfo}
                  placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
                />
              </div>
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </>
  );
};

export default DraftGenerate;
