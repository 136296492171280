import request from '../../../../utils/request';

export const uploadExpand = (data: FormData) => {
  return request.post('/customer/api/customerAccessories/insert', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bundleDelete = (data: { ids: number[] }) => {
  return request.post('/customer/api/customerAccessories/delete', data);
};

export const listExpand = (data: {
  page: number;
  size: number;
  status?: number;
}) => {
  return request.post(
    '/customer/api/customerAccessories/pageListByCustomer',
    data
  );
};
