import { message } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOrderInfo } from '../../contexts/order-info';
import { GoodCategory } from '../../pages/ban-consume/constants';
import { transImage } from '../../utils';

import s from './style.module.scss';

interface IProps {
  top?: number;
  left?: number;
  url?: string;
  id?: string | number;
  isU?: boolean;
}

export const entrances = [
  {
    icon: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/entrance/ban-entrance.png',
    title: 'AI打版',
    key: '1',
    serviceType: GoodCategory.AI,
  },
  {
    icon: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/entrance/xiu-entrance.png',
    title: '样衣制作',
    key: '2',
    serviceType: GoodCategory.STYLE,
  },
  {
    icon: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/entrance/model-entrance.png',
    title: '模特试衣',
    key: '3',
    serviceType: GoodCategory.MODEL,
  },
];

const ServiceEntrance: FC<IProps> = ({ top, url, isU = true, left = 520 }) => {
  const navigate = useNavigate();
  const { setOrderInfo } = useOrderInfo();

  return (
    <div
      className={s.entrances}
      style={{ top: `${top ?? 0}px`, left: `${left ?? 0}px` }}
    >
      {entrances.map((entrance) => {
        return (
          <div
            className={s.entrance}
            key={entrance.key}
            onClick={() => {
              if (!isU) {
                return message.warning('请您先单击选择一张图，再使用此功能');
              }
              navigate(`/bantu?serviceType=${entrance.serviceType}`);
              setOrderInfo({
                url,
              });
            }}
          >
            <img className={s.img} src={transImage(entrance.icon, 'webp')} />
            <div className={s.tip}>{entrance.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceEntrance;
