import { StopOutlined } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import { getColors } from './api';

import s from './style.module.scss';

type ColorItem = {
  code: string;
  id: string;
  hex: string;
  [key: string]: string;
};

type Props = {
  code?: string;
  onChange?: (code: string) => void;
};

const ColorSelect: FC<Props> = ({ code, onChange }) => {
  const dom = useRef<any>();
  const [open, setOpen] = useState(false);
  const [colors, setColors] = useState<ColorItem[]>([]);
  const [colorItem, setItem] = useState<null | ColorItem>(null);

  useEffect(() => {
    getColors()
      .then((res) => {
        setColors(res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    if (code && colors.length) {
      const item = colors.find((cell) => cell.code === code);
      setItem(item as ColorItem);
    } else if (!colors.length) {
      // console.log('没有颜色数据');
    }
    if (!code) {
      setItem(null);
    }
  }, [colors, code]);

  const Content = () => (
    <div className={s.content}>
      <div
        className={s.item}
        onClick={() => {
          onChange?.('');
          setOpen(false);
        }}
      >
        <StopOutlined className={s.cancel} />
      </div>
      {colors.map((item) => (
        <Tag
          className={cs(s.item, s.color, {
            [s.active]: colorItem?.code === item.code,
          })}
          key={item.id}
          color={`#${item.hex}`}
          onClick={() => {
            onChange?.(item.code);
            // setColor(item.code);
            setOpen(false);
          }}
        />
      ))}
    </div>
  );
  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      content={<Content />}
      color="#18191b"
      open={open}
      destroyTooltipOnHide
      getPopupContainer={() => dom.current}
      onOpenChange={(newOpen: boolean) => setOpen(newOpen)}
      overlayStyle={{
        borderRadius: '8px',
        boxShadow: '8px 8px 16px 0px rgba(0,0,0,0.4)',
      }}
    >
      <div className={s.container} ref={dom}>
        {code ? (
          <div
            className={s['selected-color']}
            style={{
              backgroundColor: `#${colorItem?.hex}`,
            }}
          ></div>
        ) : (
          <img
            src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/color-icon.png"
            className={s.icon}
          />
        )}
        色板
      </div>
    </Popover>
  );
};

export default ColorSelect;
