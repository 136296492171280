import request from '../../../../utils/request';

export const getMyOrders = (data: { page: number; size: number }) =>
  request.get(
    `/trade/front/order/getPageList?page=${data.page}&size=${data.size}`
  );

export const getMyServiceOrders = (data: { page: number; size: number }) =>
  request.get(
    `/commercializeOrder/api/front/order/commercializeOrder/pageList?page=${data.page}&size=${data.size}`
  );
