import axios from "axios";
import { IUserInfo } from "../contexts/user-info/types";

const formatNumber = (n: number) => {
  const s = n.toString();
  return s[1] ? s : "0" + s;
};

export const formatTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return (
    [year, month, day].map(formatNumber).join("/") +
    " " +
    [hour, minute, second].map(formatNumber).join(":")
  );
};

export const filterNilValue = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_k, v]) => v !== null && v !== undefined)
  );
};

export const filterUseValue = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v));
};

export const dataURLToBlob = (dataurl: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const tempArr = [];
  for (let i = 0; i < n; i++) {
    tempArr.push(bstr.charCodeAt(i));
  }
  return new Blob([new Uint8Array(tempArr)], { type: mime });
};

export const dataUrlToFile = (dataurl: string, filename: string = "file") => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const throttle = (func: any, wait = 1000) => {
  let context;
  let previous = 0;
  return (...args: any[]) => {
    const now = +new Date();
    context = this;
    if (now - previous > wait) {
      func.apply(context, args);
      previous = now;
    }
  };
};

export const debounce = (
  func: (...args: any[]) => any,
  wait: number
): ((...args: any[]) => void) => {
  let timeout: any = null;

  return (...args: any[]): void => {
    const later = () => {
      timeout = null;
      func(...args);
    };

    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// 转换image
export const transImage = (
  url: string,
  format: "webp" | "png" | "jpg" | "" = "",
  size: string = ""
) => {
  if (!url) return "";
  if (
    url.includes("imageMogr2") ||
    url.startsWith("data:image") ||
    url.startsWith("blob:")
  ) {
    return url;
  }
  let newUrl = url + "?imageMogr2";
  // 转格式
  if (format) {
    newUrl += `/format/${format}`;
  }
  // 调整大小
  if (size) {
    newUrl += `/thumbnail/${size}`;
  }
  // 渐进显示,默认开启，只针对jpg有效
  newUrl += "/interlace/1";
  return newUrl;
};

// a标签下载
export const downloadBtn = async (url: string, name?: string) => {
  const btn = document.createElement("a");
  btn.href = url;
  const filename = url.split("/").at(-1) as string;
  const res = await axios({
    method: "GET",
    url,
    responseType: "blob",
  });
  const newUrl = window.URL.createObjectURL(res.data);
  btn.setAttribute("href", newUrl);
  btn.setAttribute("download", name ?? filename);
  btn.setAttribute("target", "_blank");
  document.body.appendChild(btn);
  btn.click();
  btn.remove();
  window.URL.revokeObjectURL(newUrl);
};

// 需要根据是否联通账号显示不同内容
export const isLianton = (userInfo: IUserInfo) => {
  const liantonArr = ["LT001"];
  return liantonArr.includes(userInfo?.account);
};
