import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { ConfigProvider, InputNumber, Slider } from 'antd';
import Konva from 'konva';
import { FC, useEffect, useRef, useState } from 'react';

import s from './style.module.scss';

export interface IProps {
  fill?: boolean;
  backgroundColor?: string;
  url: string;
  scale: number;
  changeScale: (scale: number) => void;
  rotate: number;
  changeRotate: (rotate: number) => void;
  pos: { x: number; y: number };
  changePos: (pos: { x: number; y: number }) => void;
  changeStage: (stage: Konva.Stage) => void;
}

const BASEPADDING = 8;
const STAGESIZE = 320;

const OperateImage: FC<IProps> = ({
  fill,
  url,
  scale = 100,
  changeScale,
  rotate = 0,
  changeRotate,
  pos,
  changePos,
  changeStage,
  backgroundColor = '#fff',
}) => {
  const canvasRef = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState<Konva.Stage>();
  const [layer, setLayer] = useState<Konva.Layer>();
  const [utilLayer, setUtilLayer] = useState<Konva.Layer>();
  const [img, setImg] = useState<Konva.Image>();

  useEffect(() => {
    if (!url || !canvasRef.current) return;
    const stage = new Konva.Stage({
      container: canvasRef.current,
      width: STAGESIZE,
      height: STAGESIZE,
    });
    setStage(stage);
    const layer = new Konva.Layer({
      name: 'draw',
    });
    stage.add(layer);
    const utilLayer = new Konva.Layer({
      name: 'util',
    });
    stage.add(utilLayer);
    setLayer(layer);
    setUtilLayer(utilLayer);
    const rect = new Konva.Rect({
      width: STAGESIZE,
      height: STAGESIZE,
      fill: backgroundColor,
    });
    layer.add(rect);
  }, [url, canvasRef.current]);

  useEffect(() => {
    if (!url || !layer || !stage) return;
    Konva.Image.fromURL(url, (img) => {
      const { width, height } = img.size();
      const MAX_SIZE = STAGESIZE - BASEPADDING * 2;
      const scale = fill
        ? Math.max(STAGESIZE / width, STAGESIZE / height)
        : Math.min(MAX_SIZE / width, MAX_SIZE / height);
      const imgWidth = width * scale;
      const imgHeight = height * scale;
      img.setAttrs({
        name: 'picture',
        width: imgWidth,
        height: imgHeight,
        offsetX: imgWidth / 2,
        offsetY: imgHeight / 2,
        x: pos.x,
        y: pos.y,
        // x: STAGESIZE / 2,
        // y: STAGESIZE / 2,
        draggable: true,
      });
      setImg(img);
      layer.add(img);
      layer.draw();
    });
  }, [url, layer, stage, fill]);

  useEffect(() => {
    if (!img) return;
    const s = scale / 100;
    img.setAttrs({
      scaleX: s,
      scaleY: s,
    });
  }, [scale, img]);

  useEffect(() => {
    if (!img) return;
    img.rotation(rotate);
  }, [rotate, img]);

  useEffect(() => {
    if (!stage || !utilLayer) return;
    stage.on('mousedown touchstart', (e: any) => {
      e.evt.cancelBubble = true;
      e.evt.preventDefault();
      const name = e.target.name();
      if (name === 'picture') {
        const tr = new Konva.Transformer({
          name: 'transformer',
          centeredScaling: true,
          enabledAnchors: [
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ],
        });
        tr.nodes([e.target]);
        utilLayer?.add(tr);
        utilLayer?.draw();
        tr.on('dragend', (ev: any) => {
          const node = ev.target.nodes()?.[0];
          if (node) {
            const { x, y } = node.getAttrs();
            changePos({ x, y });
          }
        });
        tr.on('transformend', (ev: any) => {
          const { scaleX, rotation } = ev.target.getAttrs();
          changeScale(Math.floor(scaleX * 100));
          changeRotate(Math.floor(rotation));
        });
      } else if (!name.includes('anchor')) {
        stage
          .find('Transformer')
          .forEach((node: Konva.Node) => node?.destroy());
      }
    });
  }, [stage, utilLayer]);

  // dom绑定事件，移出transformer
  useEffect(() => {
    const fn = (e: any) => {
      if (e.target.nodeName === 'CANVAS') {
        return;
      }
      stage?.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
    };
    document.addEventListener('click', fn);
    document.addEventListener('touchstart', fn);
    return () => {
      document.removeEventListener('click', fn);
      document.removeEventListener('touchstart', fn);
    };
  }, [stage]);

  useEffect(() => {
    stage && changeStage?.(stage);
  }, [stage]);

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            controlWidth: 52,
            paddingInline: 8,
          },
          Slider: {
            trackBg: '#6451E7',
            trackHoverBg: '#6451E7',
            handleColor: '#6451E7',
            handleLineWidth: 6,
          },
        },
        token: {
          lineWidth: 1,
          fontSize: 12,
        },
      }}
    >
      <div className={s.container}>
        <div className={s.operator} id="canvas" ref={canvasRef}></div>
        <div className={s['operate-con']}>
          <div className={s['opereate-title']}>画面缩放</div>
          <div className={s['operate-row']}>
            <Slider
              className={s.slider}
              max={300}
              min={10}
              value={scale}
              onChange={(value) => changeScale(value as number)}
            />
            <InputNumber
              controls={false}
              suffix="%"
              min={10}
              max={300}
              value={scale}
              onChange={(value) => changeScale(value as number)}
            />
          </div>
        </div>
        <div className={s['operate-con']}>
          <div className={s['opereate-title']}>画面旋转</div>
          <div className={s['operate-row']}>
            <Slider
              className={s.slider}
              min={-180}
              max={180}
              value={rotate}
              onChange={(value) => changeRotate(value as number)}
            />
            <InputNumber
              controls={false}
              suffix="°"
              min={-180}
              max={180}
              value={rotate}
              onChange={(value) => changeRotate(value as number)}
            />
          </div>
        </div>
        <div className={s['icons-con']}>
          <div className={s.icons}>
            <div className={s['icon-con']}>
              <UndoOutlined
                className={s.icon}
                onClick={() => {
                  const temp = rotate - 90;
                  changeRotate(temp < -180 ? 360 + temp : temp);
                }}
              />
            </div>
            <div className={s['icon-con']}>
              <RedoOutlined
                className={s.icon}
                onClick={() => {
                  const temp = rotate + 90;
                  changeRotate(temp > 180 ? -360 + temp : temp);
                }}
              />
            </div>
          </div>
          <div
            className={s.reset}
            onClick={() => {
              changeRotate(0);
              changeScale(100);
              changePos({
                x: STAGESIZE / 2,
                y: STAGESIZE / 2,
              });
              img?.setAttrs({
                x: STAGESIZE / 2,
                y: STAGESIZE / 2,
              });
            }}
          >
            重置
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default OperateImage;
