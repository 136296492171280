import request from '../../utils/request';
import {
  IDrawGenerateParams,
  IDrawResultParams,
  IRealtimeGenerateParams,
  ISaveDrawResultParams,
} from './types';

export const realtimeGenerate = (data: IRealtimeGenerateParams) =>
  request.post(`/customer/api/design/realTimeDrawImg`, data);

// 请求绘画创款
export const drawGenerate = (data: IDrawGenerateParams) =>
  request.post(`/customer/api/design/drawGenerate`, data);

// 获取绘画创款结果
export const drawGenerateResult = (data: IDrawResultParams) =>
  request.post(`/customer/api/design/drawGenerateData`, data);

// 生图的结果保存
export const drawImageSave = (data: ISaveDrawResultParams) =>
  request.post(`/customer/api/design/drawGenerateSave`, data);
