import request from '../../utils/request';
import { GoodCategory } from './constants';
import { IOrderSubmit } from './type';

// 获取商品列表
export const getGoods = (params: { categoryType?: GoodCategory }) =>
  request.get('/trade/front/sku/getPageList', params);

// 获取商品详情和需求清单详情
export const getGoodsDetail = (skuId: string) =>
  request.get(`/trade/front/sku/info?skuId=${skuId}`);

// 下单
export const ensureOrder = (data: IOrderSubmit) => {
  return request.post('/trade/front/order/submit', data);
};

// 查询需求清单
export const getDemandDetail = (id: number) =>
  request.get(`/trade/api/requirementList/detail?id=${id}`);

// 收集客户的制衣需求
export const collectCustomYiDemand = () => {
  return request.post('/trade/front/order/coming', {
    type: 2,
    context: '样衣制作功能开发收集',
  });
};
