import { GenerateType } from '../constants';
import { LabelType } from '../pages/generate/constants';

// 图片融合的参数
export interface IBlendParams {
  imgUrl: string;
  blendImgUrl?: string;
  // 14是以图生图，15是图片融合
  labelType: LabelType;
  strength?: number;
  prompt?: string;
  bizId?: number;
  colorCode?: string;
  fabricSourceType?: number;
  fabricCode?: string;
}
// 图片融合结果
export interface IBlendResult {
  promptIdentity: string;
  labelType: LabelType;
}

// 上传图片历史记录
export interface IHistoryRecord {
  createTime: number;
  customerId: number;
  generateType: GenerateType;
  id: number;
  labelType: number;
  materialId: number;
  memo: string;
  messageId?: string;
  customId?: string;
  promptId: string;
  updateTime: number;
  url: string;
  visible: number;
}

export enum GalleryType {
  ALL = 1,
  COLLECT = 2,
}

// 图片来源类型
export enum ImageSourceType {
  UNKNOWN = 0,
  AI_DRESSING_IMAGE = 1,
  LING_DRAWING_IMAGE = 2,
  DESIGN_IMAGE = 3,
}

// 收藏状态
export enum CollectStatus {
  COLLECT = 1,
  UNCOLLECT = 0,
}

// 图片转线稿参数
export interface IImageToDraftParams {
  url: string;
}
// 轮询线稿结果
export interface IImageToDraftResultParams {
  promptIdentity: string;
}

// 模特试衣参数
export interface IGeneModelCloth {
  modelImgId: number;
  modelImg: string;
  modelImgMaskType: number;
  clothesImgUrl: string;
}

// 面料替换生成
export interface IFabricGenerateParams {
  imgUrl: string;
  code: string;
  sourceType: 0 | 1; // 0个人面料库，1公共面料库
  strength: number;
  prompt?: string;
}

// 面料替换轮询结果
export interface IFabricPullResultParams {
  promptIdentity: string;
  code: string;
  sourceType?: 0 | 1;
}

// 局部替换参数
export interface IAreaReplaceParams {
  maskBase64: string;
  prompt: string;
  labelType?: string | number;
  id?: string | number;
  imgUrl?: string;
}

// 消除的图片保存参数
export interface IEditSaveImageParams {
  promptIdentity?: string;
  promptId?: string;
  imgBase64: string;
  labelType?: string | number;
  preUrl?: string;
  id?: string | number;
  imgUrl?: string;
}

// 支付状态
export enum PayStatus {
  PAY_STATUS_UNPAID = 1, // 待支付
  PAY_STATUS_PAID = 2, // 已支付
  PAY_STATUS_ERRORPAID = 3, // 支付失败
}

// 支付状态
export const PayStatusMap = {
  [PayStatus.PAY_STATUS_UNPAID]: '待支付',
  [PayStatus.PAY_STATUS_PAID]: '已支付',
  [PayStatus.PAY_STATUS_ERRORPAID]: '支付失败',
};
