import request from '../../utils/request';

export async function upScale(data: {
  originImageUrl: string;
  resize?: number;
}) {
  return request.post('/customer/api/design/imgUpscale', data);
}

export async function loopDownload(data: { imgMd5: string }) {
  return request.post('/customer/api/design/downloadUpscale', data);
}
