import Konva from 'konva';
import { useEffect, useState } from 'react';

import { useExpandOperation } from '../../../contexts/expand-operate';
import { transImage } from '../../../utils';

export default function useExpand(
  stage: Konva.Stage,
  currentShape: any,
  clickCurrentShape: React.Dispatch<any>
) {
  const { currentOperation: expandUrls, setCurrentOperation } =
    useExpandOperation();

  const [layer, setLayer] = useState<any>();
  const [rotateImg, setRotateImg] = useState<any>();

  useEffect(() => {
    if (!stage) return;
    const expandLayer = new Konva.Layer({
      name: 'expand',
    });
    setLayer(expandLayer);
    stage.add(expandLayer);
  }, [stage]);

  useEffect(() => {
    if (!layer || !expandUrls.length) return;
    const {
      attrs: { width, height },
    } = stage as Konva.Stage;
    expandUrls.forEach((item: string | Konva.Shape, index: number) => {
      if (typeof item === 'string') {
        Konva.Image.fromURL(transImage(item, 'webp'), (img: any) => {
          if (!img) return;
          img.setAttrs({
            name: 'shape_expand',
            width: 102,
            height: 102,
            draggable: true,
            x: (width - 102) / 2,
            y: (height - 102) / 2,
          });
          const list = [...expandUrls];
          list.splice(index, 1, img);
          setCurrentOperation(list as Array<string | Konva.Shape>);
          layer.add(img);
          layer.batchDraw();
          clickCurrentShape(img);
        });
      }
    });
  }, [expandUrls, layer, stage]);

  // 控制可旋转的图片
  useEffect(() => {
    const img = new Image();
    // 这里需要配置该属性，否则图片无法导出，跨域的错
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      setRotateImg(img);
    };
    img.src = transImage(
      'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/Group%20738.png',
      'webp',
      '22x'
    );
  }, []);

  useEffect(() => {
    if (!stage) return;
    stage.off('mousedown touchstart');
    stage.on('mousedown touchstart', (e: any) => {
      e.evt.cancelBubble = true;
      e.evt.preventDefault();
      if (e.target.name() === 'backgroundImage') {
        stage
          .find('Transformer')
          .forEach((node: Konva.Node) => node?.destroy());
        clickCurrentShape(null);
        layer.draw();
        return;
      }
      e.target.moveToTop();
      if (!e.target.name().includes('shape_expand')) {
        return;
      }
      // 将当前选择图形保存，因为要展示菜单
      clickCurrentShape(e.target);
    });
  }, [stage, layer, rotateImg]);

  useEffect(() => {
    if (!stage) return;
    stage.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
    if (currentShape) {
      // 它是一个组
      const transformer = new Konva.Transformer({
        keepRatio: true,
        rotateAnchorOffset: 30,
        enabledAnchors: [
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
        ],
        anchorStroke: 'rgba(0,0,0,0.2)',
        anchorFill: '#fff',
        anchorSize: 12,
        anchorCornerRadius: 6, // 锚点的圆角
        borderStroke: '#6451E7',
        borderStrokeWidth: 2,
        boundBoxFunc: (_, newBox) => {
          if (newBox.width < 50) {
            transformer.enabledAnchors([
              (transformer as any).getActiveAnchor?.(),
            ]);
          } else {
            transformer.enabledAnchors([
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ]);
          }
          return newBox;
        },
        // 修改旋转的图片
        anchorStyleFunc: (anchor: any) => {
          if (anchor.hasName('rotater')) {
            anchor.setAttrs({
              width: 22,
              height: 22,
              strokeWidth: 0,
              fill: null,
              fillPatternImage: rotateImg,
            });
          }
        },
      });
      // 图形，改变底层的矩形
      const shape: Konva.Shape = transformer.findOne(
        (node: Konva.Node) => node.getType() === 'Shape'
      ) as Konva.Shape;
      shape?.sceneFunc((ctx: any, sp: any) => {
        ctx.beginPath();
        ctx.rect(0, 0, sp.width(), sp.height());
        ctx.closePath();
        ctx.fillStrokeShape(shape);
      });
      transformer.nodes([currentShape]);
      layer.add(transformer);
      layer.draw();
    }
  }, [currentShape, stage, rotateImg]);

  return {
    layer,
    toUrl: () => {
      stage.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      stage.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: 2,
      });
    },
    deleteItem: (shape: Konva.Shape) => {
      const idx = expandUrls.findIndex(
        (item) => (item as Konva.Shape) === shape
      );
      const list = [...expandUrls];
      list.splice(idx, 1);
      setCurrentOperation(list);
      shape.destroy();
      stage.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      clickCurrentShape(null);
    },
    copy: (shape: Konva.Shape) => {
      const position = shape.getAbsolutePosition();
      const cloneNode = shape.clone({
        x: position.x + 10,
        y: position.y + 10,
      });
      setCurrentOperation(cloneNode);
      layer.add(cloneNode);
      cloneNode.moveToTop();
      const transformer = layer.findOne('Transformer');
      if (transformer) {
        transformer.nodes([cloneNode]);
        transformer.moveToTop();
      }
      layer.batchDraw();
      clickCurrentShape(cloneNode);
    },
  };
}
