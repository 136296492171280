import { CaretDownOutlined } from '@ant-design/icons';
import { ConfigProvider, message, Select } from 'antd';
import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useRef, useState } from 'react';

import Btn from '../../components/btn';
import DejaGenerateResult from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import OptUpload from '../../components/opt-upload';
import ZoomImage from '../../components/zoom-image';
import {
  tryOnImageItemAtom,
  tryOnParamsAtom,
  tryOnResultAtom,
  tryOnStatusAtom,
} from '../../store/generation';
import { transImage } from '../../utils';
import { geneModelCloth, pullModelClothResult } from '../../utils/api';
import { getModelPic } from './api';
import { bodyMap, GenType } from './constants';

import s from './style.module.scss';

const GenerateFromPicture: FC = () => {
  const timer = useRef<NodeJS.Timeout>();
  const dom = useRef<any>();
  const [models, setModels] = useState<any[]>([]);
  const [gen, setGen] = useState(GenType.WOMAN);

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const [tryOnStatus, setTryOnStatus] = useAtom(tryOnStatusAtom);
  const [tryOnParams, setTryOnParams] = useAtom(tryOnParamsAtom);
  const [tryOnResult, setTryOnResult] = useAtom(tryOnResultAtom);
  const [tryOnImageItem, setTryOnImageItem] = useAtom(tryOnImageItemAtom);

  const changePromptIdentity = (promptIdentity: string) => {
    setTryOnParams((prev) => ({ ...prev, promptIdentity }));
  };
  const changeBodyType = (bodyPos: number) => {
    setTryOnParams((prev) => ({ ...prev, modelImgMaskType: bodyPos }));
  };
  const changeLoading = (loading: boolean) => {
    setTryOnStatus((prev) => ({ ...prev, loading }));
  };

  const generateApi = () => {
    if (!tryOnParams.clothesImgUrl) {
      message.error('图片不能为空');
      return Promise.reject('图片不能为空');
    }
    if (!tryOnParams.modelImgId) {
      message.error('请选择模特');
      return Promise.reject('模特不能为空');
    }
    changeLoading(true);
    geneModelCloth({
      modelImgId: tryOnParams.modelImgId,
      modelImg: tryOnParams.modelImg,
      modelImgMaskType: tryOnParams.modelImgMaskType,
      clothesImgUrl: tryOnParams.clothesImgUrl,
    })
      .then(changePromptIdentity)
      .catch(() => changeLoading(false));
  };

  const pollingResultApi = () => {
    if (!tryOnParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), tryOnStatus.timing);
      return;
    }
    pullModelClothResult({
      promptIdentity: tryOnParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setTryOnResult(res);
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            tryOnStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setTryOnResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), tryOnStatus.timing);
  };

  useEffect(() => {
    tryOnStatus.loading && !tryOnResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [tryOnStatus.loading, tryOnParams.promptIdentity, tryOnResult]);

  useEffect(() => {
    getModelPic(gen).then((res: any) => {
      setModels(res);
    });
  }, [gen]);

  return (
    <>
      <PageLayout
        left={
          <DejaGenerateResult
            key={tryOnParams.promptIdentity}
            loading={tryOnStatus.loading as boolean}
            item={tryOnResult ? { url: tryOnResult } : null}
            tipText="选择服装让AI模特开始试衣吧！"
          />
        }
      >
        <OperateLayout
          title="智能试衣"
          footer={
            <Btn
              isGenerate
              className={s.button}
              disabled={!tryOnParams.clothesImgUrl}
              loading={tryOnStatus.loading}
              onClick={() => {
                if (tryOnStatus.loading) return;
                generateApi();
                setTryOnParams((prev) => ({
                  ...prev,
                  promptIdentity: '',
                }));
                setTryOnResult(null);
              }}
            >
              生成设计图
            </Btn>
          }
        >
          <div className={s.content}>
            <div className={s.box}>
              <div className={s.title}>服装图</div>
              <ConfigProvider
                theme={{
                  token: {
                    lineWidth: 0,
                    colorBgElevated: '#212126',
                    colorText: '#f2f4ff',
                    borderRadius: 12,
                  },
                  components: {
                    Select: {
                      selectorBg: '#18181C',
                      optionSelectedBg: '#373740',
                    },
                  },
                }}
              >
                <div ref={dom}>
                  <Select
                    value={tryOnParams.modelImgMaskType}
                    className={s.select}
                    options={Object.entries(bodyMap).map(([value, label]) => ({
                      label,
                      value: Number(value),
                    }))}
                    onChange={(val) => {
                      changeBodyType(val);
                    }}
                    suffixIcon={<CaretDownOutlined />}
                    getPopupContainer={() => dom.current}
                  />
                </div>
              </ConfigProvider>
              <div className={s.wrap}>
                <OptUpload
                  imageItem={tryOnImageItem}
                  onChange={(imgItem) => {
                    setTryOnImageItem(imgItem);
                    setTryOnParams((prev) => ({
                      ...prev,
                      clothesImgUrl: imgItem?.cosUrl,
                    }));
                  }}
                />
              </div>
            </div>

            <div className={s.box}>
              <div className={s.title}>选择模特</div>
              <div className={s.tabs}>
                <div
                  className={cs(s.tab, {
                    [s.active]: gen === GenType.WOMAN,
                  })}
                  onClick={() => {
                    setGen(GenType.WOMAN);
                  }}
                >
                  女模特
                </div>
                <div
                  className={cs(s.tab, {
                    [s.active]: gen === GenType.MAN,
                  })}
                  onClick={() => {
                    setGen(GenType.MAN);
                  }}
                >
                  男模特
                </div>
              </div>
              <div className={s.models} id="models">
                {models.map((item) => (
                  <div
                    className={cs(s.model, {
                      [s.active]: item.id === tryOnParams.modelImgId,
                    })}
                    key={item.id}
                    onClick={() => {
                      setTryOnParams((prev) => ({
                        ...prev,
                        modelImgId: item.id,
                        modelImg: item.imgUrl,
                      }));
                    }}
                  >
                    {/* <div className={s['img-radius']}>
                      <img
                        src={transImage(item.imgUrl, 'webp')}
                        className={s.img}
                      />
                    </div> */}
                    <ZoomImage url={transImage(item.imgUrl, 'webp')} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </>
  );
};

export default GenerateFromPicture;
