import cs from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import s from './style.module.scss';

// const size = 10;
const RECT_HEIGHT = 12;

interface IProps {
  rightColor?: string;
  height?: number;
  offsets?: number[];
  value: number;
  onChange: (value: number) => void;
  onChangeStateus?: (isActive: boolean) => void;
}

const Slider: FC<IProps> = ({
  value,
  onChange,
  onChangeStateus,
  height = 120,
  offsets = [2, 50],
}) => {
  const start = useRef<HTMLDivElement>(null);
  const pointer = useRef<HTMLDivElement>(null);

  const [startPos, setStartPos] = useState(0);
  const [isActive, setActive] = useState(false);
  const [topPx, setTopPx] = useState(0);

  const move = (e: any) => {
    const top = e.pageY - startPos;
    if (top < 0 || top > height - RECT_HEIGHT) {
      return;
    }
    setTopPx(top);
    const long = height - RECT_HEIGHT;
    const range = offsets[1] - offsets[0];
    const numb = offsets[1] - Math.floor((top / long) * range);
    onChange?.(numb);
  };
  const stopMove = () => {
    setActive(false);
  };

  useEffect(() => {
    if (!isActive) return;
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', stopMove);
    document.addEventListener('touchmove', move);
    document.addEventListener('touchend', stopMove);
    return () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', stopMove);
      document.removeEventListener('touchmove', move);
      document.removeEventListener('touchend', stopMove);
    };
  }, [isActive]);

  useEffect(() => {
    const rect = start.current?.getBoundingClientRect();
    setStartPos(rect?.top ?? 0);
  }, []);

  useEffect(() => {
    const long = height - RECT_HEIGHT;
    const range = offsets[1] - offsets[0];
    const top = Math.floor(((offsets[1] - value) * long) / range);
    setTopPx(top);
  }, []);

  useEffect(() => {
    onChangeStateus?.(isActive);
  }, [isActive]);

  return (
    <div
      ref={start}
      className={s.container}
      style={{
        height: `${height}px`,
      }}
    >
      <div
        style={{
          top: `${topPx}px`,
        }}
        ref={pointer}
        className={cs(s.point, {
          [s.active]: isActive,
        })}
        onMouseDown={(e) => {
          e.preventDefault();
          setActive(true);
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          setActive(true);
        }}
      ></div>
    </div>
  );
};

export default Slider;
