import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useOperateInteraction } from '../../contexts/operate-interaction';
import { transImage } from '../../utils';
import { areaReplace } from './api';
import Tools from './components/tools';
import useReplaceLayer from './hooks/useReplaceLayer';
import useStage from './hooks/useStage';
import useUtilLayer from './hooks/useUtilLayer';

import s from './style.module.scss';

type Props = {
  detail?: any;
  bgUrl?: string | null;
  setCustomImage?: (params: any) => void;
};

const ReplaceMode = ({ bgUrl, detail, setCustomImage }: Props, ref: any) => {
  const [mode, setMode] = useState<'brush' | 'eraser'>('brush'); // 画笔或者橡皮擦
  const [strokeWidth, setStrokeWidth] = useState(30); // 画笔宽度
  const [color, setColor] = useState('#fff'); // 画笔颜色
  const [editable, setEditable] = useState<boolean>(true);

  const { setLoading } = useOperateInteraction();

  const { stage, eventRecord } = useStage({
    bgUrl,
    container: 'canvas',
    // width: 512,
    // height: 512,
  });
  const {
    prev,
    next,
    reset,
    // bgUrl: toBgUrl,
    getBg,
    // setCurrentBg,
    getBgAndMask,
  } = useReplaceLayer({
    stage,
    eventRecord,
    bgUrl: transImage(bgUrl as string, 'jpg', '1024x'),
    stroke: strokeWidth,
    editable,
    setEditable,
  });
  const { changeStroke, toogleUtil } = useUtilLayer({
    stage,
    color,
    mode,
    eventRecord,
    daub: true,
  });

  useEffect(() => {
    changeStroke(strokeWidth);
  }, [strokeWidth]);
  useEffect(() => {
    toogleUtil(editable);
  }, [editable]);

  useImperativeHandle(ref, () => ({
    hasOperation: () => getBg().includes('blob:'),
    saveImage: async (extraParams: any) => {
      const params = getBgAndMask();
      if (params === false) return;
      setEditable(false);
      setLoading(true);
      areaReplace({
        ...params,
        prompt: extraParams.prompt,
        labelType: 12,
        id: detail.id,
        imgUrl: bgUrl as string,
      })
        .then((res: any) => {
          setLoading(false);
          setEditable(true);
          setCustomImage?.(res);
          message.success('替换成功');
        })
        .catch((err: any) => {
          setEditable(true);
          setLoading(false);
          console.log('areaReplace-err', err);
          // message.error('超时或网络原因导致替换失败，请重新再试');
        });
    },
  }));

  return (
    <>
      <Tools
        disabled={!editable}
        tools={['brush']}
        prev={prev}
        next={next}
        reset={reset}
        noColorPicker={true}
        mode={mode}
        setMode={setMode}
        color={color}
        setColor={setColor}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
      />
      {!editable ? (
        <div className={s['loading-mask']}>
          <LoadingOutlined
            style={{
              color: '#6155FF',
              fontSize: 40,
              fontWeight: 'bold',
            }}
          />
        </div>
      ) : null}
      <div id="canvas" className={s.canvas}></div>
    </>
  );
};

export default forwardRef(ReplaceMode);
