import { ColorPicker, InputNumber, Slider } from 'antd';
import React, { FC } from 'react';

import s from './style.module.scss';

type Props = {
  disabled?: boolean;
  tools?: [] | ['brush', 'eraser'] | ['eraser'] | ['brush'];
  noColorPicker?: boolean;
  prev?: () => void;
  next?: () => void;
  reset?: () => void;
  mode?: 'brush' | 'eraser';
  setMode?: React.Dispatch<React.SetStateAction<'brush' | 'eraser'>>;
  strokeWidth?: number;
  setStrokeWidth?: React.Dispatch<React.SetStateAction<number>>;
  color?: string;
  setColor?: React.Dispatch<React.SetStateAction<string>>;
};

const presets = [
  {
    label: '推荐',
    colors: [
      '#FFFFFF',
      '#E0E0E0',
      '#C2C2C2',
      '#A3A3A3',
      '#858585',
      '#666666',
      '#474747',
      '#292929',
      '#000000',
      '#FFE500',
      '#FF9717',
      '#F06C1A',
      '#F64B1C',
      '#E7372D',
      '#DD227C',
      '#BF00B6',
      '#9600D1',
      '#5F11DE',
      '#DCE711',
      '#B8E31A',
      '#87D110',
      '#2FB91D',
      '#00CB70',
      '#13D4BE',
      '#05ABD9',
      '#0040E1',
      '#3800D6',
    ],
  },
];

const Tools: FC<Props> = ({
  disabled,
  tools = ['brush', 'eraser'],
  noColorPicker,
  prev,
  next,
  reset,
  mode,
  setMode,
  strokeWidth,
  setStrokeWidth,
  color,
  setColor,
}) => {
  return (
    <div className={s.operation}>
      <div className={s['do-group']}>
        <span
          className="font_family icon-a-xiaochugongjulanshangyibu"
          onClick={() => {
            if (disabled) return;
            prev?.();
          }}
        ></span>
        <span
          className="font_family icon-a-xiaochugongjulanxiayibu"
          onClick={() => {
            if (disabled) return;
            next?.();
          }}
        ></span>
        <span
          className="font_family icon-a-xiaochugongjulanchexiao"
          onClick={() => {
            if (disabled) return;
            reset?.();
          }}
        ></span>
      </div>
      <div className={s['draw-group']}>
        <div className={s.mode}>
          {(tools as any).includes('brush') ? (
            <div
              className={s.item + ' ' + (mode === 'brush' ? s.active : '')}
              onClick={() => setMode?.('brush')}
            >
              <span className="font_family icon-huihuachuangkuan-huabigongju"></span>
            </div>
          ) : null}
          {(tools as any).includes('eraser') ? (
            <div
              className={s.item + ' ' + (mode === 'eraser' ? s.active : '')}
              onClick={() => setMode?.('eraser')}
            >
              <span className="font_family icon-huihuachuangkuan-xiangpigongju"></span>
            </div>
          ) : null}
        </div>
        <div className={s.slider}>
          <Slider
            disabled={disabled}
            dots={false}
            min={1}
            max={50}
            step={1}
            tooltip={{ open: false }}
            onChange={(num) => setStrokeWidth?.(num)}
            value={strokeWidth}
            style={{
              width: '108px',
            }}
          />
          <InputNumber
            disabled={disabled}
            bordered={false}
            controls={false}
            min={1}
            max={50}
            value={strokeWidth}
            onChange={(num) => setStrokeWidth?.(num as number)}
            onBlur={() => {
              if (!strokeWidth) {
                setStrokeWidth?.(1);
              }
            }}
            className={s.number}
          />
        </div>
        {noColorPicker ? null : (
          <ColorPicker
            placement="topRight"
            presets={presets}
            disabled={mode === 'eraser'}
            value={color}
            onChange={(val) => {
              setColor?.(typeof val === 'string' ? val : val.toHexString());
            }}
          >
            {mode === 'brush' ? (
              <span className={s.color} style={{ background: color }}></span>
            ) : (
              <div
                className={s.color + ' ' + s.forbidden}
                style={{ background: color }}
              >
                <span className="font_family icon-a-huabigongjulanjinyongxuanse"></span>
              </div>
            )}
          </ColorPicker>
        )}
      </div>
    </div>
  );
};

export default Tools;
