import { FC, useEffect, useMemo, useState } from 'react';

import WorkflowBan from '../../components/workflow-ban';
import WorkflowDeepDesign from '../../components/workflow-deep-design';
import WorkflowGenerate from '../../components/workflow-generate';
import { useUserInfo } from '../../contexts/user-info';
import Steps from './components/steps';
import { IInputParams, IStepItem } from './types';

// import s from './style.module.scss';

const DESIGN_PAGENAME = 'design';
const EDIT_PAGENAME = 'edit';
const BAN_PAGENAME = 'ban';

const list = [
  {
    name: '图文创款',
    pageName: DESIGN_PAGENAME,
    sort: 1,
  },
  {
    name: '深化设计',
    pageName: EDIT_PAGENAME,
    sort: 2,
  },
  {
    name: '设计打版',
    pageName: BAN_PAGENAME,
    sort: 3,
    // accessPage: [DESIGN_PAGENAME],
  },
];

const Workflow: FC = () => {
  const { userInfo } = useUserInfo();
  const [steps, setSteps] = useState<IStepItem[]>([]);
  const [activeItem, setActiveItem] = useState<IStepItem>();
  const [outputParams, setOutputParams] = useState<Record<string, any>>({});

  const { level = 0 } = userInfo;

  const stepsComponent = useMemo(() => {
    return (
      <Steps
        list={steps}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
    );
  }, [activeItem, steps]);

  // 跳转到下一个流程节点
  const clickToNextProcessNode = (nextKey: string) => {
    return (params: IInputParams) => {
      setOutputParams((prev) => ({
        ...prev,
        [nextKey]: params,
      }));
      // 设置下一个节点
      setActiveItem(steps.find((item) => item.pageName === nextKey));
    };
  };

  useEffect(() => {
    setSteps(list);
    setActiveItem(list[0]);
  }, []);

  console.log('outputParams', outputParams);

  const renderComponent = () => {
    switch (activeItem?.pageName) {
      case BAN_PAGENAME:
        return (
          <WorkflowBan
            steps={stepsComponent}
            inputParams={outputParams?.[BAN_PAGENAME]}
            clickToPrevProcessNode={() => setActiveItem(list[1])}
          />
        );
      case EDIT_PAGENAME:
        return (
          <WorkflowDeepDesign
            steps={stepsComponent}
            inputParams={outputParams?.[EDIT_PAGENAME]}
            clickToNextProcessNode={clickToNextProcessNode(BAN_PAGENAME)}
            clickToPrevProcessNode={() => setActiveItem(list[0])}
          />
        );
      default:
        return (
          <WorkflowGenerate
            steps={stepsComponent}
            clickToNextProcessNode={clickToNextProcessNode(EDIT_PAGENAME)}
          />
        );
    }
  };

  if (level !== 1) {
    return (
      <WorkflowGenerate steps={null} clickToNextProcessNode={() => null} />
    );
  }

  return renderComponent();
};

export default Workflow;
