import { ConfigProvider, Menu } from 'antd';
import cs from 'classnames';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Content from './components/Content';

import s from './style.module.scss';

const FunctionIntroductionMenuTheme = {
  darkItemBg: 'transparent',
  darkSubMenuItemBg: 'transparent',
  darkItemHoverBg: '#393C46',
  darkItemHoverColor: '#f2f4ff',
  darkItemSelectedBg: '#393C46',
  darkItemSelectedColor: '#f2f4ff',
  darkItemColor: '#9FA4BD',
  itemBorderRadius: 4,
  itemHeight: 44,
  itemMarginBlock: 2,
};

const contents = [
  {
    key: 'tuwenchuangkuan',
    title: '图文创款',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/tuwenchuangkuan.gif',
        text: [
          '1. 您可以填写设计描述关键词，点击“生成设计图”即可创作。',
          '2. 您也可以添加设计参考图，再输入设计描述词，创作出更相似参考图片的AI作品。',
          '3. 填写描述词时，词与词之间应用逗号隔开。',
        ],
      },
    ],
  },
  {
    key: 'xiangaochuangkuan',
    title: '线稿创款',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/xiangaoshenji.gif',
        text: [
          '1. 您可以上传线稿图（必选）、面料图（非必选）并填写设计描述词，点击“生成设计图”即可创作。',
          '2. 填写设计描述词时，请对线稿图的颜色、款式、风格进行描述。',
          '3. 此功能建议您只上传线稿图进行创作。',
        ],
      },
    ],
  },
  {
    key: 'danpinyanshen',
    title: '单品延伸',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/danpinyanshen.gif',
        text: [
          '1. 您可以上传参考灵感图，DEJAIA会对参考灵感图进行二次延伸创作。',
          '2. 还原系数越大，还原程度越高，AI作品和参考灵感图越相似。',
        ],
      },
    ],
  },
  {
    key: 'kuanshironghe',
    title: '款式融合',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/kuanshironghe.gif',
        text: [
          '1. 上传设计底图（必传） 和 上传设计元素（必传）。',
          '2. 可以通过影响系数调节设计元素对结果的影响程度。',
          '3. 设计描述（非必填），主要对出图结果进行进一步描述，当有灵感图的时候，设计描述对出图影响不大。',
        ],
      },
    ],
  },
  {
    key: 'mianliaotihuan',
    title: '面料替换',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/mianliaotihuan.gif',
        text: [
          '1. 上传面料（必选）和灵感图（必选），AI会自动帮您把面料替换到灵感图上。',
          '2. 可以通过影响系数调节面料图对结果的影响程度。',
          '3. 设计描述（非必填），主要对出图结果进行进一步描述，当有灵感图的时候，设计描述对出图影响不大。',
        ],
      },
    ],
  },
  {
    key: 'huihuachuangkuan',
    title: '绘画创款',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/huihuachuangkuan.gif',
        text: [
          '1. 用画笔工具进行草图绘制，点击“确认生成”即可进行AI创作。',
          '2. 设计描述（非必填），主要对出图结果进行进一步描述，出图效果会更佳。',
        ],
      },
    ],
  },
  {
    key: 'jubugaikuan',
    title: '局部改款',
    children: [
      {
        title: '1. 画笔工具',
        text: [
          '1. 您可以在主操作区点击添加一幅历史作品，然后通过画笔工具可以对历史作品进行勾画和标注。',
        ],
      },
      {
        title: '2. 消除工具',
        text: [
          '1. 可以对选中的图片的局部内容进行消除，效果神奇，大多用于对辅料进行消除，比如纽扣、领结等。',
        ],
      },
      {
        title: '3. 局部替换',
        text: ['1. 涂抹要替换的区域，再输入关键词即可进行局部替换。'],
      },
      {
        title: '4. 辅料上身',
        text: [
          '1. DEJAAI为您准备了好看的辅料，徽章、LOGO、钮扣等，在辅料库中双击辅料图片即可搭配到创作作品上。',
        ],
      },
    ],
  },
  {
    key: 'aidaban',
    title: 'AI打版',
    children: [
      {
        pic: 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/gongnengjieshao/aidaban.gif',
        text: [
          '1. 上传要打版的图片，选择商品、填写需求清单、支付费用、等待制作、确认收货。',
          '2. 您也可以通过联系客服定制您想要的款式。',
        ],
      },
    ],
  },
  {
    key: 'yangyizhizuo',
    title: '样衣制作',
    children: [
      {
        text: [
          '1. 因为业务线正在搭建，暂时还不能使用此功能，如您感兴趣可点击“我想制作样衣”。',
          '2. 您也可以通过联系客服定制您想要的样衣。',
        ],
      },
    ],
  },
  {
    key: 'moteshiyi',
    title: '模特试衣',
    children: [
      {
        text: [
          '1. 此功能提供精修模特试衣图，因为制作模特试衣图需要进行人工精修，需要您等待一段时间。',
          '2. 您也可以通过联系客服定制您想要的模特试衣图。',
        ],
      },
    ],
  },
  // {
  //   key: 'wodezuopin',
  //   title: '我的作品',
  //   children: [
  //     {
  //       text: [
  //         '1. 展示我的作品，可以二次对于作品进行编辑、打版、下载等操作。',
  //         '2. 您也可以对好的进行收藏。',
  //       ],
  //     },
  //   ],
  // },
  // {
  //   key: 'wodedingdan',
  //   title: '我的订单',
  //   children: [
  //     {
  //       text: [
  //         '1. 查看订单信息以及下单、支付、收货都可以在【我的订单】中查看。',
  //       ],
  //     },
  //   ],
  // },
  // {
  //   key: 'wodemianliao',
  //   title: '我的面料',
  //   children: [
  //     {
  //       text: [
  //         '1. 上传本地电脑的面料照片，提交到DEJAAI后台审核，审核通过后即可用于创作。',
  //       ],
  //     },
  //   ],
  // },
];

const Question: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'tuwenchuangkuan';

  const menus = [
    {
      key: 'mainFunction',
      label: <div className={s.anchor}>主要功能</div>,
      children: [
        {
          key: 'tuwenchuangkuan',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=tuwenchuangkuan');
              }}
            >
              图文创款
            </div>
          ),
        },
        {
          key: 'xiangaochuangkuan',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=xiangaochuangkuan');
              }}
            >
              线稿创款
            </div>
          ),
        },
        {
          key: 'danpinyanshen',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=danpinyanshen');
              }}
            >
              单品延伸
            </div>
          ),
        },
        {
          key: 'kuanshironghe',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=kuanshironghe');
              }}
            >
              款式融合
            </div>
          ),
        },
        {
          key: 'mianliaotihuan',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=mianliaotihuan');
              }}
            >
              面料替换
            </div>
          ),
        },
        {
          key: 'huihuachuangkuan',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=huihuachuangkuan');
              }}
            >
              绘画创款
            </div>
          ),
        },
        {
          key: 'jubugaikuan',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=jubugaikuan');
              }}
            >
              局部改款
            </div>
          ),
        },
      ],
    },
    {
      key: 'expandService',
      label: <div className={s.anchor}>增值服务</div>,
      children: [
        {
          key: 'aidaban',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=aidaban');
              }}
            >
              AI打版
            </div>
          ),
        },
        {
          key: 'yangyizhizuo',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=yangyizhizuo');
              }}
            >
              样衣制作
            </div>
          ),
        },
        {
          key: 'moteshiyi',
          label: (
            <div
              className={s['anc-text']}
              onClick={() => {
                navigate('/userinfo?type=function&tab=moteshiyi');
              }}
            >
              模特试衣
            </div>
          ),
        },
      ],
    },
    // {
    //   key: 'userInfo',
    //   label: <div className={s.anchor}>个人中心</div>,
    //   children: [
    //     {
    //       key: 'wodezuopin',
    //       label: (
    //         <div
    //           className={s['anc-text']}
    //           onClick={() => {
    //             navigate('/userinfo?type=function&tab=wodezuopin');
    //           }}
    //         >
    //           我的作品
    //         </div>
    //       ),
    //     },
    //     {
    //       key: 'wodedingdan',
    //       label: (
    //         <div
    //           className={s['anc-text']}
    //           onClick={() => {
    //             navigate('/userinfo?type=function&tab=wodedingdan');
    //           }}
    //         >
    //           我的订单
    //         </div>
    //       ),
    //     },
    //     {
    //       key: 'wodemianliao',
    //       label: (
    //         <div
    //           className={s['anc-text']}
    //           onClick={() => {
    //             navigate('/userinfo?type=function&tab=wodemianliao');
    //           }}
    //         >
    //           我的面料
    //         </div>
    //       ),
    //     },
    //   ],
    // },
  ];

  const contentData = contents.find(({ key }) =>
    tab ? key === tab : key === 'tuwenchuangkuan'
  );

  return (
    <div className={s.container}>
      <div className={cs(s.gongneng, s['flex-wrap'])}>
        <div className={s.left}>
          <div className={s['category-con']}>
            <div className={s.category}>
              <ConfigProvider
                theme={{
                  components: {
                    Menu: FunctionIntroductionMenuTheme,
                  },
                }}
              >
                <Menu
                  style={{ width: '100%' }}
                  // defaultSelectedKeys={['tuwenchuangkuan']}
                  defaultOpenKeys={['mainFunction']}
                  selectedKeys={[tab]}
                  mode="inline"
                  theme="dark"
                  items={menus}
                  inlineIndent={0}
                  onSelect={(item) => {
                    console.log('item', item);
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className={s.right}>
          <div className={s.content}>
            <Content {...contentData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
