import { addMonths, addYears, parseISO } from 'date-fns';

export enum MemberPeriod {
  Year = 'year',
  Month = 'month',
}

export const MemberPeriods = {
  [MemberPeriod.Year]: {
    name: '年度会员',
    product: 'DEJAAI会员',
    price: '39800',
    unit: '年',
    period: {
      start: (start?: string) => (start ? parseISO(start) : new Date()),
      end: (start?: string) =>
        addYears(start ? parseISO(start) : new Date(), 1),
    },
    periodDesc: '自开通之日起一年内有效。',
    benefitDesc:
      '会员制度是DEJAAI为用户提供的一项增值服务，会员功能仅在会员周期内有效，请及时使用。',
  },
  [MemberPeriod.Month]: {
    name: '月度会员',
    product: 'DEJAAI会员',
    price: '5000',
    unit: '月',
    period: {
      start: (start?: string) => (start ? parseISO(start) : new Date()),
      end: (start?: string) =>
        addMonths(start ? parseISO(start) : new Date(), 1),
    },
    periodDesc: '自开通之日起一月内有效。',
    benefitDesc:
      '会员制度是DEJAAI为用户提供的一项增值服务，会员功能仅在会员周期内有效，请及时使用。',
  },
};
