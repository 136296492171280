import { Form, Image, Input, message } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Btn from '../../components/btn';
import { resetPassword, sendCode as sendCodeApi } from './api';

import s from './style.module.scss';

const ResetPassword: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const timer = useRef<any>(null);
  const [sendable, setSendable] = useState(false);
  const [seconds, setSeconds] = useState<number | null>(null);

  const phone = Form.useWatch('mobile', form);
  useEffect(() => {
    if (phone) {
      form
        .validateFields(['mobile'])
        .then(() => {
          setSendable(true);
        })
        .catch(() => {
          setSendable(false);
        });
    } else {
      setSendable(false);
    }
  }, [phone]);

  useEffect(() => {
    if (seconds !== null && seconds <= 0) {
      setSeconds(null);
      setSendable(true);
    }
    if (seconds !== null && !timer.current) {
      timer.current = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [seconds]);

  const resetPasswordLogin = (values: any) => {
    resetPassword({
      mobile: values.mobile,
      code: values.code,
      pass: values.password,
      scene: 'reset_pass',
    })
      .then(() => {
        message.success('密码重置成功');
        navigate('/login');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const sendCode = () => {
    setSendable(false);
    setSeconds(60);
    sendCodeApi({
      phone,
      scene: 'reset_pass',
    });
  };

  return (
    <div className={s['reset-container']}>
      <div className={s['reset-box']}>
        <Image
          width={136}
          height={28}
          preview={false}
          src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230717/5e6d47dbb38e4ba4bb74645af4e00b68.png"
        />
        <a onClick={() => navigate(-1)} className={s['cancel-reset-text']}>
          取消密码重置
        </a>
      </div>
      <div className={s['reset-inner-box']}>
        <div className={s['reset-header']}>
          <span className={s['reset-title']}>重置密码</span>
        </div>
        <Form form={form} className={s.form} onFinish={resetPasswordLogin}>
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: /^1[3-9][0-9]{9}$/,
                message: '请输入正确的手机号',
              },
            ]}
          >
            <Input
              maxLength={11}
              placeholder="手机号"
              className={s['height-40']}
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: '请输入验证码',
              },
            ]}
          >
            <div className={s.flex}>
              <Input
                maxLength={6}
                placeholder="验证码"
                className={s['height-40']}
              />
              <Btn
                buttonType="goast"
                disabled={!sendable || seconds !== null}
                onClick={() => sendCode()}
                className={s['reset-code']}
              >
                {seconds ? seconds + 's' : '获取验证码'}
              </Btn>
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
              {
                pattern:
                  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\\._])[0-9a-zA-Z!@#$%^&*,\\._]{8}$/,
                message: '8位的大小写+符号+数字',
              },
            ]}
          >
            <Input.Password
              maxLength={8}
              placeholder="密码"
              className={s['height-40']}
            />
          </Form.Item>
          <Form.Item
            name="passowrdAgain"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: '请确认密码',
              },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  return value === form.getFieldValue('password')
                    ? Promise.resolve()
                    : Promise.reject(new Error('新密码和确认密码不一致'));
                },
              },
            ]}
          >
            <Input.Password
              maxLength={8}
              placeholder="确认密码"
              className={s['height-40']}
            />
          </Form.Item>
          <Form.Item>
            <div className={s['reset-button-wrap']}>
              <Btn className={s['reset-button']} htmlType="submit">
                立即重置
              </Btn>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
