import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import { IOperations } from './type';

const Context = createContext<IOperations>({} as IOperations);

export const OperateInteractionProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [currentBg, setCurrentBg] = useState<string>('');
  const [editItem, setEditItem] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Context.Provider
      value={{
        currentBg,
        setCurrentBg: (param: string) => {
          setCurrentBg(param);
        },
        loading,
        setLoading: (bool: boolean) => {
          setLoading(bool);
        },
        editItem,
        setEditItem: (params: any) => {
          setEditItem(params);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useOperateInteraction = (): IOperations => useContext(Context);
