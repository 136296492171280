import { atom } from 'jotai';

import {
  IGeneParams,
  IGenerationParams,
  IGenerationsParams,
  IGeneResult,
  IGeneStatus,
  ITryOnParams,
} from './types';

// 生成数据参数及过程
export const generationAtom = atom<IGenerationParams>({
  prompt: '',
  imageUrl: '',
  loading: false,
});

// 已经生成过的数据做留存
export const generationsAtom = atom<IGenerationsParams>({
  promptIdentity: '',
  labelType: 0,
});

/** 图文创款 */

// 生图状态
const generateStatusInitialValue: IGeneStatus = {
  loading: false,
  startTime: 0,
  timer: null,
  tryCount: 60,
  timing: 3000,
};

export const generateStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);

// 生图参数
const generateParamsInitialValue: IGeneParams = {
  url: '',
  originImaginePrompt: '',
  colorCode: '',
  garryId: '',
  labelType: 0,
  customId: '',
  messageId: '',
  promptIdentity: '',
  recommandPrompt: '', // 推荐的输入词
  messageReferenceId: '',
};

export const generateParamsAtom = atom<IGeneParams>(generateParamsInitialValue);
// 生图时轮询的参数
export const generatePullParamsAtom = atom<any>({});

// 生图结果
export const generateResultAtom = atom<IGeneResult | null>(null);
export const generateResultsAtom = atom<IGeneResult[]>([]);

// 4图的数据保存
export const generateResult4PicAtom = atom<IGeneResult | null>(null);
export const generateImageItemAtom = atom<any>(null);

/** 线稿创款 */
export const draftStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const draftParamsAtom = atom<Record<string, any>>({
  imgUrl: '',
  labelType: 17,
  prompt: '',
  colorCode: '',
  blendImgUrl: '',
  fabricSourceType: 1,
  fabricCode: '',
});
export const draftResultAtom = atom<null | Record<string, any>>(null);
export const draftImageItemAtom = atom<any>(null);
export const draftUpperImageItemAtom = atom<any>(null);

/** 单品延伸 */
export const picStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const picParamsAtom = atom<Record<string, any>>({
  imgUrl: '',
  labelType: 14,
  strength: 5,
  promptIdentity: '',
});
export const picResultAtom = atom<Record<string, any> | null>(null);
export const picImageItemAtom = atom<any>(null);

/** 款式融合 */
export const blendStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const blendParamsAtom = atom<Record<string, any>>({
  strength: 5,
  labelType: 15,
  url: '',
  blendUrl: '',
  prompt: '',
  promptIdentity: '',
});
export const blendResultAtom = atom<Record<string, any> | null>(null);
export const blendOriginImageItemAtom = atom<any>(null);
export const blendImageItemAtom = atom<any>(null);

/** 面料替换 */
export const fabricStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const fabricParamsAtom = atom<Record<string, any>>({
  code: '',
  codeId: '',
  codeUrl: '',
  imgUrl: '',
  imgId: '',
  promptIdentity: '',
  prompt: '',
  sourceType: 1,
  strength: 5,
});
export const fabricResultAtom = atom<Record<string, any> | null>(null);
export const fabricImageItemAtom = atom<any>(null);
export const fabricUpperImageItemAtom = atom<any>(null);

/** 图片转线稿 */
export const lineStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const lineParamsAtom = atom<Record<string, any>>({
  url: '',
  promptIdentity: '',
});
export const lineResultAtom = atom<Record<string, any> | null>(null);
export const lineImageItemAtom = atom<any>(null);

/** 智能试衣参数 */
export const tryOnStatusAtom = atom<IGeneStatus>(generateStatusInitialValue);
export const tryOnParamsAtom = atom<ITryOnParams>({
  modelImgId: 0,
  modelImg: '',
  modelImgMaskType: 1,
  promptIdentity: '',
  clothesImgUrl: '',
  id: 0,
});
export const tryOnResultAtom = atom<string | null>(null);
export const tryOnImageItemAtom = atom<any>(null);
