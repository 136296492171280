import request from '../../utils/request';

export const generateImagePrev = (data: {
  base64?: string;
  url?: string;
}): Promise<string> => request.post('/customer/api/design/imgProcess', data);

export const pullGenerateImageResult = (data: {
  promptIdentity: string;
  base64Result?: number;
}) => request.post('/customer/api/design/imgProcessData', data);
