import request from '../../utils/request';

export const getAuthCode = () => request.get('/customer/api/auth/code');

export const login = (data: {
  code: string;
  password: string;
  username: string;
  uuid: string;
}) =>
  request.post('/customer/api/customer/login', {
    ...data,
    bizDomain: 2,
  });

export const resetPassword = (data: {
  mobile: string;
  code: string;
  pass: string;
  scene: 'login' | 'register' | 'reset_pass';
}) => request.post('/customer/api/customer/resetPass', data);

export const phoneLogin = (data: { phone: string; code: string }) =>
  request.post('/customer/api/customer/phoneLogin', {
    ...data,
    bizDomain: 2,
  });

// 类目树结构-试用的类目树
export const getRegisterCategory = () =>
  request.get('/api/category/trialTreeList');

// 平铺类目树
export const getRegisterTileCategory = () => request.get('/api/category/list');

// 用户注册
export const register = (data: {
  name: string;
  mobile: string;
  nickName: string;
  division: string | number;
  categoryId: string | number;
  inviteCode?: string;
}) => request.post('/api/customer/register', data);

// 获取省市区
export const getLocation = () => request.get('/api/area/treeList');

export const sendCode = (data: {
  phone: string;
  scene: 'login' | 'register' | 'reset_pass' | 'phone_login_2';
}) => request.post('/customer/api/message/sendVerifycode', data);
