import Konva from 'konva';
import { useEffect, useState } from 'react';

export default function useUtilLayer({
  stage,
  color,
  mode,
  eventRecord,
  daub,
}: {
  stage: Konva.Stage;
  color: string;
  mode: 'brush' | 'eraser';
  eventRecord: any;
  daub?: boolean;
}) {
  const [layer, setLayer] = useState<any>();
  const [circle, setCircle] = useState<any>();
  const [show, setShow] = useState<boolean>(true);
  const [stroke, setStroke] = useState<number>(0);

  useEffect(() => {
    const layer = new Konva.Layer({
      name: 'utilLayer',
    });
    const circle = new Konva.Circle({
      x: 0,
      y: 0,
      radius: 0,
      fill: 'rgba(100, 81, 231, 0.1)',
      stroke: '#6451e7',
      strokeWidth: 2,
    });
    layer.add(circle);
    setCircle(circle);
    setLayer(layer);
  }, []);

  useEffect(() => {
    if (!layer) return;
    stage?.add(layer);
  }, [stage]);

  useEffect(() => {
    if (!eventRecord) return;
    const { eventName, pos } = eventRecord;
    if (eventName === 'mousemove' || eventName === 'touchmove') {
      circle.setAttrs({
        x: pos?.x,
        y: pos?.y,
        radius: show ? stroke / 2 : 0,
      });
    }
    if (eventName === 'mouseout' || eventName === 'touchend') {
      circle.setAttrs({
        x: 0,
        y: 0,
        radius: 0,
      });
    }
  }, [stroke, eventRecord, show]);

  useEffect(() => {
    if (!circle) return;
    if (daub) {
      circle.setAttrs({
        fill: 'rgba(100, 81, 231, 0.1)',
        stroke: '#6451e7',
      });
    } else {
      circle.setAttrs({
        fill: mode === 'eraser' ? 'rgba(100, 81, 231, 0.1)' : color,
        stroke: mode === 'eraser' ? '#6451e7' : color,
      });
    }
  }, [color, mode, circle, daub]);

  return {
    layer,
    toogleUtil: (boolean: boolean) => setShow(boolean),
    strokeWidth: stroke,
    changeStroke: (width: number = 0) => setStroke(width),
  };
}
