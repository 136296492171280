import { ConfigProvider, message } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Btn from '../../components/btn';
import Collapse from '../../components/collapse';
import EditSpace from '../../components/editSpace';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import ModalTip from '../../components/modal-tip';
import { useOperateInteraction } from '../../contexts/operate-interaction';
import { useUserInfo } from '../../contexts/user-info';
import AreaClear from '../generate/components/area-clear';
import AreaExpand from '../generate/components/area-expand';
import AreaFabric from '../generate/components/area-fabric';
import AreaPancel from '../generate/components/area-pancel';
import AreaReplace from '../generate/components/area-replace';

import s from './style.module.scss';

const AreaModify: FC = () => {
  const editRef = useRef<any>(null);
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const { editItem, setEditItem } = useOperateInteraction();

  const [editKey, setEditKey] = useState<string[]>([]);
  const [tipShow, setTipShow] = useState(false);
  const [nextTab, setNextTab] = useState<string>('');

  useEffect(() => {
    switch (tab) {
      case '1':
        setEditKey(['pancel']);
        break;
      case '2':
        setEditKey(['clear']);
        break;
      case '3':
        setEditKey(['expand']);
        break;
      case '4':
        setEditKey(['fabricMatching']);
        break;
      case '5':
        setEditKey(['replace']);
        break;
      default:
        setEditKey(['edit']);
    }
  }, [tab]);

  useEffect(() => {
    return () => {
      localStorage.setItem('hasOperation', '');
    };
  }, []);

  return (
    <>
      <PageLayout
        left={
          <EditSpace
            ref={editRef}
            detail={editItem}
            bgUrl={editItem?.imageUrl || editItem?.url}
            choseImage={setEditItem}
          />
        }
      >
        <OperateLayout title="局部改款">
          <div className={s.box}>
            <ConfigProvider
              theme={{
                token: {},
                components: {
                  Collapse: {
                    contentBg: '#18181C',
                  },
                },
              }}
            >
              <Collapse
                ghost
                key="pancel"
                keyName="pancel"
                activeKey={editKey}
                labelName="画笔工具"
                labelIconName="icon-a-gongzuoqudabiaotijubuxiugai"
                onChange={() => {
                  if (localStorage.getItem('hasOperation') === 'Y') {
                    setNextTab('1');
                    return setTipShow(true);
                  }
                  navigate('/generate/areaModify?tab=1');
                }}
              >
                <AreaPancel>
                  <Btn
                    disabled={!editItem}
                    className={s.button}
                    onClick={(props: any) => {
                      if (userInfo?.lifeStatus === 0) {
                        return message.error(
                          '您的账户已到期，请联系客服开通权限'
                        );
                      }
                      editRef.current?.saveEditImage(props);
                    }}
                  >
                    确认修改
                  </Btn>
                </AreaPancel>
              </Collapse>
              <Collapse
                ghost
                key="clear"
                keyName="clear"
                activeKey={editKey}
                labelName="消除工具"
                labelIconName="icon-a-gongzuoqudabiaotixiaochugongju"
                onChange={() => {
                  if (localStorage.getItem('hasOperation') === 'Y') {
                    setNextTab('2');
                    return setTipShow(true);
                  }
                  navigate('/generate/areaModify?tab=2');
                }}
              >
                <AreaClear>
                  <Btn
                    disabled={!editItem}
                    className={s.button}
                    onClick={(props: any) => {
                      if (userInfo?.lifeStatus === 0) {
                        return message.error(
                          '您的账户已到期，请联系客服开通权限'
                        );
                      }
                      editRef.current?.saveEditImage(props);
                    }}
                  >
                    确认修改
                  </Btn>
                </AreaClear>
              </Collapse>
              <Collapse
                ghost
                key="replace"
                activeKey={editKey}
                keyName="replace"
                labelName="局部替换"
                labelIconName="icon-a-gongzuoqudabiaotijubutihuan"
                onChange={() => {
                  if (localStorage.getItem('hasOperation') === 'Y') {
                    setNextTab('5');
                    return setTipShow(true);
                  }
                  navigate('/generate/areaModify?tab=5');
                  // setEditKey(['expand']);
                }}
              >
                <AreaReplace>
                  <Btn
                    disabled={!editItem}
                    className={s.button}
                    onClick={(props: any) => {
                      if (userInfo?.lifeStatus === 0) {
                        return message.error(
                          '您的账户已到期，请联系客服开通权限'
                        );
                      }
                      if (!editItem) {
                        return message.warning('请先选择图片');
                      }
                      editRef.current?.saveEditImage(props);
                    }}
                  ></Btn>
                </AreaReplace>
              </Collapse>
              <Collapse
                ghost
                key="expand"
                activeKey={editKey}
                keyName="expand"
                labelName="辅料上身"
                labelIconName="icon-a-gongzuoqudabiaotifuliaoshangshen"
                onChange={() => {
                  if (localStorage.getItem('hasOperation') === 'Y') {
                    setNextTab('3');
                    return setTipShow(true);
                  }
                  navigate('/generate/areaModify?tab=3');
                }}
              >
                <AreaExpand>
                  <Btn
                    disabled={!editItem}
                    className={s.button}
                    onClick={(props: any) => {
                      if (userInfo?.lifeStatus === 0) {
                        return message.error(
                          '您的账户已到期，请联系客服开通权限'
                        );
                      }
                      editRef.current?.saveEditImage(props);
                    }}
                  >
                    保存
                  </Btn>
                </AreaExpand>
              </Collapse>
              <Collapse
                ghost
                key="fabricMatching"
                activeKey={editKey}
                labelName="面料匹配"
                keyName="fabricMatching"
                labelIconName="icon-a-gongzuoqudabiaotimianliaopipei"
                onChange={() => {
                  if (localStorage.getItem('hasOperation') === 'Y') {
                    setNextTab('4');
                    return setTipShow(true);
                  }
                  navigate('/generate/areaModify?tab=4');
                }}
              >
                <AreaFabric editItem={editItem}>
                  <Btn
                    disabled={!editItem}
                    className={s.button}
                    onClick={async () => {
                      if (userInfo?.lifeStatus === 0) {
                        return message.error(
                          '您的账户已到期，请联系客服开通权限'
                        );
                      }
                      return true;
                    }}
                  />
                </AreaFabric>
              </Collapse>
            </ConfigProvider>
          </div>
        </OperateLayout>
      </PageLayout>
      <ModalTip
        tipOpen={tipShow}
        setTipOpen={setTipShow}
        onSuccess={() => {
          localStorage.setItem('hasOperation', '');
          navigate(`/generate/areaModify?tab=${nextTab || 1}`);
        }}
      />
    </>
  );
};

export default AreaModify;
