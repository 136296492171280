export enum GenType {
  WOMAN = 0,
  MAN = 1,
}

export enum BodyType {
  UPPER = 1,
  LOWER = 2,
  FULL = 3,
}

export const genMap = {
  [GenType.WOMAN]: '女',
  [GenType.MAN]: '男',
};

export const bodyMap = {
  [BodyType.UPPER]: '上衣',
  [BodyType.LOWER]: '裤子',
  [BodyType.FULL]: '单件连体',
};

export const modelImgMap = {
  [BodyType.UPPER]: 'upper-cloth',
  [BodyType.LOWER]: 'lower-cloth',
  [BodyType.FULL]: 'full-cloth',
};
