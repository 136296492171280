import React, { FC, PropsWithChildren, useState } from 'react';

import ModalImage, { Tabs } from '../../../modal-image';

export interface IProps extends PropsWithChildren {
  onChange?: (img: string) => void;
}

const CloudUpload: FC<IProps> = ({ children, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => setOpen(!open),
        });
      })}
      <ModalImage
        tabs={[Tabs.Recommend, Tabs.History, Tabs.Drafts]}
        open={open}
        close={() => setOpen(false)}
        onChange={(imgItem) => {
          onChange?.(imgItem.imageUrl);
          setOpen(false);
        }}
      />
    </div>
  );
};

export default CloudUpload;
