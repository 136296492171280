import { message, Modal } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { IResult } from '../../pages/generate/types';
import { downloadBtn } from '../../utils';
import Btn from '../btn';
import { loopDownload, upScale } from './api';

import s from './style.module.scss';

type IProps = {
  btnLoading: boolean;
  result?: IResult;
  children: React.ReactNode;
};

const TIME = 60; // 暂定下载需要1分钟
const PROGRESS_MAX = 90;

const Download: FC<IProps> = ({ children, result, btnLoading }) => {
  const timer = useRef<NodeJS.Timer>();
  const animation = useRef<number>();

  const [tipOpen, setTipOpen] = useState(false);
  const [imgMd5, setImageMd5] = useState('');
  const [now, setNow] = useState(0);
  const [percent, setPercent] = useState(0);

  const upScaleImage = useCallback(() => {
    if (result?.imageUrl) {
      return upScale({
        originImageUrl: result.imageUrl,
        resize: 2,
      });
    }
    return null;
  }, [result?.imageUrl]);

  const downloadCb = useCallback(() => {
    if (!imgMd5) return;
    loopDownload({ imgMd5 }).then((res: any) => {
      if (res) {
        downloadBtn(res);
        timer.current && clearInterval(timer.current);
        window.cancelAnimationFrame(animation.current!);
        setImageMd5('');
        setNow(0);
        message.success('下载成功');
      }
    });
  }, [imgMd5]);

  const animationCb = () => {
    if (!now) return;
    const timeDiff = Date.now() - now;
    const nowPercent = ((timeDiff / 1000) * PROGRESS_MAX) / TIME;
    if (nowPercent >= PROGRESS_MAX) {
      setNow(0);
      window.cancelAnimationFrame(animation.current!);
      return;
    }
    setPercent(nowPercent);
    animation.current = window.requestAnimationFrame(animationCb);
  };

  useEffect(() => {
    downloadCb();
    timer.current = setInterval(() => {
      downloadCb();
    }, 5000);
    return () => {
      clearInterval(timer.current);
    };
  }, [downloadCb]);

  useEffect(() => {
    if (!now) return;
    animationCb();
  }, [now]);

  return (
    <div>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => {
            if (btnLoading) return;
            return Promise.resolve(child.props.onClick?.()).then(() => {
              setTipOpen(true);
            });
          },
        });
      })}
      <Modal
        centered
        open={tipOpen}
        onCancel={() => setTipOpen(false)}
        title={
          <div className={s['tip-title']}>
            <span className="font_family icon-a-tishidanchuangtishi"></span>提示
          </div>
        }
        footer={
          <div className={s['tip-footer']}>
            <Btn
              buttonType="goast"
              className={s.button}
              onClick={async () => {
                setTipOpen(false);
              }}
            >
              取消
            </Btn>
            <Btn
              className={s.button}
              onClick={async () => {
                setTipOpen(false);
                if (
                  ['O', '0', '9', '10', '11'].includes(
                    String(result?.labelType)
                  )
                ) {
                  downloadBtn(result?.imageUrl!);
                  setImageMd5('');
                } else {
                  const res = await upScaleImage();
                  setImageMd5(res);
                  setNow(Date.now());
                }
              }}
            >
              确认
            </Btn>
          </div>
        }
      >
        <div className={s['tip-content']}>
          <div className={s.text}>
            点击下载后将需要部分时间生成，确认是否下载?
          </div>
        </div>
      </Modal>
      <Modal
        open={!!now}
        footer={null}
        width={440}
        centered
        onCancel={() => {
          setNow(0);
          window.cancelAnimationFrame(animation.current!);
          setImageMd5('');
        }}
      >
        <div className={s['loading-container']}>
          <div className={s.percent}></div>
          <div className={s['percent-number']}>{Math.ceil(percent)} %</div>
          <div className={s.text}>
            <div>超清图片正在下载中...</div>
            <div>关闭当前弹框会中断下载，请耐心等待</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Download;
