import { message } from 'antd';
import { useAtom } from 'jotai';
import { FC, useEffect, useRef } from 'react';

import Btn from '../../components/btn';
import DejaGenerateResult from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import OptUpload from '../../components/opt-upload';
import {
  lineImageItemAtom,
  lineParamsAtom,
  lineResultAtom,
  lineStatusAtom,
} from '../../store/generation';
import { imageToDraft, pullImageToDraftResult } from '../../utils/api';

import s from './style.module.scss';

const ImageToDraft: FC = () => {
  const timer = useRef<NodeJS.Timeout>();

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const [lineStatus, setLineStatus] = useAtom(lineStatusAtom);
  const [lineParams, setLineParams] = useAtom(lineParamsAtom);
  const [lineResult, setLineResult] = useAtom(lineResultAtom);
  const [lineImageItem, setLineImageItem] = useAtom(lineImageItemAtom);

  const changeImgUrl = (url: string) => {
    setLineParams((prev) => ({ ...prev, url }));
  };
  const changePromptIdentity = (promptIdentity: string) => {
    setLineParams((prev) => ({ ...prev, promptIdentity }));
  };

  const changeLoading = (loading: boolean) => {
    setLineStatus((prev) => ({ ...prev, loading }));
  };

  const generateApi = () => {
    if (!lineParams.url) {
      message.error('图片不能为空');
      return Promise.reject('图片不能为空');
    }
    changeLoading(true);
    imageToDraft({
      url: lineParams.url,
    })
      .then(changePromptIdentity)
      .catch(() => changeLoading(false));
  };

  const pollingResultApi = () => {
    if (!lineParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), lineStatus.timing);
      return;
    }
    pullImageToDraftResult({
      promptIdentity: lineParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setLineResult({
            ...res,
            url: res.imageUrl,
          });
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            lineStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setLineResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), lineStatus.timing);
  };

  useEffect(() => {
    lineStatus.loading && !lineResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [lineStatus.loading, lineParams.promptIdentity, lineResult]);

  return (
    <>
      <PageLayout
        left={
          <DejaGenerateResult
            key={lineParams.promptIdentity}
            loading={lineStatus.loading as boolean}
            item={lineResult}
            tipText="上传设计灵感图即可转成线稿"
            showSelect
          />
        }
      >
        <OperateLayout
          title="图转线稿"
          footer={
            <Btn
              isGenerate
              className={s.button}
              disabled={!lineParams.url}
              loading={lineStatus.loading}
              onClick={() => {
                if (lineStatus.loading) return;
                generateApi();
                setLineResult(null);
              }}
            >
              生成线稿图
            </Btn>
          }
        >
          <div className={s.content}>
            <div className={s.box}>
              <div className={s.title}>灵感图</div>
              <div className={s.wrap}>
                <OptUpload
                  imageItem={lineImageItem}
                  onChange={(imgItem) => {
                    setLineImageItem(imgItem);
                    changeImgUrl(imgItem?.cosUrl);
                  }}
                />
              </div>
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </>
  );
};

export default ImageToDraft;
