import { EllipsisOutlined } from '@ant-design/icons';
import { message, Popover } from 'antd';
import cs from 'classnames';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { listExpand } from '../../../../pages/mine/components/expand-list/api';
import {
  getExpandDetail as getExpandDetailApi,
  getExpandList,
} from '../../../../utils/api';
import { transImage } from '../../../../utils/index';
import Btn from '../../../btn';
import Icon from '../../../icon';

import s from './style.module.scss';

type Props = {
  children: React.ReactNode;
  onChooseImg?: (item: any) => void;
  his: any[];
  setHis?: (item: any) => void;
};

const types = [
  {
    title: '全部',
    value: '',
  },
  {
    title: '紧扣',
    value: 1,
  },
  {
    title: '线带',
    value: 3,
  },
  {
    title: '其他',
    value: 4,
  },
  {
    title: '泰迪熊',
    value: 1001,
  },
  {
    title: '我的',
    value: 100,
  },
];

const typesObj = {
  '1': '紧扣',
  '3': '线带',
  '4': '其他',
  '1001': 'IP图案',
};

const AreaExpand: FC<Props> = ({ children, onChooseImg, his, setHis }) => {
  const navigate = useNavigate();
  const dom = useRef<any>();

  const [active, setActive] = useState<string | number>('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [expandDetail, setExpandDetail] = useState<any>();

  console.log('page', page, 'total', total);

  const getList = useCallback(() => {
    if (active === 100) {
      listExpand({ page, size: 9, status: 0 })
        .then((res: any) => {
          setList(res.rows);
          setTotal(res.total);
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else {
      getExpandList(page, active)
        .then((res: any) => {
          setList(res.rows);
          setTotal(res.total);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [active, page]);

  useEffect(() => {
    getList();
  }, [getList]);

  const getExpandDetail = (id: number) => {
    getExpandDetailApi(id).then((res: any) => setExpandDetail(res));
  };

  const content = useMemo(() => {
    return (
      <div className={s.content}>
        <div className={s['name-list']}>
          <div className={s['name-item']}>
            <div className={s.name}>工厂名称</div>
            <div className={s.value}>{expandDetail?.companyName}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料货号</div>
            <div className={s.value}>{expandDetail?.itemNo}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料名称</div>
            <div className={s.value}>{expandDetail?.name}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料分类</div>
            <div className={s.value}>
              {typesObj[expandDetail?.type as '1' | '3' | '4']}
            </div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料材质</div>
            <div className={s.value}>{expandDetail?.composition}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料尺寸</div>
            <div className={s.value}>{expandDetail?.size}</div>
          </div>
        </div>
        <Btn className={s.button} onClick={() => navigate('/service')}>
          联系客服购买
        </Btn>
      </div>
    );
  }, [expandDetail]);

  return (
    <div className={s.container}>
      <div className={s.tags}>
        {types.map((item) => (
          <div
            key={item.value}
            className={cs({ [s.tag]: true, [s.active]: active === item.value })}
            onClick={() => {
              setActive(item.value);
              setPage(1);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={s.images}>
        {list.length === 0 ? (
          <div className={s['empty-wrap']}>
            <img
              src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/my-expand-empty.png"
              className={s.empty}
            />
            <div className={s.text}>暂无辅料数据</div>
          </div>
        ) : null}
        <div className={s.list} ref={dom}>
          {list.map((item: any) => (
            <div className={s.item} key={`${item.id}`}>
              <div className={s['image-wrap']}>
                <img
                  src={transImage(item.imageUrl, 'webp')}
                  onClick={() => {
                    if (onChooseImg) {
                      return onChooseImg?.(item.imageUrl);
                    }
                    if (his.length >= 10) {
                      return message.error(
                        '当前辅料已达上限，请删除后进行操作哦'
                      );
                    }
                    setHis?.([...his, item.imageUrl]);
                  }}
                />
              </div>
              {active === 100 ? null : (
                <Popover
                  content={content}
                  placement="bottomRight"
                  arrow={false}
                  color="#1F2022"
                  overlayClassName={s['fabric-tip-container']}
                  trigger="click"
                  getTooltipContainer={() => dom.current}
                >
                  <div className={s.icon}>
                    <EllipsisOutlined
                      onClick={() => {
                        getExpandDetail(item.id);
                      }}
                    />
                  </div>
                </Popover>
              )}
            </div>
          ))}
        </div>

        {[3, 5].includes(list.length) ? (
          <div key="placeholder" className={s.wrap}></div>
        ) : null}
      </div>
      <div className={s.pages}>
        <Icon
          direction="right"
          disabled={page <= 1}
          iconName="icon-a-fanyexiayiye"
          onClick={() => setPage(page - 1)}
        />
        <Icon
          disabled={page >= total}
          iconName="icon-a-fanyexiayiye"
          onClick={() => setPage(page + 1)}
        />
      </div>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
        });
      })}
    </div>
  );
};

export default AreaExpand;
