import { Pagination } from 'antd';
import cs from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import Btn from '../../../../components/btn';
import { format } from '../../../../utils/price';
import { PayStatus } from '../../../../utils/types';
import {
  GoodCategory,
  GoodCategoryMap,
  OrderStatus,
  OrderStatusMap,
} from '../../../ban-consume/constants';
import { getMyOrders, getMyServiceOrders } from './api';

import s from './style.module.scss';

const Size = 10;

interface ITagProps {
  item: any;
}

type TempStatus = {
  [OrderStatus.ORDER_STATUS_CANCEL]: any;
  [OrderStatus.ORDER_STATUS_COMPLETE]: any;
  [OrderStatus.ORDER_STATUS_SHIPPED]: any;
  [OrderStatus.ORDER_STATUS_UNSHIPPED]: any;
};

const Tag: FC<ITagProps> = ({ item }) => {
  const { payStatus, status } = item;
  const tempMap = {
    [OrderStatus.ORDER_STATUS_CANCEL]: [s.cancel, '已取消'],
    [OrderStatus.ORDER_STATUS_COMPLETE]: [s.success, '已完成'],
    [OrderStatus.ORDER_STATUS_SHIPPED]: [s.receive, '待收货'],
    [OrderStatus.ORDER_STATUS_UNSHIPPED]: [s.shipping, '待发货'],
  };

  const [cls, text] = (() => {
    if (
      payStatus === PayStatus.PAY_STATUS_UNPAID &&
      status === OrderStatus.ORDER_STATUS_PAID
    ) {
      return [s.pay, '待支付'];
    }
    return [
      tempMap[status as keyof TempStatus],
      OrderStatusMap[status as OrderStatus],
    ];
  })();

  return <div className={cs(s.tag, cls)}>{text}</div>;
};

const OrderList: FC = () => {
  const navigate = useNavigate();

  const [orderList, setOrderList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [businessType, setBusinessType] = useState(1);

  const serving = useCallback((endTime: any, startTime: any) => {
    return new Date(endTime).getTime() - new Date(startTime).getTime() > 0;
  }, []);

  const resetParams = useCallback((buttonType: number) => {
    unstable_batchedUpdates(() => {
      setBusinessType(buttonType);
      setPage(1);
      setTotal(0);
      setOrderList([]);
    });
  }, []);

  const getOrders = (page: number, businessType: number) => {
    (businessType === 1
      ? getMyOrders({
          page,
          size: Size,
        })
      : getMyServiceOrders({
          page,
          size: Size,
        })
    ).then((res: any) => {
      unstable_batchedUpdates(() => {
        setOrderList(res.rows);
        setPage(res.page);
        setTotal(res.records);
      });
    });
  };

  useEffect(() => {
    getOrders(page, businessType);
  }, [page, businessType]);

  if (total === 0) {
    return (
      <div className={s.container}>
        <div className={s['page-header']}>
          <h2 className={s['page-title']}>我的订单</h2>
          <div className={s.tabs}>
            <div
              className={cs(s.tab, {
                [s.active]: businessType === 1,
              })}
              onClick={() => resetParams(1)}
            >
              业务订单
            </div>
            <div
              className={cs(s.tab, {
                [s.active]: businessType === 2,
              })}
              onClick={() => resetParams(2)}
            >
              服务订阅
            </div>
          </div>
        </div>
        <div className={s.empty}>
          <img
            src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/order-empty1.png"
            className={s.img}
          />
          <div>暂无订单</div>
        </div>
      </div>
    );
  }

  const BusinessItem = ({ item }: any) => {
    return (
      <div className={s.item}>
        <div className={s.header}>
          <div className={s.time}>{item.createTime}</div>
          <Tag item={item} />
        </div>
        <div className={s.content}>
          <div className={s.left}>
            <img src={item.picUrl} className={s.img} />
            <div className={s.cell}>
              <div className={s.title}>订单号</div>
              <div className={s.value}>{item.orderNo}</div>
            </div>
            <div className={s.cell}>
              <div className={s.title}>类目</div>
              <div className={s.value}>
                {GoodCategoryMap[item.categoryType as GoodCategory]}
              </div>
            </div>
            <div className={s.cell}>
              <div className={s.title}>商品名称</div>
              <div className={s.value}>{item.skuName}</div>
            </div>
          </div>
          <div className={s.right}>
            <Btn
              className={s.button}
              onClick={() => {
                navigate(
                  `/orderDetail?serviceType=${item.source}&id=${item.id}&payOrderNo=${item.payOrderNo}`
                );
              }}
            >
              查看
            </Btn>
          </div>
        </div>
      </div>
    );
  };

  const ServiceItem = ({ item }: any) => {
    const isServing = serving(item.serviceFinishTime, item.nowTime);

    return (
      <div className={cs(s.item, s.service)}>
        <div className={cs(s.cell, s.left)}>
          <div className={s.title}>订单号</div>
          <div className={s.value}>{item.orderNo}</div>
        </div>
        <div className={s.right}>
          <div className={s.cell}>
            <div className={s.title}>服务开始时间</div>
            <div className={s.value}>
              <div className={s.abs}>{item.payFinishTime}</div>
            </div>
          </div>
          <div className={s.cell}>
            <div className={s.title}>服务到期时间</div>
            <div className={s.value}>
              <div className={s.abs}>{item.serviceFinishTime}</div>
            </div>
          </div>
          <div className={s.cell}>
            <div className={s.title}>实际付款</div>
            <div className={s.value}>
              <div className={cs(s.abs, s['price-color'])}>
                ￥<span className={s.price}>{format(item.totalPrice)}</span>
              </div>
            </div>
          </div>
          <div className={s.cell}>
            <div className={s.title}>状态</div>
            <div className={s.value}>
              <div
                className={cs(s.abs, {
                  [s.success]: isServing,
                  [s.expired]: !isServing,
                })}
              >
                {isServing ? '服务中' : '已失效'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={s.container}>
      <div className={s['page-header']}>
        <h2 className={s['page-title']}>我的订单</h2>
        <div className={s.tabs}>
          <div
            className={cs(s.tab, {
              [s.active]: businessType === 1,
            })}
            onClick={() => resetParams(1)}
          >
            业务订单
          </div>
          <div
            className={cs(s.tab, {
              [s.active]: businessType === 2,
            })}
            onClick={() => resetParams(2)}
          >
            服务订阅
          </div>
        </div>
      </div>
      {orderList.map((item: any) => {
        return businessType === 1 ? (
          <BusinessItem key={item.id} item={item} />
        ) : (
          <ServiceItem key={item.id} item={item} />
        );
      })}
      <div className={s.pagination}>
        <Pagination
          total={total}
          current={page}
          showTotal={(total) => {
            return <div className={s.total}>{`共 ${total} 条`}</div>;
          }}
          onChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default OrderList;
