import { FC } from 'react';

import s from './style.module.scss';

const Functions: FC = () => {
  return (
    <div className={s.container}>
      <div className={s.answer}>
        <div className={s.title}>购买产品后能不能多人使用?</div>
        <div className={s.text}>
          1. 目前是支持多端登录，但是只能同时保持一个人在线使用。
        </div>
        <div className={s.title}>增值服务里的功能需要另收费么?</div>
        <div className={s.text}>
          1.
          增值服务里的功能需另外收费，您在增值服务中提交申请后12小时内，客服会电话回访给您报价。
        </div>
        <div className={s.text}>
          2. 购买旗舰版的用户，后续升级的功能均可以免费试用。
        </div>
        <div className={s.title}>关键词如何撰写生成更好看的图?</div>
        <div className={s.text}>
          1.
          在输入描述词的时候，应提前构想好自己想要的服装“灵感”，按照范围由大到小的逻辑来描述“灵感”，比如：连衣裙，吊带连衣裙，收腰显瘦，ins风……，设计图效果会更佳。{' '}
        </div>
        <div className={s.text}>
          2.
          在输入描述词的时候尽量避免：词与词之间前后互相矛盾、词与词之间词义重复、描述词过于夸张、非服装系列的词汇、非描述目标的关键词、数量尽量不要超过15个词以上。
        </div>
        <div className={s.title}>生图速度过慢怎么回事?</div>
        <div className={s.text}>
          1.
          DEJAAI的生图速度属于行业中上等水平，速度慢跟很多因素有关，平均出图时间约48s左右，如果超过这个时间可能与网络速度不太稳定有关，请您耐心等待。
        </div>
      </div>
    </div>
  );
};

export default Functions;
