import { Col, ConfigProvider, Input, Radio, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';

interface IProps {
  options?: Array<{
    keyword: string; // 选项关键字
    value: string; // 选项值
    dataType?: string; // 选项数据类型
    unit?: string; // 选项单位
  }>;
  value?: string;
  onChange?: (value: string) => void;
}

const PREV = 'else-';

const RadioInput: FC<IProps> = ({ value, options, onChange }) => {
  const [strVal, setStrVal] = useState(value);
  const [customVal, setCustomVal] = useState(
    value?.startsWith(PREV) ? value : PREV
  );

  useEffect(() => {
    onChange?.(strVal as string);
  }, [strVal]);

  useEffect(() => {
    if (!strVal?.startsWith(PREV)) return;
    onChange?.(customVal as string);
  }, [customVal, strVal]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            dotSize: 8,
          },
        },
      }}
    >
      <Row gutter={[16, 16]}>
        {options?.map((item, index) => (
          <Col key={`${item.keyword}-${index}`} span={12}>
            {item.keyword === 'else' ? (
              <Radio
                checked={value?.startsWith(PREV)}
                value={item.keyword}
                onChange={() =>
                  setStrVal(
                    customVal.startsWith(PREV)
                      ? customVal
                      : `${PREV}${customVal}`
                  )
                }
              >
                <Space>
                  其他：
                  <Input
                    disabled={!value?.startsWith(PREV)}
                    style={{ width: '150px' }}
                    value={customVal?.split(PREV)?.[1]}
                    onChange={(e) => setCustomVal(`${PREV}${e.target.value}`)}
                    placeholder="请输入"
                  />
                </Space>
              </Radio>
            ) : (
              <Radio
                checked={value === item.keyword}
                value={item.keyword}
                onChange={() => setStrVal(item.keyword)}
              >
                {item.value}
              </Radio>
            )}
          </Col>
        ))}
      </Row>
    </ConfigProvider>
  );
};

export default RadioInput;
