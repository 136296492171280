import { message, Modal } from 'antd';
import React, { FC, useCallback, useState } from 'react';

import { throttle } from '../../utils';
import { collect } from '../../utils/api';
import Btn from '../btn';
import Textarea from '../textarea';

import s from './style.module.scss';

interface IProps {
  isLoading?: boolean;
  collectId: number;
  collectStatus: number; // 0 未收藏 1 已收藏
  children?: React.ReactNode;
  onSuccess?: (isSuccess: boolean, res?: any) => void;
}

const DejaCollectRemark: FC<IProps> = ({
  isLoading,
  children,
  collectId,
  collectStatus,
  onSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState('');

  const collectFn = useCallback(
    (id: number, collectStatus: number, remarks: string) => {
      return collect({
        customerCollects: [
          {
            materialHistoryId: id,
            remarks,
          },
        ],
        status: Math.abs((collectStatus ?? 0) - 1),
      })
        .then((res: any) => {
          message.success(collectStatus ? '取消收藏成功' : '收藏成功');
          onSuccess?.(true, res);
          setOpen(false);
        })
        .catch(() => {
          onSuccess?.(false);
        });
    },
    [collectId, collectStatus]
  );

  const throttleCollect = throttle(collectFn, 1000);

  return (
    <div className={s.container}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => {
            if (isLoading) return;
            return Promise.resolve(child.props?.onClick?.()).then(() => {
              // 处理点击事件
              if (!collectStatus) {
                setOpen(true);
              } else {
                // 处理已收藏
                throttleCollect(collectId, collectStatus, '');
              }
            });
          },
        });
      })}
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        centered
        destroyOnClose
        width={440}
        maskClosable={false}
        style={{ pointerEvents: 'auto' }}
        modalRender={() => {
          return (
            <div className={s.modal}>
              <div className={s.title}>添加收藏</div>
              <div className={s['sub-title']}>收藏备注</div>
              <Textarea
                maxLength={50}
                pureText
                value={remark}
                onChange={setRemark}
                placeholder="请输入备注，不超过50个字"
              />
              <div className={s.footer}>
                <Btn buttonType="goast" onClick={() => setOpen(false)}>
                  取消
                </Btn>
                <Btn
                  onClick={() => {
                    throttleCollect(collectId, collectStatus, remark);
                  }}
                >
                  确认
                </Btn>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DejaCollectRemark;
