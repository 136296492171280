export enum STEP {
  STEP_1 = 1,
  STEP_2 = 2,
}

// 商品类目
export enum GoodCategory {
  AI = 1, // AI打版
  MODEL = 2, // 模特试衣
  STYLE = 3, // 样衣制作
}

// 问题类型
export enum QuestionType {
  RADIO = 1, // 单选
  CHECKBOX = 2, // 多选
  TEXT = 3, // 文本
  OTHER = 4, // 其他
}

// 数据单位
export enum Unit {
  M = 1, // 米
  CM = 2, // 厘米
  MM = 3, // 毫米
  GM = 4, // 克每平方
}

export const UnitMap = {
  [Unit.M]: '米',
  [Unit.CM]: '厘米',
  [Unit.MM]: '毫米',
  [Unit.GM]: '克/平方米',
};

// 数据类型
export enum DataType {
  NUMBER_LESS_9 = 1000, // 数字类型，不超过9位
  STRING_LESS_10 = 2000, // 字符串类型，不超过10个字
  SRTING_LESS_100 = 2001, // 字符串类型，不超过100个字
  NUMBER_FIXED_1 = 3000, // 小数类型，精准到小数点后一位
  NUMBER_FIXED_2 = 4000, // 小数类型，精准到小数点后两位
}

export const DataTypeMap = {
  [DataType.NUMBER_LESS_9]: '数字类型，不超过9位',
  [DataType.STRING_LESS_10]: '字符串类型，不超过10个字',
  [DataType.SRTING_LESS_100]: '字符串类型，不超过100个字',
  [DataType.NUMBER_FIXED_1]: '最长4位整数，保留1位小数',
  [DataType.NUMBER_FIXED_2]: '最长4位整数，保留2位小数',
};

export const DataTypeRegMap = {
  [DataType.NUMBER_LESS_9]: /^[1-9]\d{0,8}$|^0$/,
  [DataType.STRING_LESS_10]: /^.{1,10}$/,
  [DataType.SRTING_LESS_100]: /^.{1,100}$/,
  [DataType.NUMBER_FIXED_1]: /^[1-9]\d{0,3}(?:\.\d)?$|^0(?:\.\d)?$/,
  [DataType.NUMBER_FIXED_2]: /^[1-9]\d{0,3}(?:\.\d{1,2})?$|^0(?:\.\d{1,2})?$/,
};

// 问题类型
export const QuestionTypeMap = {
  [QuestionType.RADIO]: '单选',
  [QuestionType.CHECKBOX]: '多选',
  [QuestionType.TEXT]: '文本',
  [QuestionType.OTHER]: '问答',
};

// 订单状态 1：已下单  2：待发货 3：已发货 4：待改价 5：改价完成 6：订单完成 7：订单取消'
export enum OrderStatus {
  ORDER_STATUS_PAID = 1, // 已下单
  ORDER_STATUS_UNSHIPPED = 2, // 待发货
  ORDER_STATUS_SHIPPED = 3, // 已发货
  ORDER_STATUS_CHANGE = 4, // 待改价
  ORDER_STATUS_CHANGE_COMPLETE = 5, // 改价完成
  ORDER_STATUS_COMPLETE = 6, // 订单完成
  ORDER_STATUS_CANCEL = 7, // 订单取消
}

// 前端展示不同于后端
export const OrderStatusMap = {
  [OrderStatus.ORDER_STATUS_PAID]: '已下单',
  [OrderStatus.ORDER_STATUS_UNSHIPPED]: '待发货',
  [OrderStatus.ORDER_STATUS_SHIPPED]: '已发货',
  [OrderStatus.ORDER_STATUS_CHANGE]: '待改价',
  [OrderStatus.ORDER_STATUS_CHANGE_COMPLETE]: '改价完成',
  [OrderStatus.ORDER_STATUS_COMPLETE]: '已完成',
  [OrderStatus.ORDER_STATUS_CANCEL]: '已取消',
};

export const GoodCategoryMap = {
  [GoodCategory.AI]: 'AI打版', // AI打版
  [GoodCategory.MODEL]: '模特试衣', // 模特试衣
  [GoodCategory.STYLE]: '样衣制作', // 样衣制作
};

// 根据类目进行提示
export const GoodCategoryTipsMap = {
  [GoodCategory.AI]: '请选择需要AI打版的图片', // AI打版
  [GoodCategory.MODEL]: '请选择需要模特试衣的图片', // 模特试衣
  [GoodCategory.STYLE]: '请选择需要样衣制作的图片', // 样衣制作
};
