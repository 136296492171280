import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse, CollapseProps, ConfigProvider } from 'antd';
import React, { FC } from 'react';

import s from './style.module.scss';

interface IProps extends CollapseProps {
  labelName: string;
  labelIconName?: string;
  children: React.ReactNode;
  keyName?: string;
  activeKeyName?: string;
}

const Collapse: FC<IProps> = ({
  labelName,
  labelIconName,
  children,
  keyName,
  activeKeyName,
  ...restProps
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: '17px 16px',
            contentBg: '#18181C',
          },
        },
      }}
    >
      <AntdCollapse
        className={s.container}
        expandIconPosition="end"
        style={{
          background: '#18181c',
          marginBottom: '12px',
          borderRadius: '12px',
        }}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? -90 : 90}
            style={{
              color: '#B7BAC7',
            }}
          />
        )}
        activeKey={activeKeyName ? [activeKeyName] : []}
        items={[
          {
            key: keyName,
            label: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  paddingLeft: '30px',
                  fontSize: '16px',
                }}
              >
                <span
                  className={`font_family ${labelIconName}`}
                  style={{
                    fontSize: '28px',
                    position: 'absolute',
                    left: 0,
                  }}
                ></span>
                {labelName}
              </div>
            ),
            children,
          },
        ]}
        {...restProps}
      />
    </ConfigProvider>
  );
};

export default Collapse;
