import { Modal } from 'antd';
import cs from 'classnames';
import Konva from 'konva';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import useGenerate from '../../hooks/useGenerate';
import { dataUrlToFile } from '../../utils';
import { uploadPicture } from '../../utils/api';
import Btn from '../btn';
import { generateImagePrev, pullGenerateImageResult } from './api';
import OperateImage from './components/operate-image';
import { Tabs, TabsMap } from './constants';

import s from './style.module.scss';

export interface IProps extends PropsWithChildren {
  local?: boolean;
  url: string;
  params?: any;
  onChange?: (params: any, url: string) => void;
  disabled?: boolean;
  defaultOpen?: boolean;
  backgroundColor?: '#fff' | 'transparent';
  divideFile?: (file: File) => void;
}

const PrevoperateImage: FC<IProps> = ({
  local = false,
  url,
  children,
  onChange,
  params,
  disabled,
  defaultOpen = false,
  backgroundColor = '#fff',
  divideFile,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [currentTab, setCurrentTab] = useState(Tabs.ORIGINAL);
  const [scale, setScale] = useState(100);
  const [rotate, setRotate] = useState(0);
  const [pos, setPos] = useState({ x: 160, y: 160 });
  const [stage, setStage] = useState<Konva.Stage>();

  const { loading, startGenerate, result } = useGenerate({
    generateApi: () =>
      generateImagePrev(
        url.startsWith('data:') ? { base64: url?.split(',')?.[1] } : { url }
      ),
    pollingResultApi: pullGenerateImageResult,
  });

  useEffect(() => {
    if (local || !url || !open || result) return;
    startGenerate();
  }, [url, open, result, local]);

  useEffect(() => {
    if (result || local) {
      setCurrentTab(Tabs.OPTIMIZED);
    }
  }, [result, local]);

  const modelContent = () => (
    <div className={s.content}>
      <div
        className={s.close}
        onClick={() => {
          setOpen(false);
        }}
      >
        <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
      </div>
      <div className={s.tags}>
        <div
          className={cs(s.tag, {
            [s.active]: currentTab === Tabs.ORIGINAL,
          })}
          onClick={() => setCurrentTab(Tabs.ORIGINAL)}
        >
          {TabsMap[Tabs.ORIGINAL]}
        </div>
        <div
          className={cs(s.tag, {
            [s.active]: currentTab === Tabs.OPTIMIZED,
          })}
          onClick={() => {
            if (loading) return;
            setCurrentTab(Tabs.OPTIMIZED);
          }}
        >
          {TabsMap[Tabs.OPTIMIZED]}
        </div>
      </div>
      <div
        className={cs(s.wrapper, {
          [s.hidden]: currentTab !== Tabs.ORIGINAL,
        })}
      >
        <div className={s['origin-img-con']}>
          <img className={s.img} src={url} />
        </div>
        <div
          className={cs(s['loading-container'], {
            [s.show]: loading,
          })}
        >
          <div className={s.percent}></div>
        </div>
      </div>
      <div
        className={cs({
          [s.hidden]: currentTab !== Tabs.OPTIMIZED || loading,
        })}
      >
        <OperateImage
          scale={scale}
          changeScale={setScale}
          rotate={rotate}
          changeRotate={setRotate}
          url={local ? url : result}
          pos={pos}
          changePos={setPos}
          changeStage={setStage}
          backgroundColor={backgroundColor}
          fill={local}
        />
      </div>
      <div className={s.buttons}>
        <Btn
          buttonType="goast"
          className={s.button}
          onClick={() => setOpen(false)}
        >
          取消
        </Btn>
        <Btn
          disabled={loading}
          className={s.button}
          onClick={() => {
            setOpen(false);
            stage
              ?.find('Transformer')
              .forEach((node: Konva.Node) => node?.destroy());
            const base64 = stage?.toDataURL({
              mimeType:
                backgroundColor === 'transparent' ? 'image/png' : 'image/jpeg',
              pixelRatio: 512 / 320,
            });
            const file = dataUrlToFile(base64 as string);
            if (divideFile) {
              return divideFile(file);
            }
            const data = new FormData();
            data.append('file', file);
            data.append('model', 'sd');
            uploadPicture(data).then(({ cosUrl }) =>
              onChange?.(params, cosUrl)
            );
          }}
        >
          确认
        </Btn>
      </div>
    </div>
  );

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => {
            if (disabled) return;
            setOpen(true);
          },
        });
      })}
      <Modal
        style={{ pointerEvents: 'auto' }}
        open={open}
        modalRender={modelContent}
        centered
      ></Modal>
    </>
  );
};

export default PrevoperateImage;
