// import axios from 'axios';
import Konva from 'konva';
import { useEffect, useState } from 'react';

import { dataURLToBlob, dataUrlToFile } from '../../../utils/index';
import { areaDelete } from '../api';
import useBgLayer from './useBgLayer';

export default function useDaubLayer({
  stage,
  eventRecord,
  stroke,
  bgUrl,
  editable,
  setEditable,
}: {
  bgUrl: string;
  stage: Konva.Stage;
  editable: boolean;
  bindEvent?: any;
  stroke: number;
  eventRecord: any;
  setEditable: (editable: boolean) => void;
}) {
  const [layerWhite, setLayerWhite] = useState<any>();
  const [layer, setLayer] = useState<any>();
  const [currentLine, setCurrentLine] = useState<any>();
  const [bg, setBg] = useState<string>(bgUrl);
  const [bgList, setBgList] = useState<string[]>([]);

  const { toUrl } = useBgLayer(bg, stage);

  const getDaubImage = () => {
    const bgUrl = toUrl();
    setEditable(false);
    // 添加白色线的图层
    const line = new Konva.Line({
      stroke: '#fff',
      strokeWidth: stroke,
      globalCompositeOperation: 'source-over',
      points: currentLine?.points(),
      lineCap: 'round',
      lineJoin: 'round',
    });
    layerWhite.add(line);

    const maskUrl = layerWhite.toDataURL({
      pixelRatio: 2,
    });
    const image = dataUrlToFile(bgUrl);
    const mask = dataURLToBlob(maskUrl);

    const formData = new FormData();

    formData.append('image', image);
    formData.append('mask', mask);
    return areaDelete(formData);
  };

  useEffect(() => {
    if (bg) {
      setBgList((list: any) => [...new Set([...list, bg].filter(Boolean))]);
    }
    if (bg?.includes('blob:')) {
      localStorage.setItem('hasOperation', 'Y');
    } else {
      localStorage.setItem('hasOperation', '');
    }
  }, [bg]);

  useEffect(() => {
    if (bgUrl) {
      setBg(bgUrl);
      setBgList([bgUrl]);
    }
  }, [bgUrl]);

  useEffect(() => {
    if (!stage) return;
    const layer1 = new Konva.Layer({
      name: 'daubLayerWhiteLine',
    });
    const layer = new Konva.Layer({
      name: 'daubLayer',
    });
    stage?.add(layer1);
    stage?.add(layer);
    setLayerWhite(layer1);
    setLayer(layer);
  }, [stage]);

  useEffect(() => {
    if (!eventRecord || !layer || !editable) return;
    const { eventName, pos } = eventRecord;
    if (['mousedown', 'touchstart'].includes(eventName)) {
      const line = new Konva.Line({
        // stroke: '#6451e7',
        stroke: 'rgba(100, 81, 231, 0.6)',
        strokeWidth: stroke,
        globalCompositeOperation: 'source-over',
        points: [pos?.x ?? 0, pos?.y ?? 0, pos?.x ?? 0, pos?.y ?? 0],
        lineCap: 'round',
        lineJoin: 'round',
      });
      layer.add(line);
      setCurrentLine(line);
    }
  }, [eventRecord, layer, editable]);

  useEffect(() => {
    if (!eventRecord || !layer || !currentLine || !editable) return;
    const { eventName, pos } = eventRecord;
    if (['mousemove', 'touchmove'].includes(eventName)) {
      currentLine.points([...(currentLine?.points() ?? []), pos?.x, pos?.y]);
      layer.batchDraw();
    }
  }, [currentLine, eventRecord, layer, editable]);

  useEffect(() => {
    if (!eventRecord || !currentLine || !editable) return;
    const { eventName } = eventRecord;
    if (['mouseup', 'touchend', 'mouseout'].includes(eventName)) {
      setCurrentLine(null);
      setTimeout(() => {
        getDaubImage()
          .then((res: any) => {
            setEditable(true);
            const url = URL.createObjectURL(
              new Blob([new Uint8Array(res.data as Uint8Array)], {
                type: 'image/jpeg',
              })
            );
            setBg(url);
            // 清除之前在该图层上绘制的线段
            currentLine?.remove();
            layerWhite?.removeChildren();
          })
          .catch((err: any) => {
            console.log('err', err);
            setEditable(true);
          });
      }, 200);
    }
  }, [eventRecord, currentLine, layerWhite, editable]);

  return {
    prev: () => {
      const idx = bgList.indexOf(bg);
      if (idx <= 0) return;
      setBg(bgList[idx - 1]);
    },
    next: () => {
      const idx = bgList.indexOf(bg);
      if (idx >= bgList.length - 1) return;
      setBg(bgList[idx + 1]);
    },
    reset: () => {
      const bg0 = bgList[0];
      setBgList([]);
      setBg(bg0);
    },
    bgUrl: () => toUrl(),
    maskUrl: () =>
      layer.toDataURL({
        pixelRatio: 2,
      }),
    getBg: () => bg,
    setCurrentBg: () => {
      setBgList([]);
      setBg(bg);
    },
  };
}
