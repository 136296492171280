import { Tooltip } from 'antd';
import cn from 'classnames';
import { format as formatDate } from 'date-fns';
import React, { FC, useState } from 'react';

import { useUserInfo } from '../../contexts/user-info';
import { format as formatPrice } from '../../utils/price';
import { MemberPeriod, MemberPeriods } from './contants';

import s from './style.module.scss';

const Buy: FC = () => {
  const { userInfo } = useUserInfo();
  const [period, setPeriod] = useState<MemberPeriod>(MemberPeriod.Year);

  return (
    <div>
      <div>
        <div className={s['user-info']}>
          <div className={s['user-logo']}>
            {userInfo?.nickName?.slice(0, 1)}
          </div>
          <div className={s['nick-name']}>{userInfo?.nickName}</div>
        </div>
        <div className={s.content}>
          <div>
            <div className={s.choice}>
              <div className={cn([s.table, s[period]])}>
                <div className={s.periods}>
                  <div
                    className={cn({
                      active: period === MemberPeriod.Year,
                    })}
                    onClick={() => setPeriod(MemberPeriod.Year)}
                  >
                    年度会员
                  </div>
                  <div
                    className={cn({
                      active: period === MemberPeriod.Month,
                    })}
                    onClick={() => setPeriod(MemberPeriod.Month)}
                  >
                    月度会员
                  </div>
                </div>
                <div className={s.grid}>
                  <div className={s.th}>
                    <div className={s.product}>订购产品</div>
                    <div className={s.price}>产品价格</div>
                    <div className={s.period}>有效截止日</div>
                  </div>
                  <div className={s.td}>
                    <div className={s.product}>
                      {MemberPeriods[period].product}
                    </div>
                    <div className={s.price}>
                      CNY ￥
                      {formatPrice(
                        MemberPeriods[period].price,
                        false,
                        true,
                        true
                      )}
                    </div>
                    <div className={s.period}>
                      {formatDate(
                        MemberPeriods[period].period.start(),
                        'yyyy/MM/dd'
                      )}
                      -
                      {formatDate(
                        MemberPeriods[period].period.end(),
                        'yyyy/MM/dd'
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.desc}>
                <div>会员有效期：{MemberPeriods[period].periodDesc}</div>
                <div>会员权益说明：{MemberPeriods[period].benefitDesc}</div>
              </div>
            </div>
            <div className={s.protocol}>
              <div className={s.title}>DEJA用户协议条款</div>
              <div>
                为避免交易纠纷，请仔细阅读本协议，如无法同意请终止交易。
              </div>
              <div>
                1、本公司所售会员权益，版权归DEJA所有，并且由DEJA承担所有法律责任和义务。
              </div>
              <div>2、本公司所售为虚拟权益，一旦发货不支持退货。</div>
              <div>
                3、本公司订单均可以开具增值税电子发票，开票请
                <Tooltip
                  trigger="click"
                  title="客服电话：0571-88761133"
                  placement="bottom"
                >
                  <span className={s.service}>联系客服</span>
                </Tooltip>
                。
              </div>
            </div>
          </div>
          <div>
            <div className={s.card}>
              <div>{MemberPeriods[period].name}</div>
              <div>
                ￥
                <span className={s['price-number']}>
                  {formatPrice(MemberPeriods[period].price, false, true, true)}
                </span>
                /{MemberPeriods[period].unit}
              </div>
            </div>
            <div className={s.qrcode}>
              <img src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/qrcode.png" />
              <div>微信扫描上方二维码进行开通</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
