import { Button, ButtonProps } from 'antd';
import cs from 'classnames';
import React, { FC } from 'react';

import s from './style.module.scss';

type IButtonProps = ButtonProps & {
  children?: React.ReactNode;
  isGenerate?: boolean;
  buttonType?: 'default' | 'goast';
};

const Btn: FC<IButtonProps> = (props) => {
  const {
    loading,
    isGenerate,
    buttonType,
    children,
    disabled,
    className = '',
    onClick,
    ...restProps
  } = props;

  if (buttonType === 'goast') {
    return (
      <Button
        {...restProps}
        className={cs(
          s.goast,
          {
            [s.disabled]: disabled,
            [s.loading]: loading,
          },
          className
        )}
        onClick={(e) => {
          if (disabled || loading) return;
          onClick?.(e);
        }}
      >
        {isGenerate && <div className={s.icon}></div>}
        {children}
      </Button>
    );
  }

  return (
    <Button
      {...restProps}
      className={cs(
        s.container,
        {
          [s.disabled]: disabled,
          [s.loading]: loading,
          [s.generate]: isGenerate,
        },
        className
      )}
      onClick={(e) => {
        if (disabled || loading) return;
        onClick?.(e);
      }}
      loading={loading && !isGenerate}
    >
      {isGenerate && <div className={s.icon}></div>}
      {children}
    </Button>
  );
};

export default Btn;
export * from './optimization';
export * from './return';
