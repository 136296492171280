import { Image } from 'antd';
import { FC } from 'react';

import s from '../style.module.scss';

interface IProps {
  key?: string;
  title?: string;
  children?: any[];
}

const Content: FC<IProps> = ({ title, children }) => {
  return (
    <div className={s.answer}>
      <div className={s.title}>{title}</div>
      {children?.map(({ title, pic, text }, index) => (
        <div
          key={index}
          style={{
            marginBottom: '-16px',
          }}
        >
          {title && <div className={s['third-title']}>{title}</div>}
          {pic && (
            <Image src={pic} width={500} className={s.img} preview={false} />
          )}
          {text.map((t: string, i: number) => (
            <div key={i} className={s.text}>
              {t}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Content;
