import { Checkbox, Col, Input, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';

interface IProps {
  options?: Array<{
    keyword: string; // 选项关键字
    value: string; // 选项值
    dataType?: string; // 选项数据类型
    unit?: string; // 选项单位
  }>;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const PREV = 'else-';

const RadioInput: FC<IProps> = ({ value, options, onChange }) => {
  const [arrVal, setArrVal] = useState<string[]>(value as string[]);
  const [customVal, setCustomVal] = useState(
    value?.find((cell: string) => cell?.toString().startsWith(PREV)) ?? ''
  );

  useEffect(() => {
    onChange?.(arrVal);
  }, [arrVal]);

  useEffect(() => {
    setArrVal((arr) => {
      const index = arr?.findIndex((cell) => cell.startsWith(PREV));
      if (index !== -1) {
        const newArr = [...(arr ?? [])];
        newArr.splice(index, 1, customVal);
        return newArr;
      }
      return arr ?? [];
    });
  }, [customVal]);

  return (
    <Row gutter={[16, 16]}>
      {options?.map((item, index) => (
        <Col key={`${item.keyword}-${index}`} span={12}>
          {item.keyword === 'else' ? (
            <Checkbox
              checked={value?.some((cell) => cell?.toString().startsWith(PREV))}
              value={item.keyword}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setArrVal((arr) => [
                    ...arr,
                    customVal?.startsWith(PREV) ? customVal : PREV,
                  ]);
                } else {
                  setArrVal((arr) =>
                    arr.filter((cell) => !cell?.toString().startsWith(PREV))
                  );
                }
              }}
            >
              <Space>
                其他：
                <Input
                  disabled={
                    !value?.some((cell: string) =>
                      cell?.toString().startsWith(PREV)
                    )
                  }
                  style={{ width: '150px' }}
                  value={customVal?.split(PREV)?.[1]}
                  onChange={(e) => setCustomVal(`${PREV}${e.target.value}`)}
                  placeholder="请输入"
                />
              </Space>
            </Checkbox>
          ) : (
            <Checkbox
              checked={value?.includes(item.keyword)}
              value={item.keyword}
              onChange={() => {
                value?.includes(item.keyword)
                  ? setArrVal((prev) => prev.filter((v) => v !== item.keyword))
                  : setArrVal((prev) => [...prev, item.keyword]);
              }}
            >
              {item.value}
            </Checkbox>
          )}
        </Col>
      ))}
    </Row>
  );
};

export default RadioInput;
