import { message, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import ColorSelect from '../../../../pages/generate/components/colorSelect';
import FixPrompt from '../../../../pages/generate/components/fix-prompt';
import { transImage } from '../../../../utils';
import Btn from '../../../btn';
import Icon from '../../../icon';
import ModalImage, { Tabs } from '../../../modal-image';
import TextArea from '../../../textarea';

import s from './style.module.scss';

interface IProps {
  placeholder?: string;
  onStart?: (item: any, prompt: string, color: string) => void;
}

const tabs = [Tabs.Recommend, Tabs.History, Tabs.Location];
const MAXLENGTH = 500;

const LocalCollectParams: FC<IProps> = ({ placeholder, onStart }) => {
  const [selectItem, setSelectItem] = useState<any>();
  const [open, setOpen] = useState(false);
  const [inputsOpen, setInputsOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [color, setColor] = useState<any>();

  useEffect(() => {
    if (!open && !inputsOpen) {
      setSelectItem(null);
    }
  }, [open, inputsOpen]);

  return (
    <div className={s.container}>
      <div
        className={s['empty-container']}
        onClick={() => {
          setOpen(true);
        }}
      >
        <img
          src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/card-generate-empty.png"
          className={s.icon}
        />
        <div className={s.text}>{placeholder}</div>
      </div>
      <ModalImage
        needMd
        key={`${open}`}
        item={selectItem}
        tabs={tabs}
        open={open}
        close={() => {
          setOpen(false);
        }}
        onChange={(selectItem) => {
          setSelectItem(selectItem);
          setOpen(false);
        }}
        footer={({ select }) => {
          return (
            <div className={s.footer}>
              <Btn
                buttonType="goast"
                onClick={() => {
                  setOpen(false);
                }}
              >
                取消
              </Btn>
              <Btn
                onClick={() => {
                  select();
                  setInputsOpen(true);
                }}
              >
                下一步
              </Btn>
            </div>
          );
        }}
      />
      <Modal
        centered
        destroyOnClose
        open={inputsOpen}
        style={{ pointerEvents: 'auto' }}
        width={selectItem ? 686 : 390}
        onCancel={() => {
          setInputsOpen(false);
        }}
        modalRender={() => {
          return (
            <div className={s.modal}>
              <div className={s.title}>设计描述</div>
              <div
                className={s['icon-wrap']}
                onClick={() => {
                  setInputsOpen(false);
                }}
              >
                <Icon
                  iconName="icon-a-lishijiluerjiyemianchahao"
                  isTransparent
                />
              </div>
              <div className={s.content}>
                {selectItem ? (
                  <div className={s['img-radius']}>
                    <img src={transImage(selectItem.url, 'webp')} />
                  </div>
                ) : null}
                <div className={s.inputs}>
                  <div className={s.grids}>
                    <ColorSelect code={color} onChange={setColor} />
                    <FixPrompt
                      originPrompt={prompt}
                      onChangePrompt={setPrompt}
                    />
                  </div>
                  <TextArea
                    placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
                    rows={7}
                    maxLength={MAXLENGTH}
                    value={prompt}
                    onChange={(val) =>
                      setPrompt(val.trim()?.slice(0, MAXLENGTH))
                    }
                  />
                </div>
              </div>
              <div className={s.footer}>
                <Btn
                  buttonType="goast"
                  onClick={() => {
                    setInputsOpen(false);
                  }}
                >
                  取消
                </Btn>
                <Btn
                  buttonType="goast"
                  onClick={() => {
                    setInputsOpen(false);
                    setOpen(true);
                    setSelectItem(null);
                  }}
                >
                  上一步
                </Btn>
                <Btn
                  onClick={() => {
                    if (!prompt.trim()) {
                      return message.error('请先输入描述词');
                    }
                    onStart?.(selectItem, prompt, color);
                  }}
                >
                  生成设计图
                </Btn>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default LocalCollectParams;
