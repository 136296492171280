import { CaretDownOutlined } from '@ant-design/icons';
import type { SelectProps } from 'antd';
import { ConfigProvider, Select as AntdSelect } from 'antd';
import { FC, useRef } from 'react';

import s from './style.module.scss';

const Select: FC<SelectProps> = (props) => {
  const dom = useRef<any>();
  return (
    <ConfigProvider
      theme={{
        token: {
          lineWidth: 0,
          colorBgElevated: '#212126',
          colorText: '#f2f4ff',
          borderRadius: 12,
        },
        components: {
          Select: {
            selectorBg: '#18181C',
            optionSelectedBg: '#373740',
          },
        },
      }}
    >
      <div ref={dom}>
        <AntdSelect
          {...props}
          className={s.select}
          suffixIcon={<CaretDownOutlined />}
          getPopupContainer={() => dom.current}
        />
      </div>
    </ConfigProvider>
  );
};

export default Select;
