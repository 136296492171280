import { message } from 'antd';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useRef } from 'react';

import Btn from '../../components/btn';
import ButtonParams from '../../components/button-params';
import DejaGenerateResult from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import OptUpload from '../../components/opt-upload';
import {
  picImageItemAtom,
  picParamsAtom,
  picResultAtom,
  picStatusAtom,
} from '../../store/generation';
import { blendGenerate, blendResult } from '../../utils/api';

import s from './style.module.scss';

const GenerateFromPicture: FC = () => {
  const timer = useRef<NodeJS.Timeout>();

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const [picStatus, setPicStatus] = useAtom(picStatusAtom);
  const [picParams, setPicParams] = useAtom(picParamsAtom);
  const [picResult, setPicResult] = useAtom(picResultAtom);
  const [picImageItem, setPicImageItem] = useAtom(picImageItemAtom);

  const changeStrength = (value: number) => {
    setPicParams((prev) => ({ ...prev, strength: value }));
  };

  const changeImgUrl = (url: string) => {
    setPicParams((prev) => ({ ...prev, imgUrl: url }));
  };
  const changePromptIdentity = (promptIdentity: string) => {
    setPicParams((prev) => ({ ...prev, promptIdentity }));
  };

  const changeLoading = (loading: boolean) => {
    setPicStatus((prev) => ({ ...prev, loading }));
  };

  const generateApi = () => {
    if (!picParams.imgUrl) {
      message.error('图片不能为空');
      return Promise.reject('图片不能为空');
    }
    changeLoading(true);
    blendGenerate({
      imgUrl: picParams.imgUrl,
      labelType: 14,
      strength: Number((picParams.strength / 10).toFixed(1)),
    })
      .then(changePromptIdentity)
      .catch(() => changeLoading(false));
  };

  const pollingResultApi = () => {
    if (!picParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), picStatus.timing);
      return;
    }
    blendResult({
      labelType: 14,
      promptIdentity: picParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setPicResult(res);
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            picStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setPicResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), picStatus.timing);
  };

  useEffect(() => {
    picStatus.loading && !picResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [picStatus.loading, picParams.promptIdentity, picResult]);

  return (
    <>
      <PageLayout
        left={
          <DejaGenerateResult
            key={picParams.promptIdentity}
            loading={picStatus.loading as boolean}
            item={picResult}
            tipText="选择图片进行创意延伸吧！"
          />
        }
      >
        <OperateLayout
          title="单品延伸"
          footer={
            <Btn
              isGenerate
              className={s.button}
              disabled={!picParams.imgUrl}
              loading={picStatus.loading}
              onClick={() => {
                if (picStatus.loading) return;
                generateApi();
                setPicResult(null);
              }}
            >
              生成设计图
            </Btn>
          }
        >
          <div className={s.content}>
            <div className={s.box}>
              <div className={s.title}>灵感图</div>
              <OptUpload
                imageItem={picImageItem}
                onChange={(imageItem) => {
                  setPicImageItem(imageItem);
                  changeImgUrl(imageItem?.cosUrl);
                }}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>
                <span>创新幅度</span>
              </div>
              <div className={s['slider-con']}>
                <ButtonParams
                  value={picParams.strength}
                  onChange={changeStrength}
                />
              </div>
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </>
  );
};

export default GenerateFromPicture;
