import { Modal } from 'antd';
import cs from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { transImage } from '../../utils';
import { listHistory } from '../../utils/api';
import Btn from '../btn';
import LoadList from '../load-list';
import UploadLocal from '../upload';
import { getDraftLabels, getLabels, pageRecommendImgs } from './api';
import { Tabs } from './constants';
import {
  IGetLabelsRes,
  ILabel,
  ImageQuality,
  IPageRecommendParams,
} from './types';

import s from './style.module.scss';

const TabList = [
  {
    key: Tabs.Recommend,
    text: '推荐图库',
  },
  {
    key: Tabs.Drafts,
    text: '推荐线稿',
  },
  {
    key: Tabs.History,
    text: '历史记录',
  },
  {
    key: Tabs.Location,
    text: '本地图片',
  },
];

const HistoryTabs = [
  {
    key: 0,
    title: '全部',
  },
  {
    key: 1,
    title: '我的收藏',
  },
];

interface IProps {
  tabs?: Tabs[];
  open: boolean;
  item?: any;
  needMd?: boolean;
  close?: () => void;
  onChange?: (item: any) => void;
  footer?: ({ select }: any) => React.ReactNode;
}

const ModalImage: FC<IProps> = ({
  tabs = [Tabs.Recommend, Tabs.History, Tabs.Location],
  open,
  close,
  onChange,
  item,
  needMd,
  footer,
}) => {
  const tabList = TabList.filter((tab) => tabs.includes(tab.key));
  const [clothTab, setClothTab] = useState<Tabs>(tabs?.[0]);
  const [imgItem, setImgItem] = useState<any>(item);
  const [model, setModel] = useState<ILabel | null>(null);
  const [style, setStyle] = useState<ILabel | null>(null);
  const [labels, setLabels] = useState<IGetLabelsRes>({
    models: [],
    styles: [],
  });
  const [historyType, setHistoryType] = useState(1);

  const styles = useMemo(() => {
    if (model) {
      return model.children || [];
    }

    return labels.styles;
  }, [model, labels]);

  const loadRecommends = useCallback(
    (p: { page: number; size: number }) => {
      const params: IPageRecommendParams = { ...p };
      params.type =
        clothTab === Tabs.Drafts
          ? ImageQuality.DraftCloth
          : ImageQuality.StandCloth;
      if (model) {
        params.pid = model.id;

        if (style) {
          params.labelId = style.id;
        }
      } else if (style) {
        params.labelNameDes = style.nameDes;
      }

      return pageRecommendImgs(params);
    },
    [model, style, clothTab]
  );

  useEffect(() => {
    setModel(null);
    setStyle(null);
  }, [clothTab]);

  useEffect(() => {
    setStyle(null);
  }, [model]);

  useEffect(() => {
    setImgItem(null);
  }, [clothTab, model, style]);

  useEffect(() => {
    if (!item) {
      setImgItem(null);
    }
  }, [item]);

  useEffect(() => {
    if (!open) return;
    if (tabs.includes(Tabs.Recommend)) {
      getLabels().then((res: IGetLabelsRes) => {
        setLabels(res);
      });
    } else if (tabs.includes(Tabs.Drafts)) {
      getDraftLabels().then((res: ILabel[]) => {
        setLabels({
          models: res,
          styles: [],
        });
      });
    }
  }, [open, tabs]);

  const loadListKey = useMemo(
    () => `${String(model?.id)}_${String(style?.id)}`,
    [model, style]
  );

  const renderTabContent = () => {
    // 历史记录
    if (clothTab === Tabs.History) {
      return (
        <div>
          <div className={s['history-tabs']}>
            {HistoryTabs.map(({ title, key }) => {
              return (
                <div
                  className={cs(s.tab, {
                    [s.active]: historyType === key,
                  })}
                  key={key}
                  onClick={() => setHistoryType(key)}
                >
                  {title}
                </div>
              );
            })}
          </div>
          <div className={s.content}>
            <LoadList
              key={`history_${historyType}`}
              activeItem={imgItem ?? item}
              loadData={(params) =>
                listHistory({
                  ...params,
                  isEdit: 1,
                  collectStatus: historyType,
                })
              }
              onSelect={(item) => {
                // console.log('item', item);
                const newItem = {
                  ...item,
                  imageUrl: item.url,
                };
                newItem.cosUrl = item.url;
                setImgItem(newItem);
              }}
              renderEmpty={() => (
                <div className={s.empty}>
                  <img
                    className={s.img}
                    src={
                      historyType
                        ? 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/collect-empty.png'
                        : 'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/history/history-empty.png'
                    }
                    alt=""
                  />
                  <div className={s.tip}>
                    暂无{historyType ? '收藏记录' : '历史记录'}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      );
    }

    // 本地图片
    if (clothTab === Tabs.Location) {
      return (
        <div className={s['con-wrap']}>
          <div className={s.content}>
            <UploadLocal
              needMd={needMd}
              onChange={(item) => {
                setImgItem(item);
              }}
              activeItem={imgItem ?? item}
            />
          </div>
          <div className={s['text-tip']}>
            建议使用512X512px的白底图来生图，您可使用智能优化功能处理。
          </div>
        </div>
      );
    }

    // 推荐图库和线稿图，默认
    return (
      <div className={s.recommend}>
        <div className={s.arrow}></div>
        {clothTab === Tabs.Drafts ? (
          <div className={s['history-tabs']}>
            <div
              className={cs([
                s.tab,
                {
                  [s.active]: !model,
                },
              ])}
              onClick={() => setModel(null)}
            >
              全部
            </div>
            {labels.models?.map((item) => (
              <div
                className={cs([
                  s.tab,
                  {
                    [s.active]: model?.id === item.id,
                  },
                ])}
                key={item.id}
                onClick={() => setModel(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        ) : (
          <div className={s['design-choose']}>
            <div
              className={cs([
                s.radio,
                {
                  [s.active]: !model,
                },
              ])}
              onClick={() => setModel(null)}
            >
              全部
            </div>
            {labels.models?.map((item) => (
              <div
                className={cs([
                  s.radio,
                  {
                    [s.active]: model?.id === item.id,
                  },
                ])}
                key={item.id}
                onClick={() => setModel(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}

        {/* 二级分类 */}
        {clothTab === Tabs.Drafts ? null : (
          <div className={s['style-choose']}>
            <div
              className={cs([
                s.radio,
                {
                  [s.active]: !style,
                },
              ])}
              onClick={() => setStyle(null)}
            >
              全部
            </div>
            {styles?.map((item) => (
              <div
                className={cs([
                  s.radio,
                  {
                    [s.active]: style?.id === item.id,
                  },
                ])}
                key={item.id}
                onClick={() => setStyle(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
        <div
          className={s['img-content']}
          style={{
            height: `${clothTab === Tabs.Drafts ? 288 : 242}px`,
          }}
        >
          <LoadList
            height={clothTab === Tabs.Drafts ? 288 : 242}
            key={`${clothTab}_${loadListKey}`}
            activeItem={imgItem ?? item}
            loadData={loadRecommends}
            renderEmpty={() => (
              <div className={s.empty}>
                <img
                  className={s.img}
                  src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/history/collect-empty.png"
                  alt=""
                />
                <div className={s.tip}>暂无推荐数据</div>
              </div>
            )}
            itemRender={(item) => (
              <div
                key={item.id}
                className={cs(s['image-card'], {
                  [s.active]: item.id === imgItem?.activeId,
                })}
                onClick={() => {
                  // console.log('item', item);
                  const newItem = { ...item };
                  newItem.activeId = item.id; // 高亮显示
                  newItem.garryId = item.id; // md模型需要，生图匹配discord上图片地址
                  newItem.id = null; // 有id会导致后端接口判断有问题
                  setImgItem({
                    ...newItem,
                    url: item.imageUrl,
                    cosUrl: item.imageUrl,
                  });
                }}
              >
                {clothTab === Tabs.Drafts ? null : (
                  <div className={s.label}>{item.labels?.[0].name}</div>
                )}
                <img
                  src={transImage(item.imageUrl, 'webp')}
                  className={s.img}
                />
              </div>
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      centered
      destroyOnClose
      width={712}
      style={{ pointerEvents: 'auto' }}
      modalRender={() => (
        <div className={s.modal}>
          <div
            className={s.close}
            onClick={() => {
              setImgItem(null);
              close?.();
            }}
          >
            <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
          </div>
          <div className={s['tabs-con']}>
            <div className={s.tabs}>
              {tabList?.map(({ key, text }) => (
                <div
                  key={key}
                  className={cs(s.tab, {
                    [s.active]: clothTab === key,
                  })}
                  onClick={() => setClothTab(key)}
                >
                  {text}
                </div>
              ))}
            </div>
          </div>
          {renderTabContent()}
          {footer ? (
            <div className={s.footer}>
              {footer?.({
                select: () => {
                  onChange?.(imgItem);
                },
              })}
            </div>
          ) : (
            <div className={s.footer}>
              <Btn
                disabled={!imgItem}
                className={s.button}
                onClick={() => {
                  onChange?.(imgItem);
                }}
              >
                确认
              </Btn>
            </div>
          )}
        </div>
      )}
    ></Modal>
  );
};

export default ModalImage;

export * from './constants';
export * from './fabric';
export * from './types';
