import { Descriptions, Image, message, Modal, Space } from 'antd';
import cs from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Btn, { Return } from '../../components/btn';
import { downloadBtn } from '../../utils';
import { PayStatus, PayStatusMap } from '../../utils/types';
import {
  GoodCategory,
  GoodCategoryMap,
  OrderStatus,
  OrderStatusMap,
  QuestionType,
} from '../ban-consume/constants';
import { cancelOrder, getOrderDetail } from './api';

import s from './style.module.scss';

interface IProps {
  serviceType?: string;
}

const transStatus = (payStatus: number, orderStatus: number) => {
  if (
    payStatus === PayStatus.PAY_STATUS_UNPAID &&
    orderStatus === OrderStatus.ORDER_STATUS_PAID
  ) {
    return '待支付';
  }
  if (
    payStatus === PayStatus.PAY_STATUS_PAID &&
    orderStatus === OrderStatus.ORDER_STATUS_PAID
  ) {
    return '待发货';
  }
  if (orderStatus === OrderStatus.ORDER_STATUS_SHIPPED) {
    return '待收货';
  }
  return OrderStatusMap[orderStatus as OrderStatus];
};

const OrderDetail: FC<IProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceType = searchParams.get('serviceType');
  const id = searchParams.get('id');
  const payOrderNo = searchParams.get('payOrderNo');
  const [detail, setDetail] = useState<any | null>();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const [demand]: [any] = detail?.tradeOrderDetailInfoVOList ?? [];

  useEffect(() => {
    if (!serviceType || !id) {
      navigate(-1);
    }
  }, [serviceType]);

  const geDetail = useCallback(() => {
    getOrderDetail(id as string)
      .then(setDetail)
      .catch((err: any) => message.error(err.msg));
  }, [id]);

  useEffect(() => {
    if (!id) return;
    geDetail();
  }, [geDetail, id]);

  const modal = (
    <Modal
      width={440}
      centered
      open={open}
      closeIcon={false}
      onCancel={() => setOpen(false)}
      title={
        <div className={s['tip-title']}>
          <span className="font_family icon-a-tishidanchuangtishi"></span>提示
        </div>
      }
      footer={
        <div className={s['tip-footer']}>
          <Btn
            className={s.button}
            buttonType="goast"
            onClick={() => {
              setOpen(false);
              cancelOrder({
                orderId: id as string,
                status: OrderStatus.ORDER_STATUS_CANCEL,
              }).then(geDetail);
            }}
          >
            确认
          </Btn>
          <Btn
            className={s.button}
            onClick={() => {
              setOpen(false);
            }}
          >
            关闭
          </Btn>
        </div>
      }
    >
      <div className={s['tip-content']}>确认取消订单么？</div>
    </Modal>
  );

  const select = (
    <div>
      <div className={s.upload}>
        <div className={s['upload-content']}>
          <div className={s['detail-upload-wrapper']}>
            {demand?.picUrl && <img className={s.img} src={demand.picUrl} />}
            {demand?.picUrl && (
              <img
                className={s.watermark}
                src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/ban-watermark.png"
              />
            )}
          </div>
          {/* 待支付 */}
          {detail?.payStatus === PayStatus.PAY_STATUS_UNPAID &&
            detail?.status === OrderStatus.ORDER_STATUS_PAID && (
              <div className={s['wait-pay-button-group']}>
                <Space>
                  <Btn
                    buttonType="goast"
                    className={s.btn}
                    onClick={() => setOpen(true)}
                  >
                    取消订单
                  </Btn>
                  <Btn
                    className={s.btn}
                    onClick={() => {
                      navigate(
                        `/waitPay?serviceType=${serviceType}&id=${id}&payOrderNo=${payOrderNo}`
                      );
                    }}
                  >
                    继续支付
                  </Btn>
                </Space>
              </div>
            )}
          {[
            OrderStatus.ORDER_STATUS_SHIPPED,
            OrderStatus.ORDER_STATUS_COMPLETE,
          ].includes(detail?.status) && (
            <div className={s['wait-pay-button-group']}>
              <Space>
                <Btn
                  className={s.btn}
                  disabled={
                    OrderStatus.ORDER_STATUS_COMPLETE === detail?.status
                  }
                  buttonType={
                    OrderStatus.ORDER_STATUS_COMPLETE === detail?.status
                      ? 'goast'
                      : 'default'
                  }
                  onClick={() =>
                    cancelOrder({
                      orderId: id as string,
                      status: OrderStatus.ORDER_STATUS_COMPLETE,
                    }).then(geDetail)
                  }
                >
                  {OrderStatus.ORDER_STATUS_COMPLETE === detail?.status
                    ? '已完成'
                    : '确认收货'}
                </Btn>
                <Btn
                  buttonType="goast"
                  className={s.btn}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  查看预览图
                </Btn>
                <Btn
                  buttonType={
                    OrderStatus.ORDER_STATUS_COMPLETE === detail?.status
                      ? 'default'
                      : 'goast'
                  }
                  className={s.btn}
                  onClick={() => {
                    if (OrderStatus.ORDER_STATUS_COMPLETE !== detail?.status) {
                      return message.warning('确认收货后可下载文件');
                    }
                    return downloadBtn(
                      demand?.deliverablesUrl,
                      demand?.deliverablesName
                    );
                  }}
                >
                  下载文件
                </Btn>
              </Space>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={s.container}>
      <div className={s.left}>
        {select}
        <Image
          width={512}
          style={{ display: 'none' }}
          src=""
          preview={{
            visible,
            src: demand?.previewUrl,
            // src: `${demand?.previewUrl}?watermark/1/image/aHR0cDovL2V4YW1wbGVidWNrZXQtMTI1MDAwMDAwMC5jb3MuYXAtc2hhbmdoYWkubXlxY2xvdWQuY29tL3NodWl5aW5fMi5wbmc/gravity/southeast`,
            onVisibleChange: setVisible,
          }}
        />
        {modal}
        <Return onClick={() => navigate(-1)} />
      </div>
      <div className={s.right}>
        <div
          className={cs(s.content, {
            [s.stretch]: GoodCategory.AI !== demand?.categoryType,
          })}
        >
          <div className={s.card}>
            <div className={s.title}>订单信息</div>
            <div className={s['title-status']}>
              订单状态：{transStatus(detail?.payStatus, detail?.status)}
            </div>
            <div className={s.descriptions}>
              <Descriptions
                column={2}
                labelStyle={{
                  color: '#9fa4bd',
                  fontSize: '12px',
                }}
                contentStyle={{
                  fontSize: '12px',
                  color: '#f2f4ff',
                }}
              >
                {/* <Descriptions.Item label="打版图">
                  {detail?.order_no}
                </Descriptions.Item> */}
                <Descriptions.Item label="订单号码">
                  {detail?.orderNo}
                </Descriptions.Item>
                <Descriptions.Item label="下单时间">
                  {detail?.createTime}
                </Descriptions.Item>
                <Descriptions.Item label="服务类型">
                  {GoodCategoryMap[detail?.source as GoodCategory]}
                </Descriptions.Item>
                {/* <Descriptions.Item label="订单状态">
                  {detail?.status}
                </Descriptions.Item> */}
                <Descriptions.Item label="商品名称">
                  {demand?.skuName}
                </Descriptions.Item>
                <Descriptions.Item label="支付状态">
                  {PayStatusMap[detail?.payStatus as PayStatus]}
                </Descriptions.Item>
                <Descriptions.Item label="实付金额">
                  <div className={s['desc-price']}>
                    ￥<span>{detail?.totalPrice}</span>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="订单备注" span={2}>
                  {detail?.remark}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          {/* ai打板会有需求清单 */}
          {GoodCategory.AI === demand?.categoryType && (
            <div className={s.card}>
              <div className={s.title}>需求清单</div>
              <div className={s.descriptions}>
                <Descriptions
                  column={1}
                  labelStyle={{
                    color: '#9fa4bd',
                    fontSize: '12px',
                  }}
                  contentStyle={{
                    fontSize: '12px',
                    color: '#f2f4ff',
                  }}
                >
                  {demand?.listInfo?.[0]?.simpleAnswers?.map(
                    (item: any, index: number) => {
                      if (item.type === QuestionType.CHECKBOX) {
                        return (
                          <Descriptions.Item label={item.keyword} key={index}>
                            {item.optionValues
                              ?.map((cell: any) => cell.value)
                              ?.join(',')}
                          </Descriptions.Item>
                        );
                      }
                      if (item.type === QuestionType.TEXT) {
                        if (item.optionValues.length > 0) {
                          return [
                            <Descriptions.Item key="title" label={item.keyword}>
                              {' '}
                            </Descriptions.Item>,
                            <Descriptions.Item>
                              <div key={index} className={s['sub-dex-wrapper']}>
                                <Descriptions
                                  column={4}
                                  labelStyle={{
                                    color: '#9fa4bd',
                                    fontSize: '12px',
                                  }}
                                  contentStyle={{
                                    fontSize: '12px',
                                    color: '#f2f4ff',
                                  }}
                                >
                                  {item.optionValues?.map(
                                    (cell: any, idx: number) => (
                                      <Descriptions.Item
                                        key={idx}
                                        label={cell.keyword}
                                      >
                                        {cell.value}
                                      </Descriptions.Item>
                                    )
                                  )}
                                </Descriptions>
                              </div>
                            </Descriptions.Item>,
                          ];
                        }
                        return (
                          <Descriptions.Item label={item.keyword} key={index}>
                            {item.value}
                          </Descriptions.Item>
                        );
                      }
                      return (
                        <Descriptions.Item label={item.keyword} key={index}>
                          {item.value}
                        </Descriptions.Item>
                      );
                    }
                  )}
                </Descriptions>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
