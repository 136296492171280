import { ButtonProps } from 'antd';
import cs from 'classnames';
import { FC } from 'react';

import s from './style.module.scss';

export const Optimization: FC<ButtonProps> = (props) => {
  const { children, disabled, className = '', onClick } = props;

  return (
    <div
      className={cs(
        s['optimization-container'],
        {
          [s.disabled]: disabled,
        },
        className
      )}
      onClick={(e) => {
        if (disabled) return;
        onClick?.(e);
      }}
    >
      {children ?? (
        <>
          <img
            src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/magic.png"
            className={s.icon}
          />
          智能优化
        </>
      )}
    </div>
  );
};
