import cs from "classnames";
import React, { FC, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import About from "./components/about";
import Functions from "./components/function";
import Info from "./components/info";
import OrderList from "./components/order-list";
import Question from "./components/question";

import s from "./style.module.scss";
import { useUserInfo } from "../../contexts/user-info";

const tabs = [
  {
    title: "功能介绍",
    key: "function",
  },
  {
    title: "常见问题",
    key: "question",
  },
  {
    title: "关于我们",
    key: "about",
  },
];

const UserInfo: FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const renderContent = () => {
    if (type === "function") {
      return <Functions />;
    }
    if (type === "question") {
      return <Question />;
    }
    if (type === "about") {
      return <About userInfo={userInfo} />;
    }
    if (type === "order") {
      return <OrderList />;
    }
    return <Info />;
  };

  const types = useMemo(() => {
    return tabs.map((item) => item.key);
  }, []);

  return (
    <div className={s.container}>
      <div className={s.wapper}>
        {types.includes(type ?? "") ? (
          <div className={s.titles}>
            {tabs.map((item) => (
              <div
                key={item.key}
                className={cs(s["nav-title"], {
                  [s.active]:
                    (!type && item.key === "history") || item.key === type,
                })}
                onClick={() => {
                  navigate(`/userinfo?type=${item.key}`);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {renderContent()}
    </div>
  );
};

export default UserInfo;
