import { message } from 'antd';
import cs from 'classnames';
import Konva from 'konva';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import useGenerate from '../../hooks/useGenerate';
import { dataURLToBlob, dataUrlToFile } from '../../utils';
import {
  areaDelete,
  areaReplace,
  blendGenerate,
  blendResult,
  fabricGenerate,
  fabricPollingResult,
  imageToDraft,
  pullImageToDraftResult,
  saveEditImage,
} from '../../utils/api';
import Btn, { Return } from '../btn';
import ButtonParams from '../button-params';
import Collapse from '../collapse';
import DejaCollectRemark from '../deja-collect-remark';
import Download from '../download';
import useBgLayer from '../editSpace/hooks/useBgLayer';
import useBrushLayer from '../editSpace/hooks/useBrushLayer';
import useReplaceLayer from '../editSpace/hooks/useReplaceLayer';
import useStage from '../editSpace/hooks/useStage';
import useUtilLayer from '../editSpace/hooks/useUtilLayer';
import Icon from '../icon';
import PageLayout, { OperateLayout } from '../layout/page-layout';
import ModalTip from '../modal-tip';
import OptUpload from '../opt-upload';
import ShareDownloadImage from '../share-download-image';
import Textarea from '../textarea';
import AreaExpand from './components/area-expand';
import HistoryList from './components/history-list';
import Loading from './components/loading';
import PaintTool, { ERASER, PAINT } from './components/paint-tool';
import {
  CONVERT_KEY,
  DEFAULT_PROMPT,
  DEFAULT_STRENG,
  ERASER_KEY,
  EXPAND_KEY,
  FABRIC_KEY,
  LABEL_TYPE,
  PAINT_KEY,
  REPLACE_KEY,
  SINGLE_KEY,
} from './constants';
import useExpand from './hooks/useExpand';

import s from './style.module.scss';

interface IProps {
  steps?: React.ReactNode;
  inputParams?: any;
  clickToNextProcessNode: (node: any) => void;
  clickToPrevProcessNode?: () => void;
}

const WorkflowDeepDesign: FC<IProps> = ({
  steps,
  inputParams,
  clickToNextProcessNode,
  clickToPrevProcessNode,
}) => {
  const [currrentItem, setCurrentItem] = useState<any>();
  const [activeKey, setActiveKey] = useState(PAINT_KEY);
  const [areaReplaceLoading, setAreaReplaceLoading] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [activePreKey, setActivePreKey] = useState(PAINT_KEY);

  // 通用的生图参数
  const [strength, setStrength] = useState(DEFAULT_STRENG);
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT);
  const [fabricItem, setFabricItem] = useState<any>();

  // 绘画相关
  const [strokeWidth, setStrokeWidth] = useState(10);
  const [color, setColor] = useState('#fff');
  const [mode, setMode] = useState<'brush' | 'eraser'>('brush');
  const [currentShap, setCurrentShap] = useState<any>();
  const [his, setHis] = useState<any[]>([]);

  // 轮询结果需要携带的参数
  // 消除工具
  const replaceApi = (url: File, mask: Blob) => {
    const fd = new FormData();
    fd.append('image', url);
    fd.append('mask', mask);
    areaDelete(fd)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([new Uint8Array(res.data as Uint8Array)], {
            type: 'image/jpeg',
          })
        );
        setCurrentItem({
          url,
          prevId: currrentItem?.prevId ?? currrentItem?.id,
          prevUrl: currrentItem?.prevUrl ?? currrentItem?.url,
        });
        setReplaceLoading(false);
      })
      .catch(() => {
        setReplaceLoading(false);
      });
  };
  // 局部替换
  const areaReplaceApi = (params: any) => {
    setAreaReplaceLoading(true);
    areaReplace({
      ...(params || { maskBase64: '' }),
      prompt,
      labelType: LABEL_TYPE,
      id: currrentItem.id,
      imgUrl: currrentItem.url,
    })
      .then((res) => {
        setAreaReplaceLoading(false);
        setCurrentItem(res);
      })
      .catch(() => {
        setAreaReplaceLoading(false);
      });
  };

  useEffect(() => {
    setCurrentItem(inputParams);
  }, [inputParams]);

  // 画笔相关
  // 舞台
  const { stage, eventRecord } = useStage({
    bgUrl: currrentItem?.url,
    container: 'paint-canvas',
  });
  // 背景
  const { toUrl, layer: bgLayer } = useBgLayer(currrentItem?.url, stage);
  const {
    prev,
    next,
    reset,
    getHis,
    layer: brushLayer,
  } = useBrushLayer({
    bgUrl: currrentItem?.url,
    stage,
    editable: true,
    stroke: strokeWidth,
    mode,
    color,
    eventRecord,
    daub: false,
    onMourseUp(params) {
      if (activeKey !== ERASER_KEY || replaceLoading) return;
      setReplaceLoading(true);
      const points = params.line?.points();
      const layer = new Konva.Layer({ name: 'tempLayer' });
      stage.add(layer);
      // 添加白色线的图层
      const line = new Konva.Line({
        stroke: '#fff',
        strokeWidth,
        globalCompositeOperation: 'source-over',
        points,
        lineCap: 'round',
        lineJoin: 'round',
      });
      layer.add(line);
      replaceApi(
        dataUrlToFile(toUrl()),
        dataURLToBlob(
          layer.toDataURL({
            pixelRatio: 2,
          })
        )
      );
      layer.remove();
    },
  });
  const { changeStroke, layer: utilLayer } = useUtilLayer({
    stage,
    color,
    mode,
    eventRecord,
    daub: false,
  });
  const {
    layer: replaceLayer,
    getBgAndMask,
    prev: replacePrev,
    next: replaceNext,
    reset: replaceReset,
  } = useReplaceLayer({
    stage,
    eventRecord,
    bgUrl: currrentItem?.url,
    stroke: strokeWidth,
  });
  const {
    layer: expandLayer,
    copy,
    deleteItem,
  } = useExpand(stage, currentShap, setCurrentShap, his, setHis);

  const saveCurrentImage = useCallback(
    (callback?: (res: any) => void) => {
      stage.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      const base64 = stage.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: 2,
      });
      return saveEditImage({
        labelType: LABEL_TYPE,
        id: currrentItem.prevId ?? currrentItem.id,
        imgUrl: currrentItem.prevUrl ?? currrentItem.url,
        imgBase64: base64,
      }).then((res) => {
        setCurrentShap(null);
        setHis([]);
        setCurrentItem(res);
        callback?.(res);
      });
    },
    [currrentItem, stage]
  );

  // 重置参数
  useEffect(() => {
    setStrength(DEFAULT_STRENG);
    changeStroke(DEFAULT_STRENG);
    setPrompt(DEFAULT_PROMPT);
    setFabricItem(null);
    setMode('brush');
    // 这里重置，需要每一个图层都要重置
    reset?.();
    replaceReset?.();
    setHis([]);
    setCurrentShap(null);
    // 消除工具和局部替换颜色需要设置
    setColor(
      [ERASER_KEY, REPLACE_KEY].includes(activeKey)
        ? 'rgba(100, 81, 231, 0.6)'
        : '#fff'
    );
    // 预跳转
    setActivePreKey(activeKey);
  }, [activeKey]);

  // 重置画板的层
  useEffect(() => {
    if (!stage) return;
    stage.removeChildren();
    expandLayer?.removeChildren();
    bgLayer && stage.add(bgLayer);
    if ([PAINT_KEY, ERASER_KEY].includes(activeKey)) {
      brushLayer && stage?.add(brushLayer);
      utilLayer && stage?.add(utilLayer);
    } else if ([REPLACE_KEY].includes(activeKey)) {
      replaceLayer && stage?.add(replaceLayer);
      utilLayer && stage?.add(utilLayer);
    } else if ([EXPAND_KEY].includes(activeKey)) {
      expandLayer && stage?.add(expandLayer);
    }
  }, [
    activeKey,
    bgLayer,
    brushLayer,
    utilLayer,
    replaceLayer,
    expandLayer,
    stage,
  ]);

  // 消除工具 - 需要偷偷保存
  // 局部替换

  // 辅料上身
  // 面料替换
  const { loading: fabricLoading, startGenerate: startFabric } = useGenerate({
    generateApi: fabricGenerate,
    pollingResultApi: fabricPollingResult,
    onSuccess(result) {
      setCurrentItem(result);
    },
  });

  // 单品延伸
  const { loading: singleLoading, startGenerate: startSingle } = useGenerate({
    generateApi: blendGenerate,
    pollingResultApi: blendResult,
    onSuccess(result) {
      setCurrentItem(result);
    },
  });

  // 图转线稿
  const { loading: convertLoading, startGenerate: startConvert } = useGenerate({
    generateApi: imageToDraft,
    pollingResultApi: pullImageToDraftResult,
    onSuccess(result) {
      setCurrentItem({
        ...result,
        url: result.imageUrl,
      });
    },
  });

  // 部分状态
  const hasHis = getHis() || his.length;
  const isPageLoading =
    convertLoading ||
    singleLoading ||
    fabricLoading ||
    replaceLoading ||
    areaReplaceLoading;

  // 画笔工具操作栏
  const paintItemRender = useMemo(() => {
    return (
      <PaintTool
        key={activeKey}
        modes={[PAINT, ERASER]}
        onChangeWidth={(w) => {
          changeStroke(w);
          setStrokeWidth(w);
        }}
        onChangeColor={setColor}
        onChangeMode={setMode}
      />
    );
  }, [activeKey]);
  // 消除工具操作栏
  const eraserItemRender = useMemo(() => {
    return (
      <PaintTool
        key={activeKey}
        needColorPicker={false}
        modes={[PAINT]}
        onChangeWidth={(w) => {
          changeStroke(w);
          setStrokeWidth(w);
        }}
      />
    );
  }, [activeKey]);
  // 局部替换操作栏
  const replaceItemRender = useMemo(() => {
    return (
      <div>
        <PaintTool
          key={activeKey}
          needColorPicker={false}
          modes={[PAINT]}
          onChangeWidth={(w) => {
            changeStroke(w);
            setStrokeWidth(w);
          }}
        />
        <div className={s['mt-12']}>
          <Textarea
            value={prompt}
            onChange={setPrompt}
            maxLength={500}
            placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
          />
        </div>
        <div className={s['mt-center-24']}>
          <Btn
            disabled={areaReplaceLoading}
            onClick={() => {
              const params = getBgAndMask();
              if (!params) return;
              if (!prompt?.trim()) return message.error('请输入描述词');
              areaReplaceApi(params);
            }}
          >
            确认生成
          </Btn>
        </div>
      </div>
    );
  }, [prompt, areaReplaceLoading, getBgAndMask, activeKey]);
  // 辅料上身操作栏
  const expandItemRender = useMemo(() => {
    return (
      <AreaExpand his={his} setHis={setHis}>
        <span></span>
      </AreaExpand>
    );
  }, [his]);
  // 面料替换操作栏
  const fabricItemRender = useMemo(() => {
    return (
      <div>
        <div className={s.title}>面料图</div>
        <div className={s['mt-12']}>
          <OptUpload
            fabricable
            hideOptButton
            imageItem={fabricItem}
            onChange={(imageItem) => {
              setFabricItem(imageItem);
            }}
          />
        </div>
        <div className={s['mt-24']}>
          <div className={s.title}>设计描述</div>
        </div>
        <div className={s['mt-12']}>
          <Textarea
            value={prompt}
            onChange={setPrompt}
            maxLength={500}
            placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
          />
        </div>
        <div className={s['mt-24']}>
          <div className={s.title}>
            <span>面料对生图的影响</span>
          </div>
          <div className={s['mt-12']}>
            <div className={s['slider-con']}>
              <ButtonParams value={strength} onChange={setStrength} />
            </div>
          </div>
        </div>
        <div className={s['mt-center-24']}>
          <Btn
            disabled={fabricLoading || !fabricItem?.code}
            onClick={() =>
              startFabric({
                code: fabricItem?.code,
                imgUrl: currrentItem.url,
                prompt,
                sourceType: fabricItem?.sourceType,
                strength: Number((strength / 10).toFixed(1)),
              } as any)
            }
          >
            确认生成
          </Btn>
        </div>
      </div>
    );
  }, [prompt, strength, fabricItem, currrentItem]);
  // 单品延伸操作栏
  const singleItemRender = useMemo(() => {
    return (
      <div>
        <div className={s.title}>
          <span>创新幅度</span>
        </div>
        <div className={s['mt-12']}>
          <div className={s['slider-con']}>
            <ButtonParams value={strength} onChange={setStrength} />
          </div>
        </div>
        <div className={s['mt-center-24']}>
          <Btn
            disabled={singleLoading}
            onClick={() =>
              startSingle({
                imgUrl: currrentItem.url,
                labelType: 14,
                strength: Number((strength / 10).toFixed(1)),
              } as any)
            }
          >
            确认生成
          </Btn>
        </div>
      </div>
    );
  }, [strength, singleLoading, currrentItem]);
  // 图转线稿操作栏
  const convertItemRender = useMemo(() => {
    return (
      <div className={s['mt-center-24']}>
        <Btn
          disabled={convertLoading}
          onClick={() => startConvert({ url: inputParams.url } as any)}
        >
          生成线稿
        </Btn>
      </div>
    );
  }, [convertLoading]);

  // useEffect(() => {
  //   console.log('currrentItem', currrentItem);
  // }, [currrentItem]);

  // 顶部操作栏，回退和重置，消除工具顶部操作栏
  const topTools = useMemo(() => {
    return (
      <>
        {replaceLoading && <Loading />}
        <div className={s['top-tools']}>
          <Icon
            isTransparent
            iconName="icon-huihuachuangkuan-shangyibu"
            disabled={!hasHis}
            onClick={prev}
          />
          <Icon
            isTransparent
            iconName="icon-huihuachuangkuan-xiayibu"
            disabled={!hasHis}
            onClick={next}
          />
          <Icon
            isTransparent
            iconName="icon-a-xiaochugongjulanchexiao"
            onClick={reset}
          />
        </div>
      </>
    );
  }, [hasHis, prev, next, reset]);
  // 局部替换顶部操作栏
  const replaceTopTools = useMemo(() => {
    return (
      <>
        {areaReplaceLoading && <Loading />}
        <div className={s['top-tools']}>
          <Icon
            isTransparent
            iconName="icon-huihuachuangkuan-shangyibu"
            disabled={!hasHis}
            onClick={replacePrev}
          />
          <Icon
            isTransparent
            iconName="icon-huihuachuangkuan-xiayibu"
            disabled={!hasHis}
            onClick={replaceNext}
          />
          <Icon
            isTransparent
            iconName="icon-a-xiaochugongjulanchexiao"
            onClick={replaceReset}
          />
        </div>
      </>
    );
  }, [areaReplaceLoading, hasHis, replacePrev, replaceNext, replaceReset]);

  const list = [
    {
      name: '画笔工具',
      key: PAINT_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotijubuxiugai',
      renderPreview: () => topTools,
      render: () => paintItemRender,
    },
    {
      name: '消除工具',
      key: ERASER_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotixiaochugongju',
      renderPreview: () => replaceLoading && <Loading />,
      render: () => eraserItemRender,
    },
    {
      name: '局部替换',
      key: REPLACE_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotijubutihuan',
      renderPreview: () => replaceTopTools,
      render: () => replaceItemRender,
    },
    {
      name: '辅料上身',
      key: EXPAND_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotifuliaoshangshen',
      renderPreview: () =>
        currentShap && (
          <div className={cs(s['top-tools'], s.menu)}>
            <Icon
              isTransparent
              iconName="icon-a-fuliaoshangshengongjulanfuzhi1"
              onClick={() => copy(currentShap)}
            />
            <Icon
              isTransparent
              iconName="icon-a-fuliaoshangshengongjulanshanchu3"
              onClick={() => deleteItem(currentShap)}
            />
          </div>
        ),
      render: () => expandItemRender,
    },
    {
      name: '面料替换',
      key: FABRIC_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotimianliaopipei',
      renderPreview: () => {
        return <>{fabricLoading && <Loading />}</>;
      },
      render: () => fabricItemRender,
    },
    {
      name: '单品延伸',
      key: SINGLE_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotidanpinyanshen',
      renderPreview: () => {
        return <>{singleLoading && <Loading />}</>;
      },
      render: () => singleItemRender,
    },
    {
      name: '图转线稿',
      key: CONVERT_KEY,
      labelIconName: 'icon-a-gongzuoqudabiaotituzhuanxiangao',
      renderPreview: () => {
        return <>{convertLoading && <Loading />}</>;
      },
      render: () => convertItemRender,
    },
  ];

  return (
    <PageLayout
      steps={steps}
      left={
        <div className={s.container}>
          <div className={s['render-content']}>
            <div className={s['img-content']}>
              <div className={s['right-tools']}>
                <ShareDownloadImage
                  url={currrentItem?.url}
                  btnLoading={isPageLoading}
                >
                  <div className={s['collect-container']}>
                    <Icon
                      iconName="icon-a-fenxiangtupianfenxiang"
                      fontSize={20}
                    />
                    <div>分享</div>
                  </div>
                </ShareDownloadImage>
                <Download
                  result={{
                    ...currrentItem,
                    imageUrl: currrentItem?.url,
                  }}
                  btnLoading={isPageLoading}
                >
                  <div
                    className={s['collect-container']}
                    onClick={() => {
                      if (hasHis || !currrentItem.id) {
                        return saveCurrentImage();
                      }
                    }}
                  >
                    <Icon
                      iconName="icon-a-shengtuyouceanniuchaoqingxiazai"
                      fontSize={20}
                    />
                    <div>超清下载</div>
                  </div>
                </Download>
                <DejaCollectRemark
                  isLoading={isPageLoading}
                  collectId={currrentItem?.id}
                  collectStatus={currrentItem?.collectStatus}
                  onSuccess={(isTrue: boolean) => {
                    if (isTrue) {
                      setCurrentItem({
                        ...currrentItem,
                        collectStatus: Math.abs(
                          (currrentItem?.collectStatus ?? 0) - 1
                        ),
                      });
                    }
                  }}
                >
                  <div
                    className={s['collect-container']}
                    onClick={() => {
                      if (hasHis || !currrentItem.id) {
                        return saveCurrentImage();
                      }
                    }}
                  >
                    <Icon
                      iconName="icon-a-lishizuopinyulanshoucang"
                      isActive={currrentItem?.collectStatus}
                      fontSize={20}
                    />
                    <div>收藏</div>
                  </div>
                </DejaCollectRemark>
              </div>
              {list.find((item) => item.key === activeKey)?.renderPreview()}
              <div className={s['img-radius']}>
                <div className={s.bg}>
                  <img src={currrentItem?.url} />
                </div>
                <div id="paint-canvas" className={s.canvas}></div>
              </div>
            </div>
            <HistoryList
              item={currrentItem}
              onChange={(item) => setCurrentItem(item)}
            />
          </div>
          <Return className={s.return} onClick={clickToPrevProcessNode} />
        </div>
      }
    >
      <OperateLayout
        footer={
          <div className={s['footer-container']}>
            <Btn
              buttonType="goast"
              disabled={isPageLoading}
              onClick={() => {
                if (!currrentItem.id || hasHis) {
                  return saveCurrentImage((res) => {
                    clickToNextProcessNode(res);
                  });
                }
                clickToNextProcessNode(currrentItem);
              }}
            >
              样衣打版
            </Btn>
            <Btn
              disabled={
                [
                  CONVERT_KEY,
                  SINGLE_KEY,
                  FABRIC_KEY,
                  REPLACE_KEY,
                  ERASER_KEY,
                ].includes(activeKey) ||
                isPageLoading ||
                !hasHis
              }
              onClick={() => {
                if (isPageLoading) return;
                saveCurrentImage();
              }}
            >
              保存设计
            </Btn>
          </div>
        }
      >
        <div className={s['collapse-container']}>
          {list.map((item) => {
            return (
              <Collapse
                ghost
                labelIconName={item.labelIconName}
                key={item.key}
                labelName={item.name}
                keyName={item.key}
                activeKey={activeKey}
                onChange={() => {
                  if (isPageLoading) return;
                  if (hasHis || !currrentItem.id) {
                    setActivePreKey(item.key);
                    return setTipOpen(true);
                  }
                  setActiveKey(item.key);
                }}
              >
                {item.render()}
              </Collapse>
            );
          })}
          <ModalTip
            textEnsure="保存"
            tipOpen={tipOpen}
            setTipOpen={setTipOpen}
            onSuccess={() => {
              saveCurrentImage(() => {
                setActiveKey(activePreKey);
              });
            }}
          />
        </div>
      </OperateLayout>
    </PageLayout>
  );
};

export default WorkflowDeepDesign;
