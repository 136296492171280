import request from '../../utils/request';

export const getUserInfo = () => request.get('/customer/api/customer/info');

export const logout = () => request.post('/cas/api/logout');

export const getMyOrders = (data: { page: number; size: number }) =>
  request.get(
    `/trade/front/order/getPageList?page=${data.page}&size=${data.size}`
  );
