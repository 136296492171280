export const presets = [
  {
    label: '推荐',
    colors: [
      '#FFFFFF',
      '#E0E0E0',
      '#C2C2C2',
      '#A3A3A3',
      '#858585',
      '#666666',
      '#474747',
      '#292929',
      '#000000',
      '#FFE500',
      '#FF9717',
      '#F06C1A',
      '#F64B1C',
      '#E7372D',
      '#DD227C',
      '#BF00B6',
      '#9600D1',
      '#5F11DE',
      '#DCE711',
      '#B8E31A',
      '#87D110',
      '#2FB91D',
      '#00CB70',
      '#13D4BE',
      '#05ABD9',
      '#0040E1',
      '#3800D6',
    ],
  },
];

export const PAINT = 'brush';
export const ERASER = 'eraser';
