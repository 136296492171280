import request from '../../utils/request';
import { AppealStatus, LabelType, Read } from './constants';
import { IAppeal, IAppealSubmit, IResult } from './types';

export const pollingResult = (data: {
  labelType?: LabelType;
  promptIdentity?: string;
  messageReferenceId?: string | number;
  model?: string;
  mdConfigId?: string;
  garryId?: any;
  originImaginePrompt?: string;
}): Promise<IResult> =>
  request.get('/customer/api/design/interactions/data', data);

export const interactions = (data: {
  originImaginePrompt?: string;
  url?: string;
  colorCode?: string;
  model?: 'sd' | '';
  garryId?: number | string;
}): Promise<string> => request.post('/customer/api/design/interactions', data);

export const uvOrReInteractions = (data: {
  customId?: string;
  messageId?: string;
  labelType?: LabelType;
  promptIdentity?: string;
  colorCode?: string;
  model?: string;
}): Promise<string> =>
  request.post('/customer/api/design/uvOrReInteractions', data);

export const submitAppeal = (data: IAppealSubmit) =>
  request.post('/customer/api/customerAppeal/submit', data);

export const listCustomerAppeal = (data?: {
  page?: number;
  size?: number;
  status?: AppealStatus;
}): Promise<{
  page: number;
  records: number;
  rows: IAppeal[];
  size: number;
  total: 2;
}> => request.get('/customer/api/customerAppeal/list', data);

export const getCustomerAppeal = (id: number) =>
  request.get(`/customer/api/customerAppeal/${id}/detail`);

export const countUnreadMsg = (data?: { status?: AppealStatus; read?: Read }) =>
  request.get('/customer/api/customerAppeal/msgCount', data);

export const getPromptHistory = () =>
  request.get('/customer/api/design/latestPrompt');

export const getUserInfo = () => request.get('/customer/api/customer/info');

// 辅料列表，全部，1紧扣，3线带，4其他
export const getExpandList = (page: number, type: string | number, size = 9) =>
  request.post('/customer/api/accessoires/pageList', {
    page,
    size,
    type,
  });

export interface IExpandParams {
  page: number;
  type: string | number;
  size?: number;
}
export const getExpandObjList = ({ page, type, size }: IExpandParams) =>
  request.post('/customer/api/accessoires/pageList', {
    page,
    size: size || 6,
    type,
  });

// 获取辅料详情
export const getExpandDetail = (id: number) => {
  return request.post('/customer/api/accessories/getInfo', {
    id,
  });
};

// 发送当前生图的数据
export const sendGenerateData = (data: {
  garryId?: any;
  labelType: any;
  interactionsVOStr: string;
}) => {
  return request.post('/customer/api/design/collectData', data);
};
