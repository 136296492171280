import { ConfigProvider, Select } from 'antd';
import cs from 'classnames';
import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOperateInteraction } from '../../contexts/operate-interaction';
import { useRealtimeDrawing } from '../../contexts/realtime-draw';
import { draftParamsAtom } from '../../store/generation';
import { transImage } from '../../utils';
import Btn from '../btn';
import Download from '../download';
import GenerateLoading from '../generate-loading';
import ServiceEntrance from '../service-entrance';

import s from './style.module.scss';

interface IProps {
  loading: boolean;
  onFinish?: () => void;
  item?: any;
  tipText?: string;
  tipContent?: React.ReactNode;
  showSelect?: boolean;
}

const Result: FC<IProps> = ({
  loading,
  onFinish,
  item,
  tipText = '选择面料进行上身创意吧！',
  tipContent,
  showSelect,
}) => {
  const navigate = useNavigate();
  const { setEditItem } = useOperateInteraction();
  const { setBgUrl } = useRealtimeDrawing();

  const [_, setDraftParams] = useAtom(draftParamsAtom);

  return (
    <div className={s.container}>
      {loading ? (
        <GenerateLoading onFinish={() => onFinish?.()} />
      ) : (
        <>
          {item ? (
            <div
              className={cs(s.result, {
                [s.full]: !!item.url,
              })}
            >
              <div className={s.radius}>
                <img className={s.img} src={transImage(item.url, 'webp')} />
              </div>
              <div className={s.edit}>
                {showSelect ? (
                  <div className={s['select-con']}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Select: {
                            optionFontSize: 12,
                          },
                        },
                        token: {
                          fontSize: 12,
                          colorBgContainerDisabled: '#1f2022',
                          colorTextPlaceholder: '#E2E6F7',
                        },
                      }}
                    >
                      <Select
                        value={null}
                        bordered={false}
                        placeholder="发送到"
                        options={[
                          { value: '/generate/fromDraft', label: '线稿创款' },
                          {
                            value: '/generate/areaModify?tab=1',
                            label: '局部改款',
                          },
                          { value: 'realtime', label: '绘画创款' },
                        ]}
                        style={{
                          width: '90px',
                          fontSize: '12px',
                        }}
                        onSelect={(val: any) => {
                          if (val === '/generate/areaModify?tab=1') {
                            setEditItem(item);
                            navigate('/generate/areaModify?tab=1');
                          } else if (val === '/generate/fromDraft') {
                            setDraftParams((prev) => ({
                              ...prev,
                              imgUrl: item.url,
                            }));
                            navigate('/generate/fromDraft');
                          } else {
                            setBgUrl(item.url);
                            navigate('/generate/realtimeDrawing');
                          }
                        }}
                      />
                    </ConfigProvider>
                  </div>
                ) : (
                  <Btn
                    onClick={() => {
                      setEditItem(item);
                      navigate('/generate/areaModify?tab=1');
                    }}
                  >
                    图片编辑
                  </Btn>
                )}
              </div>
              <div className={s.services1}>
                <Download
                  result={{ ...item, imageUrl: item.url }}
                  btnLoading={loading}
                >
                  <div className={s['download-wrap']}>
                    <div
                      className={cs(s.icon, {
                        [s.disabled]: loading,
                      })}
                    >
                      <span className="font_family icon-a-shengtuyouceanniuchaoqingxiazai" />
                    </div>
                    <div>超清下载</div>
                  </div>
                </Download>
              </div>
              <ServiceEntrance top={94} url={item.url} />
            </div>
          ) : (
            <>
              {tipContent ? (
                <>{tipContent}</>
              ) : (
                <div className={s.placeholder}>
                  <img
                    src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230717/69c7d3f15b404fc7bb9d03ba5bf7762b.png"
                    className={s.image}
                  />
                  {tipText}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Result;
