import { Spin } from 'antd';
import React, { FC, useEffect, useRef } from 'react';

// 无线滚动组件
import s from './style.module.scss';

interface IProps {
  height: number;
  loading?: boolean;
  children?: React.ReactNode;
  trggerLoad?: () => void;
}

const InfiniteScroll: FC<IProps> = ({
  height,
  children,
  trggerLoad,
  loading,
}) => {
  const parent = useRef<HTMLDivElement>(null);
  const target = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const entity = entries[0];
        if (entity.intersectionRatio === 1) {
          trggerLoad?.();
        }
      },
      {
        root: parent.current,
        rootMargin: '30px',
        threshold: [1],
      }
    );
    target.current && intersectionObserver.observe(target.current);
    return () => {
      target.current && intersectionObserver.unobserve(target.current);
    };
  }, []);

  return (
    <Spin spinning={loading}>
      <div
        ref={parent}
        id="infinite-scroll"
        className={s.container}
        style={{ height: `${height}px` }}
      >
        <div className={s.scroll}>
          {children}
          <div
            id="infinite-scroll-foot"
            className={s['infinite-scroll-foot']}
            ref={target}
          ></div>
        </div>
      </div>
    </Spin>
  );
};

export default InfiniteScroll;
