import { ColorPicker, InputNumber, Slider } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ERASER, PAINT, presets } from './constants';

import s from './style.module.scss';

interface IProps {
  modes: string[];
  needColorPicker?: boolean;
  onChangeWidth?: (strokeWidth: number) => void;
  onChangeColor?: (strokeColor: string) => void;
  onChangeMode?: (mode: 'brush' | 'eraser') => void;
}

const PaintTool: FC<IProps> = ({
  modes = [PAINT, ERASER],
  needColorPicker = true,
  onChangeWidth,
  onChangeColor,
  onChangeMode,
}) => {
  const [width, setWidth] = useState(10);
  const [color, setColor] = useState('#fff');
  const [mode, setMode] = useState<'brush' | 'eraser'>(PAINT);
  const dom = useRef<any>();

  useEffect(() => {
    onChangeWidth?.(width);
  }, [width]);
  useEffect(() => {
    onChangeColor?.(color);
  }, [color]);
  useEffect(() => {
    onChangeMode?.(mode);
  }, [mode]);

  return (
    <div className={s.container} ref={dom}>
      <div className={s.mode}>
        {modes.includes(PAINT) && (
          <div
            className={cs(s.item, {
              [s.active]: mode === PAINT,
            })}
            onClick={() => setMode(PAINT)}
          >
            <span className="font_family icon-huihuachuangkuan-huabigongju"></span>
          </div>
        )}
        {modes.includes(ERASER) && (
          <div
            className={cs(s.item, {
              [s.active]: mode === ERASER,
            })}
            onClick={() => setMode(ERASER)}
          >
            <span className="font_family icon-huihuachuangkuan-xiangpigongju"></span>
          </div>
        )}
      </div>
      <Slider
        dots={false}
        min={1}
        max={50}
        step={1}
        tooltip={{ open: false }}
        onChange={setWidth}
        value={width}
        className={s.slider}
      />
      <InputNumber
        bordered={false}
        controls={false}
        min={1}
        max={50}
        value={width}
        onChange={(num) => setWidth(num ?? 1)}
        onBlur={() => {
          if (!width) {
            setWidth(1);
          }
        }}
        className={s.number}
      />
      {needColorPicker && mode === PAINT && (
        <ColorPicker
          placement="bottomRight"
          presets={presets}
          value={color}
          onChange={(val) => {
            setColor?.(typeof val === 'string' ? val : val.toHexString());
          }}
          getPopupContainer={() => dom.current}
        >
          <div className={s.color}>
            <div className={s.circle} style={{ background: color }}></div>
          </div>
        </ColorPicker>
      )}
    </div>
  );
};

export default PaintTool;
export { ERASER, PAINT };
