import { Divider, Input } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import Sound from '../sound';

import s from './style.module.scss';

interface ITextAreaProps {
  value?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  placeholder?: string;
  rows?: number;
  userInfo?: any;
  pureText?: boolean;
  [key: string]: any;
}

const { TextArea } = Input;

const Textarea: FC<ITextAreaProps> = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const cancal = () => {
      setActive(false);
    };
    document.addEventListener('click', cancal);
    return () => {
      document.removeEventListener('click', cancal);
    };
  });

  return props.pureText ? (
    <div
      className={cs({ [s.container]: true, [s.active]: active })}
      onClick={(e: any) => {
        e.stopPropagation();
        setActive(true);
      }}
    >
      <TextArea
        bordered={false}
        autoSize={{ minRows: props?.rows || 3, maxRows: props?.rows || 3 }}
        value={props.value}
        onChange={(e) => {
          props.onChange?.(e.target.value);
        }}
        placeholder={props.placeholder}
        className={s.textarea}
        maxLength={props.maxLength}
      />
      <div className={s.line}></div>
      <div className={s['text-operate-pure']}>
        {props.maxLength && (
          <div className={s['text-length']}>
            {props.value?.length ?? 0}/{props.maxLength}
          </div>
        )}
        <Divider type="vertical" />
        <div
          className={s.clear}
          onClick={() => {
            props.onChange?.('');
            props.changeColor?.('');
          }}
        >
          清空
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cs({ [s.container]: true, [s.active]: active })}
      onClick={(e: any) => {
        e.stopPropagation();
        setActive(true);
      }}
    >
      <TextArea
        bordered={false}
        autoSize={{ minRows: props?.rows || 3, maxRows: props?.rows || 3 }}
        value={props.value}
        onChange={(e) => {
          props.onChange?.(e.target.value);
        }}
        placeholder={props.placeholder}
        className={s.textarea}
        maxLength={props.maxLength}
      />
      <div className={s.line}></div>
      <div className={s.operate}>
        <Sound
          onSuccess={(text: string) => props.onChange?.(props.value + text)}
          userInfo={props.userInfo}
        />
        <div className={s.place}></div>
        <div className={s['text-operate']}>
          {props.maxLength && (
            <div className={s['text-length']}>
              {props.value?.length || 0}/{props.maxLength}
            </div>
          )}
          <Divider type="vertical" />
          <div
            className={s.clear}
            onClick={() => {
              props.onChange?.('');
              props.changeColor?.('');
            }}
          >
            清空
          </div>
        </div>
      </div>
    </div>
  );
};

export default Textarea;
