import { EllipsisOutlined } from '@ant-design/icons';
import { Modal, Popover } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getExpandDetail as getExpandDetailApi,
  getExpandObjList,
} from '../../pages/generate/api';
import Btn from '../btn';
import LoadList from '../load-list';
import { ExpandTabs } from './constants';
import { IProps } from './types';

import s from './style.module.scss';

const ExpandTabList = [
  {
    key: ExpandTabs.All,
    text: '全部',
    value: '',
  },
  {
    key: ExpandTabs.Jin,
    text: '紧扣',
    value: 1,
  },
  {
    key: ExpandTabs.Xian,
    text: '线带',
    value: 3,
  },
  {
    key: ExpandTabs.Other,
    text: '其他',
    value: 4,
  },
  {
    key: ExpandTabs.Taidi,
    text: '泰迪熊',
    value: 1001,
  },
];

const typesObj = {
  '1': '紧扣',
  '3': '线带',
  '4': '其他',
  '1001': 'IP图案',
};

const ExpandModalImage: FC<IProps> = ({ open, onChange, item, close }) => {
  const navigate = useNavigate();
  const [currentModel, setCurrentModel] = useState(ExpandTabs.All);
  const [imgItem, setImgItem] = useState<any>();
  const [expandDetail, setExpandDetail] = useState<any>();

  const getExpandDetail = (id: number) => {
    getExpandDetailApi(id).then((res: any) => setExpandDetail(res));
  };

  const content = useMemo(() => {
    return (
      <div className={s.content}>
        <div className={s['name-list']}>
          <div className={s['name-item']}>
            <div className={s.name}>工厂名称</div>
            <div className={s.value}>{expandDetail?.companyName}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料货号</div>
            <div className={s.value}>{expandDetail?.itemNo}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料名称</div>
            <div className={s.value}>{expandDetail?.name}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料分类</div>
            <div className={s.value}>
              {typesObj[expandDetail?.type as '1' | '3' | '4']}
            </div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料材质</div>
            <div className={s.value}>{expandDetail?.composition}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>辅料尺寸</div>
            <div className={s.value}>{expandDetail?.size}</div>
          </div>
        </div>
        <Btn
          style={{ width: '100%', marginTop: '16px', fontSize: '12px' }}
          onClick={() => navigate('/service')}
        >
          联系客服购买
        </Btn>
      </div>
    );
  }, [expandDetail]);

  useEffect(() => {
    if (!open) {
      setImgItem(null);
    }
  }, [open]);

  useEffect(() => {
    console.log('currentModel', currentModel);
  }, [currentModel]);

  return (
    <Modal
      open={open}
      centered
      destroyOnClose
      width={712}
      style={{ pointerEvents: 'auto' }}
      modalRender={() => (
        <div className={s.modal}>
          <div
            className={s.close}
            onClick={() => {
              setImgItem(null);
              close?.();
            }}
          >
            <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
          </div>
          <div className={s['tabs-con']}>
            <div className={s.tabs}>推荐辅料</div>
          </div>
          <div>
            <div className={s['history-tabs']}>
              {ExpandTabList.map(({ text, key }) => {
                return (
                  <div
                    className={cs(s.tab, {
                      [s.active]: currentModel === key,
                    })}
                    key={key}
                    onClick={() => setCurrentModel(key)}
                  >
                    {text}
                  </div>
                );
              })}
            </div>
            <div className={s.content}>
              <LoadList
                key={`history_${currentModel}`}
                activeItem={imgItem ?? item}
                loadData={(params) =>
                  getExpandObjList({
                    ...params,
                    size: 20,
                    type:
                      ExpandTabList?.find(({ key }) => key === currentModel)
                        ?.value || '',
                  })
                }
                onSelect={(item) => {
                  const newItem = {
                    ...item,
                  };
                  newItem.cosUrl = item.url;
                  setImgItem(newItem);
                }}
                renderEmpty={() => (
                  <div className={s.empty}>
                    <img
                      className={s.img}
                      src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/expand_placeholder.png"
                      alt=""
                    />
                    <div className={s.tip}>暂无辅料数据</div>
                  </div>
                )}
                itemRender={(item: any, isActive?: boolean) => (
                  <div
                    className={cs(s['render-item'], {
                      [s.active]: isActive,
                    })}
                    onClick={() =>
                      setImgItem({
                        ...item,
                        cosUrl: item.url,
                      })
                    }
                  >
                    <img src={item.imageUrl} />
                    <Popover
                      content={content}
                      placement="bottomRight"
                      arrow={false}
                      color="#1F2022"
                      overlayClassName={s['fabric-tip-container']}
                      trigger="click"
                    >
                      <div className={s.icon}>
                        <EllipsisOutlined
                          onClick={() => {
                            getExpandDetail(item.id);
                          }}
                        />
                      </div>
                    </Popover>
                  </div>
                )}
              />
            </div>
          </div>
          <div className={s.footer}>
            <Btn
              disabled={!imgItem}
              className={s.button}
              onClick={() => {
                onChange?.(imgItem);
              }}
            >
              确认
            </Btn>
          </div>
        </div>
      )}
    ></Modal>
  );
};

export default ExpandModalImage;
