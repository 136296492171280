import { ConfigProvider, message, Modal } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import Btn from '../../components/btn';
import ServiceEntrance from '../../components/service-entrance';
import { transImage } from '../../utils';
import DejaCollectRemark from '../deja-collect-remark';
import Download from '../download';
import ShareDownloadImage from '../share-download-image';

import s from './style.module.scss';

interface IProps {
  placeholder?: string;
  startTime?: number;
  loading?: boolean;
  list?: any[];
  renderEmpty?: React.ReactNode;
  onOverTime?: () => void;
  onGenerate?: (params: any) => void;
  onChangeCollectStatus?: (item: any) => void;
  onChangePullParams?: (params: any) => void;
  onClick?: (params: any) => void;
}

const MAX_TIME = 100; // 最大请求时间是100秒
const ONE_MIN = 60; // 1分钟

const CardGenerate: FC<IProps> = ({
  placeholder,
  startTime = 0,
  loading,
  list = [],
  renderEmpty,
  onOverTime,
  onGenerate,
  onChangeCollectStatus,
  onChangePullParams,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const requestId = useRef<number>(0);

  const changeDetailStatus = (item: any) => {
    onChangeCollectStatus?.(item);
  };

  useEffect(() => {
    const updateProgress = () => {
      if (!startTime) return;
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000; // in seconds
      if (elapsedTime >= MAX_TIME) {
        message.error('输入的服装效果暂不支持');
        onOverTime?.();
      } else {
        // 超过1分钟就显示90%的进度
        setProgress(
          elapsedTime > ONE_MIN ? 90 : (elapsedTime / MAX_TIME) * 100
        );
        requestId.current = requestAnimationFrame(updateProgress);
      }
    };

    if (loading) {
      requestId.current = requestAnimationFrame(updateProgress);
    }

    return () => {
      cancelAnimationFrame(requestId.current);
    };
  }, [loading, startTime]);

  useEffect(() => {
    if (!loading) {
      setProgress(0);
    }
  }, [loading]);

  useEffect(() => {
    console.log('渲染的currentItem', currentItem);
  }, [currentItem]);

  const empty = renderEmpty ?? (
    <div className={s.empty}>
      <img
        src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/card-generate-empty.png"
        className={s.icon}
      />
      <div className={s.text}>{placeholder}</div>
    </div>
  );
  return (
    <div className={s.container}>
      {!list?.length && !loading ? (
        empty
      ) : (
        <div className={s.scroll}>
          <div className={s.cards}>
            {loading
              ? Array(4)
                  .fill(1)
                  .map((_, index) => (
                    <div key={index} className={s.card}>
                      <div className={s.loading}>
                        <img
                          src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/card-generate-loading.gif"
                          className={s.icon}
                        />
                        <div className={s.wrap}>
                          <div className={s.line}>
                            <div
                              className={s.progress}
                              style={{ width: `${progress?.toFixed(0)}%` }}
                            ></div>
                          </div>
                        </div>
                        <div className={s.text}>{progress?.toFixed(0)}%</div>
                      </div>
                    </div>
                  ))
              : null}
            {list.map((item: any) => (
              <div key={item.vcomponent} className={s.card}>
                <div
                  className={s.item}
                  onClick={() => {
                    if (onClick) {
                      onClick?.({
                        ...item,
                        url: item.preImage,
                      });
                    } else {
                      setCurrentItem(item);
                      setOpen(true);
                    }
                  }}
                >
                  <img
                    src={transImage(item?.preImage, 'webp')}
                    className={s.content}
                  />
                  <div className={s.operate}>
                    <div
                      className={s.icons}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div
                        className={cs(s.icon, s.text)}
                        onClick={() => {
                          if (loading) return;
                          const mark = item?.vcomponent?.startsWith('MJ')
                            ? '::'
                            : ':';
                          const vType = item?.vcomponent?.split(mark)?.[3];
                          onChangePullParams?.({
                            colorCode: item?.colorCode,
                            messageReferenceId: item?.messageId as string,
                            promptIdentity: item?.identification as string,
                            customId: item?.vcomponent,
                            labelType: vType ? Number(vType) + 4 : 0,
                          });
                          onGenerate?.({
                            isV: true,
                            colorCode: item?.colorCode,
                            messageId: item?.messageId as string,
                            messageReferenceId: item?.messageId as string,
                            promptIdentity: item?.identification as string,
                            customId: item?.vcomponent,
                            labelType: vType ? Number(vType) + 4 : 0,
                          });
                        }}
                      >
                        延伸
                      </div>
                      <ShareDownloadImage url={item?.preImage}>
                        <div
                          className={cs(s.icon, {
                            [s.disabled]: loading,
                          })}
                        >
                          <span className="font_family icon-a-fenxiangtupianfenxiang" />
                        </div>
                      </ShareDownloadImage>
                      <Download
                        result={{
                          ...(item || {}),
                          imageUrl: item?.preImage,
                        }}
                        btnLoading={loading as boolean}
                      >
                        <div
                          className={cs(s.icon, {
                            [s.disabled]: loading,
                          })}
                        >
                          <span className="font_family icon-a-shengtuyouceanniuchaoqingxiazai" />
                        </div>
                      </Download>
                      <DejaCollectRemark
                        collectId={item?.id}
                        collectStatus={item?.collectStatus}
                        onSuccess={() => changeDetailStatus(item)}
                      >
                        <div
                          className={cs(s.icon, {
                            [s.active]: item?.collectStatus,
                          })}
                          onClick={(e) => {
                            e?.stopPropagation();
                          }}
                        >
                          <span className="font_family icon-a-lishizuopinyulanshoucang" />
                        </div>
                      </DejaCollectRemark>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <ConfigProvider
        theme={{
          token: {
            colorBgMask: '#000',
          },
        }}
      >
        <Modal
          centered
          destroyOnClose
          width={668}
          open={open}
          style={{ pointerEvents: 'auto' }}
          modalRender={() => (
            <div className={s.modal}>
              <div
                className={s.close}
                onClick={() => {
                  setOpen(false);
                  setCurrentItem(null);
                }}
              >
                <span className="font_family icon-a-lishijiluerjiyemianchahao" />
              </div>
              <div className={s.left}>
                <div className={s.content}>
                  <img src={(currentItem as any)?.preImage} className={s.img} />
                </div>
                <Btn
                  loading={loading}
                  className={s.button}
                  onClick={() => {
                    const mark = currentItem?.vcomponent?.startsWith('MJ')
                      ? '::'
                      : ':';
                    const vType = currentItem?.vcomponent?.split(mark)?.[3];
                    onChangePullParams?.({
                      colorCode: currentItem?.colorCode,
                      messageReferenceId: currentItem?.messageId as string,
                      promptIdentity: currentItem?.identification as string,
                      customId: currentItem?.vcomponent,
                      labelType: vType ? Number(vType) + 4 : 0,
                    });
                    onGenerate?.({
                      isV: true,
                      colorCode: currentItem?.colorCode,
                      messageId: currentItem?.messageId as string,
                      messageReferenceId: currentItem?.messageId as string,
                      promptIdentity: currentItem?.identification as string,
                      customId: currentItem?.vcomponent,
                      labelType: vType ? Number(vType) + 4 : 0,
                    });
                    setOpen(false);
                  }}
                >
                  以此延伸
                </Btn>
              </div>
              <div className={s.right}>
                <div className={s.service}>
                  <ShareDownloadImage
                    url={currentItem?.preImage}
                    btnLoading={loading as boolean}
                  >
                    <div className={s['download-wrap']}>
                      <div
                        className={cs(s.icon, {
                          [s.disabled]: loading,
                        })}
                      >
                        <span className="font_family icon-a-fenxiangtupianfenxiang" />
                      </div>
                      <div>分享</div>
                    </div>
                  </ShareDownloadImage>
                  <Download
                    result={{
                      ...(currentItem || {}),
                      imageUrl: currentItem?.preImage,
                    }}
                    btnLoading={loading as boolean}
                  >
                    <div className={s['download-wrap']}>
                      <div
                        className={cs(s.icon, {
                          [s.disabled]: loading,
                        })}
                      >
                        <span className="font_family icon-a-shengtuyouceanniuchaoqingxiazai" />
                      </div>
                      <div>超清下载</div>
                    </div>
                  </Download>
                  <DejaCollectRemark
                    key={`${currentItem?.id}_${currentItem?.collectStatus}`}
                    collectId={currentItem?.id}
                    collectStatus={currentItem?.collectStatus}
                    onSuccess={() => {
                      const temp = JSON.parse(
                        JSON.stringify({ ...currentItem })
                      );
                      temp.collectStatus = Math.abs(
                        (temp.collectStatus ?? 0) - 1
                      );
                      setCurrentItem(temp);
                      changeDetailStatus?.(currentItem);
                    }}
                  >
                    <div
                      className={cs(s['download-wrap'], {
                        [s.active]: currentItem?.collectStatus,
                      })}
                    >
                      <div
                        className={cs(s.icon, {
                          [s.disabled]: loading,
                        })}
                      >
                        <span className="font_family icon-a-lishizuopinyulanshoucang" />
                      </div>
                      <div>收藏</div>
                    </div>
                  </DejaCollectRemark>
                </div>
                <ServiceEntrance
                  left={0}
                  top={234}
                  url={(currentItem as any)?.preImage}
                />
              </div>
            </div>
          )}
        />
      </ConfigProvider>
    </div>
  );
};

export default CardGenerate;
