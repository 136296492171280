import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Btn from '../../components/btn';
import { transImage } from '../../utils';

import s from './style.module.scss';

const PaySuccess: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={s['success-container']}>
      <div className={s['success-title']}>支付成功！</div>
      <div className={s['success-sub-title']}>
        您可在【个人中心】-【我的订单】中查看订单
      </div>
      <div className={s['success-image-con']}>
        <img
          className={s.img}
          src={transImage(
            'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/goods/pay-success.png',
            'webp'
          )}
        />
      </div>
      <Btn className={s['success-button']} onClick={() => navigate('/')}>
        返回首页
      </Btn>
    </div>
  );
};

export default PaySuccess;
