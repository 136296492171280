import request from '../../utils/request';

interface IEditSaveImage {
  promptIdentity?: string;
  promptId?: string;
  imgBase64: string;
  labelType?: string;
  preUrl?: string;
  id?: string | number;
  imgUrl?: string;
}

interface IAreaReplace {
  maskBase64: string;
  prompt: string;
  labelType?: string | number;
  id?: string | number;
  imgUrl?: string;
}

export const saveEditImage = (data: IEditSaveImage) => {
  return request.post('/customer/api/design/imgEditSave', data);
};

// // 面料匹配
// export async function matchFabric(data: {
//   colorCode?: string;
//   promptId: string;
// }) {
//   return request.post(
//     'https://api.shentuzn.com/supplier/api/fabri/match',
//     data
//   );
// }

// 局部消除
export const areaDelete = (data: FormData) => {
  // return request.post('/core/api/design/sota/inpaint', data, {
  //   headers: {
  //     // 'Content-Type': 'multipart/form-data',
  //     responseType: 'arraybuffer',
  //   },
  // });
  return request.stream('/core/api/design/sota/inpaint', data);
};

// 局部替换
export const areaReplace = (data: IAreaReplace) => {
  return request.post('/customer/api/design/imgPartReplace', data);
};
