import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Tooltip,
} from 'antd';
import cs from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Btn, { Return } from '../../components/btn';
import ModalImage, { Tabs } from '../../components/modal-image';
import Textarea from '../../components/textarea';
import { useOrderInfo } from '../../contexts/order-info';
import { throttle, transImage } from '../../utils';
import {
  collectCustomYiDemand,
  ensureOrder,
  getDemandDetail,
  getGoods,
} from './api';
import CheckBoxInput from './components/checkbox-input';
import RadioInput from './components/radio-input';
import {
  DataType,
  DataTypeMap,
  DataTypeRegMap,
  GoodCategory,
  GoodCategoryTipsMap,
  QuestionType,
  STEP,
} from './constants';
import type { IDemandInfo, IGood, IOrderSubmit } from './type';

import s from './style.module.scss';

const banners = {
  [GoodCategory.AI]: 'ai-banner',
  [GoodCategory.MODEL]: 'model-banner',
  [GoodCategory.STYLE]: 'xiu-banner',
};

const transformFormValues = (data: any, values: any) => {
  const { questions } = data;
  const keys = Object.keys(values);
  const superQuestions = questions.map((question: any) => {
    const { id, type, keyword, options, title, ...rest } = question;
    if (type === QuestionType.OTHER) {
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id],
      };
    }
    if (type === QuestionType.RADIO) {
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id]?.startsWith('else-') ? 'else' : values[id],
        options: options.map((option: any) => ({
          ...option,
          value:
            option.keyword === 'else'
              ? values[id]?.split('else-')?.[1]
              : option.value,
        })),
      };
    }
    if (type === QuestionType.CHECKBOX) {
      const elseValue = values[id]?.find((cell: string) =>
        cell.startsWith('else-')
      );
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id]
          ?.map((cell: string) => (cell.startsWith('else-') ? 'else' : cell))
          ?.filter(Boolean),
        options: options.map((option: any) => ({
          ...option,
          value:
            option.keyword === 'else' && elseValue
              ? elseValue.split('else-')?.[1]
              : option.value,
        })),
      };
    }
    if (type === QuestionType.TEXT) {
      const usefulKeys = keys.filter((key) => key.startsWith(id));
      const arrValues = usefulKeys.map((key) => values[key]);
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        options: options.map((option: any, index: number) => ({
          ...option,
          value: arrValues[index],
        })),
      };
    }
    return question;
  });
  return {
    ...data,
    questions: superQuestions,
  };
};

const BanConsume: FC = () => {
  const navigate = useNavigate();
  const { orderInfo, setOrderInfo } = useOrderInfo();
  const [searchParams] = useSearchParams();
  const serviceType = searchParams.get('serviceType');
  const [step, setStep] = useState(STEP.STEP_1);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [goods, setGoods] = useState<IGood[]>();
  const [good, setGood] = useState<IGood | null>();
  const [remark, setRemark] = useState('');
  const [demand, setDemand] = useState<IDemandInfo | null>();
  // const [initialValues, setInitValues] = useState<any>({});

  const submit = useCallback(
    async (values: IOrderSubmit) => {
      const questionAnswer = demand ? transformFormValues(demand, values) : {};
      const finalParams = {
        remark,
        source: 1, // 默认deja
        tradeOrderDetailDTOList: [
          {
            placeCount: 1,
            skuId: good?.id,
            orderDetailPicUrl: orderInfo.url,
            listInfo: demand
              ? [
                  {
                    ...questionAnswer,
                  },
                ]
              : [],
          },
        ],
      };
      try {
        const result = await ensureOrder(finalParams as IOrderSubmit);
        navigate(
          `/waitPay?serviceType=${serviceType}&id=${result.id}&payOrderNo=${result.payOrderNo}`
        );
        return true;
      } catch (err) {
        console.log('err', err);
        return false;
      }
    },
    [demand, good, orderInfo, remark]
  );
  const submit1 = throttle(submit, 1000);

  useEffect(() => {
    if (!serviceType) {
      navigate(-1);
    }
  }, [serviceType]);

  useEffect(() => {
    getGoods({ categoryType: serviceType as any as GoodCategory }).then(
      (res: any) => {
        setGoods(res.rows);
      }
    );
  }, [serviceType]);

  useEffect(() => {
    if (step !== STEP.STEP_2 || !good) return;
    getDemandDetail((good as IGood).requirementListId).then(setDemand);
  }, [step, good]);

  useEffect(() => {
    if (!demand?.questions) return;
    const values = demand?.questions
      .filter((question) => question?.required)
      .reduce((acc: any, cur: any) => {
        acc[cur.id] = cur?.options?.[0]?.keyword;
        return acc;
      }, {});
    form.setFieldsValue(values);
  }, [demand, form]);

  const select = (
    <div>
      <div className={s.upload}>
        <div className={s['upload-content']}>
          <div className={s['upload-wrapper']}>
            {orderInfo.url ? (
              <img className={s.img} src={orderInfo.url} />
            ) : (
              <div className={s.placeholder} onClick={() => setOpen(true)}>
                <PlusOutlined className={s.icon} />
                <div className={s.text}>
                  <span>点击上传</span>款式图
                </div>
              </div>
            )}
          </div>
          <div className={s['button-group']}>
            {orderInfo.url && (
              <Btn className={s.replace} onClick={() => setOpen(true)}>
                替换图片
              </Btn>
            )}
          </div>
        </div>
      </div>
      <ModalImage
        tabs={[Tabs.History, Tabs.Location]}
        open={open}
        close={() => setOpen(false)}
        onChange={(imgItem) => {
          setOrderInfo({
            url: imgItem.url,
          });
          setOpen(false);
        }}
      />
    </div>
  );

  const headerBanner = (
    <div
      className={cs(s['banner-wrapper'], {
        [s[GoodCategory[Number(serviceType)]]]: true,
      })}
    >
      <img
        src={transImage(
          `https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/${
            banners[serviceType as any as GoodCategory]
          }.png`,
          'webp'
        )}
        className={s.banner}
      />
      <Tooltip
        placement="bottom"
        color="#242528"
        title={
          <div className={s['banner-tip']}>
            您可以在【我的订单】找到此订单，
            <br />
            点击【确认收货】后，即可【下载文件】。
          </div>
        }
      >
        <div className={s.tip}></div>
      </Tooltip>
    </div>
  );

  if (step === STEP.STEP_1) {
    return (
      <div className={s.container}>
        <div className={s.left}>
          {select}
          <Return onClick={() => navigate(-1)} />
        </div>
        <div className={s.right}>
          <div className={s.content}>
            {serviceType && headerBanner}
            {serviceType === GoodCategory.STYLE.toString() ? (
              <div className={s.placeholder}>智能样衣制作，为您极速定制</div>
            ) : (
              <div className={s['step1-content']}>
                <div className={s.title}>
                  请选择您要打板的衣服类型：
                  <div className={s['sub-title']}>
                    如需定制其他款式，请联系在线客服
                  </div>
                </div>
                <div className={s.goods}>
                  {goods?.map((item) => (
                    <div
                      key={item.id}
                      className={cs(s.item, {
                        [s.active]: good?.id === item.id,
                      })}
                      onClick={() => setGood(item)}
                    >
                      <div className={s['text-title']}>{item.name}</div>
                      <div className={s.price}>
                        ￥<span className={s.num}>{item.unitPrice}</span>
                      </div>
                      {good?.id === item.id && (
                        <div className={s['selected-icon']}>
                          <CheckOutlined
                            style={{
                              color: '#fff',
                              fontSize: '10px',
                              marginTop: '5px',
                              marginLeft: '4px',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className={s.title}>订单备注：</div>
                <div className={s['textarea-wrapper']}>
                  <Textarea
                    value={remark}
                    onChange={(str) => setRemark(str)}
                    pureText
                    maxLength={100}
                    placeholder="此处非必填，请输入您的其他要求"
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className={cs(s.footer, {
              [s.shadow]: serviceType === GoodCategory.AI.toString(),
            })}
          >
            <div className={s.price}>
              {good && (
                <>
                  ￥<span className={s.num}>{good?.unitPrice}</span>
                </>
              )}
            </div>
            {serviceType === GoodCategory.AI.toString() ? (
              <div className={s['button-groups']}>
                <Btn
                  className={s.service}
                  buttonType="goast"
                  onClick={() => navigate('/service')}
                >
                  联系客服
                </Btn>
                <Btn
                  className={s.ensure}
                  onClick={() => {
                    if (!orderInfo.url) {
                      return message.warning(
                        GoodCategoryTipsMap[serviceType as any as GoodCategory]
                      );
                    }
                    if (!good) {
                      return message.warning('请选择需要下单的商品');
                    }
                    setStep(STEP.STEP_2);
                  }}
                >
                  下一步
                </Btn>
              </div>
            ) : (
              <div className={s['button-groups']}>
                <Btn
                  className={s.service}
                  buttonType="goast"
                  onClick={() => navigate('/service')}
                >
                  联系客服
                </Btn>
                {serviceType === GoodCategory.STYLE.toString() ? (
                  <Btn
                    className={s.ensure}
                    onClick={async () => {
                      await collectCustomYiDemand();
                      message.success('感谢您的支持，如您想制作样衣请联系客服');
                    }}
                  >
                    我想制作样衣
                  </Btn>
                ) : (
                  <Btn
                    className={s.ensure}
                    onClick={async () => {
                      if (!orderInfo.url) {
                        return message.warning(
                          GoodCategoryTipsMap[
                            serviceType as any as GoodCategory
                          ]
                        );
                      }
                      if (!good) {
                        return message.warning('请选择需要下单的商品');
                      }
                      await submit1({
                        remark,
                        source: 1,
                        tradeOrderDetailDTOList: [
                          {
                            placeCount: 1,
                            skuId: good?.id,
                          },
                        ],
                      });
                    }}
                  >
                    确认下单
                  </Btn>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (step === STEP.STEP_2) {
    return (
      <div className={s.container}>
        <div className={s.left}>
          {select}
          <Return onClick={() => navigate(-1)} />
        </div>
        <div className={s.right}>
          <div className={s.content}>
            {serviceType && headerBanner}
            <div className={s['step1-content']}>
              <ConfigProvider
                theme={{
                  token: {
                    colorText: '#f2f4ff',
                    lineWidth: 1,
                    colorBorder: '#4b4e57',
                    colorBgContainer: '#100f12',
                  },
                }}
              >
                <Form
                  form={form}
                  onFinish={async (values) => {
                    await submit1(values);
                  }}
                  className={s.form}
                >
                  {demand?.questions?.map((item: any) => (
                    <div key={item.id}>
                      <div className={s['question-title']}>{item.title}</div>
                      <Row gutter={[16, 0]}>
                        {item.type === QuestionType.TEXT &&
                          item.options?.map((option: any, index: number) => (
                            <Col key={index} span={12}>
                              <Form.Item
                                label={option.keyword}
                                name={`${item.id}_${index}`}
                                rules={[
                                  {
                                    pattern: option.dataType
                                      ? (DataTypeRegMap as any)?.[
                                          option.dataType
                                        ]
                                      : '',
                                    message: option.dataType
                                      ? (DataTypeMap as any)?.[option.dataType]
                                      : '',
                                  },
                                ]}
                              >
                                {[
                                  DataType.NUMBER_FIXED_1,
                                  DataType.NUMBER_FIXED_2,
                                  DataType.NUMBER_LESS_9,
                                ].includes(option.dataType) ? (
                                  <InputNumber
                                    min={option.min}
                                    max={option.max}
                                    precision={
                                      // eslint-disable-next-line
                                      DataType.NUMBER_FIXED_1 ===
                                      option.dataType
                                        ? 1
                                        : DataType.NUMBER_FIXED_2 ===
                                          option.dataType
                                        ? 2
                                        : option.precision
                                    }
                                    step={option.step}
                                    suffix={
                                      <div className={s.suffix}>
                                        {option.unitStr}
                                      </div>
                                    }
                                    style={{
                                      width: '100%',
                                    }}
                                    controls={false}
                                  />
                                ) : (
                                  <Input
                                    suffix={
                                      <div className={s.suffix}>
                                        {option.unitStr}
                                      </div>
                                    }
                                    style={{
                                      width: '100%',
                                    }}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          ))}
                      </Row>
                      {QuestionType.RADIO === item.type && (
                        <Form.Item
                          name={item.id}
                          rules={[
                            {
                              validator: (_, val) => {
                                return val === 'else-'
                                  ? Promise.reject('请输入')
                                  : Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <RadioInput options={item.options} />
                        </Form.Item>
                      )}
                      {QuestionType.CHECKBOX === item.type && (
                        <Form.Item
                          name={item.id}
                          rules={[
                            {
                              validator: (_, val) => {
                                return val?.includes('else-')
                                  ? Promise.reject('请输入')
                                  : Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <CheckBoxInput options={item.options} />
                        </Form.Item>
                      )}
                      {item.type === QuestionType.OTHER && (
                        <Form.Item name={item.id}>
                          <Textarea
                            pureText
                            maxLength={100}
                            placeholder="请输入您的其他要求"
                          />
                        </Form.Item>
                      )}
                    </div>
                  ))}
                </Form>
              </ConfigProvider>
            </div>
          </div>
          <div className={s.footer}>
            <div className={s.price}>
              ￥<span className={s.num}>{good?.unitPrice}</span>
            </div>
            <div className={s['button-groups']}>
              <Btn
                className={s.service1}
                onClick={() => setStep(STEP.STEP_1)}
                buttonType="goast"
              >
                上一步
              </Btn>
              <Btn className={s.service1} buttonType="goast">
                联系客服
              </Btn>
              <Btn className={s.ensure} onClick={() => form.submit()}>
                确认下单
              </Btn>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default BanConsume;
