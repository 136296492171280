import cs from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import s from './style.module.scss';

const list = [
  {
    name: '个人中心',
    icon: 'icon-gerenzhongxin',
    path: '/userinfo',
  },
  {
    name: '我的订单',
    icon: 'icon-wodedingdan',
    path: '/userinfo?type=order',
  },
  {
    name: '联系我们',
    icon: 'icon-lianxiwomen',
    path: '/userinfo?type=about',
  },
];

const NavList: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      {list.map((item) => (
        <div
          key={item.path}
          className={s.item}
          onClick={() => navigate(item.path)}
        >
          <div className={s.left}>
            <span className={`font_family ${item.icon}`}></span>
            {item.name}
          </div>
          <span className={cs('font_family icon-jiantou2', s.arrow)}></span>
        </div>
      ))}
    </div>
  );
};

export default NavList;
