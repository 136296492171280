import cs from 'classnames';
import { FC } from 'react';

import s from './style.module.scss';

interface IProps {
  className?: string;
  paramsList?: Array<{
    num: number;
    name: string;
    key: number;
  }>;
  value: number;
  onChange: (value: number) => void;
}

const defaultParamsList = [
  {
    num: 3,
    name: '较小',
    key: 1,
  },
  {
    num: 5,
    name: '中等',
    key: 2,
  },
  {
    num: 7,
    name: '较大',
    key: 3,
  },
];

const ButtonParams: FC<IProps> = ({
  className = '',
  paramsList = defaultParamsList,
  value,
  onChange,
}) => {
  return (
    <div
      className={cs(s.container, {
        [className]: !!className,
      })}
    >
      {paramsList.map(({ num, name, key }) => (
        <div
          className={cs(s.tag, {
            [s.active]: value === num,
          })}
          key={key}
          onClick={() => onChange?.(num)}
        >
          {name}
        </div>
      ))}
    </div>
  );
};

export default ButtonParams;
