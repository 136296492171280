// 生图过程中的loading效果
import { Image } from 'antd';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  LoadintTextMap,
  ProgressBarAnimationMaxPercent,
  ProgressBarAnimationTime,
} from '../../pages/generate/constants';

import s from './style.module.scss';

interface IProps {
  onFinish?: () => void;
  [key: string]: any;
}

const GenerateLoading: FC<IProps> = ({ onFinish }) => {
  const animation = useRef<number>();
  const animationStart = useRef(new Date().valueOf());

  const [percent, setPercent] = useState(0);

  const progressAnimation = () => {
    const timeDiff = new Date().valueOf() - animationStart.current;
    const nowPercent =
      ((timeDiff / 1000) * ProgressBarAnimationMaxPercent) /
      ProgressBarAnimationTime;
    if (nowPercent >= ProgressBarAnimationMaxPercent) {
      window.cancelAnimationFrame(animation.current!);
      onFinish?.();
      return;
    }
    setPercent(nowPercent);
    animation.current = window.requestAnimationFrame(progressAnimation);
  };

  const startProgressBarAnimation = () => {
    if (animation.current) {
      window.cancelAnimationFrame(animation.current);
    }
    setPercent(0);
    animationStart.current = new Date().valueOf();
    animation.current = window.requestAnimationFrame(progressAnimation);
  };

  useEffect(() => {
    startProgressBarAnimation();
  }, []);

  const loadingText = useMemo(() => {
    return LoadintTextMap[Math.min(Math.floor(percent / 25), 4)];
  }, [percent]);

  return (
    <div className={s['loading-container']}>
      <div className={s['rotate-animate']}></div>
      <div className={s['inner-container']}>
        <Image
          preview={false}
          width={160}
          height={80}
          src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/loading.gif"
        />
        <div className={s['precent-wrap']}>
          <div className={s.info}>{loadingText}</div>
          <div className={s.number}>{percent.toFixed(0)}%</div>
        </div>
      </div>
    </div>
  );
};

export default GenerateLoading;
