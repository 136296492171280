import Konva from 'konva';
import { useEffect, useState } from 'react';

const eventNames = [
  'mousedown',
  'touchstart',
  'mousemove',
  'touchmove',
  'mouseup',
  'touchend',
  'mouseout',
  'touchend',
];

export default function useStage(
  params: Konva.StageConfig,
  bindEvent: boolean = true
) {
  const [stage, setStage] = useState<any>();
  const [eventRecord, setEventRecord] = useState<any>({});

  useEffect(() => {
    if (!params.bgUrl) return;
    const sg = new Konva.Stage(params);
    setStage(sg);
  }, [params.bgUrl]);

  useEffect(() => {
    if (!stage || !bindEvent) return;
    eventNames.forEach((eventName) => {
      stage.on(eventName, (evt: any) => {
        setEventRecord({
          eventName,
          pos: {
            x: evt.evt.layerX,
            y: evt.evt.layerY,
          },
        });
      });
    });
    return () => {
      eventNames.forEach((eventName) => {
        stage.off(eventName);
      });
    };
  }, [stage, bindEvent]);

  return {
    stage,
    eventRecord,
  };
}
