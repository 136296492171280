import { FC, useEffect, useState } from 'react';

import { Optimization } from '../btn';
import ModalImage, { ModalFabric, Tabs } from '../modal-image';
import PrevoperateImage from '../prevoperate-image';

import s from './style.module.scss';

interface IProps {
  hideOptButton?: boolean; // 是否隐藏优化按钮
  fabricable?: boolean; // 是否使用面料库数据
  imageItem?: any; // 选择的图片所有数据
  showRecommandPrompt?: boolean; // 是否显示推荐提示
  size?: number; // 图片大小
  tabs?: Tabs[]; // 图片tab
  onChange?: (selectItem: any) => void; // 选中图片
  onSelectWord?: (keyword: string) => void; // 选中文字
}

const OptUpload: FC<IProps> = ({
  hideOptButton = false,
  fabricable = false,
  imageItem = null,
  showRecommandPrompt = false,
  size = 20,
  tabs = [Tabs.Recommend, Tabs.History, Tabs.Location],
  onChange = () => {},
  onSelectWord = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [selectItem, setSelectItem] = useState<any>(imageItem);

  useEffect(() => {
    onChange?.(selectItem);
  }, [selectItem]);

  useEffect(() => {
    setSelectItem(imageItem);
  }, [imageItem]);

  if (selectItem) {
    return (
      <div className={s['prev-wrap']}>
        <div className={s['img-wrap']}>
          <img src={selectItem.cosUrl} className={s.img} />
        </div>
        <div className={s['ope-wrap']}>
          <div className={s.opets}>
            <div
              className={s.delete}
              onClick={() => {
                setSelectItem(null);
              }}
            >
              <span className="font_family icon-huihuachuangkuan-qingkonghuabu"></span>
            </div>
            {hideOptButton ? null : (
              <PrevoperateImage
                key={selectItem?.cosUrl}
                url={selectItem?.cosUrl}
                onChange={(params, url) => {
                  setSelectItem({
                    ...selectItem,
                    cosUrl: url,
                  });
                }}
              >
                <Optimization />
              </PrevoperateImage>
            )}
          </div>
          {showRecommandPrompt && selectItem?.keyword && (
            <div
              className={s.desc}
              onClick={() => {
                onSelectWord?.(selectItem.keyword);
              }}
            >
              <div className={s.title}>
                智能描述
                <span className={s.tips}>(点击应用于设计描述)</span>
              </div>
              <div className={s.text}>{selectItem.keyword}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={s.container}
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className={s.upload}>
          <img
            src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/upload-icon.png"
            className={s.icon}
          />
          <div className={s.text}>
            <span className={s.link}>点击上传</span>
            图片
          </div>
          <div className={s.tips}>支持PNG、JPG，{size}M以内</div>
        </div>
      </div>
      {fabricable ? (
        <ModalFabric
          open={open}
          close={() => {
            setOpen(false);
          }}
          item={selectItem}
          onChange={(selectItem) => {
            console.log('ModalFabric-selectItem', selectItem);
            setSelectItem(selectItem);
            setOpen(false);
          }}
        />
      ) : (
        <ModalImage
          needMd
          item={selectItem}
          tabs={tabs}
          open={open}
          close={() => {
            setOpen(false);
          }}
          onChange={(selectItem) => {
            // console.log('ModalImage-selectItem', selectItem);
            setSelectItem(selectItem);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default OptUpload;
