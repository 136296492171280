export const DEFAULT_STRENG = 5;
export const DEFAULT_PROMPT = '';

export const PAINT_KEY = 'paint';
export const ERASER_KEY = 'eraser';
export const REPLACE_KEY = 'replace';
export const EXPAND_KEY = 'expand';
export const FABRIC_KEY = 'fabric';
export const SINGLE_KEY = 'single';
export const CONVERT_KEY = 'convert';

export const LABEL_TYPE = 12;
