export enum Category {
  '其他',
  '羽绒服',
  '棉布',
  '麻布',
  '丝绸',
  '呢绒',
  '皮革',
  '化纤',
  '混纺',
  '棉纶',
  '萱麻',
  '尼龙',
}
