import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import s from './style.module.scss';

type Props = {
  editArea?: any;
  children?: React.ReactNode;
};

const AreaClear: FC<Props> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  return (
    <div className={s['area-replace']}>
      <div className={s.placeholder}>
        <span>选中图片区域后</span>
        <span>可用于局部内容消除</span>
      </div>
      <div>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return React.cloneElement(child, {
            ...child.props,
            onClick: () => {
              child.props?.onClick({
                tab,
                prompt,
              });
            },
            children: '确认修改',
          });
        })}
      </div>
    </div>
  );
};

export default AreaClear;
