import cs from 'classnames';
import React, { FC } from 'react';

import s from './style.module.scss';

interface IProps {
  title?: string;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

export const OperateLayout: FC<IProps> = ({ title, children, footer }) => {
  return (
    <div
      className={cs(s.container, {
        [s['has-footer']]: footer,
      })}
    >
      {title ? (
        <div className={s.title}>
          <span>{title}</span>
        </div>
      ) : null}
      <div
        className={cs(s.content, {
          [s['has-header']]: title,
        })}
      >
        {children}
      </div>
      {footer && <div className={s.footer}>{footer}</div>}
    </div>
  );
};
