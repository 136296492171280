import request from '../../../../utils/request';

export const updateNickName = (nickName: string) =>
  request.post('/customer/api/customer/changeNick', {
    nickName,
  });

export const upadtePass = (oldPass: string, newPass: string) =>
  request.post('/customer/api/customer/changePass', {
    oldPass,
    newPass,
  });
