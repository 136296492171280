import { ColorPicker, Popover } from 'antd';
import type { Color } from 'antd/es/color-picker';
import cs from 'classnames';
import Konva from 'konva';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useImageEditor } from '../../contexts/image-editor';
import CloudUpload from './components/cloud-upload';
import ExpandChose from './components/expand-chose';
import Slider from './components/slider';
import useBrush from './hooks/useBrush';
import useEraser from './hooks/useEraser';
import useTransform from './hooks/useTransform';
import useUtilLayer from './hooks/useUtilLayer';
import { IContainerParams, ModeType } from './types';

import s from './style.module.scss';

const eventNames = [
  'mousedown',
  'touchstart',
  'mousemove',
  'touchmove',
  'mouseup',
  'touchend',
  'mouseout',
  'touchend',
];

interface IHistoryItem {
  node: Konva.Node;
  attrs: any;
}

const ImageEditor: FC<IContainerParams> = ({
  containerId = 'image-editor',
  width = 500,
  height = 500,
  bgUrl,
  setBgUrl,
  onFinish,
  children,
}) => {
  const {
    stage,
    setStage,
    bgLayer,
    setBgLayer,
    shapLayer,
    setShapLayer,
    utilLayer,
    setUtilLayer,
    mode,
    setMode,
    eventRecord,
    setEventRecord,
    bgColor,
    setBgColor,
    strokeColor,
    setStrokeColor,
    strokeWidth,
    setStrokeWidth,
    freshTime,
    setFreshTime,
    layerBgImg,
    setLayerBgImg,
    history,
    setHistory,
    currentIndex,
    setCurrentIndex,
    operate,
    setOperate,
    showRemove,
    setShowRemove,
  } = useImageEditor();

  const [colorOpen, setColorOpen] = useState(false);
  const [isActive, setActive] = useState(false);

  const addHistory = useCallback(
    (obj: any) => {
      // console.log('obj', obj);
      setOperate(false);
      setCurrentIndex(currentIndex + 1);
      return setHistory((list: any) => [
        ...list.slice(0, currentIndex),
        {
          node: obj,
          attrs: {
            // 默认的参数
            offsetX: 0,
            offsetY: 0,
            rotation: 0,
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
            x: 0,
            y: 0,
            ...obj?.getAttrs(),
            visible: obj?.isVisible(),
          },
        },
      ]);
    },
    [currentIndex]
  );

  useEffect(() => {
    console.log('history', history);
  }, [history]);

  // 添加辅料
  const addPreviewImage = (file: any) => {
    setMode(ModeType.SELECT);
    // Konva.Image.fromURL(URL.createObjectURL(file), (image) => {
    Konva.Image.fromURL(file, (image) => {
      const w = image.width();
      const h = image.height();
      const scale = w > h ? w / 4 / w : h / 4 / h;
      const finalWidth = w * scale;
      const finalHeight = h * scale;
      image.setAttrs({
        name: 'prev-image',
        width: finalWidth,
        height: finalHeight,
      });
      // 放置到历史记录
      addHistory(image);
      shapLayer?.add(image as Konva.Image);
    });
  };

  // 笔刷
  useBrush({
    layer: shapLayer,
    color: strokeColor,
    stroke: strokeWidth,
    eventRecord,
    mode,
    addHistory,
  });
  // 橡皮
  useEraser({
    layer: shapLayer,
    stroke: strokeWidth,
    eventRecord,
    mode,
    addHistory,
  });
  // 工具栏
  useUtilLayer({
    stage,
    layer: utilLayer,
    stroke: strokeWidth,
    color: strokeColor,
    eventRecord,
    mode,
  });
  // 变换
  useTransform({
    stage,
    layer: shapLayer,
    eventRecord,
    mode,
    freshTime,
    addHistory,
  });
  // 删除按钮
  useEffect(() => {
    const ts = stage?.findOne('Transformer');
    if (stage && ts) {
      setShowRemove(true);
    } else {
      setShowRemove(false);
    }
  }, [stage, stage?.findOne('Transformer')]);

  // 初始化添加对应图层
  useEffect(() => {
    const newStage = new Konva.Stage({
      container: containerId,
      width,
      height,
    });
    const bgLayer = new Konva.Layer({
      name: 'background',
    });
    const shapLayer = new Konva.Layer({
      name: 'shap-brush',
    });
    const utilLayer = new Konva.Layer({
      name: 'util',
    });

    newStage.add(bgLayer);
    newStage.add(shapLayer);
    newStage.add(utilLayer);

    setStage(newStage);

    setBgLayer(bgLayer);
    setShapLayer(shapLayer);
    setUtilLayer(utilLayer);
  }, []);
  // 用于状态保存回显
  useEffect(() => {
    if (!history?.length || !shapLayer) return;
    if (shapLayer) {
      history.forEach((his: any) => {
        his.node.setAttrs(his.attrs);
        shapLayer.add(his.node);
      });
    }
  }, [history, shapLayer]);
  // 为canvas绑定事件
  useEffect(() => {
    if (!stage) return;
    eventNames.forEach((eventName) => {
      stage.on(eventName, (e) => {
        const pos = stage.getPointerPosition();
        setEventRecord({
          eventName,
          pos,
          e,
        });
      });
    });
  }, [stage]);
  // 添加背景色-白色
  useEffect(() => {
    if (!stage || !bgLayer) return;
    const bgRect = bgLayer.findOne('.background-rect');
    if (history?.length && bgColor === 'transparent') {
      setBgColor('#fff');
      bgRect?.setAttr('fill', '#fff');
    } else if (bgRect) {
      bgRect.setAttr('fill', bgColor);
    } else {
      const rect = new Konva.Rect({
        name: 'background-rect',
        x: 0,
        y: 0,
        width: stage.width(),
        height: stage.height(),
        fill: bgColor,
        draggable: false,
        cornerRadius: 12,
      });
      bgLayer.add(rect);
    }
  }, [stage, bgLayer, bgColor, history]);
  // 添加背景图
  useEffect(() => {
    if (!layerBgImg || !stage || !shapLayer) {
      return;
    }
    Konva.Image.fromURL(layerBgImg, (image) => {
      const width = stage.width();
      const height = stage.height();
      image.setAttrs({
        name: 'background-image',
        width,
        height,
      });
      // 添加圆角
      image.cornerRadius(12);
      // 只保留一张背景图
      const bgImage = shapLayer.findOne('.background-image');
      bgImage?.remove();
      shapLayer?.add(image as Konva.Image);
      // 图片移动到最下面
      image.moveToBottom();
    });
  }, [layerBgImg, stage, shapLayer]);
  // 监听外部传入的背景，主要是引用作品到画布
  useEffect(() => {
    if (bgUrl && shapLayer) {
      setLayerBgImg(bgUrl);
    }
  }, [bgUrl, shapLayer]);

  // 具体需要展示的进行整理后显示，否则多次状态绘制会出现来回跳的情况
  useEffect(() => {
    const showHistory = history.slice(0, currentIndex);
    // 上一步和下一步
    if (operate) {
      const _showIds = showHistory.map((item: IHistoryItem) => item.node?._id);
      // 隐藏不在显示区域内的节点
      history.forEach((item: IHistoryItem) => {
        if (!_showIds.includes(item.node?._id)) {
          item.node?.hide();
        }
      });
    }
    const obj: any = {};
    let index: number = 0;
    showHistory.forEach((item: IHistoryItem, idx: number) => {
      index = idx;
      const id = item.node?._id;
      if (!(id in obj)) {
        obj[id] = {
          node: item.node,
          status: [item.attrs],
        };
      } else {
        obj[id].status.push(item.attrs);
      }
    });
    // 遍历其中的每一个节点，单独绘制出
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      obj[key].node.setAttrs({
        ...obj[key].status.at(-1),
        // visible: true,
      });
    });
    if (!showHistory.length) {
      return onFinish?.('', 0);
    }
    const imgBase64 = stage?.toDataURL({
      mimeType: 'image/jpeg',
      pixelRatio: 512 / width,
    });
    onFinish?.(
      imgBase64?.replace('data:image/jpeg;base64,', '') as string,
      index
    );
    /** 下面的操作是为了保证展示的元素一致性，防止出现多元素了之后转换出来的base64不一致 * */
    // 克隆一个子元素，去掉隐藏的元素
    /*
    const cloneStage = stage?.clone();
    if (cloneStage) {
      // 图形的图层
      const shapCloneLayer = cloneStage?.find('.shap-brush')?.[0];
      // 工具图层去掉
      const utilCloneLayer = cloneStage?.find('.util')?.[0];
      if (utilCloneLayer) {
        utilCloneLayer?.destroy();
      }
      const children = shapCloneLayer?.getChildren();
      if (children) {
        children.forEach((node: Konva.Node) => {
          if (!node.isVisible()) {
            node.destroy();
          }
        });
      }
      console.log('转换出来', cloneStage?.toObject());
      const imgBase64 = cloneStage?.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: 512 / width,
      });
      if (!imgBase64) return;
      onFinish?.(imgBase64?.replace('data:image/jpeg;base64,', ''), index);
    }
    */
  }, [history, currentIndex, operate, shapLayer, stage]);

  // dom绑定事件，移出transformer
  useEffect(() => {
    const fn = (e: any) => {
      if (e.target.nodeName === 'CANVAS') {
        setColorOpen(false);
      }
      if (e.target.nodeName === 'CANVAS' || e.target.id === 'remove') {
        return;
      }
      stage?.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      setFreshTime(Date.now());
    };
    document.addEventListener('click', fn);
    document.addEventListener('touchstart', fn);
    return () => {
      document.removeEventListener('click', fn);
      document.removeEventListener('touchstart', fn);
    };
  }, [stage]);

  useEffect(() => {
    const dis = (e: any) => {
      if (isActive) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', dis, { passive: false });
    return () => {
      document.removeEventListener('touchmove', dis);
    };
  }, [isActive]);

  // useEffect(() => {
  //   console.log('currentIndex', currentIndex);
  // }, [currentIndex]);

  useEffect(() => {
    const dele = (e: any) => {
      if (e.code !== 'Backspace') return;
      const tr = stage?.findOne('Transformer');
      const node = (tr as Konva.Transformer)?.nodes()?.[0];
      if (!node) return;
      if (node?.name() === 'background-image') {
        node.remove();
      } else {
        node?.hide();
        addHistory?.(node);
      }
      setFreshTime(Date.now());
    };
    document.addEventListener('keydown', dele);
    return () => {
      document.removeEventListener('keydown', dele);
    };
  }, [showRemove]);

  return (
    <div className={s.container}>
      <div className={cs(s.tools, s['top-tools'])}>
        <Popover
          zIndex={300}
          color="#393c46"
          content={<div style={{ color: '#f2f4ff' }}>移动工具</div>}
        >
          <div
            className={cs('font_family icon-huihuachuangkuan-yidonggongju', {
              [s.active]: mode === ModeType.SELECT,
            })}
            onClick={() => setMode(ModeType.SELECT)}
          ></div>
        </Popover>
        <Popover
          zIndex={300}
          color="#393c46"
          content={<div style={{ color: '#f2f4ff' }}>画笔工具</div>}
        >
          <div
            className={cs('font_family icon-huihuachuangkuan-huabigongju', {
              [s.active]: mode === ModeType.BRUSH,
            })}
            onClick={() => setMode(ModeType.BRUSH)}
          ></div>
        </Popover>
        <Popover
          zIndex={300}
          color="#393c46"
          content={<div style={{ color: '#f2f4ff' }}>橡皮工具</div>}
        >
          <div
            className={cs('font_family icon-huihuachuangkuan-xiangpigongju', {
              [s.active]: mode === ModeType.ERASER,
            })}
            onClick={() => {
              setMode(ModeType.ERASER);
            }}
          ></div>
        </Popover>
        <CloudUpload onChange={setLayerBgImg}>
          <Popover
            zIndex={300}
            color="#393c46"
            content={<div style={{ color: '#f2f4ff' }}>上传底图</div>}
          >
            <div className="font_family icon-huihuachuangkuan-shangchuantupian"></div>
          </Popover>
        </CloudUpload>
        <ExpandChose onChange={addPreviewImage}>
          <Popover
            zIndex={300}
            color="#393c46"
            content={<div style={{ color: '#f2f4ff' }}>上传辅料</div>}
          >
            <div className="font_family icon-huihuachuangkuan-fuliaoku"></div>
          </Popover>
        </ExpandChose>
      </div>
      <div className={cs(s['right-tools'], s.tools)}>
        <ColorPicker
          open={colorOpen}
          onOpenChange={setColorOpen}
          value={strokeColor}
          onChange={(val: Color) => setStrokeColor(val.toHexString())}
          onChangeComplete={() => setColorOpen(false)}
        >
          <span className={s.color} style={{ background: strokeColor }}></span>
        </ColorPicker>
        {/* <ColorPicker
          disabledAlpha
          value={bgColor}
          onChange={(val: Color) => setBgColor(val.toHexString())}
        >
          <span
            className={cs(s.color, s.gan)}
            style={{ background: bgColor === 'transparent' ? '#fff' : bgColor }}
          >
            <span className={s.sd}></span>
            <span className={s.sd}></span>
            <span className={s.sd}></span>
          </span>
        </ColorPicker> */}
        <Slider
          value={strokeWidth}
          onChange={(val) => {
            // setMode(ModeType.BRUSH);
            setStrokeWidth(val || 2);
          }}
          onChangeStateus={setActive}
        />
        <div
          className={cs('font_family icon-huihuachuangkuan-shangyibu', {
            [s.disabled]: currentIndex <= 0,
          })}
          onClick={() => {
            if (currentIndex >= 1) {
              setFreshTime(Date.now());
              setOperate(true);
              setCurrentIndex(currentIndex - 1);
            }
          }}
        ></div>
        <div
          className={cs('font_family icon-huihuachuangkuan-xiayibu', {
            [s.disabled]: currentIndex >= history.length,
          })}
          onClick={() => {
            if (currentIndex < history.length) {
              setFreshTime(Date.now());
              setOperate(true);
              setCurrentIndex(currentIndex + 1);
            }
          }}
        ></div>
        <div
          className={cs('font_family icon-huihuachuangkuan-qingkonghuabu', {
            [s.disabled]: !history?.length && !layerBgImg,
          })}
          onClick={() => {
            shapLayer?.destroyChildren();
            setHistory([]);
            setCurrentIndex(0);
            bgLayer?.destroyChildren();
            setBgUrl?.('');
            setLayerBgImg('');
            setBgColor('transparent');
          }}
        ></div>
      </div>
      {showRemove && (
        <div
          id="remove"
          className={s['remove-tools']}
          onClick={() => {
            const tr = stage?.findOne('Transformer');
            const node = (tr as Konva.Transformer)?.nodes()?.[0];
            if (!node) return;
            if (node?.name() === 'background-image') {
              node.remove();
            } else {
              node?.hide();
              addHistory?.(node);
            }
            setFreshTime(Date.now());
          }}
        >
          <div className={s['icon-con']}>
            <span
              className={cs(
                'font_family icon-huihuachuangkuan-qingkonghuabu',
                s['delete-icon']
              )}
            ></span>
          </div>
        </div>
      )}
      <div
        className={s['canvas-container']}
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        <div className={s.tips}>请在此区域绘制草图</div>
        <div
          id={containerId}
          className={s.canvas}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        ></div>
      </div>

      <div className={s.footer}>{children}</div>
    </div>
  );
};

export default ImageEditor;

export * from './types';
