import { message } from 'antd';
import Konva from 'konva';
import { useEffect, useState } from 'react';

import { useOperateInteraction } from '../../../contexts/operate-interaction';
import useBgLayer from './useBgLayer';

export default function useReplaceLayer({
  stage,
  eventRecord,
  stroke,
  bgUrl,
  editable = true,
  setEditable = () => null,
}: {
  bgUrl: string;
  stage: Konva.Stage;
  editable?: boolean;
  bindEvent?: any;
  stroke: number;
  eventRecord: any;
  setEditable?: (params?: any) => void;
}) {
  // const [layerWhite, setLayerWhite] = useState<any>();
  const [layer, setLayer] = useState<any>();
  const [lines, setLines] = useState<any>([]);
  const [his, setHis] = useState(0);
  const [currentLine, setCurrentLine] = useState<any>();
  const [bg, setBg] = useState<string>(bgUrl);
  const [currentStep, setCurrentStep] = useState<{
    value: number;
    trigger: 'auto' | 'manual';
  }>({
    trigger: 'auto',
    value: 0,
  });
  const { setCurrentBg } = useOperateInteraction();

  const { toUrl } = useBgLayer(bg, stage);

  useEffect(() => {
    if (bgUrl) {
      setBg(bgUrl);
      setHis(0);
      setLines([]);
      setCurrentBg(bgUrl);
    }
  }, [bgUrl]);

  useEffect(() => {
    if (!stage) return;
    // const layer1 = new Konva.Layer({
    //   name: 'replaceLayerWhiteLine',
    // });
    const layer = new Konva.Layer({
      name: 'replaceLayer',
    });
    // stage?.add(layer1);
    stage?.add(layer);
    // setLayerWhite(layer1);
    setLayer(layer);
  }, [stage]);

  useEffect(() => {
    if (!eventRecord || !layer || !editable) return;
    const { eventName, pos } = eventRecord;
    if (['mousedown', 'touchstart'].includes(eventName)) {
      const line = new Konva.Line({
        // stroke: '#6451e7',
        stroke: 'rgba(100, 81, 231, 0.6)',
        strokeWidth: stroke,
        globalCompositeOperation: 'source-over',
        points: [pos?.x ?? 0, pos?.y ?? 0, pos?.x ?? 0, pos?.y ?? 0],
        lineCap: 'round',
        lineJoin: 'round',
      });
      layer.add(line);
      setCurrentLine(line);
    }
  }, [eventRecord, layer, editable]);

  useEffect(() => {
    if (!eventRecord || !layer || !currentLine || !editable) return;
    const { eventName, pos } = eventRecord;
    if (['mousemove', 'touchmove'].includes(eventName)) {
      currentLine.points([...(currentLine?.points() ?? []), pos?.x, pos?.y]);
      layer.batchDraw();
    }
  }, [currentLine, eventRecord, layer, editable]);

  useEffect(() => {
    if (!eventRecord || !currentLine || !editable) return;
    const { eventName } = eventRecord;
    if (['mouseup', 'touchend', 'mouseout'].includes(eventName)) {
      setCurrentLine(null);
      setLines((lines: any) => [
        ...lines.slice(0, currentStep.value),
        currentLine,
      ]);
      setCurrentStep((val) => ({
        trigger: 'auto',
        value: val.value + 1,
      }));
    }
    // }, [eventRecord, currentLine, layerWhite, editable]);
  }, [eventRecord, currentLine, editable]);

  useEffect(() => {
    setHis(lines.length);
  }, [lines]);

  useEffect(() => {
    if (currentStep.trigger === 'auto') return;
    const temArr = lines.slice(0, currentStep.value);
    layer?.removeChildren();
    temArr.forEach((line: any) => {
      layer.add(line);
    });
  }, [currentStep, lines, layer]);

  useEffect(() => {
    if (his > 0) {
      localStorage.setItem('hasOperation', 'Y');
    } else {
      localStorage.setItem('hasOperation', '');
    }
  }, [his]);

  return {
    layer,
    prev: () => {
      currentStep.value > 0 &&
        setCurrentStep((val) => ({
          trigger: 'manual',
          value: val.value - 1,
        }));
    },
    next: () => {
      currentStep.value < his &&
        setCurrentStep((val) => ({
          trigger: 'manual',
          value: val.value + 1,
        }));
    },
    reset: () => {
      setLines([]);
      setCurrentStep(() => ({
        trigger: 'manual',
        value: 0,
      }));
    },
    bgUrl: () => toUrl(),
    maskUrl: () =>
      layer.toDataURL({
        pixelRatio: 2,
      }),
    getBg: () => bg,
    setCurrentBg: () => {
      setBg(bg);
    },
    getBgAndMask: () => {
      if (!lines.length) {
        message.error('请使用画笔涂抹想要替换的区域');
        setEditable(true);
        return false;
      }
      const layerWhite = new Konva.Layer({
        name: 'replaceLayerWhiteLine',
      });
      stage.add(layerWhite);
      lines.forEach((line: any) => {
        const tempLine = new Konva.Line({
          stroke: '#fff',
          strokeWidth: stroke,
          globalCompositeOperation: 'source-over',
          points: line.points(),
          lineCap: 'round',
          lineJoin: 'round',
        });
        layerWhite.add(tempLine);
      });

      const maskUrl = layerWhite.toDataURL({
        pixelRatio: 2,
      });
      layerWhite.destroy();
      return {
        maskBase64: maskUrl,
      };
    },
  };
}
