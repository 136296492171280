import cs from 'classnames';
import React, { FC } from 'react';

import { useUserInfo } from '../../../contexts/user-info';
import Menus from '../../menus';

import s from './style.module.scss';

interface ILayoutProps {
  width?: string;
  children?: React.ReactNode;
  left?: React.ReactNode;
  leftClassName?: string;
  steps?: React.ReactNode;
}

const PageLayout: FC<ILayoutProps> = ({
  children,
  left,
  leftClassName,
  width,
  steps,
}) => {
  const { userInfo } = useUserInfo();

  return (
    <div className={cs(s.container)}>
      <div className={s.nav}>
        <div className={s.content}>
          <Menus userInfo={userInfo} />
        </div>
      </div>
      {steps ? (
        <div className={s['step-left']}>
          {steps}
          {left}
        </div>
      ) : (
        <div className={cs(s.left, leftClassName)}>{left}</div>
      )}

      <div
        className={s.right}
        style={{
          width: width ?? '405px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
export * from '../app-layout';
export * from '../operate-layout';
