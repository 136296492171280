import cs from 'classnames';
import { FC, useCallback } from 'react';

import { IStepItem } from '../../types';

import s from './style.module.scss';

interface IProps {
  activeItem?: IStepItem;
  setActiveItem: (activeCell: IStepItem) => void;
  list: IStepItem[];
}

const Steps: FC<IProps> = ({ activeItem, setActiveItem, list }) => {
  const clickNode = useCallback(
    (activeCell: IStepItem, currentCell: IStepItem) => {
      if (currentCell.sort <= activeCell.sort) {
        setActiveItem(currentCell);
      } else if (
        currentCell.sort > activeCell.sort &&
        currentCell?.accessPage?.includes(activeCell.pageName)
      ) {
        setActiveItem(currentCell);
      }
    },
    []
  );

  return (
    <div className={s.container}>
      {list.map((item, index) => (
        <div
          key={item.sort}
          className={cs(s.item, {
            [s.first]: index === 0,
            [s.active]: item.sort <= (activeItem?.sort ?? 1),
          })}
          onClick={() => clickNode(activeItem ?? item, item)}
        >
          <div className={s.title}>{item.name}</div>
          <div className={s.num}>
            {item.sort <= (activeItem?.sort ?? 1) ? item.sort : ''}
            <div className={s.line}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Steps;
