import request from '../../utils/request';

// 获取供应商面料列表
export const getProviderFabricList = (data: {
  page?: number;
  size?: number;
  matcher?: string;
}) => {
  return request.post('/customer/api/fabri/pageList', data);
};

// 获取供应商面料详情
export const getProviderFabricDetail = (id: number) => {
  return request.post(`/customer/api/fabri/getInfo`, { id });
};

// 面料上身
export const fabricDesignApi = (data: {
  imgUrl: string;
  code: string;
  sourceType: 0 | 1; // 0个人面料库，1公共面料库
  strength: number;
  prompt?: string;
}) => {
  return request.post('/customer/api/design/fabricGenerate', data);
};

// 轮询面料上身结果
export const pollingResult = (data: {
  promptIdentity: string;
  code: string;
  sourceType?: 0 | 1;
}) => {
  return request.post('/customer/api/design/fabricGenerateData', data);
};

export const listFabic = (data: {
  page: number;
  size: number;
  status?: number;
}) => {
  return request.post('/customer/api/customerFabri/pageListByCustomer', data);
};
