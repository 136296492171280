import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getUserInfo } from './api';
import { IProps, IUserInfo } from './types';

const Context = createContext<IProps>({} as IProps);

export const UserInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);

  const getUserInfoApi = useCallback(() => {
    getUserInfo().then((res) => setUserInfo(res));
  }, []);

  useEffect(() => {
    getUserInfoApi();
  }, [getUserInfoApi]);

  return (
    <Context.Provider value={{ userInfo, getUserInfoApi }}>
      {children}
    </Context.Provider>
  );
};

export const useUserInfo = (): IProps => useContext(Context);
