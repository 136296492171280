import { PlusOutlined } from '@ant-design/icons';
import { message, Pagination, Upload } from 'antd';
import cs from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import Btn from '../../../../components/btn';
import { transImage } from '../../../../utils';
import ImageHandle from '../image-handle';
import { bundleDelete, listFabic, uploadFabic } from './api';

import s from './style.module.scss';

const FabricList: FC = () => {
  const [localFile, setLocalFile] = useState<File>();
  const [localUrl, setLocalUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<'show' | 'edit'>('show');
  const [ids, setIds] = useState<number[]>([]);

  const divRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [requestPage, setRequestPage] = useState(1);
  const [list, setList] = useState<any[]>([]);

  const createUrl = (file: File, cb?: () => void) => {
    const reader = new FileReader();
    reader.onload = () => {
      setLocalUrl(reader.result as string);
      cb?.();
    };
    reader.readAsDataURL(file);
  };

  const pageSize = 44;

  const getHistory = () => {
    if (loading) return;
    if (requestPage > total && total !== 0) {
      return;
    }
    setLoading(true);
    listFabic({
      page: requestPage,
      size: pageSize,
    })
      .then((res) => {
        setRequestPage(res.page);
        setTotal(res.records);
        setList(res.rows);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log('err', err);
      });
  };

  const deleteIds = () => {
    if (!ids.length) {
      return message.error('请先选择要删除的面料');
    }
    bundleDelete({ ids })
      .then(() => {
        setList([]);
        setIds([]);
        setRequestPage(1);
        getHistory();
        message.success('删除成功');
      })
      .catch((err) => {
        console.log('获取结果错误', err);
      });
  };

  useEffect(() => {
    getHistory();
  }, [requestPage]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.tip}>如审核未通过，将不在列表中展示。</div>
        {mode === 'edit' ? (
          <div className={s.operates}>
            <Btn
              className={s.btn}
              onClick={() => {
                deleteIds();
              }}
            >
              确认删除
            </Btn>
            <Btn
              className={s.btn}
              buttonType="goast"
              onClick={() => {
                setMode('show');
                setIds([]);
              }}
            >
              取消
            </Btn>
          </div>
        ) : (
          <Btn
            icon={
              <span className="font_family icon-huihuachuangkuan-qingkonghuabu"></span>
            }
            className={s.delete}
            onClick={() => setMode('edit')}
          >
            删除
          </Btn>
        )}
      </div>
      <div
        className={cs(s.list, {
          [s.empty]: total === 0,
        })}
      >
        <div className={s['loop-wrap']}>
          <div
            className={cs(s.loop, {
              [s.loading]: loading,
            })}
            id="history-list"
            style={{ height: 'calc(100vh - 160px)' }}
            ref={divRef}
          >
            <div className={s['item-list']}>
              <Upload
                accept=".png, .jpg, .jpeg"
                showUploadList={false}
                maxCount={1}
                disabled={mode === 'edit'}
                beforeUpload={(file) => {
                  // 限制大小
                  if (file.size > 20 * 1024 * 1024) {
                    message.error('图片大小不能超过20M');
                    return Upload.LIST_IGNORE;
                  }
                  if (file.size > 10 * 1024 * 1024) {
                    message.warning(
                      '您上传的图片较大，可能会对生成结果的清晰度和细节产生影响~'
                    );
                  }
                  setLocalFile(file);
                  createUrl(file, () => setOpen(true));
                  return false;
                }}
              >
                <div
                  className={cs(s.upload, {
                    [s['disabled-point']]: mode === 'edit',
                  })}
                >
                  <div className={s['icon-wrap']}>
                    <PlusOutlined style={{ fontSize: 12 }} />
                  </div>
                  <span>上传面料</span>
                </div>
              </Upload>
              {list.map((item: any) => (
                <div
                  key={item.id}
                  className={cs(s.item, {
                    [s.chosed]: ids.includes(item.id),
                  })}
                  onClick={() => {
                    if (mode === 'show') {
                      return;
                    }
                    const idx = ids.findIndex((i) => i === item.id);
                    if (idx > -1) {
                      setIds(ids.filter((i) => i !== item.id));
                    } else {
                      setIds([...ids, item.id]);
                    }
                  }}
                >
                  <div
                    className={cs(s['img-content'], {
                      [s.mask]: item?.status === 1,
                    })}
                  >
                    <div className={s['img-container']}>
                      <img
                        className={s.bg}
                        src={transImage(item?.imageUrl, 'webp')}
                        alt="item"
                      />
                    </div>
                    {mode === 'edit' ? (
                      <img
                        src={
                          ids.includes(item.id)
                            ? 'https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230726/818963fc8f184905975c459874e93f24.png'
                            : 'https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230726/854880ddb3ef48ac953543715886e6b6.png'
                        }
                        className={s.icon}
                      />
                    ) : null}
                    <div className={s.tag}>待审核</div>
                  </div>
                </div>
              ))}
            </div>
            <div className={s.pagination}>
              <Pagination
                total={total}
                current={requestPage}
                pageSize={pageSize}
                hideOnSinglePage={true}
                showSizeChanger={false}
                showTotal={(total: number) => {
                  return <div className={s.total}>{`共 ${total} 条`}</div>;
                }}
                onChange={(page: number) => {
                  setRequestPage(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ImageHandle
        local
        open={open}
        close={() => setOpen(false)}
        localUrl={localUrl}
        onChange={(file: File) => {
          setLocalFile(file);
          createUrl(file);
        }}
        handle={() => {
          const fd = new FormData();
          fd.append('file', localFile as File);
          uploadFabic(fd).then(() => {
            setList([]);
            setRequestPage(1);
            getHistory();
          });
        }}
      />
    </div>
  );
};

export default FabricList;
