import { Form, Input, message, Space, Tooltip } from 'antd';
import cs from 'classnames';
import { md5 } from 'js-md5';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Btn from '../../components/btn';
import args from '../../utils/args';
import {
  getAuthCode,
  login,
  phoneLogin as phoneLoginApi,
  sendCode as sendCodeApi,
} from './api';

import s from './style.module.scss';

const Login: FC<{ paramPhoneLogin?: boolean }> = ({ paramPhoneLogin }) => {
  const [form] = Form.useForm();
  const [codeInfo, setCodeInfo] = useState<{
    img?: string;
    uuid?: string;
  }>({});
  const timer = useRef<any>(null);
  const [sendable, setSendable] = useState(false);
  const [seconds, setSeconds] = useState<number | null>(null);
  const phone = Form.useWatch('mobile', form);

  const navigate = useNavigate();

  const initAuthCode = () => {
    return getAuthCode().then(setCodeInfo);
  };

  const onLogin = (values: any) => {
    const { username, password, code } = values;
    if (!codeInfo.uuid) {
      message.error('请刷新验证码');
      return;
    }

    login({
      code,
      username,
      password: md5(password),
      uuid: codeInfo.uuid,
    })
      .then((res) => {
        if (res.identity === 0) {
          message.error('请先升级到正式版账号');
          return;
        }
        window.localStorage.setItem('access_token', res.token);
        const redirect = args.get('redirect') || '/';
        window.location.href = redirect;
      })
      .catch(initAuthCode);
  };

  const phoneLogin = (values: any) => {
    phoneLoginApi({
      phone: values.mobile,
      code: values.code,
    })
      .then((res) => {
        if (res.identity === 0) {
          message.error('请先升级到正式版账号');
          return;
        }
        window.localStorage.setItem('access_token', res.token);
        const redirect = args.get('redirect') || '/';
        window.location.href = redirect;
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const sendCode = () => {
    setSendable(false);
    setSeconds(60);
    sendCodeApi({
      phone,
      scene: 'phone_login_2',
    });
  };

  useEffect(() => {
    initAuthCode();
  }, []);

  useEffect(() => {
    if (seconds !== null && seconds <= 0) {
      setSeconds(null);
      setSendable(true);
    }
    if (seconds !== null && !timer.current) {
      timer.current = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [seconds]);

  useEffect(() => {
    if (phone) {
      form
        .validateFields(['mobile'])
        .then(() => {
          setSendable(true);
        })
        .catch(() => {
          setSendable(false);
        });
    } else {
      setSendable(false);
    }
  }, [phone]);

  return (
    <div className={s['st-login-container']}>
      <div className={s['st-login-container']}>
        <div className={s.welcome}>欢迎您的登录</div>
        <div className={s.con}>
          <div className={s.header}>
            <span
              className={cs(s.default, {
                [s.active]: paramPhoneLogin,
              })}
              onClick={() => navigate('/login/phoneLogin')}
            >
              手机登录
              {paramPhoneLogin ? <i className={s.line}></i> : null}
            </span>
            <span
              className={cs(s.default, {
                [s.active]: !paramPhoneLogin,
              })}
              onClick={() => navigate('/login')}
            >
              密码登录
              {!paramPhoneLogin ? <i className={s.line}></i> : null}
            </span>
          </div>
          {paramPhoneLogin ? (
            <Form
              className={s.form}
              form={form}
              layout="horizontal"
              onFinish={phoneLogin}
              style={{ marginTop: '68px' }}
            >
              <Form.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: /^1[3-9][0-9]{9}$/,
                    message: '请输入正确的手机号',
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  placeholder="手机号"
                  className={s['height-40']}
                />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: '请输入验证码',
                  },
                ]}
              >
                <div className={s.flex}>
                  <Input
                    maxLength={6}
                    placeholder="验证码"
                    className={s['height-40']}
                  />
                  <Btn
                    buttonType="goast"
                    disabled={!sendable || seconds !== null}
                    onClick={() => sendCode()}
                    className={s['code-button']}
                  >
                    {seconds ? seconds + 's' : '获取验证码'}
                  </Btn>
                </div>
              </Form.Item>
              <Form.Item>
                <div className={s['phone-login-tip-con']}>
                  <Btn block className={s['login-button']} htmlType="submit">
                    立即登录
                  </Btn>
                  <div className={s['']}>
                    请联系
                    <Tooltip
                      trigger="click"
                      title="客服电话：0571-88761133"
                      placement="bottom"
                    >
                      <span className={s['text-server']}>客服人员</span>
                    </Tooltip>
                    开通账号后使用
                  </div>
                </div>
              </Form.Item>
            </Form>
          ) : (
            <Form
              className={s.form}
              layout="horizontal"
              form={form}
              onFinish={onLogin}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名' }]}
              >
                <Input placeholder="用户名" className={s['account-input']} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入登录密码' }]}
              >
                <Input.Password
                  className={s['account-input']}
                  placeholder="登录密码"
                />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[{ required: true, message: '请输入验证码' }]}
              >
                <Space className={s['w-full']} align="center">
                  <Input
                    placeholder="验证码"
                    className={cs(s['account-input'], s['w-256'])}
                  />
                  <img
                    className={s['account-img']}
                    src={codeInfo.img}
                    onClick={initAuthCode}
                  />
                </Space>
              </Form.Item>
              <Form.Item>
                <div className={s['account-button-wrap']}>
                  <Btn block className={s['w-240-h-48']} htmlType="submit">
                    登录
                  </Btn>
                  <div>
                    <Link className={s['text-primary']} to="/resetPassword">
                      忘记密码？
                    </Link>
                  </div>
                  <div className={s['text-bright-7']}>
                    请联系
                    <Tooltip
                      trigger="click"
                      title="客服电话：0571-88761133"
                      placement="bottom"
                    >
                      <span className={s['text-server']}>客服人员</span>
                    </Tooltip>
                    开通账号后使用
                  </div>
                </div>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
