import React, { FC, useState } from 'react';

import ModalTip from '../../../../components/modal-tip';
import ModalImage, { Tabs } from '../../../modal-image';

type Props = {
  children: React.ReactNode;
  choseImage?: (params: any) => void;
};

const ChoseHistory: FC<Props> = ({ children, choseImage }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [tipOpen, setTipOpen] = useState<boolean>(false);

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => {
            if (localStorage.getItem('hasOperation') === 'Y') {
              return setTipOpen(true);
            }
            setOpen(true);
            child.props?.onClick?.();
          },
        });
      })}
      <ModalTip
        tipOpen={tipOpen}
        setTipOpen={setTipOpen}
        onSuccess={() => setOpen(true)}
      />
      <ModalImage
        tabs={[Tabs.Recommend, Tabs.History, Tabs.Location]}
        open={open}
        close={() => setOpen(false)}
        onChange={(imgItem) => {
          console.log('imgItem', imgItem);
          choseImage?.(imgItem);
          setOpen(false);
        }}
      />
    </>
  );
};

export default ChoseHistory;
