import { message } from 'antd';
import { useAtom } from 'jotai';
import React, { FC, useEffect, useRef } from 'react';

import Btn from '../../components/btn';
import ButtonParams from '../../components/button-params';
import Result from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import OptUpload from '../../components/opt-upload';
import TextArea from '../../components/textarea';
import { useUserInfo } from '../../contexts/user-info';
import {
  blendImageItemAtom,
  blendOriginImageItemAtom,
  blendParamsAtom,
  blendResultAtom,
  blendStatusAtom,
} from '../../store/generation';
import { blendGenerate, blendResult as blendResultApi } from '../../utils/api';

import s from './style.module.scss';

const BlendPicture: FC = () => {
  const timer = useRef<NodeJS.Timeout>();
  const { userInfo } = useUserInfo();

  const [blendStatus, setBlendStatus] = useAtom(blendStatusAtom);
  const [blendParams, setBlendParams] = useAtom(blendParamsAtom);
  const [blendResult, setBlendResult] = useAtom(blendResultAtom);
  const [blendOriginImageItem, setBlendOriginImageItem] = useAtom(
    blendOriginImageItemAtom
  );
  const [blendImageItem, setBlendImageItem] = useAtom(blendImageItemAtom);

  const INTRODUCE_VIDEO_SRC =
    'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/compose.mp4';

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const changeStrength = (value: number) => {
    setBlendParams((prev) => ({ ...prev, strength: value }));
  };

  const changePrompt = (value: string) => {
    setBlendParams((prev) => ({ ...prev, prompt: value }));
  };
  const changeImgUrl = (url: string) => {
    setBlendParams((prev) => ({ ...prev, url }));
  };
  const changeBlendUrl = (url: string) => {
    setBlendParams((prev) => ({ ...prev, blendUrl: url }));
  };
  const changePromptIdentity = (promptIdentity: string) => {
    setBlendParams((prev) => ({ ...prev, promptIdentity }));
  };

  const changeLoading = (loading: boolean) => {
    setBlendStatus((prev) => ({ ...prev, loading }));
  };

  const generateApi = () => {
    if (!blendParams.url || !blendParams.blendUrl) {
      message.error('图片不能为空');
      return Promise.reject('图片不能为空');
    }
    changeLoading(true);
    blendGenerate({
      imgUrl: blendParams.url,
      blendImgUrl: blendParams.blendUrl,
      labelType: 15,
      strength: Number(((10 - blendParams.strength) / 10).toFixed(1)),
      prompt: blendParams.prompt,
    })
      .then(changePromptIdentity)
      .catch(() => changeLoading(false));
  };

  const pollingResultApi = () => {
    if (!blendParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), blendStatus.timing);
      return;
    }
    blendResultApi({
      labelType: 15,
      promptIdentity: blendParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setBlendResult(res);
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            blendStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setBlendResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), blendStatus.timing);
  };

  useEffect(() => {
    blendStatus.loading && !blendResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [blendStatus.loading, blendParams.promptIdentity, blendResult]);

  return (
    <div className={s.container}>
      <PageLayout
        left={
          <Result
            key={blendParams.promptIdentity}
            loading={blendStatus.loading as boolean}
            item={blendResult}
            tipContent={
              <div style={{ padding: '76px' }}>
                <video autoPlay loop width={360} height={360} muted>
                  <source src={INTRODUCE_VIDEO_SRC} type="video/mp4" />
                </video>
              </div>
            }
          />
        }
      >
        <OperateLayout
          title="款式融合"
          footer={
            <Btn
              isGenerate
              className={s.button}
              loading={blendStatus.loading}
              disabled={!blendParams?.url || !blendParams?.blendUrl}
              onClick={() => {
                if (blendStatus.loading) return;
                generateApi();
                setBlendResult(null);
              }}
            >
              生成设计图
            </Btn>
          }
        >
          <div>
            <div className={s.box}>
              <div className={s.title}>灵感图</div>
              {/* 原图 */}
              <OptUpload
                imageItem={blendOriginImageItem}
                onChange={(imageItem) => {
                  setBlendOriginImageItem(imageItem);
                  changeImgUrl(imageItem?.cosUrl);
                }}
              />
              {/* 融合图 */}
              <div className={s.wrap}>
                <OptUpload
                  imageItem={blendImageItem}
                  onChange={(imageItem) => {
                    setBlendImageItem(imageItem);
                    changeBlendUrl(imageItem?.cosUrl);
                  }}
                />
              </div>
            </div>
            <div className={s.box}>
              <div className={s.title}>设计描述</div>
              <TextArea
                userInfo={userInfo}
                rows={4}
                maxLength={500}
                placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
                value={blendParams.prompt}
                onChange={changePrompt}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>
                <div>设计底图和生图的相似度</div>
              </div>
              <ButtonParams
                value={blendParams.strength}
                onChange={changeStrength}
              />
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </div>
  );
};

export default BlendPicture;
