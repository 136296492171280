import { ConfigProvider, theme } from 'antd';
import zhCN from 'antd/locale/zh_CN';
// import React, { StrictMode } from 'react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import './style.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider
    locale={zhCN}
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: '#6451e7',
        // colorPrimaryHover: '#fff',
        // colorPrimaryActive: '#fff',
        colorTextPlaceholder: '#4B4E57',
        fontSizeLG: 14,
        lineWidth: 2,
      },
      components: {
        Input: {
          colorBgContainer: 'transparent',
          colorBorder: 'rgba(255, 255, 255, 0.1)',
          colorPrimaryHover: '#6451e7',
          colorError: '#e55566',
        },
        InputNumber: {
          colorBorder: 'rgba(255, 255, 255, 0.1)',
        },
        Select: {
          colorBgContainer: '#18181c',
          colorBorder: 'rgba(255, 255, 255, 0.1)',
          colorPrimaryHover: '#6451e7',
        },
        Button: {},
        Drawer: {
          paddingLG: 16,
        },
        Collapse: {
          colorBgContainer: '#18181c',
          colorBorder: 'rgba(255, 255, 255, 0.1)',
          colorTextHeading: '#9FA4BD',
        },
        Message: {
          zIndexPopup: 9999,
        },
        Slider: {
          railBg: '#373740',
          railHoverBg: '#373740',
          handleColor: '#fff',
          handleActiveColor: '#fff',
          trackBg: '#6451E7',
          trackHoverBg: '#6451E7',
          handleSize: 10,
          handleSizeHover: 12,
          controlSize: 6,
          railSize: 6,
        },
      },
    }}
  >
    {/* <StrictMode> */}
    <App />
    {/* </StrictMode> */}
  </ConfigProvider>
);
