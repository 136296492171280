import { Modal } from 'antd';
import { FC } from 'react';

import Btn from '../../../../components/btn';
import PrevoperateImage from '../../../../components/prevoperate-image';

import s from './style.module.scss';

interface IProps {
  local?: boolean;
  open: boolean;
  localUrl: string;
  item?: any;
  needMd?: boolean;
  close?: () => void;
  onChange?: (item: any) => void;
  handle?: () => void;
  backgroundColor?: 'transparent' | '#fff';
}

const ImageHandle: FC<IProps> = ({
  local,
  open,
  localUrl,
  backgroundColor,
  close,
  onChange,
  handle,
}) => {
  return (
    <Modal
      centered
      destroyOnClose
      width={712}
      open={open}
      style={{ pointerEvents: 'auto' }}
      modalRender={() => {
        return (
          <div className={s.modal}>
            <div
              className={s.close}
              onClick={() => {
                close?.();
              }}
            >
              <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
            </div>
            <div className={s['tabs-con']}>
              <div className={s.tabs}>提示</div>
            </div>
            <div className={s['con-wrap']}>
              <div className={s.content}>
                <div className={s['image-placeholder']}>
                  <img src={localUrl} className={s.img} />
                  <PrevoperateImage
                    local={local}
                    key={localUrl}
                    url={localUrl}
                    backgroundColor={backgroundColor}
                    divideFile={(file: File) => {
                      onChange?.(file);
                    }}
                  >
                    <div className={s['opt-con']}>
                      <span className="font_family icon-a-tupianyuchulizhinengyouhua"></span>
                      点击优化
                    </div>
                  </PrevoperateImage>
                </div>
              </div>
              <div className={s['text-tip']}>
                建议使用512X512px的尺寸提交审核，您可使用智能优化工具处理
              </div>
            </div>
            <div className={s.footer}>
              <Btn
                buttonType="goast"
                className={s.button}
                onClick={() => close?.()}
              >
                取消
              </Btn>
              <Btn
                className={s.button}
                onClick={() => {
                  handle?.();
                  close?.();
                }}
              >
                提交审核
              </Btn>
            </div>
          </div>
        );
      }}
    ></Modal>
  );
};

export default ImageHandle;
