import request from '../../utils/request';
import {
  IGetLabelsRes,
  ILabel,
  IPageRecommendParams,
  IPageRecommendRes,
} from './types';

// 获取推荐图库标签
export const getLabels = (): Promise<IGetLabelsRes> =>
  request.get('/customer/api/garryLabel/list');

// 推荐图库翻页查询
export const pageRecommendImgs = (
  data: IPageRecommendParams
): Promise<IPageRecommendRes> =>
  request.post('/customer/api/garryRecommend/pageList', data);

// 获取线稿的labels
export const getDraftLabels = (): Promise<ILabel[]> =>
  request.get('/customer/api/garryLabel/models');
