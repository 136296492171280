import { message } from 'antd';
import { useAtom } from 'jotai';
import React, { FC, useEffect } from 'react';

import Btn from '../../components/btn';
import CardGenerate from '../../components/card-generate';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import OptUpload from '../../components/opt-upload';
import TextArea from '../../components/textarea';
import { useUserInfo } from '../../contexts/user-info';
import useGenerate from '../../hooks/useGenerate';
import {
  generateImageItemAtom,
  generateParamsAtom,
  generatePullParamsAtom,
  generateResultAtom,
  generateResultsAtom,
  generateStatusAtom,
} from '../../store/generation';
import {
  interactions,
  pollingResult as pollingResultApi,
  uvOrReInteractions,
} from './api';
import ColorSelect from './components/colorSelect';
import FixPrompt from './components/fix-prompt';
import LocalCollectParams from './components/local-collect-params';
import { LabelType } from './constants';
import { IComponentProps } from './types';

import s from './style.module.scss';

const MAX_COUNT = 48; // 最多显示的结果数

const Generate: FC<IComponentProps> = ({ steps, clickToNextProcessNode }) => {
  const [geneStatus, setGeneStatus] = useAtom(generateStatusAtom);
  const [geneParams, setGeneParams] = useAtom(generateParamsAtom);
  const [generatePullParams, setGeneratePullParams] = useAtom(
    generatePullParamsAtom
  );
  const [generateImageItem, setGenerateImageItem] = useAtom(
    generateImageItemAtom
  );
  const [, setCurrentResult] = useAtom(generateResultAtom);
  const [results, setResults] = useAtom(generateResultsAtom);

  const { userInfo } = useUserInfo();

  const { startGenerate, promptIdentity, result, setPullParams, stopPull } =
    useGenerate({
      defaultLoading: geneStatus.loading,
      defaultPromptIdentity: geneParams.promptIdentity,
      generateApi: (params) =>
        params.isV ? uvOrReInteractions(params) : interactions(params),
      pollingResultApi,
      defaultPullParams: generatePullParams,
      onFirstStepSuccess: (res, params) => {
        if (params?.isV) {
          setGeneratePullParams({
            ...params,
            garryId: geneParams.garryId,
            originImaginePrompt: geneParams.originImaginePrompt,
            mdConfigId: userInfo.mdConfigId as any,
            messageReferenceId: params.messageId,
          });
        } else {
          console.log('第一次请求的参数', params, userInfo);
          setGeneratePullParams({
            ...params,
            promptIdentity: res,
            mdConfigId: userInfo.mdConfigId as any,
          });
        }
      },
      onSuccess: () => {
        setGeneStatus((prev) => ({
          ...prev,
          loading: false,
        }));
      },
      onError: () => {
        setGeneStatus((prev) => ({
          ...prev,
          loading: false,
        }));
      },
    });

  useEffect(() => {
    setGeneParams((vals) => ({
      ...vals,
      promptIdentity,
    }));
  }, [promptIdentity]);

  useEffect(() => {
    setResults((prev) => [...(result || []), ...prev].slice(0, MAX_COUNT));
  }, [result]);

  return (
    <PageLayout
      steps={steps}
      left={
        <CardGenerate
          placeholder="快去右侧输入你的设计创意吧！"
          startTime={geneStatus.startTime}
          loading={geneStatus.loading}
          list={results}
          renderEmpty={
            <LocalCollectParams
              placeholder="快去右侧输入你的设计创意吧！"
              onStart={(item, prompt, color) => {
                if (userInfo?.lifeStatus === 0) {
                  return message.error('您的账户已到期，请联系客服开通权限');
                }
                setGeneStatus((vals: any) => ({
                  ...vals,
                  loading: true,
                  startTime: Date.now(),
                }));
                setCurrentResult(null);
                setGenerateImageItem(item);
                const params: any = {
                  originImaginePrompt: prompt,
                  colorCode: color,
                  garryId: item?.garryId,
                  labelType: LabelType.Origin,
                  mdConfigId: userInfo.mdConfigId as any,
                };
                if (item?.url) {
                  params.url = item.url;
                }
                setGeneParams(params);
                startGenerate(params);
              }}
            />
          }
          onOverTime={() => {
            setGeneStatus((vals: any) => ({
              ...vals,
              loading: false,
            }));
            stopPull();
          }}
          onGenerate={(params) => {
            setGeneParams((vals: any) => ({
              ...vals,
              labelType: params.labelType,
              mdConfigId: userInfo.mdConfigId as any,
            }));
            setGeneStatus((vals: any) => ({
              ...vals,
              loading: true,
              startTime: Date.now(),
            }));
            startGenerate({
              ...params,
              mdConfigId: userInfo.mdConfigId as any,
            });
          }}
          onChangeCollectStatus={(item: any) => {
            setResults((prev) => {
              const index = prev.findIndex((cell) => cell.id === item.id);
              const tempArr = [...prev];
              tempArr.splice(index, 1, {
                ...prev[index],
                collectStatus: !prev[index].collectStatus,
              });
              return tempArr;
            });
          }}
          onChangePullParams={(params) =>
            setPullParams({
              ...params,
              mdConfigId: userInfo.mdConfigId as any,
            })
          }
          onClick={clickToNextProcessNode}
        />
      }
    >
      <OperateLayout
        footer={
          <Btn
            isGenerate
            onClick={() => {
              if (userInfo?.lifeStatus === 0) {
                return message.error('您的账户已到期，请联系客服开通权限');
              }
              setGeneStatus((vals: any) => ({
                ...vals,
                loading: true,
                startTime: Date.now(),
              }));
              setCurrentResult(null);
              const params: any = {
                originImaginePrompt: geneParams.originImaginePrompt,
                colorCode: geneParams.colorCode,
                garryId: geneParams.garryId,
                labelType: LabelType.Origin,
                mdConfigId: userInfo.mdConfigId as any,
              };
              if (geneParams.url) {
                params.url = geneParams.url;
              }
              setGeneParams(params);
              startGenerate(params);
            }}
            disabled={!geneParams.originImaginePrompt}
            className={s.button}
            loading={geneStatus.loading}
          >
            生成设计图
          </Btn>
        }
      >
        <div className={s.content}>
          <div className={s.box}>
            <div className={s.title}>设计灵感图</div>
            <OptUpload
              showRecommandPrompt
              imageItem={generateImageItem}
              onChange={(imageItem) => {
                setGenerateImageItem(imageItem);
                setGeneParams((prev: any) => ({
                  ...prev,
                  url: imageItem?.url,
                  recommandPrompt: imageItem?.keyword,
                  garryId: imageItem?.garryId,
                }));
              }}
              onSelectWord={(promptWord) => {
                setGeneParams((vals: any) => ({
                  ...vals,
                  originImaginePrompt: promptWord,
                }));
              }}
            />
          </div>
          <div className={s.box}>
            <div className={s.title}>设计描述</div>
            <div className={s['fix-prompt-entry']}>
              <ColorSelect
                code={geneParams.colorCode}
                onChange={(colorCode: string) =>
                  setGeneParams((vals: any) => ({
                    ...vals,
                    colorCode,
                  }))
                }
              />
              <FixPrompt
                originPrompt={geneParams.originImaginePrompt as string}
                onChangePrompt={(str: string) => {
                  setGeneParams((vals: any) => ({
                    ...vals,
                    originImaginePrompt: str,
                  }));
                }}
              />
            </div>
            <div className={s['mt-3']}>
              <TextArea
                rows={4}
                value={geneParams.originImaginePrompt}
                onChange={(str: string) => {
                  setGeneParams((vals: any) => ({
                    ...vals,
                    originImaginePrompt: str,
                  }));
                }}
                changeColor={(colorCode: string) =>
                  setGeneParams((vals: any) => ({
                    ...vals,
                    colorCode,
                  }))
                }
                maxLength={500}
                userInfo={userInfo}
                placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
              />
            </div>
          </div>
        </div>
      </OperateLayout>
    </PageLayout>
  );
};

export default Generate;
