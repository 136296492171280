interface IPureObject {
  [key: string]: any;
}

/**
 * @memberof module:url
 * @name args
 * @desc
 * ### 对URL参数的操作
 * 1. **args.get**: 获取url中指定的键值, [url]可选参数
 *
 *  - 参数1：**name(string)**，需要获取的参数名
 *  - 参数2：**url(string)**，指定的URL
 *  - 参数3：**decode(boolean)**，是否解码参数，默认为true
 *  - 返回值：参数名的值
 *
 *  ```js
 *  args.get('b', 'http://www.youzan.com?a=2&b=3'); // '3'
 *  args.get('b', 'http://www.youzan.com?a=2&b=a%20b%20c', true); // 'a b c'
 *  args.get('b', 'http://www.youzan.com?a=2&b=a%20b%20c', false); // 'a%20b%20c'
 *  ```
 * 2. **args.remove**: 移除指定url中的键值对
 *
 *  - 参数1：**url(string)** 指定的URL
 *  - 参数2：**parameter(string)** 需要移除的参数名
 *  - 返回值：移除后的URL
 *
 *  ```js
 *  args.remove('http://www.youzan.com?a=2&b=3', 'b'); //'http://www.youzan.com?a=2'
 *  args.remove('http://www.youzan.com?b=3', 'b'); //'http://www.youzan.com?'
 *  ```
 * 3. **args.add**: 给指定url添加指定的键值对
 *
 *  - 参数1：**url(string)** 指定的URL
 *  - 参数2：**obj(object)** 指定的键值对
 *  - 返回值： 添加后的URL
 *
 *  ```js
 *  args.add('http://www.youzan.com', { a: 2 }); //'http://www.youzan.com?a=2'
 *  args.add('http://www.youzan.com?a=2', { b: 3 }); //'http://www.youzan.com?a=2&b=3'
 *  args.add('http://www.youzan.com?a=', { b: 3 }); //'http://www.youzan.com?b=3'
 *  ```
 */

const string2UrlObj = (str: string, decode = false) => {
  const result: IPureObject = {};
  const strSlice = str.split('&');
  strSlice.forEach((item) => {
    const eqPos = item.indexOf('=');
    if (eqPos <= 0) {
      return;
    }
    let key = item.substr(0, eqPos).trim();
    let value = item.substr(eqPos + 1).trim();
    value = decode ? decodeURIComponent(value) : value;
    if (key[eqPos - 2] === '[' && key[eqPos - 1] === ']') {
      key = key.substr(0, eqPos - 2).trim();
      if (!key) {
        return;
      }
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
    } else {
      result[key] = value;
    }
  });
  return result;
};

const obj2UrlString = (obj: IPureObject, encode = false) => {
  /* istanbul ignore next */
  if (obj === null || typeof obj !== 'object') {
    return '';
  }
  const strArr: string[] = [];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    const param = key.trim();
    if (value === undefined) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((valItem) => {
        valItem = encode ? encodeURIComponent(valItem) : valItem;
        strArr.push(`${param}[]=${valItem}`);
      });
    } else {
      value = encode ? encodeURIComponent(value) : value;
      strArr.push(`${param}=${value}`);
    }
  });
  return strArr.join('&');
};

const getParamArray = (url: string) => {
  url = `${url}`.trim();
  if (url === '' || url.substr(0, 10) === 'javascript') {
    return { hashArray: [] };
  }
  const hashArray = url.split('#');
  let paraArray = hashArray[0].split('?');
  paraArray = paraArray[1] === '' ? [paraArray[0]] : paraArray;
  return { hashArray, paraArray };
};

function getAllParameter(url = '', decode = false) {
  const { paraArray } = getParamArray(url);
  return (
    (paraArray && string2UrlObj(paraArray?.slice(1).join('?') || '', decode)) ||
    {}
  );
}

function getParameterByName(name: string, url?: string, decode = true) {
  /* istanbul ignore next */
  if (!url) {
    url = window.location.href || '';
  }
  const params = getAllParameter(url, decode);
  return params[name];
}

const removeParamKey = (queryStr: string, paramKey: string) => {
  let index = queryStr.indexOf(paramKey);
  let result = '';
  let lastIndex = 0;
  while (index >= 0) {
    result += queryStr.substring(lastIndex, index);
    lastIndex = queryStr.indexOf('&', index) + 1;
    // 最后一位字符
    if (lastIndex === 0) {
      return result.substr(0, Math.max(result.length - 1, 0));
    }
    index = queryStr.indexOf(paramKey, lastIndex);
  }
  result += queryStr.substr(lastIndex);
  return result;
};

function removeParameter(url: string, param: string) {
  const { hashArray, paraArray } = getParamArray(url);
  if (!paraArray) {
    return url;
  }
  if (paraArray[1]) {
    paraArray[1] = removeParamKey(paraArray[1], `${param}=`);
  }
  let result = paraArray.join('?');
  if (hashArray[1]) {
    result += `#${hashArray[1]}`;
  }
  return result;
}

function addParameter(url: string, params: IPureObject, encode = true) {
  const { hashArray, paraArray } = getParamArray(url);
  if (!paraArray) {
    return url;
  }
  if (paraArray[1]) {
    const paramMerged = {
      ...string2UrlObj(paraArray[1], true),
      ...params,
    };
    paraArray[1] = obj2UrlString(paramMerged, encode);
  } else {
    paraArray.push(obj2UrlString(params, encode));
  }
  let result = paraArray.join('?');
  if (hashArray[1]) {
    result += `#${hashArray[1]}`;
  }
  return result;
}

const args = {
  add: addParameter,
  remove: removeParameter,
  get: getParameterByName,
  getAll: getAllParameter,
};

export default args;
