import { message } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { transImage } from '../../utils';
import { saveEditImage } from './api';
import Tools from './components/tools';
import useBgLayer from './hooks/useBgLayer';
import useBrushLayer from './hooks/useBrushLayer';
import useStage from './hooks/useStage';
import useUtilLayer from './hooks/useUtilLayer';

import s from './style.module.scss';

type Props = {
  detail?: any;
  bgUrl?: string | null;
  setCustomImage?: (params: any) => void;
};

const EditMode = ({ bgUrl, detail, setCustomImage }: Props, ref: any) => {
  const [mode, setMode] = useState<'brush' | 'eraser'>('brush'); // 画笔或者橡皮擦
  const [strokeWidth, setStrokeWidth] = useState(1); // 画笔宽度
  const [color, setColor] = useState('#fff'); // 画笔颜色
  const [editable] = useState<boolean>(true);

  const { stage, eventRecord } = useStage({
    bgUrl,
    container: 'canvas',
    // width: 512,
    // height: 512,
  });
  useBgLayer(transImage(bgUrl as string, 'jpg', '1024x'), stage);
  const { prev, next, reset, getHis } = useBrushLayer({
    bgUrl,
    stage,
    editable,
    stroke: strokeWidth,
    mode,
    color,
    eventRecord,
    daub: false,
  });
  const { changeStroke, toogleUtil } = useUtilLayer({
    stage,
    color,
    mode,
    eventRecord,
    daub: false,
  });

  useEffect(() => {
    changeStroke(strokeWidth);
  }, [strokeWidth]);
  useEffect(() => {
    toogleUtil(editable);
  }, [editable]);

  useImperativeHandle(ref, () => ({
    hasOperation: () => !!getHis(),
    toEditUrl: async () => {
      const his = getHis();
      if (!his) {
        return message.warning('请先进行创作哦');
      }
      const base64 = stage.toDataURL({ mimeType: 'image/jpeg', pixelRatio: 2 });
      const result = await saveEditImage({
        imgBase64: base64,
        // promptIdentity: detail.promptIdentity,
        // promptId: detail.promptId,
        labelType: '12',
        // preUrl: bgUrl as string,
        id: detail.id,
        imgUrl: bgUrl as string,
      })
        .then((res) => {
          if (res) {
            message.success('修改成功');
            setCustomImage?.(res);
          }
          return base64;
        })
        .catch((err) => {
          console.log('err', err);
        });
      return result;
    },
  }));

  return (
    <>
      <Tools
        prev={prev}
        next={next}
        reset={reset}
        mode={mode}
        setMode={setMode}
        color={color}
        setColor={setColor}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
      />
      <div id="canvas" className={s.canvas}></div>
    </>
  );
};

export default forwardRef(EditMode);
