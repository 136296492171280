import {
  Col,
  ConfigProvider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
} from 'antd';
import cs from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { throttle } from '../../utils';
import Btn, { Return } from '../btn';
import PageLayout, { OperateLayout } from '../layout/page-layout';
import Textarea from '../textarea';
import { ensureOrder, getDemandDetail, getGoods } from './api';
import CheckBoxInput from './components/checkbox-input';
import RadioInput from './components/radio-input';
import Select from './components/select';
import {
  DataType,
  DataTypeMap,
  DataTypeRegMap,
  GoodCategory,
  QuestionType,
} from './constants';
import type { IDemandInfo, IGood, IOrderSubmit } from './type';

import s from './style.module.scss';

interface IProps {
  steps?: React.ReactNode;
  inputParams?: any;
  clickToPrevProcessNode?: () => void;
}

const transformFormValues = (data: any, values: any) => {
  const { questions } = data;
  const keys = Object.keys(values);
  const superQuestions = questions.map((question: any) => {
    const { id, type, keyword, options, title, ...rest } = question;
    if (type === QuestionType.OTHER) {
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id],
      };
    }
    if (type === QuestionType.RADIO) {
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id]?.startsWith('else-') ? 'else' : values[id],
        options: options.map((option: any) => ({
          ...option,
          value:
            option.keyword === 'else'
              ? values[id]?.split('else-')?.[1]
              : option.value,
        })),
      };
    }
    if (type === QuestionType.CHECKBOX) {
      const elseValue = values[id]?.find((cell: string) =>
        cell.startsWith('else-')
      );
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        answer: values[id]
          ?.map((cell: string) => (cell.startsWith('else-') ? 'else' : cell))
          ?.filter(Boolean),
        options: options.map((option: any) => ({
          ...option,
          value:
            option.keyword === 'else' && elseValue
              ? elseValue.split('else-')?.[1]
              : option.value,
        })),
      };
    }
    if (type === QuestionType.TEXT) {
      const usefulKeys = keys.filter((key) => key.startsWith(id));
      const arrValues = usefulKeys.map((key) => values[key]);
      return {
        ...rest,
        id,
        title,
        type,
        keyword,
        options: options.map((option: any, index: number) => ({
          ...option,
          value: arrValues[index],
        })),
      };
    }
    return question;
  });
  return {
    ...data,
    questions: superQuestions,
  };
};

const WorkflowBan: FC<IProps> = ({
  steps,
  inputParams,
  clickToPrevProcessNode,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [goods, setGoods] = useState<IGood[]>();
  const [good, setGood] = useState<IGood>();
  const [demand, setDemand] = useState<IDemandInfo>();
  const [remark, setRemark] = useState('');

  useEffect(() => {
    getGoods({ categoryType: GoodCategory.AI }).then((res: any) => {
      setGoods(res.rows);
    });
  }, []);

  const goodOptions = useMemo(() => {
    return goods?.map(({ id, name }) => ({ label: name, value: id }));
  }, [goods]);

  useEffect(() => {
    if (!good) return;
    getDemandDetail((good as IGood).requirementListId).then(setDemand);
  }, [good]);

  useEffect(() => {
    if (!demand?.questions) return;
    const values = demand?.questions
      .filter((question) => question?.required)
      .reduce((acc: any, cur: any) => {
        acc[cur.id] = cur?.options?.[0]?.keyword;
        return acc;
      }, {});
    form.setFieldsValue(values);
  }, [demand, form, good]);

  const submit = useCallback(
    async (values: IOrderSubmit) => {
      const questionAnswer = demand ? transformFormValues(demand, values) : {};
      const finalParams = {
        remark,
        source: 1, // 默认deja
        tradeOrderDetailDTOList: [
          {
            placeCount: 1,
            skuId: good?.id,
            orderDetailPicUrl: inputParams.url,
            listInfo: demand
              ? [
                  {
                    ...questionAnswer,
                  },
                ]
              : [],
          },
        ],
      };
      try {
        const result = await ensureOrder(finalParams as IOrderSubmit);
        navigate(
          `/waitPay?serviceType=1&id=${result.id}&payOrderNo=${result.payOrderNo}`
        );
        return true;
      } catch (err) {
        console.log('err', err);
        return false;
      }
    },
    [demand, good, inputParams, remark]
  );

  const submit1 = throttle(submit, 1000);

  return (
    <PageLayout
      steps={steps}
      left={
        <div className={s.container}>
          <div className={s['img-wrap']}>
            <img src={inputParams.url} />
          </div>
          <Return className={s.return} onClick={clickToPrevProcessNode} />
        </div>
      }
    >
      <OperateLayout
        footer={
          <div className={s.footer}>
            <div className={s.price}>
              {good ? (
                <>
                  <span className={s.prefix}>￥</span>
                  {good?.unitPrice}
                </>
              ) : null}
            </div>
            <div className={s.buttons}>
              <Btn buttonType="goast" onClick={() => navigate('/service')}>
                联系客服
              </Btn>
              <Btn
                onClick={() => {
                  if (!demand)
                    return message.warning('请先选择要打版的衣服类型');
                  console.log(
                    '发送的请求',
                    transformFormValues(demand, form.getFieldsValue())
                  );
                  form.submit();
                }}
              >
                确认下单
              </Btn>
            </div>
          </div>
        }
      >
        <div className={s.content}>
          <div className={s.title}>请选择您要打板的衣服类型</div>
          <div className={s['sub-title']}>如需定制其他款式，请联系在线客服</div>
          <div className={s['select-wrap']}>
            <Select
              options={goodOptions}
              className={s.select}
              value={good?.id}
              onChange={(val) => {
                setGood(goods?.find(({ id }) => id === val));
              }}
              placeholder="请选择"
            />
          </div>
          <div className={s.title}>订单备注</div>
          <div className={s['select-wrap']}>
            <Textarea
              value={remark}
              onChange={setRemark}
              pureText
              placeholder="此处非必填，请输入您的其他要求"
              maxLength={100}
            />
          </div>
          <ConfigProvider theme={{ token: { controlHeight: 40 } }}>
            <Form
              form={form}
              onFinish={async (values) => {
                await submit1(values);
              }}
            >
              {demand?.questions?.map((item: any) => (
                <div key={item.id}>
                  <div className={cs(s.title, s['mb-16'])}>{item.title}</div>
                  <Row gutter={[16, 0]}>
                    {item.type === QuestionType.TEXT &&
                      item.options?.map((option: any, index: number) => (
                        <Col key={index} span={12}>
                          <Form.Item
                            label={option.keyword}
                            name={`${item.id}_${index}`}
                            rules={[
                              {
                                pattern: option.dataType
                                  ? (DataTypeRegMap as any)?.[option.dataType]
                                  : '',
                                message: option.dataType
                                  ? (DataTypeMap as any)?.[option.dataType]
                                  : '',
                              },
                            ]}
                          >
                            {[
                              DataType.NUMBER_FIXED_1,
                              DataType.NUMBER_FIXED_2,
                              DataType.NUMBER_LESS_9,
                            ].includes(option.dataType) ? (
                              <InputNumber
                                min={option.min}
                                max={option.max}
                                precision={
                                  // eslint-disable-next-line
                                  DataType.NUMBER_FIXED_1 === option.dataType
                                    ? 1
                                    : DataType.NUMBER_FIXED_2 ===
                                      option.dataType
                                    ? 2
                                    : option.precision
                                }
                                step={option.step}
                                suffix={
                                  <div className={s.suffix}>
                                    {option.unitStr}
                                  </div>
                                }
                                style={{
                                  width: '100%',
                                }}
                                controls={false}
                              />
                            ) : (
                              <Input
                                suffix={
                                  <div className={s.suffix}>
                                    {option.unitStr}
                                  </div>
                                }
                                style={{
                                  width: '100%',
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      ))}
                  </Row>
                  {QuestionType.RADIO === item.type && (
                    <Form.Item
                      name={item.id}
                      rules={[
                        {
                          validator: (_, val) => {
                            return val === 'else-'
                              ? Promise.reject('请输入')
                              : Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <RadioInput options={item.options} />
                    </Form.Item>
                  )}
                  {QuestionType.CHECKBOX === item.type && (
                    <Form.Item
                      name={item.id}
                      rules={[
                        {
                          validator: (_, val) => {
                            return val?.includes('else-')
                              ? Promise.reject('请输入')
                              : Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <CheckBoxInput options={item.options} />
                    </Form.Item>
                  )}
                  {item.type === QuestionType.OTHER && (
                    <Form.Item name={item.id}>
                      <Textarea
                        pureText
                        maxLength={100}
                        placeholder="请输入您的其他要求"
                      />
                    </Form.Item>
                  )}
                </div>
              ))}
            </Form>
          </ConfigProvider>
        </div>
      </OperateLayout>
    </PageLayout>
  );
};

export default WorkflowBan;
