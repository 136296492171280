import Konva from 'konva';
import { useEffect, useState } from 'react';

export default function useBrushLayer({
  stage,
  eventRecord,
  mode,
  color,
  stroke,
  daub,
  bgUrl,
  onMourseUp = () => null,
}: // editable,
{
  stage: Konva.Stage;
  editable: boolean;
  bindEvent?: any;
  stroke: number;
  mode: 'brush' | 'eraser';
  color: string;
  eventRecord: any;
  daub?: boolean;
  bgUrl?: any;
  onMourseUp?: (params: any) => void;
}) {
  const [layer, setLayer] = useState<any>();
  const [lines, setLines] = useState<any>([]);
  const [currentLine, setCurrentLine] = useState<any>();
  const [his, setHis] = useState(0);
  const [currentStep, setCurrentStep] = useState<{
    value: number;
    trigger: 'auto' | 'manual';
  }>({
    trigger: 'auto',
    value: 0,
  });

  useEffect(() => {
    if (!stage) return;
    const layer = new Konva.Layer({
      name: 'brushLayer',
    });
    stage?.add(layer);
    setLayer(layer);
  }, [stage]);

  useEffect(() => {
    if (!eventRecord || !layer) return;
    if (!stage.children?.find((ly: any) => ly.attrs.name === 'brushLayer'))
      return;
    const { eventName, pos } = eventRecord;
    if (['mousedown', 'touchstart'].includes(eventName)) {
      const line = new Konva.Line({
        stroke: daub ? '#6451e7' : color,
        strokeWidth: stroke,
        globalCompositeOperation:
          mode === 'brush' ? 'source-over' : 'destination-out',
        points: [pos?.x ?? 0, pos?.y ?? 0, pos?.x ?? 0, pos?.y ?? 0],
        lineCap: 'round',
        lineJoin: 'round',
      });
      layer.add(line);
      setCurrentLine(line);
    }
  }, [eventRecord, layer, daub]);

  useEffect(() => {
    if (!eventRecord || !layer || !currentLine) return;
    const { eventName, pos } = eventRecord;
    if (['mousemove', 'touchmove'].includes(eventName)) {
      currentLine.points([...(currentLine?.points() ?? []), pos?.x, pos?.y]);
      layer.batchDraw();
    }
  }, [currentLine, eventRecord, layer]);

  useEffect(() => {
    if (!eventRecord || !currentLine) return;
    const { eventName } = eventRecord;
    if (['mouseup', 'touchend', 'mouseout'].includes(eventName)) {
      setCurrentLine(null);
      setLines((lines: any) => [
        ...lines.slice(0, currentStep.value),
        currentLine,
      ]);
      setCurrentStep((val) => ({
        trigger: 'auto',
        value: val.value + 1,
      }));
      onMourseUp?.({
        line: currentLine,
      });
    }
  }, [eventRecord, currentStep, currentLine]);

  useEffect(() => {
    setHis(lines.length);
  }, [lines]);

  useEffect(() => {
    if (currentStep.trigger === 'auto') return;
    const temArr = lines.slice(0, currentStep.value);
    layer?.removeChildren();
    temArr.forEach((line: any) => {
      layer.add(line);
    });
  }, [currentStep, lines, layer]);

  useEffect(() => {
    setLines([]);
    setCurrentStep({
      trigger: 'auto',
      value: 0,
    });
  }, [bgUrl]);

  useEffect(() => {
    if (his > 0) {
      localStorage.setItem('hasOperation', 'Y');
    } else {
      localStorage.setItem('hasOperation', '');
    }
  }, [his]);

  return {
    prev: () =>
      currentStep.value > 0 &&
      setCurrentStep((val) => ({
        trigger: 'manual',
        value: val.value - 1,
      })),
    next: () =>
      currentStep.value < his &&
      setCurrentStep((val) => ({
        trigger: 'manual',
        value: val.value + 1,
      })),
    reset: () => {
      setLines([]);
      setCurrentStep(() => ({
        trigger: 'manual',
        value: 0,
      }));
    },
    toUrl: () =>
      layer.toDataURL({
        pixelRatio: 1,
      }),
    getHis: () => his,
    layer,
  };
}
