import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { transImage } from '../../utils';
import Btn from '../btn';
import ServiceEntrance from '../service-entrance';
// import { matchFabric } from './api';
import ChoseHistory from './components/chose-history';
// import Custom from './components/custom';
import DaubMode from './daubMode';
import EditMode from './editMode';
import ExpandMode from './expandMode';
import ReplaceMode from './replaceMode';

import s from './style.module.scss';

type Props = {
  bgUrl?: string | null;
  choseImage?: (params: any) => void;
  [key: string]: any;
  mode?: string[];
  children?: React.ReactNode;
  // 这个属性可以拿掉了
  model?: string;
};

const EditSpace = ({ bgUrl, choseImage, detail }: Props, ref: any): any => {
  const editRef = useRef<any>();
  const daubRef = useRef<any>();
  const expandRef = useRef<any>();
  const replaceRef = useRef<any>();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [, setCustomImage] = useState<string>('');
  const [tempBg, setTempBg] = useState<any>('');

  useEffect(() => {
    if (bgUrl) {
      setCustomImage(bgUrl);
    }
    setTempBg(bgUrl);
  }, [bgUrl]);

  useImperativeHandle(ref, () => ({
    hasOperation: () => {
      switch (tab) {
        case '2':
          return daubRef.current?.hasOperation();
        default:
          return editRef.current?.hasOperation();
      }
    },
    saveEditImage: async (extraParams: any) => {
      let result: any;
      switch (tab) {
        case '2':
          // 消除工具
          result = await daubRef.current?.saveDaub();
          break;
        case '3':
          // 辅料上身
          result = await expandRef.current?.saveImage();
          break;
        case '4':
          // 面料匹配
          // result = await expandRef.current?.saveImage();
          // return matchFabric({
          //   colorCode: detail?.colorCode,
          //   promptId: detail?.transferPrompt,
          //   ...props,
          // });
          break;
        case '5':
          result = await replaceRef.current?.saveImage(extraParams);
          break;
        default:
          // 画笔
          result = await editRef.current?.toEditUrl();
      }
      setTempBg(result);
    },
  }));

  // console.log('tempBg', tempBg);
  // console.log('bgUrl', bgUrl);

  return (
    <>
      {!bgUrl ? (
        <ChoseHistory choseImage={choseImage}>
          <div className={s.placeholder}>
            <img
              src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230717/69c7d3f15b404fc7bb9d03ba5bf7762b.png"
              className={s.image}
            />
            <span>
              请<a className={s.click}>点击选择</a>你要编辑的图片
            </span>
          </div>
        </ChoseHistory>
      ) : (
        <div className={s.container}>
          <div className={s['tem-radius']}>
            <img
              // key={tempBg ?? bgUrl}
              src={tempBg || transImage(bgUrl, 'webp')}
              className={s['placeholder-img']}
            />
          </div>
          <div className={s.radius}>
            {tab === '1' || !tab ? (
              <EditMode
                ref={editRef}
                bgUrl={bgUrl}
                detail={detail}
                setCustomImage={choseImage}
              />
            ) : null}
            {tab === '2' ? (
              <DaubMode
                ref={daubRef}
                bgUrl={bgUrl}
                detail={detail}
                setCustomImage={choseImage}
              />
            ) : null}
            {tab === '3' ? (
              <ExpandMode
                ref={expandRef}
                bgUrl={bgUrl}
                detail={detail}
                setCustomImage={choseImage}
              />
            ) : null}
            {tab === '4' ? (
              <div style={{ width: '514px', height: '514px' }}></div>
            ) : null}
            {tab === '5' ? (
              <ReplaceMode
                ref={replaceRef}
                bgUrl={bgUrl}
                detail={detail}
                setCustomImage={choseImage}
              />
            ) : null}
          </div>

          <div className={s['bottom-operation']}>
            <ChoseHistory choseImage={choseImage}>
              <Btn style={{ marginRight: '12px' }} className={s['other-btn']}>
                替换图片
              </Btn>
            </ChoseHistory>
          </div>
          <ServiceEntrance url={bgUrl} top={-2} />
        </div>
      )}
    </>
  );
};

export default forwardRef(EditSpace);
