import cs from 'classnames';
import React, { FC } from 'react';

import s from './style.module.scss';

interface IProps {
  iconName: string;
  isActive?: boolean;
  isTransparent?: boolean;
  direction?: 'left' | 'right';
  borderRadius?: string;
  defaultBg?: string;
  disabled?: boolean;
  onClick?: () => void; // 点击事件
  fontSize?: number; // 字体大小
  className?: string;
}

const Icon: FC<IProps> = ({
  iconName,
  isActive,
  disabled,
  direction,
  isTransparent,
  onClick,
  fontSize = 24,
  borderRadius = '50%',
  defaultBg,
  className = '',
}) => {
  return (
    <div
      style={{
        borderRadius,
        backgroundColor: defaultBg,
      }}
      className={cs(s.container, {
        [s.transparent]: isTransparent,
        [s.disabled]: disabled,
        [s.active]: isActive,
        [className]: !!className,
      })}
      onClick={() => {
        if (disabled) return;
        onClick?.();
      }}
    >
      <span
        className={cs('font_family', iconName, {
          [s.right]: direction === 'right',
        })}
        style={{ fontSize: `${fontSize}px` }}
      ></span>
    </div>
  );
};

export default Icon;
