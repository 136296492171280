import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Return } from '../../components/btn';

import s from './style.module.scss';

const ServiceOnline: FC = () => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <iframe
        style={{
          height: 'calc(100vh - 200px)',
        }}
        // src='https://ykf-webchat.7moor.com/wapchat.html?accessId=362add30-52ae-11ee-baae-374443b4b312&fromUrl=https://dejaai.com&urlTitle=Deja&language=ZHCN&otherParams={"peerId":"10061303"}'
        src="https://ykf-weixin01.7moor.com/wapchat.html?accessId=362add30-52ae-11ee-baae-374443b4b312&fromUrl=https://dejaai.com&urlTitle=Deja&language=ZHCN"
      ></iframe>
      <Return onClick={() => navigate(-1)} />
    </div>
  );
};

export default ServiceOnline;
