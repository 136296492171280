import request from './request';
import {
  IAreaReplaceParams,
  IBlendParams,
  IBlendResult,
  IEditSaveImageParams,
  IFabricGenerateParams,
  IFabricPullResultParams,
  IGeneModelCloth,
  IHistoryRecord,
  IImageToDraftParams,
  IImageToDraftResultParams,
} from './types';

// 图片上传
export const uploadPicture = (data: FormData) => {
  return request.post('/core/api/design/uploadImage', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// 图生图和图片融合和线稿生图
export const blendGenerate = (data: IBlendParams) => {
  return request.post('/customer/api/design/generate', data);
};
// 图生图和图片融合和线稿生图请求结果
export const blendResult = (data: IBlendResult) => {
  return request.post('/customer/api/design/generateData', data);
};

// 查询图片上传历史记录
export const listHistory = (params: {
  page?: number;
  size?: number;
  isEdit?: number; // 1是，0否
  collectStatus?: number;
  imageSourceType?: number | null;
}): Promise<{
  page: number;
  total: number;
  rows: IHistoryRecord[];
  size: number;
  records: any;
}> => request.get('/customer/api/design/gallery', params);

// 收藏作品 V1 -> V2
// export const collect = (data: {
//   materialHistoryIds: number[];
//   collectStatus: number;
// }) => {
//   return request.post(
//     '/customer/api/design/update/materialHistory/collectStatus',
//     data
//   );
// };

export const collect = (data: {
  customerCollects: Array<{
    materialHistoryId: number;
    remarks?: string;
  }>;
  status: number;
}) => {
  return request.post(
    '/customer/api/design/update/materialHistory/collectStatus/v2',
    data
  );
};

// 图片转线稿
export const imageToDraft = (data: IImageToDraftParams) => {
  return request.post('/customer/api/design/canny', data);
};

// 图片转线稿查询结果
export const pullImageToDraftResult = (data: IImageToDraftResultParams) => {
  return request.post('/customer/api/design/cannyData', data);
};

// 模特试衣生图
export const geneModelCloth = (data: IGeneModelCloth) => {
  return request.post('/customer/api/design/modelChangeClothes', data);
};

// 模特试衣轮询结果
export const pullModelClothResult = (data: IImageToDraftResultParams) => {
  return request.post('/customer/api/design/modelChangeClothesData', data);
};

// 面料上身生成
export const fabricGenerate = (data: IFabricGenerateParams) => {
  return request.post('/customer/api/design/fabricGenerate', data);
};

// 轮询面料上身结果
export const fabricPollingResult = (data: IFabricPullResultParams) => {
  return request.post('/customer/api/design/fabricGenerateData', data);
};

// 局部消除
export const areaDelete = (data: FormData) => {
  return request.stream('/core/api/design/sota/inpaint', data);
};

// 局部替换
export const areaReplace = (data: IAreaReplaceParams) => {
  return request.post('/customer/api/design/imgPartReplace', data);
};

// 消除的图片保存
export const saveEditImage = (data: IEditSaveImageParams) => {
  return request.post('/customer/api/design/imgEditSave', data);
};

// 辅料列表，全部，1紧扣，3线带，4其他
export const getExpandList = (page: number, type: string | number, size = 9) =>
  request.post('/customer/api/accessoires/pageList', {
    page,
    size,
    type,
  });

// 获取辅料详情
export const getExpandDetail = (id: number) => {
  return request.post('/customer/api/accessories/getInfo', {
    id,
  });
};

// 获取支付的二维码
export const getPayQrCode = (outTradeNo: string) =>
  request.post(`/pay/api/payment/create`, {
    outTradeNo,
    channel: 'wx',
    paymentWay: 'native',
  });
