export enum UserLevel {
  Ultimate = 1,
  Professional = 2,
}

export const menus = [
  {
    title: '款式创新',
    path: '/generate/fromPic',
    iconIndex: 1,
    access: 0,
    redirectTitle: '图文创款',
    children: [
      {
        title: '图文创款',
        path: '/generate/fromPic',
        access: 0,
      },
      {
        title: '绘画创款',
        path: '/generate/realtimeDrawing',
        access: 1,
      },
      {
        title: '款式融合',
        path: '/generate/blendPic',
        access: 1,
      },
      {
        title: '单品延伸',
        path: '/generate/geneFromPic',
        access: 1,
      },
    ],
  },
  {
    title: '线稿系列',
    path: '/generate/fromDraft',
    iconIndex: 2,
    access: 1,
    children: [
      {
        title: '线稿创款',
        path: '/generate/fromDraft',
        access: 1,
      },
      {
        title: '图转线稿',
        path: '/generate/imageToDraft',
        access: 1,
      },
    ],
  },
  {
    title: '面料替换',
    path: '/generate/fabricOnDesign',
    iconIndex: 3,
    access: 1,
  },
  {
    title: '局部改款',
    path: '/generate/areaModify?tab=1',
    iconIndex: 4,
    access: 1,
  },
  {
    title: '智能试衣',
    path: '/generate/aiModel',
    iconIndex: 5,
    access: 1,
    isNew: true,
  },
  {
    title: '我的作品集',
    path: '/mine/history',
    iconIndex: 6,
    children: [
      {
        title: '我的作品',
        path: '/mine/history',
      },
      {
        title: '我的面料',
        path: '/mine/fabric',
        access: 1,
      },
      {
        title: '我的辅料',
        path: '/mine/expand',
        access: 1,
      },
      {
        title: '我的收藏',
        path: '/mine/collection',
      },
    ],
  },
];

export enum GenerateType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}
