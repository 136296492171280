import request from '../../utils/request';
import { IOrderProps, ISubmitData } from './types';

export const getSeriveList = () =>
  request.get('/commercializeProduct/api/availableCombo');

export const submitService = (data: ISubmitData) =>
  request.post('/commercializeOrder/api/front/order/submit', data);

export const cancelOrder = (data: IOrderProps) =>
  request.post('/commercializeOrder/api/front/order/cancel', data);

export const getOrderStatus = (params: IOrderProps) =>
  request.get(
    `/commercializeOrder/api/front/order/getOrderPayStatus?orderId=${params.orderId}`
  );
