import { message } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { transImage } from '../../utils';
import { saveEditImage } from './api';
import Tools from './components/tools';
import useDaubLayer from './hooks/useDaubLayer';
import useStage from './hooks/useStage';
import useUtilLayer from './hooks/useUtilLayer';

import s from './style.module.scss';

type Props = {
  detail?: any;
  bgUrl?: string | null;
  setCustomImage?: (params: any) => void;
};

const DaubMode = ({ bgUrl, detail, setCustomImage }: Props, ref: any) => {
  const [mode, setMode] = useState<'brush' | 'eraser'>('brush'); // 画笔或者橡皮擦
  const [strokeWidth, setStrokeWidth] = useState(30); // 画笔宽度
  const [color, setColor] = useState('#fff'); // 画笔颜色
  const [editable, setEditable] = useState<boolean>(true);

  const { stage, eventRecord } = useStage({
    bgUrl,
    container: 'canvas',
    // width: 512,
    // height: 512,
  });
  const {
    prev,
    next,
    reset,
    bgUrl: toBgUrl,
    getBg,
    setCurrentBg,
  } = useDaubLayer({
    stage,
    eventRecord,
    bgUrl: transImage(bgUrl as string, 'jpg', '1024x'),
    stroke: strokeWidth,
    editable,
    setEditable,
  });
  const { changeStroke, toogleUtil } = useUtilLayer({
    stage,
    color,
    mode,
    eventRecord,
    daub: true,
  });

  useEffect(() => {
    changeStroke(strokeWidth);
  }, [strokeWidth]);
  useEffect(() => {
    toogleUtil(editable);
  }, [editable]);

  useImperativeHandle(ref, () => ({
    hasOperation: () => getBg().includes('blob:'),
    saveDaub: async () => {
      const bg = getBg();
      if (!bg.includes('blob:')) {
        return message.warning('请先局部消除哦');
      }
      const base64 = toBgUrl();
      const result = await saveEditImage({
        imgBase64: base64,
        // promptIdentity: detail.promptIdentity,
        // promptId: detail.promptId,
        labelType: '12',
        // preUrl: bgUrl as string,
        id: detail.id,
        imgUrl: bgUrl as string,
      })
        .then((res) => {
          if (res) {
            message.success('修改成功');
            setCustomImage?.(res);
            setCurrentBg();
          }
          return base64;
        })
        .catch((err) => {
          console.log('err', err);
        });
      return result;
    },
  }));

  return (
    <>
      <Tools
        tools={['brush']}
        prev={prev}
        next={next}
        reset={reset}
        noColorPicker={true}
        mode={mode}
        setMode={setMode}
        color={color}
        setColor={setColor}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
      />
      {!editable ? <div className={s['animate-mask']}></div> : null}
      <div id="canvas" className={s.canvas}></div>
    </>
  );
};

export default forwardRef(DaubMode);
