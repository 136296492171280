import { ConfigProvider, Modal } from 'antd';
import React, { FC } from 'react';

import Btn from '../btn';

import s from './style.module.scss';

type Props = {
  tipOpen: boolean;
  setTipOpen: (params: boolean) => void;
  onSuccess: () => void;
  textEnsure?: string;
};

const ModalTip: FC<Props> = ({
  tipOpen,
  setTipOpen,
  onSuccess,
  textEnsure,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: '#18181c',
            contentBg: '#18181c',
          },
        },
      }}
    >
      <Modal
        width={440}
        centered
        open={tipOpen}
        closeIcon={false}
        onCancel={() => setTipOpen(false)}
        title={
          <div className={s['tip-title']}>
            <span className="font_family icon-a-tishidanchuangtishi"></span>提示
          </div>
        }
        footer={
          <div className={s['tip-footer']}>
            <Btn
              buttonType="goast"
              className={s.button}
              onClick={() => setTipOpen(false)}
            >
              取消
            </Btn>
            <Btn
              className={s.button}
              onClick={() => {
                setTipOpen(false);
                onSuccess?.();
              }}
            >
              {textEnsure ?? '确认'}
            </Btn>
          </div>
        }
      >
        <div className={s['tip-content']}>
          未保存将会失去当前创作，是否确认离开当前编辑状态？
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default ModalTip;
