import { Image } from "antd";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import Logout from "../logout";

import liantonLogo from "../../assets/liantonLogo.png";

import s from "./style.module.scss";
import { isLianton } from "../../utils";

interface IProps {
  fromPic?: boolean;
  none?: boolean;
  noTabs?: boolean;
  userInfo?: any;
}

const Header: FC<IProps> = ({ userInfo }) => {
  const navigate = useNavigate();

  const showLianton = isLianton(userInfo);

  return (
    <div className={s.container}>
      <div className={s.logo}>
        <Image
          onClick={() => navigate("/")}
          width={104}
          height={21}
          preview={false}
          style={{
            cursor: "pointer",
          }}
          src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230717/af66ae5acd36496183943aff15ca3e77.png"
        />
        {showLianton && (
          <Image
            width={176}
            height={24}
            preview={false}
            style={{
              marginLeft: 22,
            }}
            src={liantonLogo}
          />
        )}
      </div>
      <div className={s.end}>
        <Logout userInfo={userInfo} />
      </div>
    </div>
  );
};

export default Header;
