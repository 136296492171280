import { LoadingOutlined } from '@ant-design/icons';
import { message, Upload as AntdUpload } from 'antd';
import React, { FC, useState } from 'react';

import { useUserInfo } from '../../contexts/user-info';
import { transImage } from '../../utils';
import { uploadPicture } from '../../utils/api';
import PrevoperateImage from '../prevoperate-image';

import s from './style.module.scss';

interface IProps {
  needMd?: boolean;
  activeItem?: any;
  onChange?: (item: any) => void;
}

const UploadLocal: FC<IProps> = ({ onChange, activeItem, needMd }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const { userInfo } = useUserInfo();

  const doUpload = (image: File) => {
    setUploading(true);
    const data = new FormData();
    data.append('file', image);
    data.append('mdConfigId', (userInfo as any).mdConfigId);
    data.append('model', 'sd');
    if (needMd) {
      data.append('type', '0');
    }

    return uploadPicture(data)
      .then(({ url, cosUrl }: any) => {
        onChange?.({ url, cosUrl });
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  return activeItem?.cosUrl ? (
    <div className={s['image-placeholder']}>
      <img
        src={transImage(activeItem.cosUrl || activeItem.url, 'webp')}
        className={s.img}
      />
      <div className={s.delete}>
        <span
          className="font_family icon-a-shangchuantupianshanchu"
          onClick={() => {
            onChange?.(null);
          }}
        ></span>
      </div>
      <PrevoperateImage
        key={activeItem?.cosUrl}
        url={activeItem?.cosUrl}
        onChange={(_, url) => {
          onChange?.({ url, cosUrl: url });
        }}
      >
        <div className={s['opt-con']}>
          <span className="font_family icon-a-tupianyuchulizhinengyouhua"></span>
          点击优化
        </div>
      </PrevoperateImage>
    </div>
  ) : (
    <AntdUpload
      accept=".png, .jpg, .jpeg"
      customRequest={({ file }) => doUpload(file as File)}
      maxCount={1}
      onRemove={() => onChange?.(null)}
      showUploadList={false}
      disabled={uploading}
      beforeUpload={(file) => {
        if (userInfo?.lifeStatus === 0) {
          message.error('您的账户已到期，请联系客服开通权限');
          return AntdUpload.LIST_IGNORE;
        }
        // 限制大小
        if (file.size > 20 * 1024 * 1024) {
          message.error('图片大小不能超过20M');
          return AntdUpload.LIST_IGNORE;
        }
        if (file.size > 10 * 1024 * 1024) {
          message.warning(
            '您上传的图片较大，可能会对生成结果的清晰度和细节产生影响~'
          );
        }
        return true;
      }}
    >
      <div className={s.uploader}>
        <div className={s.wrap}>
          {uploading ? (
            <div className={s.loading}>
              <LoadingOutlined
                style={{
                  fontSize: 32,
                  color: '#6451e7',
                  fontWeight: 'bold',
                }}
              />
              <div className={s.text}>上传中</div>
            </div>
          ) : (
            <>
              <img
                src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/upload-local.png"
                className={s.img}
              />
              <div className={s.default}>
                <div className={s.primary}>点击上传</div>
                <div className={s.bright}>本地图片</div>
              </div>
              <div className={s.default1}>支持JPG、PNG，20M以内</div>
            </>
          )}
        </div>
      </div>
    </AntdUpload>
  );
};

export default UploadLocal;
