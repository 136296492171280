import {
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  QRCode,
  Row,
} from 'antd';
import cs from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useBeforeUnload } from 'react-router-dom';

import Btn from '../../components/btn';
import ButtonParams from '../../components/button-params';
import Icon from '../../components/icon';
import { throttle } from '../../utils';
import { getPayQrCode } from '../../utils/api';
import { format } from '../../utils/price';
import { PayStatus } from '../../utils/types';
import {
  cancelOrder,
  getOrderStatus,
  getSeriveList,
  submitService,
} from './api';
import {
  COMPANY_FORM,
  HEADER_BUTTONS,
  PERSONAL_FORM,
  TICKET_BUTTONS,
  UNIT_TYPE_MAP,
} from './contents';
import { IForm, IServiceRecord } from './types';

import s from './style.module.scss';

const TIME_DIFF = 3000;

const ServiceShop: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const [buttonType, setButtonType] = useState(1);
  const [ticketType, setTicketType] = useState(1);
  const [ruleNum, setRuleNum] = useState(1);
  const [form] = Form.useForm();
  const [list, setList] = useState<IServiceRecord[]>([]);
  const [selectService, setSelectService] = useState<IServiceRecord | null>(
    null
  );
  const [payOrder, setPayOrder] = useState<any>();
  const [qrcodeDetail, setQrcodeDetail] = useState<any>();

  const animation = useRef<number>();
  const now = useRef<number>(Date.now());
  const pageRefeTime = useRef<number>(Date.now());

  const submitCallback = useCallback(
    (num: number, form?: IForm) => {
      return !num
        ? Promise.reject()
        : submitService({
            commercializeOrderDTO: {
              type: 1,
              source: 'DEJA',
              ...(form || {}),
            },
            commercializeOrderDetailList: [
              {
                commobId: (selectService as IServiceRecord).comboId,
                actualNumber: num,
              },
            ],
          })
            .then((res: any) => {
              setPayOrder(res);
            })
            .catch((err: any) => {
              console.log('err', err);
            });
    },
    [selectService]
  );
  const throttleSubmit = throttle(submitCallback, 1000);

  const getQrcodeCallback = useCallback(() => {
    return payOrder
      ? getPayQrCode(payOrder?.payOrderNo)
          .then((res: any) => {
            setQrcodeDetail(res);
            pageRefeTime.current = Date.now();
          })
          .catch(() => {
            animation.current && window.cancelAnimationFrame(animation.current);
          })
      : Promise.resolve();
  }, [payOrder?.payOrderNo]);
  const throttleGetQrcode = throttle(getQrcodeCallback, 1000);

  const cancelOrderCallback = useCallback((id: number) => {
    return cancelOrder({ orderId: id })
      .then(() => {
        setPayOrder('');
        setQrcodeDetail(undefined);
      })
      .catch((err: any) => {
        console.log('取消订单出错了', err);
      });
  }, []);

  useEffect(() => {
    getSeriveList()
      .then((res) => {
        setList(res?.comboInfos ?? []);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  // 重置为不开具发票
  useEffect(() => {
    setButtonType(1);
    setRuleNum(1); // 数量设置为1
    if (!drawerOpen) {
      setPayOrder(null);
    }
  }, [drawerOpen]);

  // 重置成个人
  useEffect(() => {
    setTicketType(1);
  }, [buttonType]);

  // 轮询支付状态
  const animationCb = () => {
    const timeDiff = Date.now() - now.current;
    if (timeDiff >= TIME_DIFF) {
      now.current = Date.now();
      getOrderStatus({ orderId: payOrder?.id }).then((res: number) => {
        if (res !== PayStatus.PAY_STATUS_UNPAID) {
          animation.current && window.cancelAnimationFrame(animation.current);
          unstable_batchedUpdates(() => {
            setPayOrder((detail: any) => ({
              ...detail,
              status: res,
            }));
            setQrcodeDetail(null);
            setSuccessOpen(true);
          });
        }
      });
    }
    animation.current = window.requestAnimationFrame(animationCb);
  };

  useEffect(() => {
    payOrder?.payOrderNo && animationCb();
    return () => {
      animation.current && window.cancelAnimationFrame(animation.current);
    };
  }, [payOrder?.payOrderNo]);

  useEffect(() => {
    if (payOrder?.payOrderNo) {
      throttleGetQrcode();
    }
  }, [payOrder?.payOrderNo]);

  // 路由跳转之前
  useBeforeUnload(() => {
    cancelOrderCallback(payOrder?.id);
  });

  return (
    <div className={s.container}>
      <div className={s.title}>优选服务套餐</div>
      <div
        className={cs(s.content, {
          [s.grid]: list.length >= 3,
          [s.flex]: list.length < 3,
        })}
      >
        {list.map((item) => (
          <div key={item.comboId} className={s.item}>
            <div className={s.wrap}>
              <div className={s.header}>
                <div className={s['header-title']}>{item.name}</div>
                <div className={s['header-desc']}>{item.desc}</div>
              </div>
              <div className={s['price-desc']}>
                <div className={s.prefix}>￥</div>
                <div className={s.price}>{format(item.price)}</div>
                <div className={s.suffix}>
                  /{item?.comboSaleStrategy?.strategyNum}
                  {
                    (UNIT_TYPE_MAP as any)[
                      item?.comboSaleStrategy?.strategyType
                    ]
                  }
                </div>
              </div>
              <div className={s.contain}>包含服务</div>
              <div className={s.cells}>
                {item?.relatedFeatures?.map((cell: any) => (
                  <div key={cell.featureId} className={s.cell}>
                    <Icon
                      iconName="icon-a-fuwugoumaigouhao"
                      isTransparent
                      fontSize={16}
                      className={s['icon-right']}
                    />
                    {cell.name}
                  </div>
                ))}
              </div>
            </div>
            <Btn
              className={s.btn}
              onClick={() => {
                setSelectService(item);
                setDrawerOpen(true);
              }}
            >
              去购买
            </Btn>
          </div>
        ))}
      </div>
      <ConfigProvider
        theme={{
          token: {
            lineWidth: 1,
            controlHeight: 40,
          },
          components: {
            Drawer: {
              footerPaddingBlock: 0,
              footerPaddingInline: 0,
            },
            Form: {
              itemMarginBottom: 16,
              labelColor: '#B7BAC7',
            },
          },
        }}
      >
        <Drawer
          destroyOnClose
          open={drawerOpen}
          title={<div className={s['drawer-content-title']}>套餐订单</div>}
          width={640}
          style={{
            background: '#100F12',
          }}
          closeIcon={null}
          onClose={() => setDrawerOpen(false)}
          footer={
            payOrder?.status === PayStatus.PAY_STATUS_PAID ? (
              <div className={s.footer}>
                <div></div>
                <div className={s['footer-button-group']}>
                  <Btn onClick={() => setDrawerOpen(false)}>确认</Btn>
                </div>
              </div>
            ) : (
              <div className={s.footer}>
                <div className={s['footer-price-content']}>
                  <div className={s['footer-price-prefix']}>￥</div>
                  <div className={s['footer-price']}>
                    {format((selectService as IServiceRecord)?.price * ruleNum)}
                  </div>
                </div>
                <div className={s['footer-button-group']}>
                  <Btn buttonType="goast" onClick={() => setDrawerOpen(false)}>
                    取消
                  </Btn>
                  <Btn
                    onClick={() => {
                      if (buttonType === 1) {
                        throttleSubmit(ruleNum);
                      } else {
                        form.submit();
                      }
                    }}
                  >
                    去支付
                  </Btn>
                </div>
              </div>
            )
          }
        >
          {payOrder?.status === PayStatus.PAY_STATUS_PAID ? (
            <div className={s.drawer}>
              <div className={s['drawer-title']}>您已成功购买：</div>
              <div className={s['drawer-service-title']}>
                {selectService?.name}
              </div>
              <div className={s['drawer-service-desc']}>
                {selectService?.desc}
              </div>
              <div className={s.box}>
                <div className={s['drawer-title']}>服务时间：</div>
              </div>
              <div className={s.box}>
                <div className={s['drawer-title']}>
                  {ruleNum *
                    (selectService?.comboSaleStrategy?.strategyNum || 1)}
                  {
                    (UNIT_TYPE_MAP as any)[
                      (selectService as IServiceRecord)?.comboSaleStrategy
                        ?.strategyType
                    ]
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className={s.drawer}>
              <div className={s['drawer-title']}>已选套餐：</div>
              <div className={s['drawer-service-title']}>
                {selectService?.name}
              </div>
              <div className={s['drawer-service-desc']}>
                {selectService?.desc}
              </div>
              <div className={s.box}>
                <div className={s['drawer-title']}>选择套餐规格：</div>
                <div className={s['rule-content']}>
                  <InputNumber
                    style={{
                      width: '200px',
                    }}
                    min={1}
                    max={9999}
                    precision={0}
                    maxLength={4}
                    addonBefore={
                      <div
                        className={s['addon-before']}
                        onClick={() => setRuleNum(Math.max(ruleNum - 1, 1))}
                      >
                        -
                      </div>
                    }
                    addonAfter={
                      <div
                        className={s['addon-after']}
                        onClick={() => setRuleNum(ruleNum + 1)}
                      >
                        +
                      </div>
                    }
                    controls={false}
                    suffix={<div className={s.suffix}>组</div>}
                    value={ruleNum}
                    onChange={(v) => setRuleNum(v || 1)}
                  />
                  <div>
                    每{selectService?.comboSaleStrategy?.strategyNum}
                    {
                      (UNIT_TYPE_MAP as any)[
                        (selectService as IServiceRecord)?.comboSaleStrategy
                          ?.strategyType
                      ]
                    }
                    一组
                  </div>
                </div>
              </div>
              <div className={s.box}>
                <div className={s['drawer-title']}>发票服务：</div>
                <div className={s['buttons-wrap']}>
                  <ButtonParams
                    className={s['gap-16']}
                    value={buttonType}
                    onChange={setButtonType}
                    paramsList={TICKET_BUTTONS}
                  />
                </div>
                {buttonType === 2 ? (
                  <div className={s['form-content']}>
                    <div className={s.label}>抬头类型</div>
                    <div className={s['form-button-group']}>
                      <ButtonParams
                        className={s['gap-16']}
                        value={ticketType}
                        onChange={setTicketType}
                        paramsList={HEADER_BUTTONS}
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          lineWidth: 2,
                        },
                      }}
                    >
                      <Form<IForm>
                        className={s.form}
                        form={form}
                        layout="vertical"
                        onFinish={async (values) => {
                          // console.log('values', values);
                          await throttleSubmit(ruleNum, {
                            ...values,
                            invoiceHeaderType: ticketType,
                          });
                        }}
                      >
                        <Row gutter={[16, 0]}>
                          {PERSONAL_FORM.map((item) => (
                            <Col span={12} key={item.name}>
                              <Form.Item
                                name={item.name}
                                required={item.required}
                                label={item.label}
                                rules={item.rules}
                                hidden={item.hidden}
                              >
                                <Input placeholder={item.placeholder} />
                              </Form.Item>
                            </Col>
                          ))}
                          {ticketType === 2 ? (
                            <>
                              {COMPANY_FORM.map((item) => (
                                <Col span={12} key={item.name}>
                                  <Form.Item
                                    name={item.name}
                                    required={item.required}
                                    label={item.label}
                                    rules={item.rules}
                                  >
                                    <Input placeholder={item.placeholder} />
                                  </Form.Item>
                                </Col>
                              ))}
                            </>
                          ) : null}
                        </Row>
                        <Form.Item name="type" hidden initialValue={1}>
                          <Input />
                        </Form.Item>
                        <Form.Item name="source" hidden initialValue="DEJA">
                          <Input />
                        </Form.Item>
                      </Form>
                    </ConfigProvider>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Drawer>
      </ConfigProvider>
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        open={!!qrcodeDetail}
        width={400}
        style={{
          pointerEvents: 'auto',
        }}
        onCancel={() => {
          cancelOrderCallback(payOrder?.id);
          setQrcodeDetail(null);
        }}
        modalRender={() => (
          <div className={cs(s.modal, s['pay-modal'])}>
            <div className={s['pay-modal-title']}>微信扫码支付</div>
            <Icon
              iconName="icon-a-danchuangchahao"
              className={s.close}
              isTransparent
              onClick={() => {
                cancelOrderCallback(payOrder?.id);
                setQrcodeDetail(null);
              }}
            />
            <div className={s['pay-modal-info']}>
              请支付：
              <div className={s['pay-modal-price']}>
                <div className={s['pay-modal-price-prefix']}>￥</div>
                {format(payOrder?.totalPrice)}
              </div>
            </div>
            <div className={s['pay-modal-qrcode-content']}>
              <div className={s['pay-modal-qrcode']}>
                <QRCode
                  size={240}
                  bordered={false}
                  value={qrcodeDetail?.body}
                  status={
                    now.current - pageRefeTime.current >=
                    qrcodeDetail?.expireTime
                      ? 'expired'
                      : 'active'
                  }
                />
              </div>
            </div>
            <div
              className={s['pay-midal-refresh']}
              onClick={() => {
                getQrcodeCallback();
              }}
            >
              刷新
            </div>
          </div>
        )}
      />
      <Modal
        open={successOpen && payOrder?.status === PayStatus.PAY_STATUS_PAID}
        centered
        width={400}
        style={{ pointerEvents: 'auto' }}
        destroyOnClose
        onCancel={() => {
          setSuccessOpen(false);
        }}
        modalRender={() => (
          <div className={cs(s.modal, s['success-modal'])}>
            <img
              className={s['success-icon']}
              src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/goods/buy-pay.png"
            />
            <div className={s['success-text']}>您已完成支付！</div>
            <Btn
              className={s['success-button']}
              onClick={() => {
                setSuccessOpen(false);
              }}
            >
              确认
            </Btn>
          </div>
        )}
      />
    </div>
  );
};

export default ServiceShop;
