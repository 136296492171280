import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import useGenerate from '../../hooks/useGenerate';
import {
  drawGenerate,
  drawGenerateResult,
} from '../../pages/realtime-drawing/api';
import { IRealtimeProps } from './type';

const Context = createContext<IRealtimeProps>({} as IRealtimeProps);

export const RealtimeDrawingProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [prevUrl, setPrevUrl] = useState('');
  const [bgUrl, setBgUrl] = useState('');
  const [savedUrl, setSavedUrl] = useState('');
  const [prompt, setPrompt] = useState('');
  const [currentItem, setCurrentItem] = useState<any>();
  // 线稿生图的时候保存下base64
  const [geneBase64, setGeneBase64] = useState('');
  // 将图片保存之后存一下base64
  const [saveBase64, setSaveBase64] = useState('');

  const pollingResultApi = (params: any) => {
    return drawGenerateResult(params);
  };

  const generateApi = () => {
    setGeneBase64(currentItem?.imgBase64);
    return drawGenerate({
      base64: currentItem?.imgBase64,
      prompt: prompt?.trim(),
      batchSize: 1,
    });
  };

  const { loading, promptIdentity, startGenerate, result } = useGenerate({
    generateApi,
    pollingResultApi,
  });

  return (
    <Context.Provider
      value={{
        prevUrl,
        setPrevUrl,
        bgUrl,
        setBgUrl,
        prompt,
        setPrompt,
        currentItem,
        setCurrentItem,
        geneBase64,
        setGeneBase64,
        saveBase64,
        setSaveBase64,
        loading,
        promptIdentity,
        startGenerate,
        result,
        savedUrl,
        setSavedUrl,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRealtimeDrawing = (): IRealtimeProps => useContext(Context);
