import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import s from './style.module.scss';

const Layout: FC = () => {
  return (
    <div className={s.container}>
      <div className={s.inner}>
        <img
          className={s.img}
          src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230717/af66ae5acd36496183943aff15ca3e77.png"
        />
      </div>
      <Outlet />
    </div>
  );
};

export default Layout;
