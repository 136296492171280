import { FC } from 'react';
import { useParams } from 'react-router-dom';

import PageLayout from '../../components/layout/page-layout';
import ExpandList from './components/expand-list';
import FabricList from './components/fabric-list';
import HistoryList from './components/history-list';

import s from './style.module.scss';

const Mine: FC = () => {
  const { type } = useParams<{ type: string }>();

  const renderItem = () => {
    switch (type) {
      case 'history':
        return <HistoryList />;
      case 'fabric':
        return <FabricList />;
      case 'expand':
        return <ExpandList />;
      case 'collection':
        return <HistoryList isCollect key="collect" />;
      default:
        return <HistoryList />;
    }
  };

  return (
    <PageLayout
      left={renderItem()}
      width="0px"
      leftClassName={s.left}
    ></PageLayout>
  );
};

export default Mine;
