import { Popover } from 'antd';
import cs from 'classnames';
import React, { FC, useRef, useState } from 'react';

import s from './style.module.scss';

interface IProps {
  url: string;
}

const SIZE = 40;
const ZOOM = 0.75;

const ZoomImage: FC<IProps> = ({ url }) => {
  const dom = useRef<any>();
  const [pos, setPos] = useState<any>();
  const [ratio, setRatio] = useState<any>();

  return (
    <div className={s.container} ref={dom}>
      <Popover
        autoAdjustOverflow={false}
        overlayInnerStyle={{
          padding: '0px',
        }}
        placement="leftTop"
        content={
          <div className={cs(s['zoom-container'])}>
            <img src={url} style={{ left: ratio?.x, top: ratio?.y }} />
          </div>
        }
        getPopupContainer={() =>
          document.getElementById('models') ?? document.body
        }
      >
        <div
          className={s['img-radius']}
          onMouseMove={(e) => {
            const rect = dom.current.getBoundingClientRect();
            const maxX = rect.width - SIZE;
            const maxY = rect.height - SIZE;
            const scale = window.innerWidth > 1180 ? 1 : ZOOM;
            const x = Math.max(
              0,
              Math.min(
                e.clientX - rect.left * scale - (scale === 1 ? SIZE / 2 : 0),
                maxX
              )
            );
            const y = Math.max(
              0,
              Math.min(
                e.clientY - rect.top * scale - (scale === 1 ? SIZE / 2 : 0),
                maxY
              )
            );
            const pos = {
              x,
              y,
            };
            // 移动比例
            const moveX = x / maxX;
            const moveY = y / maxY;
            setRatio({
              x: moveX * (256 - 800) + 'px',
              y: moveY * (256 - 800) + 'px',
            });
            setPos(pos);
          }}
        >
          <img src={url} className={s.img} />
          <div
            className={s.mask}
            style={{ top: `${pos?.y || 0}px`, left: `${pos?.x || 0}px` }}
          ></div>
        </div>
      </Popover>
    </div>
  );
};

export default ZoomImage;
