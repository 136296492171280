import request from '../../../../utils/request';

export const uploadFabic = (data: FormData) => {
  return request.post('/customer/api/customerFabri/insert', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const bundleDelete = (data: { ids: number[] }) => {
  return request.post('/customer/api/customerFabri/delete', data);
};

export const listFabic = (data: {
  page: number;
  size: number;
  status?: number;
}) => {
  return request.post('/customer/api/customerFabri/pageListByCustomer', data);
};
