import { Modal } from 'antd';
import html2canvas from 'html2canvas';
import React, { FC, useCallback, useRef, useState } from 'react';

import { downloadBtn } from '../../utils/index';

import s from './style.module.scss';

interface IProps {
  btnLoading?: boolean;
  url: string;
  children?: React.ReactNode;
}

const ShareDownloadImage: FC<IProps> = ({ btnLoading, url, children }) => {
  const container = useRef<any>();
  const [open, setOpen] = useState(false);

  const generate = useCallback(() => {
    html2canvas(container.current, {
      width: 400,
      height: 584,
      useCORS: true,
      backgroundColor: null,
    }).then((canvas: HTMLCanvasElement) => {
      const url = canvas.toDataURL('image/png');
      downloadBtn(url);
    });
  }, [url]);

  return (
    <div className={s.container}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => {
            if (btnLoading) return;
            // 处理点击事件
            setOpen(true);
          },
        });
      })}
      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={400}
        maskClosable={false}
        destroyOnClose
        style={{ pointerEvents: 'auto' }}
        modalRender={() => (
          <div className={s.wrapper}>
            <div className={s.close} onClick={() => setOpen(false)}>
              <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
            </div>
            <div
              className={s.download}
              onClick={() => {
                generate();
              }}
            >
              <span className="font_family icon-a-fenxiangtupianxiazai"></span>
            </div>
            <div className={s.modal} ref={container} id="modal-container">
              <div>
                <div className={s['img-wrapper']}>
                  <img src={url} className={s.img} />
                </div>
                <div className={s.text}>
                  基于人工智能，专为服装设计师和服装品牌商家打造智能服装设计解决方案。
                </div>
              </div>

              <div className={s.footer}>
                <div className={s['shentu-info']}>
                  <img
                    src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/shentn-info.png"
                    className={s.info}
                  />
                </div>
                <div className={s['qrcode-wrapper']}>
                  <img
                    src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/components/shentu-qrcode.bmp"
                    className={s.qrcode}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ShareDownloadImage;
