import { message } from 'antd';
import Konva from 'konva';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useExpandOperation } from '../../contexts/expand-operate';
import { transImage } from '../../utils';
import { saveEditImage } from './api';
import useBgLayer from './hooks/useBgLayer';
import useExpand from './hooks/useExpand';
import useStage from './hooks/useStage';

import s from './style.module.scss';

type Props = {
  detail?: any;
  bgUrl?: string | null;
  setCustomImage?: (params: any) => void;
};

const ExpandMode = ({ bgUrl, detail, setCustomImage }: Props, ref: any) => {
  const [currentShape, setCurrentShape] = useState<any>();
  const { stage } = useStage(
    {
      bgUrl,
      container: 'canvas',
      // width: 512,
      // height: 512,
    },
    false
  );
  useBgLayer(transImage(bgUrl as string, 'jpg', '1024x'), stage);
  const { copy, deleteItem } = useExpand(stage, currentShape, setCurrentShape);
  const { currentOperation, setCurrentOperation } = useExpandOperation();

  useEffect(() => {
    if (!stage) return;
    const handleBack = (e: any) => {
      if (
        e.target.nodeName === 'CANVAS' ||
        ['menu', 'shape-copy', 'shape-delete'].includes(e.target.id)
      ) {
        return;
      }
      const transformer = stage.findOne('Transformer');
      transformer?.destroy();
      setCurrentShape(null);
    };
    document.addEventListener('click', handleBack);
    return () => {
      document.removeEventListener('click', handleBack);
    };
  }, [stage]);

  useEffect(() => {
    setCurrentOperation([]);
  }, [bgUrl]);

  useEffect(() => {
    if (currentOperation.length) {
      localStorage.setItem('hasOperation', 'Y');
    } else {
      localStorage.setItem('hasOperation', '');
    }
  }, [currentOperation]);

  useImperativeHandle(ref, () => ({
    saveImage: async () => {
      if (!currentOperation.length) {
        return message.warning('请先进行创作哦');
      }
      stage.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      const base64 = stage.toDataURL({ mimeType: 'image/jpeg', pixelRatio: 2 });
      const result = await saveEditImage({
        imgBase64: base64,
        // promptIdentity: detail.promptIdentity,
        // promptId: detail.promptId,
        labelType: '12',
        // preUrl: bgUrl as string,
        id: detail.id,
        imgUrl: bgUrl as string,
      })
        .then((res) => {
          if (res) {
            message.success('修改成功');
            setCustomImage?.(res);
            setCurrentOperation([]);
          }
          return base64;
        })
        .catch((err) => {
          console.log('err', err);
        });
      return result;
    },
  }));

  return (
    <>
      {currentShape ? (
        <div id="menu" className={s.menu}>
          <span
            id="shape-copy"
            className="font_family icon-a-fuliaoshangshengongjulanfuzhi1"
            onClick={() => {
              copy(currentShape);
            }}
          ></span>
          <span
            id="shape-delete"
            className="font_family icon-a-fuliaoshangshengongjulanshanchu3"
            onClick={() => {
              deleteItem(currentShape);
            }}
          ></span>
        </div>
      ) : null}
      <div id="canvas" className={s.canvas}></div>
    </>
  );
};

export default forwardRef(ExpandMode);
