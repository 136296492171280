import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { AppLayout } from './components/layout/page-layout';
import { ExpandOperationProvider } from './contexts/expand-operate';
import { ImageEditorProvider } from './contexts/image-editor';
import { OperateInteractionProvider } from './contexts/operate-interaction';
import { OrderInfoProvider } from './contexts/order-info';
import { RealtimeDrawingProvider } from './contexts/realtime-draw';
import { UserInfoProvider } from './contexts/user-info';
import AIModel from './pages/aimodel';
import AreaModify from './pages/area-modify';
import BanConsume from './pages/ban-consume';
import BlendPicture from './pages/blend-picture';
import Buy from './pages/buy';
import GenerateDraft from './pages/darft-generate';
import FabricDesignOperate from './pages/fabric-design-operate';
import GenerateFromPicture from './pages/generate-from-picture';
// import Home from './pages/home';
import ImageToDraft from './pages/image-to-draft';
import Login from './pages/login';
import LoginLayout from './pages/login/layout';
import ResetPassword from './pages/login/resetPassword';
import Mine from './pages/mine';
import OrderDetail from './pages/orders/detail';
import PaySuccess from './pages/orders/pay-success';
import WaitPay from './pages/orders/wait-pay';
import RealtimeDrawing from './pages/realtime-drawing';
import Service from './pages/service';
import ServiceShop from './pages/service-shop';
import UserInfo from './pages/user-info';
import Workflow from './pages/workflow';

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<LoginLayout />}>
        <Route index element={<Login />} />
        <Route path="phoneLogin" element={<Login paramPhoneLogin />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route
        path="/"
        element={
          <UserInfoProvider>
            <OrderInfoProvider>
              <ExpandOperationProvider>
                <OperateInteractionProvider>
                  <RealtimeDrawingProvider>
                    <ImageEditorProvider>
                      <AppLayout />
                    </ImageEditorProvider>
                  </RealtimeDrawingProvider>
                </OperateInteractionProvider>
              </ExpandOperationProvider>
            </OrderInfoProvider>
          </UserInfoProvider>
        }
      >
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/generate/fromPic" element={<Workflow />} />
        <Route path="/generate/areaModify" element={<AreaModify />} />
        <Route
          path="/generate/fabricOnDesign"
          element={<FabricDesignOperate />}
        />
        <Route path="/generate/blendPic" element={<BlendPicture />} />
        <Route path="/generate/geneFromPic" element={<GenerateFromPicture />} />
        <Route path="/generate/fromDraft" element={<GenerateDraft />} />
        <Route path="/generate/imageToDraft" element={<ImageToDraft />} />
        <Route path="/generate/realtimeDrawing" element={<RealtimeDrawing />} />
        <Route path="/generate/aiModel" element={<AIModel />} />
        <Route path="/serviceShop" element={<ServiceShop />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/service" element={<Service />} />
        <Route path="/bantu" element={<BanConsume />} />
        <Route path="/orderDetail" element={<OrderDetail />} />
        <Route path="/waitPay" element={<WaitPay />} />
        <Route path="/paySuccess" element={<PaySuccess />} />
        <Route path="/userinfo" element={<UserInfo />} />
        <Route path="/mine/:type" element={<Mine />} />
        <Route path="/" element={<Navigate to="/generate/fromPic" />} />
        <Route path="*" element={<Navigate to="/generate/fromPic" />} />
      </Route>
    </Routes>
  </Router>
);

export default App;
