import { FC } from "react";

import { isLianton, transImage } from "../../../../utils";

import s from "./style.module.scss";
import { IUserInfo } from "../../../../contexts/user-info/types";
import liantonBigLogo from "../../../../assets/liantonBigLogo.png";

const About: FC<{
  userInfo: IUserInfo;
}> = ({ userInfo }) => {
  const showLianton = isLianton(userInfo);

  return (
    <div className={s.aboutPage}>
      <div className={s.container}>
        <div className={s.about}>
          <div className={s["logo-wrapper"]}>
            <img
              className={s.logo}
              src={transImage(
                "https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/aboutus.png",
                "webp"
              )}
              alt=""
            />
          </div>
          <div className={s["content-us"]}>
            <div>深图智能是领先的AI服装设计软件开发企业。</div>
            <div>
              我们提供前沿软件解决方案，满足时尚设计师和服装制造商需求。通过分析最新时尚趋势，我们的AI系统提供创新设计理念和技巧，助力客户开发时尚产品。
            </div>
            <div>
              我们的团队由经验丰富、热爱技术和时尚的专业人士组成，致力于为客户提供最佳的服装行业解决方案。
            </div>
          </div>
          <div className={s.bottom}>
            <div className={s.left}>
              <div>
                <span className={s.label}>电话：</span>
                <span className={s.value}>0571-88761133</span>
              </div>
              <div>
                <span className={s.label}>邮箱：</span>
                <span className={s.value}>shentu@dejaai.com</span>
              </div>
              <div>
                <span className={s.label}>地址：</span>
                <span className={s.value}>
                  浙江省杭州市余杭区欧美金融城T9 23楼
                </span>
              </div>
              <div>
                <span className={s.label}>网址：</span>
                <a
                  className={s.value}
                  href="https://www.shentuzn.com"
                  target="_blank"
                >
                  www.shentuzn.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLianton && (
        <div className={`${s.container} ${s.lianton}`}>
          <div className={s.about}>
            <div className={s["logo-wrapper"]}>
              <img className={s.logo} src={liantonBigLogo} alt="" />
            </div>
            <div className={s["content-us"]}>
              <div>中国联通服装制造军团是中国联通集团首批十大行业军团之一</div>
              <div>
                是中国联通深耕现代纺织与服装产业，
                面向全国服务的数字化能力基地。
              </div>
              <div>
                服装军团打造了一支涵盖行业背景、技术背景、互联网背景在纺服行业的专家队伍，赋能纺织服装新质生产力。
              </div>
            </div>
            <div className={s.bottom}>
              <div className={s.left}>
                <div>
                  <span className={s.label}>电话：</span>
                  <span className={s.value}>0571-26279567</span>
                </div>
                <div>
                  <span className={s.label}>邮箱：</span>
                  <span className={s.value}>zjs-cuysy@chinaunicom.cn</span>
                </div>
                <div>
                  <span className={s.label}>地址：</span>
                  <span className={s.value}>
                    杭州市临平区玩月街101号CBC时代大厦2号楼
                  </span>
                </div>
                <div>
                  <span className={s.label}>网址：</span>
                  <a
                    className={s.value}
                    href="https://www.cuysy.com"
                    target="_blank"
                  >
                    www.cuysy.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
