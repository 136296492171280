import { message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Textarea from '../../../../components/textarea';
import { useOperateInteraction } from '../../../../contexts/operate-interaction';

import s from './style.module.scss';

type Props = {
  editArea?: any;
  children?: React.ReactNode;
};

const AreaModify: FC<Props> = ({ children }) => {
  const [prompt, setPrompt] = useState<string>('');

  const [searchParams] = useSearchParams();
  const { currentBg, loading } = useOperateInteraction();

  const tab = searchParams.get('tab');

  useEffect(() => {
    setPrompt('');
  }, [currentBg]);

  return (
    <div className={s['area-replace']}>
      <div className={s.textarea}>
        <Textarea
          placeholder="使用画笔涂抹一个区域，然后输入关键词，DEJAAI会根据关键词对涂抹区域进行重新创作。"
          maxLength={200}
          rows={4}
          value={prompt}
          onChange={setPrompt}
        />
      </div>
      <div>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return React.cloneElement(child, {
            ...child.props,
            disabled: loading || !prompt?.trim(),
            onClick: () => {
              if (!prompt?.trim()) {
                return message.error('请输入关键词');
              }
              child.props?.onClick({
                tab,
                prompt,
              });
            },
            children: loading ? '替换中..' : '确认替换',
          });
        })}
      </div>
    </div>
  );
};

export default AreaModify;
