import { Collapse, ConfigProvider } from 'antd';
import cs from 'classnames';
import { useAtom } from 'jotai';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { menus } from '../../constants';
import { menuAtom } from '../../store/global';

import s from './style.module.scss';

interface IProps {
  userInfo: any;
  // [key: string]: any;
}

const Menus: FC<IProps> = ({ userInfo }) => {
  const navigate = useNavigate();
  const [activeList, setActiveList] = useAtom(menuAtom);

  const { pathname } = useLocation();
  const { level = 0 } = userInfo;

  const items = useMemo(() => {
    const usefulMenus = menus.filter((item) => {
      if (item.access && level !== 1) {
        return false;
      }
      return true;
    });
    return usefulMenus.map((item, index) => {
      if (item.children?.length) {
        const isActive = item.children
          ?.map(({ path }) => path)
          ?.includes(pathname);
        const cell = {
          key: item.path,
          label: (
            <div
              className={cs(s.title, {
                [s.active]: isActive,
              })}
            >
              <div
                className={s.icon}
                style={{
                  backgroundPosition: `-${item.iconIndex * 28}px ${
                    isActive ? '0px' : '-28px'
                  }`,
                }}
              ></div>
              {item.title}
              <span
                className={cs('font_family', 'icon-jiantou1', s.arrow)}
              ></span>
            </div>
          ),
          children: item.children
            ?.filter((cell: any) => {
              if (cell.access && level !== 1) {
                return false;
              }
              return true;
            })
            .map((child) => (
              <div
                className={cs(s.child, {
                  [s.active]: child.path?.includes(pathname),
                })}
                key={child.path}
                onClick={() => navigate(child.path)}
              >
                {child.title}
              </div>
            )),
          style: {
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          },
        };
        return (
          <Collapse
            ghost
            key={index}
            items={[cell]}
            expandIconPosition="end"
            expandIcon={() => null}
            className={s.collapse}
            // defaultActiveKey={item.path}
            activeKey={activeList?.includes(item.path) ? item.path : undefined}
            onChange={() => {
              const idx = activeList.findIndex((c) => c === item.path);
              if (idx !== -1) {
                const temp = [...(activeList as string[])];
                temp.splice(idx, 1);
                setActiveList(temp);
                return;
              }
              setActiveList([...(activeList as string[]), item.path]);
            }}
          />
        );
      }
      const isActive = item.path?.includes(pathname);
      return (
        <div
          key={index}
          className={s['pure-content']}
          onClick={() => navigate(item.path)}
        >
          <div
            className={cs(s.title, {
              [s.active]: isActive,
            })}
          >
            <div
              className={s.icon}
              style={{
                backgroundPosition: `-${item.iconIndex * 28}px ${
                  isActive ? '0px' : '-28px'
                }`,
              }}
            ></div>
            {item.title}
          </div>
        </div>
      );
    });
  }, [menus, pathname, level, activeList]);

  return (
    <div className={s.container}>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: '18px 16px',
            },
          },
        }}
      >
        {items}
      </ConfigProvider>
    </div>
  );
};

export default Menus;
