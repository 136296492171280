import { EllipsisOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Modal, Popover } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getProviderFabricDetail,
  getProviderFabricList,
  listFabic,
} from '../../pages/fabric-design-operate/api';
import { Category } from '../../pages/generate/components/area-fabric/enum';
import { transImage } from '../../utils';
import Btn from '../btn';
import LoadList from '../load-list';

import s from './style.module.scss';

interface IProps {
  open: boolean;
  item?: any;
  close?: () => void;
  onChange?: (item: any) => void;
}

export const ModalFabric: FC<IProps> = ({ open, close, onChange, item }) => {
  const navigate = useNavigate();
  const [fabricTab, setFabricTab] = useState<'repository' | 'mine'>(
    'repository'
  );
  const [imgItem, setImgItem] = useState<any>(item);
  const [inputVlaue, setInputValue] = useState('');
  const [keyword, setKeyword] = useState('');
  const [fabricDetail, setFabricDetail] = useState<any>();

  useEffect(() => {
    setImgItem(null);
  }, [fabricTab]);

  const content = useMemo(() => {
    return (
      <div className={s.content}>
        <div className={s['name-list']}>
          <div className={s['name-item']}>
            <div className={s.name}>工厂名称</div>
            <div className={s.value}>{fabricDetail?.companyName}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料货号</div>
            <div className={s.value}>{fabricDetail?.itemNo}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料名称</div>
            <div className={s.value}>{fabricDetail?.name}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料分类</div>
            <div className={s.value}>{Category[fabricDetail?.type]}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料成分</div>
            <div className={s.value}>{fabricDetail?.composition}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>有效幅宽</div>
            <div className={s.value}>{fabricDetail?.validWidth}</div>
          </div>
        </div>
        <Btn
          style={{ width: '100%', marginTop: '16px', fontSize: '12px' }}
          onClick={() => navigate('/service')}
        >
          联系客服购买
        </Btn>
      </div>
    );
  }, [fabricDetail]);

  const getFabricDetail = (id: number) => {
    getProviderFabricDetail(id).then((res: any) => setFabricDetail(res));
  };

  return (
    <Modal
      open={open}
      centered
      destroyOnClose
      width={712}
      style={{ pointerEvents: 'auto' }}
      modalRender={() => (
        <div className={s.modal}>
          <div
            className={s.close}
            onClick={() => {
              close?.();
            }}
          >
            <span className="font_family icon-a-lishijiluerjiyemianchahao"></span>
          </div>
          <div className={s['tabs-con']}>
            <div className={s.tabs}>
              <div
                className={cs(s.tab, {
                  [s.active]: fabricTab === 'repository',
                })}
                onClick={() => setFabricTab('repository')}
              >
                面料库
              </div>
              <div
                className={cs(s.tab, {
                  [s.active]: fabricTab === 'mine',
                })}
                onClick={() => setFabricTab('mine')}
              >
                我的面料
              </div>
            </div>
          </div>
          {fabricTab === 'mine' ? (
            <div className={s.tip}>
              找不到合适的面料？
              <a
                onClick={() => {
                  navigate('/mine/fabric');
                }}
              >
                点击此处
              </a>
              上传面料
            </div>
          ) : (
            <div className={s['search-con']}>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 32,
                    lineWidth: 1,
                    colorTextPlaceholder: '#686b7a',
                  },
                }}
              >
                <Input
                  value={inputVlaue}
                  onChange={(e: any) => setInputValue(e.target.value)}
                  style={{ width: '320px' }}
                  placeholder="请输入货号、分类、成分等关键词"
                  suffix={
                    <span
                      className="font_family icon-a-mianliaoshangshenmianliaokusousuo"
                      onClick={() => setKeyword(inputVlaue)}
                    ></span>
                  }
                  onKeyDown={(e: any) => {
                    const evt = window.event || e;
                    if (evt?.keyCode === 13) {
                      console.log('点击了回车');
                      setKeyword(inputVlaue);
                    }
                  }}
                />
              </ConfigProvider>
            </div>
          )}
          <div className={s.content}>
            {fabricTab === 'repository' ? (
              <LoadList
                key={`repository_${keyword}`}
                imageKey="imageUrl"
                activeItem={imgItem ?? item}
                loadData={(params) =>
                  getProviderFabricList({ ...params, matcher: keyword })
                }
                onSelect={(item) => setImgItem(item)}
                itemRender={(item) => (
                  <div
                    key={item.id}
                    className={s.res}
                    onClick={() => setImgItem(item)}
                  >
                    <div
                      className={cs(s['image-card'], {
                        [s.active]: item.id === imgItem?.id,
                      })}
                    >
                      <img
                        src={transImage(item.imageUrl, 'webp')}
                        className={s.img}
                      />
                    </div>
                    <div className={s.name}>{item.itemNo}</div>
                    <Popover
                      content={content}
                      placement="bottomRight"
                      arrow={false}
                      color="#1F2022"
                      overlayClassName={s['fabric-tip-container']}
                      trigger="click"
                    >
                      <div
                        className={s.icon}
                        onClick={(e) => {
                          e.stopPropagation();
                          getFabricDetail(item.id);
                        }}
                      >
                        <EllipsisOutlined />
                      </div>
                    </Popover>
                  </div>
                )}
                renderEmpty={() => (
                  <div className={s.empty}>
                    <img
                      className={s.img}
                      src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/fabric-empty.png"
                      alt=""
                    />
                    <div className={s.tip}>暂无面料</div>
                  </div>
                )}
              />
            ) : (
              <LoadList
                key="mine"
                imageKey="imageUrl"
                activeItem={imgItem ?? item}
                loadData={(params) => listFabic({ ...params, status: 0 })}
                onSelect={(item) => setImgItem(item)}
                renderEmpty={() => (
                  <div className={s.empty}>
                    <img
                      className={s.img}
                      src="https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/fabric-empty.png"
                      alt=""
                    />
                    <div className={s.tip}>暂无面料</div>
                  </div>
                )}
              />
            )}
          </div>
          <div className={s.footer}>
            <Btn
              disabled={!imgItem}
              className={s.button}
              onClick={() => {
                onChange?.({
                  ...imgItem,
                  cosUrl: imgItem.imageUrl,
                  sourceType: fabricTab === 'repository' ? 1 : 0,
                });
              }}
            >
              确认
            </Btn>
          </div>
        </div>
      )}
    ></Modal>
  );
};
