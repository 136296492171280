import { CaretLeftOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import cs from 'classnames';
import { FC } from 'react';

import s from './style.module.scss';

export const Return: FC<ButtonProps> = (props) => {
  const { children, disabled, className = '', onClick } = props;

  return (
    <div
      className={cs(
        s['return-container'],
        {
          [s.disabled]: disabled,
        },
        className
      )}
      onClick={(e) => {
        if (disabled) return;
        onClick?.(e);
      }}
    >
      {children ?? (
        <>
          <CaretLeftOutlined />
          返回
        </>
      )}
    </div>
  );
};
