import { AnyRecord } from 'dns';
import { useEffect, useRef, useState } from 'react';

interface IParams {
  defaultLoading?: boolean;
  defaultPromptIdentity?: string;
  defaultPullParams?: any;
  generateApi: (params?: any) => Promise<any>;
  pollingResultApi: (params?: any) => Promise<any>;
  onFirstStepSuccess?: (res: any, params: any) => void; // 第一步词成功
  onSuccess?: (result?: any) => void;
  onError?: () => void;
}

export default function useGenerate({
  defaultLoading = false,
  defaultPromptIdentity = '',
  defaultPullParams = {},
  generateApi,
  pollingResultApi,
  onFirstStepSuccess,
  onSuccess,
  onError,
}: IParams) {
  const timer = useRef<NodeJS.Timeout>();
  const times = useRef(0);
  const [loading, setLoading] = useState(defaultLoading);
  const [promptIdentity, setPromptIdentity] = useState(defaultPromptIdentity);
  const [result, setResult] = useState<any>();
  const [pullParams, setPullParams] = useState<any>(defaultPullParams);

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const startGenerate = (params?: AnyRecord) => {
    setLoading(true);
    times.current = Date.now();
    // 每次请求的时候需要将promptIdentity置空
    setPromptIdentity('');
    generateApi(params)
      .then((res: any) => {
        setPromptIdentity(res || (params as any)?.promptIdentity);
        onFirstStepSuccess?.(res, params);
        setPullParams({
          promptIdentity: res,
          ...params,
        });
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
        onError?.();
      });
  };

  const pullingResult = () => {
    console.log('时间上：', Date.now() - times.current);
    if (Date.now() - times.current > 1000 * 90) {
      setLoading(false);
      cancelTimer();
      onError?.();
      return;
    }
    pollingResultApi({
      ...pullParams,
      promptIdentity: promptIdentity ?? '',
    })
      .then((res: any) => {
        if (!res && loading) {
          if (timer.current) {
            cancelTimer();
          }
          timer.current = setTimeout(() => {
            pullingResult();
          }, 3000);
        } else {
          setLoading(false);
          cancelTimer();
          setResult(res);
          onSuccess?.(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
        cancelTimer();
        onError?.();
      });
  };

  useEffect(() => {
    console.log('pullParams', pullParams, loading);
    if (!loading || !promptIdentity) {
      return cancelTimer();
    }
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => {
      pullingResult();
    }, 3000);
  }, [loading, promptIdentity, pullParams]);

  useEffect(() => {
    return () => {
      cancelTimer();
    };
  }, []);

  return {
    loading,
    setLoading,
    promptIdentity,
    setPromptIdentity,
    result,
    startGenerate,
    setPullParams,
    stopPull: () => {
      console.log('这特么这里没有执行吗？');
      cancelTimer();
    },
  };
}
