import { PropsWithChildren } from 'react';

export interface IContainerParams extends PropsWithChildren {
  containerId?: string;
  width?: number;
  height?: number;
  bgUrl?: string;
  setBgUrl?: any;
  onFinish?: (imgData: string, index: number) => void;
}

export enum ModeType {
  BRUSH = 'brush',
  ERASER = 'eraser',
  SELECT = 'select',
}
