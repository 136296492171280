/**
 * 给大数字添加逗号分隔符
 * @memberof module:money
 * @param {number} value  待格式化的值
 * @returns {string} 格式化后的值
 * @example
 * formatLargeNumber(1000000);
 * // => '1,000,000'
 */
export const formatLargeNumber = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * 格式化金钱格式
 * @memberof module:money
 * @param {number} value  待格式化的金额数字
 * @param {boolean} cent 是否是分，默认为true
 * @param {boolean} showComma 是否显示千分位逗号，默认为true
 * @param {boolean} keepInt 是否取整，默认为false
 * @returns {string} 格式化之后的金额
 * @example
 * format(1000000, false));
 * //'1,000,000.00'
 * format(1000000);
 * //'10,000.00'
 */
export const format = (
  value: number | string,
  cent: boolean = true,
  showComma: boolean = true,
  keepInt: boolean = false
) => {
  value = parseFloat(value as string);
  if (cent) {
    value /= 100;
  }
  if (keepInt) {
    value = Math.round(value).toFixed(0);
  } else {
    value = value.toFixed(2);
  }
  if (showComma) {
    return formatLargeNumber(value);
  }
  return value;
};
