import React, { FC, PropsWithChildren, useState } from 'react';

import ExpandModalImage from '../../../expand-modal-image';

export interface IProps extends PropsWithChildren {
  onChange?: (img: string) => void;
}

const ExpandChose: FC<IProps> = ({ children, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          onClick: () => setOpen(!open),
        });
      })}
      <ExpandModalImage
        open={open}
        close={() => setOpen(false)}
        onChange={(val: any) => {
          console.log('any-val', val);
          onChange?.(val.imageUrl);
          setOpen(false); // 关闭弹窗
        }}
      />
    </div>
  );
};

export default ExpandChose;
