import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import { IContextValues, IOrderInfo } from './types';

const Context = createContext<IContextValues>({} as IContextValues);

export const OrderInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [orderInfo, setOrderInfo] = useState<IOrderInfo>({} as IOrderInfo);

  return (
    <Context.Provider
      value={{
        orderInfo,
        setOrderInfo,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useOrderInfo = (): IContextValues => useContext(Context);
