import { useAtom } from 'jotai';
import React, { FC, useEffect, useRef } from 'react';

import Btn from '../../components/btn';
import ButtonParams from '../../components/button-params';
import Result from '../../components/deja-generate-result';
import PageLayout, { OperateLayout } from '../../components/layout/page-layout';
import { Tabs } from '../../components/modal-image';
import OptUpload from '../../components/opt-upload';
import TextArea from '../../components/textarea';
import { useUserInfo } from '../../contexts/user-info';
import {
  fabricImageItemAtom,
  fabricParamsAtom,
  fabricResultAtom,
  fabricStatusAtom,
  fabricUpperImageItemAtom,
} from '../../store/generation';
import { fabricDesignApi, pollingResult } from './api';

import s from './style.module.scss';

const FabricDesignOperate: FC = () => {
  const { userInfo } = useUserInfo();
  const timer = useRef<NodeJS.Timeout>();
  const [fabricStatus, setFabricStatus] = useAtom(fabricStatusAtom);
  const [fabricParams, setFabricParams] = useAtom(fabricParamsAtom);
  const [fabricResult, setFabricResult] = useAtom(fabricResultAtom);
  const [fabricImageItem, setFabricImageItem] = useAtom(fabricImageItemAtom);
  const [fabricUpperImageItem, setFabricUpperImageItem] = useAtom(
    fabricUpperImageItemAtom
  );

  const changeStrength = (value: number) => {
    setFabricParams((prev) => ({ ...prev, strength: value }));
  };

  const changePrompt = (value: string) => {
    setFabricParams((prev) => ({ ...prev, prompt: value }));
  };
  const changeImgUrl = (url: string, imgId: number | string) => {
    setFabricParams((prev) => ({ ...prev, imgUrl: url, imgId }));
  };
  const changeCode = (
    code: string,
    codeId: number | string,
    codeUrl: string,
    sourceType: number
  ) => {
    setFabricParams((prev) => ({ ...prev, code, codeId, codeUrl, sourceType }));
  };
  const changePromptIdentity = (promptIdentity: string) => {
    setFabricParams((prev) => ({ ...prev, promptIdentity }));
  };

  const changeLoading = (loading: boolean) => {
    setFabricStatus((prev) => ({ ...prev, loading }));
  };

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const startGenerate = () => {
    changeLoading(true);
    fabricDesignApi({
      imgUrl: fabricParams?.imgUrl,
      code: fabricParams?.code,
      sourceType: fabricParams?.sourceType, // 这里添加一个字段
      strength: Number((fabricParams.strength / 10).toFixed(1)),
      prompt: fabricParams.prompt,
    })
      .then(changePromptIdentity)
      .catch(() => {
        changeLoading(false);
      });
  };

  const pollingResultApi = () => {
    if (!fabricParams.promptIdentity) {
      timer.current = setTimeout(() => pollingResultApi(), fabricStatus.timing);
      return;
    }
    pollingResult({
      code: fabricParams.code,
      sourceType: fabricParams.sourceType,
      promptIdentity: fabricParams.promptIdentity,
    })
      .then((res) => {
        if (res) {
          setFabricResult(res);
          changeLoading(false);
        } else {
          timer.current = setTimeout(
            () => pollingResultApi(),
            fabricStatus.timing
          );
        }
      })
      .catch(() => {
        changeLoading(false);
        setFabricResult(null);
        changePromptIdentity('');
      });
  };

  const startPollingResult = () => {
    if (timer.current) {
      cancelTimer();
    }
    timer.current = setTimeout(() => pollingResultApi(), fabricStatus.timing);
  };

  useEffect(() => {
    fabricStatus.loading && !fabricResult && startPollingResult();
    return () => {
      cancelTimer();
    };
  }, [fabricStatus.loading, fabricParams.promptIdentity, fabricResult]);

  return (
    <div className={s.container}>
      <PageLayout
        left={
          <Result
            key={fabricParams.promptIdentity}
            tipText="选择面料进行上身创意吧！"
            item={fabricResult}
            loading={fabricStatus.loading as boolean}
            onFinish={() => {}}
          />
        }
      >
        <OperateLayout
          title="面料替换"
          footer={
            <Btn
              isGenerate
              loading={fabricStatus.loading}
              disabled={!fabricParams?.imgUrl || !fabricParams?.code}
              className={s.button}
              onClick={() => {
                startGenerate();
                setFabricResult(null);
              }}
            >
              生成设计图
            </Btn>
          }
        >
          <div className={s.content}>
            <div className={s.box}>
              <div className={s.title}>面料图</div>
              <OptUpload
                fabricable
                hideOptButton
                imageItem={fabricUpperImageItem}
                onChange={(imageItem) => {
                  setFabricUpperImageItem(imageItem);
                  changeCode(
                    imageItem?.code,
                    imageItem?.id,
                    imageItem?.imageUrl,
                    imageItem?.sourceType
                  );
                }}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>灵感图</div>
              <OptUpload
                tabs={[Tabs.Recommend, Tabs.History, Tabs.Location]}
                imageItem={fabricImageItem}
                onChange={(imageItem) => {
                  setFabricImageItem(imageItem);
                  changeImgUrl(imageItem?.cosUrl, imageItem?.id);
                }}
              />
            </div>
            <div className={s.box}>
              <div className={s.title}>面料对生图的影响</div>
              <div className={s['slider-wrap']}>
                <ButtonParams
                  value={fabricParams.strength}
                  onChange={changeStrength}
                />
              </div>
            </div>
            <div className={s.box}>
              <div className={s.title}>设计描述</div>
              <TextArea
                rows={4}
                userInfo={userInfo}
                maxLength={500}
                placeholder="请输入描述词，词与词之间请用“逗号”隔开，例如：羽绒服，白色，运动风格，男款。"
                value={fabricParams.prompt}
                onChange={changePrompt}
              />
            </div>
          </div>
        </OperateLayout>
      </PageLayout>
    </div>
  );
};

export default FabricDesignOperate;
