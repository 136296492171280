import cs from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { transImage } from '../../utils';
import InfiniteScroll from '../infinite-scroll';

import s from './style.module.scss';

interface IProps {
  height?: number;
  loadData: (params: { page: number; size: number }) => Promise<{
    page: number;
    total: number;
    rows: any[];
  }>;
  activeItem?: any;
  onSelect?: (item: any) => void;
  imageKey?: string;
  itemRender?: (item: any, isActive?: boolean) => React.ReactNode;
  renderEmpty?: () => React.ReactNode;
}

const LoadList: FC<IProps> = ({
  height = 332,
  loadData,
  onSelect,
  activeItem,
  imageKey = 'url',
  itemRender,
  renderEmpty,
}) => {
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any[]>([]);
  const [requestPage, setRequestPage] = useState(0);

  const getImageList = () => {
    if (loading) {
      return;
    }
    if (requestPage >= total && total !== 0) {
      return;
    }
    setLoading(true);
    loadData({
      page: requestPage + 1,
      size: 15,
    })
      .then((res: any) => {
        setRequestPage(res.page);
        setTotal(res.total);
        setList((list) => [...list, ...res.rows]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('请求出错了', err);
      });
  };

  useEffect(() => {
    getImageList();
  }, [current]);

  return (
    <div>
      <InfiniteScroll
        height={height}
        loading={loading}
        trggerLoad={() => setCurrent((pre) => pre + 1)}
      >
        {list?.length ? (
          <div className={s['item-list']}>
            {list.map((item: any) =>
              itemRender ? (
                itemRender(item, item.id === activeItem?.id)
              ) : (
                <div
                  key={item.id}
                  className={cs(s['image-card'], {
                    [s.active]: item.id === activeItem?.id,
                  })}
                  onClick={() => onSelect?.(item)}
                >
                  <img
                    src={transImage(item[imageKey], 'webp')}
                    className={s.img}
                  />
                </div>
              )
            )}
          </div>
        ) : (
          renderEmpty?.()
        )}
      </InfiniteScroll>
    </div>
  );
};

export default LoadList;
