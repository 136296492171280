import { message } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

import { soundToText } from './api';

import s from './style.module.scss';

type IProps = {
  userInfo?: any;
  onSuccess?: (str: any) => void;
};

const MIN = 10000; // 最小
const MAX = 1024 * 1024; // 最大1兆

const Sound: FC<IProps> = ({ onSuccess, userInfo }) => {
  const [open, setOpen] = useState(false);
  const [clickDown, setClickDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tip, setTip] = useState('按住说话转文字');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  useEffect(() => {
    if (open) {
      if (navigator.mediaDevices) {
        const constraints = { audio: true };
        const chunks: BlobPart[] = [];
        // 获取音频设备
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          // 创建媒体，收集流
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.ondataavailable = (e) => {
            chunks.push(e.data);
          };
          mediaRecorderRef.current.onstop = () => {
            setLoading(true);
            const bolb = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
            const { size } = bolb;
            if (size < MIN || size > MAX) {
              message.error(
                size < MIN ? '语音录入的时间太短' : '音频太大，请减少录入'
              );
              setLoading(false);
              chunks.length = 0; // 之前的语音需要清掉
              return;
            }
            const formdata = new FormData();
            formdata.append('file', bolb);
            soundToText(formdata)
              .then((res: string) => {
                setLoading(false);
                onSuccess?.(res);
                setOpen(false);
              })
              .catch((err: any) => {
                setLoading(false);
                chunks.length = 0;
                console.log('err', err);
              });
          };
        });
      } else {
        message.error('浏览器不支持语音');
        setOpen(false);
      }
    } else {
      mediaRecorderRef.current = null;
    }
  }, [open]);

  useEffect(() => {
    if (clickDown) {
      mediaRecorderRef.current?.start();
    } else {
      mediaRecorderRef.current?.stop();
    }
  }, [clickDown]);

  useEffect(() => {
    if (loading) {
      setTip('语音识别中...');
    } else if (clickDown) {
      setTip('说话中...');
    } else {
      setTip('按住说话转文字');
    }
  }, [loading, clickDown]);

  return (
    <div>
      {open ? (
        <div className={s.container}>
          <img
            src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230725/33f21e8e54fd47ad9f7628a3ea265a9b.png"
            className={s.sound}
            onClick={() => setOpen(false)}
          />
          <div>{tip}</div>
          <div
            className={s['w-40']}
            onMouseDown={() => {
              if (loading) return;
              setClickDown(true);
            }}
            onMouseUp={() => {
              setClickDown(false);
            }}
            onTouchStart={() => {
              if (loading) return;
              setClickDown(true);
            }}
            onTouchEnd={() => {
              setClickDown(false);
            }}
          >
            {clickDown && <div className={s['w-circle']}></div>}
            <div
              className={s['absolute-100']}
              unselectable="on"
              style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                MozUserSelect: 'none',
              }}
            ></div>
            {loading && <div className={s.loading}></div>}
            <img
              src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230721/2ed808aa64034033a8e419a0703fe1a5.png"
              className={s['w-full']}
            />
          </div>
        </div>
      ) : (
        <div
          unselectable="on"
          className={s.relative}
          onClick={() => {
            if (userInfo?.lifeStatus === 0) {
              return message.error('您的账户已到期，请联系客服开通权限');
            }
            setOpen(true);
          }}
        >
          <div className={s['absolute-100']}></div>
          <img
            src="https://deep-design-sv-1317713800.cos.na-siliconvalley.myqcloud.com/files/img/20230721/ecfb7fc3ac3e4938abd38c3ca58162b7.png"
            className={s['w-20']}
          />
        </div>
      )}
    </div>
  );
};

export default Sound;
