import Konva from 'konva';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { IExpandProps } from './type';

const Context = createContext<IExpandProps>({} as IExpandProps);

export const ExpandOperationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [currentOperation, setCurrentOperation] = useState<
    Array<string | Konva.Shape>
  >([]);

  useEffect(() => {
    if (tab !== '3') {
      setCurrentOperation([]);
    }
  }, [tab]);

  return (
    <Context.Provider
      value={{
        currentOperation,
        setCurrentOperation: (param) => {
          if (
            typeof param === 'string' &&
            currentOperation.some((item) => typeof item === 'string')
          ) {
            return;
          }
          if (Array.isArray(param)) {
            setCurrentOperation(param);
          } else {
            // 超过十张图就不能继续添加了
            if (currentOperation.length >= 10) {
              return;
            }
            setCurrentOperation((list) => [...list, param]);
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useExpandOperation = (): IExpandProps => useContext(Context);
