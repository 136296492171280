import Konva from 'konva';
import { useEffect, useState } from 'react';

import { ModeType } from '../types';

export interface IProps {
  stage?: Konva.Stage;
  layer?: Konva.Layer;
  eventRecord: any;
  mode: string;
  freshTime?: number;
  addHistory?: (obj: any) => void;
}

const BACK_IMAGE = 'background-image';

export default function useTransform({
  stage,
  layer,
  eventRecord,
  mode,
  freshTime,
  addHistory,
}: IProps) {
  const [currentShap, setCurrentShap] = useState<any>();

  useEffect(() => {
    if (
      mode === ModeType.SELECT &&
      ['mousedown', 'touchstart'].includes(eventRecord.eventName)
    ) {
      const { e } = eventRecord;
      e.evt.cancelBubble = true;
      e.evt.preventDefault();
      const name = e?.target?.name();
      console.log('name', name);
      // 背景色和线条和橡皮不能被选中
      if (name === 'background-rect' || name === 'line' || name === 'eraser') {
        setCurrentShap(null);
      } else if (!name.includes('anchor')) {
        setCurrentShap(e.target);
        name !== BACK_IMAGE && e.target.moveToTop();
      }
    } else if (mode !== ModeType.SELECT) {
      setCurrentShap(null);
      layer?.getChildren().forEach((node: Konva.Node) => {
        node.setAttr('draggable', false);
      });
      stage?.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      layer?.draw();
    }
  }, [mode, eventRecord.eventName]);

  useEffect(() => {
    stage?.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
    if (currentShap) {
      console.log('currentShap', currentShap);
      const tr = new Konva.Transformer({
        name: 'transformer',
      });
      // 背景图片不能移动
      if (currentShap.getName() === BACK_IMAGE) {
        tr.enabledAnchors([]);
        tr.nodes([currentShap]);
        layer?.add(tr);
        layer?.draw();
        return;
      }
      currentShap.setAttr('draggable', true);
      tr.nodes([currentShap]);
      // 移动结束
      tr.on('dragend', (e: any) => {
        console.log('dragend', 'dragend');
        const name = e.target.getName();
        const target =
          name === 'transformer' ? e.target.nodes()?.[0] : e.target;
        addHistory?.(target);
      });
      // 变换结束【缩放，旋转】
      tr.on('transformend', (e: any) => {
        const name = e.target.getName();
        const target =
          name === 'transformer' ? e.target.nodes()?.[0] : e.target;
        addHistory?.(target);
      });
      layer?.add(tr);
      layer?.draw();
    } else {
      layer?.getChildren().forEach((node: Konva.Node) => {
        node.setAttr('draggable', false);
      });
      stage?.find('Transformer').forEach((node: Konva.Node) => node?.destroy());
      layer?.draw();
    }
  }, [currentShap, stage, layer, addHistory]);

  useEffect(() => {
    setCurrentShap(null);
  }, [freshTime]);
}
