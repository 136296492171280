import request from '../../../../utils/request';

// 面料匹配
export async function matchFabric(data: {
  colorCode?: string;
  promptId: string;
}) {
  return request.post(
    // 'https://api.shentuzn.com/supplier/api/fabri/match',
    '/customer/api/fabri/match',
    data
  );
}
