import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import s from './style.module.scss';

type Props = {
  editArea?: any;
  children?: React.ReactNode;
};

const AreaPancel: FC<Props> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  return (
    <div className={s['area-replace']}>
      <div className={s.placeholder}>
        <span>运用画笔工具，可在已生成的</span>
        <span>设计图上进行二次创作</span>
      </div>
      <div>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return React.cloneElement(child, {
            ...child.props,
            onClick: () => {
              child.props?.onClick({
                tab,
                prompt,
              });
            },
            children: '确认修改',
          });
        })}
      </div>
    </div>
  );
};

export default AreaPancel;
