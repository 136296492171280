export interface ILabel {
  id: number;
  pid?: number;
  name: string;
  nameDes?: string;
  sort: number;
  children?: ILabel[];
}

export interface IGetLabelsRes {
  models: ILabel[];
  styles: ILabel[];
}

export interface IPageRecommendParams {
  page?: number;
  size?: number;
  labelNameDes?: string;
  pid?: number;
  labelId?: number;
  type?: number;
}

export interface IRecommendImg {
  id: number;
  imageUrl: string;
  labels: ILabel[];
  plabels: ILabel;
  status?: number;
}

export interface IPageRecommendRes {
  page: number;
  size: number;
  total: number;
  records: number;
  rows: IRecommendImg[];
}

export enum ImageQuality {
  /** 样衣图 */
  StandCloth = 1,
  /** 线稿图 */
  DraftCloth = 2,
}
