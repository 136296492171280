import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useUserInfo } from '../../../contexts/user-info';
import Header from '../../header';

import s from './style.module.scss';

export const AppLayout: FC = () => {
  const { userInfo } = useUserInfo();

  return (
    <div className={s.container}>
      <Header userInfo={userInfo} />
      <div className={s.outlet}>
        <Outlet />
      </div>
    </div>
  );
};
