export enum LabelType {
  /** 第一次生成 */
  Origin,
  U1,
  U2,
  U3,
  U4,
  V1,
  V2,
  V3,
  V4,
  /** 重新生成 */
  SOLO,
  /** 单图延升 */
  VARIATIONS,
  /** O => V => SOLO => 带有Variations */
  V_SOLO,
  /** 以图生图 */
  PicToPic = 14,
  /** 图片融合 */
  PicBlend = 15,
  /** 线稿生图 */
  PicDraft = 17,
}

export const MaxTry = 80;

export const ProgressBarAnimationTime = 100;
export const ProgressBarAnimationMaxPercent = 90;

export const LoadintTextMap = [
  '正在启动AI引擎...',
  '正在通过AI引擎设计框架...',
  '正在描画服装细节...',
  '正在渲染服装色彩...',
];

export enum AppealStatus {
  Pending,
  Processing,
  Ended,
}

export enum Read {
  Unread,
  Read,
}

export const Options = [
  {
    value: '模特上身图',
    text: '模特上身图',
  },
  {
    value: '版型工艺图',
    text: '版型工艺图',
  },
  {
    value: '制作样衣',
    text: '制作样衣',
  },
];

export const labelKeyMap = [
  LabelType.U1,
  LabelType.U2,
  LabelType.U3,
  LabelType.U4,
];
