import { Divider, Form, Input, Modal } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Btn from '../../../../components/btn';
import Icon from '../../../../components/icon';
import { useUserInfo } from '../../../../contexts/user-info';
import { throttle } from '../../../../utils';
import { upadtePass, updateNickName } from './api';

import s from './style.module.scss';

const Info: FC = () => {
  const input = useRef<any>();
  const timer = useRef<any>(null);

  const { userInfo, getUserInfoApi } = useUserInfo();

  const [isEdit, setIsEdit] = useState(false);
  const [nickName, setNickName] = useState(userInfo.nickName);
  const [phoneModal, setPhoneModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [seconds, setSeconds] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const changeNickName = useCallback(
    (nickName: string) => {
      if (nickName?.trim() === userInfo.nickName) {
        setNickName(userInfo.nickName);
        setIsEdit(false);
      } else {
        updateNickName(nickName)
          .then(() => {
            getUserInfoApi();
            setIsEdit(false);
          })
          .catch(() => {
            setNickName(userInfo.nickName);
            setIsEdit(false);
          });
      }
    },
    [userInfo?.nickName]
  );

  const changePass = useCallback((oldPass: string, newPass: string) => {
    upadtePass(oldPass, newPass)
      .then(() => {
        window.location.href = `/login`;
        window.localStorage.clear();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const throttleChangePass = throttle(changePass, 1000);

  useEffect(() => {
    if (isEdit) {
      input.current.focus?.();
    }
  }, [isEdit]);

  useEffect(() => {
    setNickName(userInfo?.nickName);
  }, [userInfo?.nickName]);

  useEffect(() => {
    if (seconds !== null && seconds <= 0) {
      setSeconds(null);
      // setSendable(true);
    }
    if (seconds !== null && !timer.current) {
      timer.current = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [seconds]);

  return (
    <div className={s.center}>
      <div className={s.container}>
        <div className={s.title}>
          <div className={s['user-name']}>{userInfo.nickName?.slice(0, 1)}</div>
          <div className={s['name-container']}>
            {isEdit ? (
              <Input
                ref={input}
                maxLength={20}
                className={s.input}
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
                onBlur={() => changeNickName(nickName)}
              />
            ) : (
              <span>{nickName}</span>
            )}
          </div>
          {isEdit ? null : (
            <Icon
              iconName="icon-xiugainicheng"
              borderRadius="8px"
              defaultBg="rgba(255, 255, 255, 0.1)"
              onClick={() => setIsEdit(true)}
            />
          )}
        </div>
        <div className={s.content}>
          {/* <div className={s['sub-title']}>当前服务包</div>
        <div className={s.value}>
          <span>XXXXXX服务包</span>
        </div>
        <Divider className={s.line} /> */}
          <div className={s['sub-title']}>到期时间</div>
          <div className={s.value}>
            <span>{userInfo?.expireTime as string}</span>
          </div>
          <Divider className={s.line} />
          {/* <div className={s['sub-title']}>我的手机号</div>
        <div className={s.value}>
          <span>{userInfo?.phone as string}</span>
          <Btn buttonType="goast" onClick={() => setPhoneModal(true)}>
            更换手机
          </Btn>
        </div>
        <Divider className={s.line} /> */}
          <div className={s['sub-title']}>我的密码</div>
          <div className={s.value}>
            <span>********</span>
            <Btn buttonType="goast" onClick={() => setPasswordModal(true)}>
              修改密码
            </Btn>
          </div>
        </div>
        <Modal
          width={440}
          style={{ pointerEvents: 'auto' }}
          maskClosable={false}
          centered
          open={phoneModal}
          onCancel={() => setPhoneModal(false)}
          modalRender={() => (
            <div className={s['modal-container']}>
              <div className={s['modal-title']}>绑定新手机</div>
              <Form className={s.form} layout="vertical">
                <Form.Item label="手机号">
                  <div className={s.flex}>
                    <Input placeholder="请输入新手机号码" maxLength={11} />
                    <Btn
                      buttonType="goast"
                      disabled={seconds !== null}
                      onClick={() => setSeconds(60)}
                      className={s.send}
                    >
                      {seconds ? seconds + 's' : '获取验证码'}
                    </Btn>
                  </div>
                </Form.Item>
                <Form.Item label="验证码">
                  <Input placeholder="请输入验证码" maxLength={6} />
                </Form.Item>
              </Form>
              <div className={s.footer}>
                <Btn buttonType="goast" onClick={() => setPhoneModal(false)}>
                  取消
                </Btn>
                <Btn>确认</Btn>
              </div>
            </div>
          )}
        />
        <Modal
          width={440}
          style={{ pointerEvents: 'auto' }}
          maskClosable={false}
          destroyOnClose
          centered
          open={passwordModal}
          onCancel={() => setPasswordModal(false)}
          modalRender={() => (
            <div className={s['modal-container']}>
              <div className={s['modal-title']}>修改密码</div>
              <Form
                requiredMark={false}
                className={s.form}
                layout="vertical"
                onFinish={async (values) => {
                  if (loading) return;
                  setLoading(true);
                  return throttleChangePass(values.oldPass, values.newPass);
                }}
              >
                <Form.Item
                  label={<span className={s.label}>原密码</span>}
                  name="oldPass"
                  rules={[{ required: true, message: '请输入原密码' }]}
                >
                  <Input.Password
                    placeholder="请输入原密码"
                    min={6}
                    maxLength={20}
                    className={s['account-input']}
                  />
                </Form.Item>
                <Form.Item
                  label={<span className={s.label}>新密码</span>}
                  name="newPass"
                  rules={[{ required: true, message: '请输入原密码' }]}
                >
                  <Input.Password
                    placeholder="请输入新密码"
                    min={6}
                    maxLength={20}
                    className={s['account-input']}
                  />
                </Form.Item>
                <Form.Item
                  label={<span className={s.label}>重复新密码</span>}
                  name="confirm"
                  dependencies={['newPass']}
                  rules={[
                    { required: true, message: '请输入原密码' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPass') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('两次输入的密码不一致!')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="请重复新密码"
                    min={6}
                    maxLength={20}
                    className={s['account-input']}
                  />
                </Form.Item>
                <div className={s.footer}>
                  <Btn
                    buttonType="goast"
                    onClick={() => setPasswordModal(false)}
                  >
                    取消
                  </Btn>
                  <Btn htmlType="submit">确认</Btn>
                </div>
              </Form>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Info;
