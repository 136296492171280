import Konva from 'konva';
import { useEffect, useState } from 'react';

import { ModeType } from '../types';

export interface IProps {
  layer?: Konva.Layer;
  eventRecord: any;
  stroke?: number;
  color?: string;
  mode?: string;
  addHistory?: (obj: any) => void;
}

export default function useBrushLayer({
  layer,
  eventRecord,
  mode = ModeType.BRUSH,
  color = '#333',
  stroke = 10,
  addHistory,
}: IProps) {
  const [currentLine, setCurrentLine] = useState<any>();

  useEffect(() => {
    if (!eventRecord || !layer || mode !== ModeType.BRUSH) return;
    const { eventName, pos } = eventRecord;
    if (['mousedown', 'touchstart'].includes(eventName)) {
      const line = new Konva.Line({
        stroke: color,
        strokeWidth: stroke,
        globalCompositeOperation: 'source-over',
        points: [pos?.x ?? 0, pos?.y ?? 0, pos?.x ?? 0, pos?.y ?? 0],
        lineCap: 'round',
        lineJoin: 'round',
        name: 'line',
      });
      layer.add(line);
      setCurrentLine(line);
    }
  }, [eventRecord, layer, mode]);

  useEffect(() => {
    if (!eventRecord || !layer || !currentLine || mode !== ModeType.BRUSH)
      return;
    const { eventName, pos } = eventRecord;
    if (['mousemove', 'touchmove'].includes(eventName)) {
      currentLine.points([...(currentLine?.points() ?? []), pos?.x, pos?.y]);
      layer.batchDraw();
    }
  }, [currentLine, eventRecord, layer, mode]);

  useEffect(() => {
    if (!eventRecord || !currentLine) return;
    const { eventName } = eventRecord;
    if (
      ['mouseup', 'touchend', 'mouseout'].includes(eventName) ||
      mode !== ModeType.BRUSH
    ) {
      setCurrentLine(null);
      // 这里需要保存到历史
      addHistory?.(currentLine);
    }
  }, [eventRecord, currentLine, mode]);
}
