import cs from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import Icon from '../../../icon';

import s from './style.module.scss';

interface IProps {
  item?: {
    url: string;
    [key: string]: any;
  };
  onChange?: (record: any) => void;
}

const MAX_COUNT = 10;
const MAX_PAGE = 3;
const MIN_PAGE = 1;
const SHOW_COUNT = 4;

const HistoryList: FC<IProps> = ({ item, onChange }) => {
  const parent = useRef<HTMLDivElement>(null);
  const [list, setList] = useState<any[]>([]);
  const [showList, setShowList] = useState<any[]>([]);
  const [current, setCurrent] = useState(item);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (item) {
      setList(
        (prev: any[]) => {
          const prevList = [...prev];
          const index = prevList.findIndex(
            (cell: any) => cell.url === item.url
          );
          if (index === -1) {
            return [item, ...prev].slice(0, MAX_COUNT);
          }
          const tempList = [...prevList];
          tempList.splice(index, 1, item);
          return tempList;
        }
        // prev?.includes(url) ? prev : [url, ...(prev ?? [])].slice(0, MAX_COUNT)
      );
      // setCurrentUrl(url);
      setCurrent(item);
    }
  }, [item]);

  useEffect(() => {
    setShowList(
      list.slice((currentPage - 1) * SHOW_COUNT, currentPage * SHOW_COUNT)
    );
  }, [list, currentPage]);

  useEffect(() => {
    onChange?.(current);
  }, [current]);

  const renderItem = () => {
    return showList?.map((item: any) => (
      <div
        className={cs(s['img-container'], {
          item: true,
          [s.active]: item.url === current?.url,
        })}
        key={item?.url}
        onClick={() => {
          setCurrent(item);
        }}
      >
        <div className={s.wrapper}>
          <img src={item.url} className={s.img} />
        </div>
      </div>
    ));
  };

  return (
    <div className={s.container}>
      {list.length > SHOW_COUNT ? (
        <Icon
          iconName="icon-jiantou2"
          disabled={currentPage <= MIN_PAGE}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        />
      ) : null}
      <div className={s['img-list']} id="img-list" ref={parent}>
        {renderItem()}
      </div>
      {list.length > SHOW_COUNT ? (
        <Icon
          iconName="icon-jiantou2"
          direction="right"
          disabled={
            currentPage >= MAX_PAGE || list.length <= SHOW_COUNT * currentPage
          }
          onClick={() => setCurrentPage((prev) => prev + 1)}
        />
      ) : null}
    </div>
  );
};

export default HistoryList;
