import { Descriptions, message, QRCode } from 'antd';
import cs from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Return } from '../../components/btn';
import { transImage } from '../../utils';
import { getPayQrCode } from '../../utils/api';
import { PayStatus, PayStatusMap } from '../../utils/types';
import {
  GoodCategory,
  GoodCategoryMap,
  OrderStatus,
  OrderStatusMap,
  QuestionType,
} from '../ban-consume/constants';
import { getOrderDetail } from './api';

import s from './style.module.scss';

interface IProps {
  serviceType?: string;
}

const TIME_DIFF = 3000;

const WaitPay: FC<IProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serviceType = searchParams.get('serviceType');
  const id = searchParams.get('id');
  const payOrderNo = searchParams.get('payOrderNo');
  const [detail, setDetail] = useState<any | null>();
  const [payDetail, setPayDetail] = useState<any>();
  const animation = useRef<number>();
  const pageRefeTime = useRef<number>(Date.now());
  const now = useRef<number>(Date.now());

  const [demand]: [any] = detail?.tradeOrderDetailInfoVOList ?? [];

  const geDetail = useCallback(() => {
    getOrderDetail(id as string)
      .then((res: any) => {
        if (res.payStatus === PayStatus.PAY_STATUS_PAID) {
          animation.current && window.cancelAnimationFrame(animation.current);
          return navigate('/paySuccess');
        }
        setDetail(res);
      })
      .catch((err: any) => message.error(err.msg));
  }, [id]);

  const getQrcode = useCallback(() => {
    getPayQrCode(payOrderNo as string).then((res: any) => {
      setPayDetail(res);
    });
  }, [payOrderNo]);

  useEffect(() => {
    if (!serviceType || !id || !payOrderNo) {
      navigate(-1);
    }
  }, [serviceType]);

  useEffect(() => {
    if (!id) return;
    geDetail();
  }, [geDetail, id]);

  useEffect(() => {
    if (!payOrderNo) return;
    getQrcode();
  }, [getQrcode, payOrderNo]);

  // 轮询到订单状态
  const animationCb = () => {
    const timeDiff = Date.now() - now.current;
    if (timeDiff >= TIME_DIFF) {
      now.current = Date.now();
      geDetail();
    }
    animation.current = window.requestAnimationFrame(animationCb);
  };

  useEffect(() => {
    animationCb();
    return () => {
      animation.current && window.cancelAnimationFrame(animation.current);
    };
  }, [geDetail]);

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s['qrcode-wrapper']}>
          <div className={s['main-tip']}>下单成功！请支付</div>
          <div className={s.qrcode}>
            {payDetail?.body && (
              <QRCode
                value={payDetail?.body}
                status={
                  now.current - pageRefeTime.current >= payDetail?.expireTime
                    ? 'expired'
                    : 'active'
                }
                onRefresh={() => {
                  pageRefeTime.current = Date.now();
                  getQrcode();
                }}
              />
            )}
          </div>
          <div className={s.channel}>
            <div className={s.icon}>
              <img
                className={s.img}
                src={transImage(
                  'https://deep-design-store-1317713800.cos.ap-guangzhou.myqcloud.com/Deja/order/weixin.png',
                  'webp'
                )}
              />
            </div>
            <div className={s['channel-tip']}>
              请使用微信扫一扫
              <br />
              扫描二维码支付
            </div>
          </div>
          <div className={s['pay-tips']}>
            应付金额：
            <div className={s['price-con']}>
              ￥<span className={s.num}>{detail?.totalPrice}</span>
            </div>
          </div>
        </div>
        <Return onClick={() => navigate(-1)} />
      </div>
      <div className={s.right}>
        <div
          className={cs(s.content, {
            [s.stretch]: GoodCategory.AI !== demand?.categoryType,
          })}
        >
          <div className={s.card}>
            <div className={s.title}>订单信息</div>
            <div className={s.descriptions}>
              <Descriptions
                column={2}
                labelStyle={{
                  color: '#7f828f',
                  fontSize: '12px',
                }}
                contentStyle={{
                  fontSize: '12px',
                  color: '#f2f4ff',
                }}
              >
                <Descriptions.Item label="参考图">
                  <div className={s['desc-img-content']}>
                    <img src={demand?.picUrl} className={s.img} />
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="订单号码">
                  {detail?.orderNo}
                </Descriptions.Item>
                <Descriptions.Item label="服务类型">
                  {GoodCategoryMap[demand?.categoryType as GoodCategory]}
                </Descriptions.Item>
                <Descriptions.Item label="下单时间">
                  {detail?.createTime}
                </Descriptions.Item>
                <Descriptions.Item label="订单状态">
                  {OrderStatusMap[detail?.status as OrderStatus]}
                </Descriptions.Item>
                <Descriptions.Item label="商品名称">
                  {demand?.skuName}
                </Descriptions.Item>
                <Descriptions.Item label="实付金额">
                  <div className={s['desc-price']}>
                    ￥<span>{detail?.totalPrice}</span>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label="支付状态">
                  {PayStatusMap[detail?.payStatus as PayStatus]}
                </Descriptions.Item>
                <Descriptions.Item label="订单备注" span={2}>
                  {detail?.remark}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          {/* ai打板会有需求清单 */}
          {GoodCategory.AI === demand?.categoryType && (
            <div className={s.card}>
              <div className={s.title}>需求清单</div>
              <div className={s.descriptions}>
                <Descriptions
                  column={1}
                  labelStyle={{
                    color: '#7f828f',
                    fontSize: '12px',
                  }}
                  contentStyle={{
                    fontSize: '12px',
                    color: '#f2f4ff',
                  }}
                >
                  {demand?.listInfo?.[0]?.simpleAnswers?.map(
                    (item: any, index: number) => {
                      if (item.type === QuestionType.CHECKBOX) {
                        return (
                          <Descriptions.Item label={item.keyword} key={index}>
                            {item.optionValues
                              ?.map((cell: any) => cell.value)
                              ?.join(',')}
                          </Descriptions.Item>
                        );
                      }
                      if (item.type === QuestionType.TEXT) {
                        if (item.optionValues.length > 0) {
                          return [
                            <Descriptions.Item key="title" label={item.keyword}>
                              {' '}
                            </Descriptions.Item>,
                            <Descriptions.Item>
                              <div key={index} className={s['sub-dex-wrapper']}>
                                <Descriptions
                                  column={4}
                                  labelStyle={{
                                    color: '#7f828f',
                                    fontSize: '12px',
                                  }}
                                  contentStyle={{
                                    fontSize: '12px',
                                    color: '#f2f4ff',
                                  }}
                                >
                                  {item.optionValues?.map(
                                    (cell: any, idx: number) => (
                                      <Descriptions.Item
                                        key={idx}
                                        label={cell.keyword}
                                      >
                                        {cell.value}
                                      </Descriptions.Item>
                                    )
                                  )}
                                </Descriptions>
                              </div>
                            </Descriptions.Item>,
                          ];
                        }
                        return (
                          <Descriptions.Item label={item.keyword} key={index}>
                            {item.value}
                          </Descriptions.Item>
                        );
                      }
                      return (
                        <Descriptions.Item label={item.keyword} key={index}>
                          {item.value}
                        </Descriptions.Item>
                      );
                    }
                  )}
                </Descriptions>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitPay;
