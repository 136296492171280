import { EllipsisOutlined } from '@ant-design/icons';
import { Modal, Popover, Tooltip } from 'antd';
import cs from 'classnames';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Btn from '../../../../components/btn';
import { transImage } from '../../../../utils/index';
import { getProviderFabricDetail } from '../../../fabric-design-operate/api';
import { getColors } from '../colorSelect/api';
import { matchFabric } from './api';
import { Category } from './enum';

import s from './style.module.scss';

// const { confirm } = Modal;

type Props = {
  children: React.ReactNode;
  editItem: any;
};

type ColorItem = {
  code: string;
  id: string;
  hex: string;
  [key: string]: string;
};

const AreaFabric: FC<Props> = ({ children, editItem }) => {
  const navigate = useNavigate();
  const dom = useRef<any>();
  // const [page, setPage] = useState(1);
  // const [total, setTotal] = useState(0);

  const [list, setList] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [textArr, setTextArr] = useState<string[]>([
    '识别此设计图中面料，点击开',
    '始匹配开始比对',
  ]);
  const [colors, setColors] = useState<any[]>([]);
  const [colorItem, setItem] = useState<null | ColorItem>(null);
  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [fabricDetail, setFabricDetail] = useState<any>();

  const getFabricDetail = (id: number) => {
    getProviderFabricDetail(id).then((res: any) => setFabricDetail(res));
  };

  const content = useMemo(() => {
    return (
      <div className={s.content}>
        <div className={s['name-list']}>
          <div className={s['name-item']}>
            <div className={s.name}>工厂名称</div>
            <div className={s.value}>{fabricDetail?.companyName}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料货号</div>
            <div className={s.value}>{fabricDetail?.itemNo}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料名称</div>
            <div className={s.value}>{fabricDetail?.name}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料分类</div>
            <div className={s.value}>{Category[fabricDetail?.type]}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>面料成分</div>
            <div className={s.value}>{fabricDetail?.composition}</div>
          </div>
          <div className={s['name-item']}>
            <div className={s.name}>有效幅宽</div>
            <div className={s.value}>{fabricDetail?.validWidth}</div>
          </div>
        </div>
        <Btn className={s.button} onClick={() => navigate('/service')}>
          联系客服购买
        </Btn>
      </div>
    );
  }, [fabricDetail]);

  useEffect(() => {
    setList([]);
    setLoading(false);
    setTextArr(['识别此设计图中面料，点击开', '始匹配开始比对']);
  }, [editItem]);

  useEffect(() => {
    getColors()
      .then((res) => {
        setColors(res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const modal = (
    <Modal
      width={440}
      centered
      open={open}
      closeIcon={false}
      onCancel={() => setOpen(false)}
      title={
        <div className={s['tip-title']}>
          <span className="font_family icon-a-tishidanchuangtishi"></span>提示
        </div>
      }
      footer={
        <div className={s['tip-footer']}>
          <Btn
            className={s.button}
            onClick={() => {
              setOpen(false);
            }}
          >
            确认
          </Btn>
        </div>
      }
    >
      <div className={s['tip-content']}>
        获取更多面料信息请
        <Tooltip
          trigger="click"
          title="客服电话：0571-88761133"
          placement="bottom"
        >
          <span className={s.service}>联系客服</span>
        </Tooltip>
      </div>
    </Modal>
  );

  return (
    <div className={s.container}>
      {list.length === 0 ? (
        <div className={s.empty}>
          {textArr.map((text: string) => (
            <div key={text}>{text}</div>
          ))}
        </div>
      ) : (
        <div className={s.list} ref={dom}>
          {list.map((item: any, ind: number) => (
            <div className={s.item} key={`${item.id}_${ind}`}>
              <div className={s['image-wrap']}>
                <img src={transImage(item.imageUrl)} />
                <Popover
                  content={content}
                  placement="bottomRight"
                  arrow={false}
                  color="#1F2022"
                  overlayClassName={s['fabric-tip-container']}
                  trigger="click"
                  getTooltipContainer={() => dom.current}
                >
                  <div className={s.icon}>
                    <EllipsisOutlined
                      onClick={() => {
                        getFabricDetail(item.id);
                      }}
                    />
                  </div>
                </Popover>
              </div>
              <div className={s.info}>
                <span>匹配度:</span>
                <span className={s.percent}>
                  {((item.rate ?? 0) * 50).toFixed(0)}%
                </span>
              </div>
            </div>
          ))}
          <div className={s.pages}>
            {/* <div className={s['arrow-wrap']}>
              {page > 1 ? (
                <span
                  className={cs(s.page, 'font_family', 'icon-a-fanyeshangyiye')}
                  onClick={() => setPage(page - 1)}
                ></span>
              ) : null}
            </div>
            <div className={s['arrow-wrap']}>
              {page < total ? (
                <span
                  className={cs(s.page, 'font_family', 'icon-a-fanyexiayiye')}
                  onClick={() => setPage(page + 1)}
                ></span>
              ) : null}
            </div> */}
          </div>
        </div>
      )}
      <Modal
        width={440}
        centered
        open={modal2}
        closeIcon={false}
        onCancel={() => setModal2(false)}
        title={
          <div className={s['tip-title']}>
            <span className="font_family icon-a-tishidanchuangtishi"></span>提示
          </div>
        }
        footer={
          <div className={s['tip-footer']}>
            <Btn
              buttonType="goast"
              className={s.button}
              onClick={async () => {
                setModal2(false);
                setLoading(true);
                try {
                  const result = await await matchFabric({
                    colorCode: editItem?.colorCode,
                    promptId: editItem?.transferPrompt,
                  });
                  if (result?.length) {
                    setList(result);
                  } else {
                    setTextArr(['未匹配到合适面料', '请重新选择图片']);
                  }
                } finally {
                  setLoading(false);
                }
              }}
            >
              不选择
            </Btn>
            <Btn
              className={s.button}
              onClick={() => {
                setModal2(false);
                setModal3(true);
                setItem(null);
              }}
            >
              选择色板
            </Btn>
          </div>
        }
      >
        <div className={s['tip-content']}>
          <div className={s.text}>
            未识别到作品面料信息，可能会影响匹配结果，是否选择面料颜色?
          </div>
        </div>
      </Modal>

      <Modal
        open={modal3}
        width={384}
        centered
        onCancel={() => setModal3(false)}
        footer={
          <div className={s.footer2}>
            <Btn
              className={s.match}
              onClick={async () => {
                setModal3(false);
                setLoading(true);
                try {
                  const result = await matchFabric({
                    colorCode: colorItem?.code,
                    promptId: editItem?.transferPrompt,
                  });
                  if (result?.length) {
                    setList(result);
                  } else {
                    setTextArr(['未匹配到合适面料', '请重新选择图片']);
                  }
                } finally {
                  setLoading(false);
                }
              }}
            >
              开始匹配
            </Btn>
          </div>
        }
      >
        <div className={s['colors-container']}>
          <div className={s.title}>色板</div>
          <div className={s['colors-wrap']}>
            <div className={s.colors}>
              {colors.map((item: ColorItem) => (
                <div
                  key={item.id}
                  className={cs(s.color, {
                    [s.active]: colorItem?.code === item.code,
                  })}
                  style={{
                    backgroundColor: `#${item?.hex}`,
                  }}
                  onClick={() => setItem(item)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </Modal>

      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          loading,
          onClick: async () => {
            const canNext = await child.props?.onClick?.();
            if (!canNext) return;
            if (list.length) {
              return setOpen(true);
            }
            if (!editItem.colorCode) {
              return setModal2(true);
            }
            const cb = async () => {
              setLoading(true);
              try {
                const result = await matchFabric({
                  colorCode: colorItem?.code,
                  promptId: editItem?.transferPrompt,
                });
                if (result?.length) {
                  setList(result);
                } else {
                  setTextArr(['未匹配到合适面料', '请重新选择图片']);
                }
              } finally {
                setLoading(false);
              }
            };
            cb();
          },
          children: list.length ? '完成' : '开始匹配',
        });
      })}
      {modal}
    </div>
  );
};

export default AreaFabric;
