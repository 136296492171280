import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, message, Modal, Select } from 'antd';
import cs from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Btn from '../../components/btn';
import ImageEditor from '../../components/image-editor';
import PageLayout from '../../components/layout/page-layout';
import { useImageEditor } from '../../contexts/image-editor';
import { useOperateInteraction } from '../../contexts/operate-interaction';
import { useRealtimeDrawing } from '../../contexts/realtime-draw';
import useWindowWidth from '../../hooks/useWindowWidth';
import { drawImageSave } from './api';

import s from './style.module.scss';

const { TextArea } = Input;

const RealtimeDrawing: FC = () => {
  const {
    prevUrl,
    setPrevUrl,
    bgUrl,
    setBgUrl,
    prompt,
    setPrompt,
    currentItem,
    setCurrentItem,
    geneBase64,
    // saveBase64,
    setSaveBase64,
    loading,
    promptIdentity,
    startGenerate,
    result,
    savedUrl,
    setSavedUrl,
  } = useRealtimeDrawing();

  const { setHistory, setCurrentIndex } = useImageEditor();
  const { isPad } = useWindowWidth();

  const dom = useRef<any>();
  const navigate = useNavigate();
  const { setEditItem } = useOperateInteraction();
  const [saveLoading, setSaveLoading] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);
  const [navigateUrl, setNavigateUrl] = useState('');

  const saveImageApi = (params: any) => {
    setSaveLoading(true);
    return drawImageSave(params)
      .then((data: any) => {
        setSavedUrl(data?.url);
        setSaveBase64(params.base64);
        setSaveLoading(false);
        return message.success('保存成功');
      })
      .catch(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    if (result) {
      const url = result?.resultUrls?.[0];
      setPrevUrl(url);
    }
  }, [result]);

  const modal = (
    <Modal
      width={440}
      centered
      open={tipOpen}
      closeIcon={false}
      onCancel={() => setTipOpen(false)}
      title={
        <div className={s['tip-title']}>
          <span className="font_family icon-a-tishidanchuangtishi"></span>提示
        </div>
      }
      footer={
        <div className={s['tip-footer']}>
          <Btn
            className={s.button}
            buttonType="goast"
            onClick={() => {
              setTipOpen(false);
            }}
          >
            取消
          </Btn>
          <Btn
            className={s.button}
            onClick={() => {
              setTipOpen(false);
              if (!prevUrl) return;
              if (savedUrl !== prevUrl) {
                saveImageApi({
                  base64: geneBase64,
                  promptIdentity,
                  resultUrl: prevUrl,
                });
              }
              setEditItem({ url: prevUrl });
              navigate(navigateUrl);
            }}
          >
            确认
          </Btn>
        </div>
      }
    >
      <div className={s['tip-content']}>确定把当前作品发送到局部改款</div>
    </Modal>
  );

  return (
    <PageLayout
      left={
        <div className={s.container}>
          <div className={s['preview-container']}>
            <div className={s['preview-img-container']}>
              <img src={prevUrl} />
              {loading ? (
                <div className={s['loading-mask']}>
                  <LoadingOutlined
                    style={{
                      color: '#6155FF',
                      fontSize: 40,
                      fontWeight: 'bold',
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className={s.footer}>
              <div className={s['left-con']}>
                <div
                  className={cs(s['output-to-input'], {
                    [s.disabled]: !prevUrl || saveLoading,
                  })}
                  onClick={() => {
                    if (prevUrl) {
                      setBgUrl(prevUrl as any);
                      setHistory([]);
                      setCurrentIndex(0);
                    } else {
                      return message.warning('请先生成图片');
                    }
                  }}
                >
                  <span className="font_family icon-huihuachuangkuan-yinyongzuopindaohuabu"></span>
                  <span>引用作品到画布</span>
                </div>
                <div className={s['select-con']}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          optionFontSize: 12,
                          optionActiveBg: '#212126',
                        },
                      },
                      token: {
                        fontSize: 12,
                        colorBgElevated: '#18181c',
                        colorBgContainerDisabled: '#18181c',
                        colorTextPlaceholder:
                          !prevUrl || saveLoading ? '#4b4e57' : '#f2f4ff',
                      },
                    }}
                  >
                    <div ref={dom}>
                      <Select
                        disabled={!prevUrl || saveLoading}
                        value={null}
                        bordered={false}
                        placeholder="发送到"
                        options={[
                          {
                            value: '/generate/areaModify?tab=1',
                            label: '局部改款',
                          },
                        ]}
                        suffixIcon={<CaretDownOutlined />}
                        style={{
                          width: '100px',
                          height: '40px',
                          fontSize: '12px',
                        }}
                        onSelect={(val: any) => {
                          setNavigateUrl(val);
                          setTipOpen(true);
                        }}
                        getPopupContainer={() => dom.current}
                      />
                    </div>
                  </ConfigProvider>
                </div>
              </div>

              <div className={s.buttons}>
                <Btn
                  className={s['save-button']}
                  disabled={!prevUrl || saveLoading}
                  buttonType="goast"
                  onClick={() => {
                    // 没有绘制出新的图片
                    console.log('新老图片是否相同', savedUrl === prevUrl);
                    // if (geneBase64 === saveBase64) {
                    if (savedUrl === prevUrl) {
                      return message.warning('已保存');
                    }
                    saveImageApi({
                      base64: geneBase64,
                      promptIdentity,
                      resultUrl: prevUrl,
                    });
                  }}
                >
                  保存图片
                </Btn>
              </div>
            </div>
          </div>
          <div className={s['editor-container']}>
            <ImageEditor
              key={bgUrl}
              bgUrl={bgUrl}
              width={isPad ? 360 : 460}
              height={isPad ? 360 : 460}
              setBgUrl={setBgUrl}
              onFinish={(imgBase64: string, index: number) => {
                setCurrentItem({
                  imgBase64,
                  index,
                });
              }}
            ></ImageEditor>
            <div className={s['textarea-container']}>
              <ConfigProvider
                theme={{
                  token: {
                    colorTextPlaceholder: '#686b7a',
                    colorText: '#f2f4ff',
                  },
                }}
              >
                <TextArea
                  className={s.textarea}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  value={prompt}
                  onChange={(e: any) => setPrompt(e.target.value)}
                  placeholder="请输入描述词，词与词之间请用“逗号”隔开"
                  maxLength={500}
                />
              </ConfigProvider>

              <Btn
                disabled={!currentItem?.imgBase64}
                loading={loading}
                onClick={() => {
                  startGenerate();
                }}
                className={s.gene}
              >
                {loading ? '生成中' : '确认生成'}
              </Btn>
            </div>
          </div>
          {modal}
        </div>
      }
      width="0px"
    ></PageLayout>
  );
};

export default RealtimeDrawing;
